<template>
  <div>
    <!--這邊是電腦版網頁的NarBar(導覽列)-->
    <el-menu mode="horizontal" default-active="1" :router="true" v-if="!$device.mobile">
      <el-menu-item>
        <router-link :to="{ path: 'data' }">
          <!--img class="nav-logo" :src="require('@/assets/logo.png')" /-->
          <img v-if="thsrRole" class="nav-thsr-logo" :src="require('@/assets/thsr.png')" />
          <img v-else class="nav-logo" :src="require('@/assets/logo.png')" />
        </router-link>
      </el-menu-item>
      <!--讓layout元件後置的主要原因-->
      <div class="is-flex justify-end">
        <el-menu-item v-if="useChanger">
          <a 
            v-if="changerType==='storm'"
            @click="test"
            href="https://thsr-rain.web.app/data"
          >
            <!--img class="nav-logo" :src="require('@/assets/暴雨預警系統切換用圖.png')" /-->
            <font-awesome-icon icon="cloud-showers-heavy"></font-awesome-icon>
            {{ $t('navbar.storm') }}
          </a>
          <a 
            v-else-if="changerType==='slope'"
            @click="test"
            href="https://thsr-slope.web.app/data"
          >
            <font-awesome-icon icon="mountain"></font-awesome-icon>
            {{ $t('navbar.slope') }}
          </a>
        </el-menu-item>
        <!--切換專案的下拉式選單-->
        <el-menu-item v-if="projectList.length > 1 && isAuthenticated">
          <el-dropdown
            @command="handleProjectChange"
            placement="bottom"
            trigger="hover"
          >
            <div class="flex-row align-center">
              <font-awesome-layers class="icon-margin-right fa-lg">
                <font-awesome-icon icon="building"></font-awesome-icon>
              </font-awesome-layers>
              <span class="el-dropdown-link text-one-line" style="max-width:150px;">{{
                selectedProject.project_id || selectedProject.name
              }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu class="dropdown-menu__project" slot="dropdown">
              <el-dropdown-item
                v-for="project in projectList"
                :key="project._id"
                :command="project._id"
                >{{ project.project_id || project.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
        <!--切換語言的下拉式選單-->
        <el-menu-item>
          <el-dropdown @command="handleLocaleChange">
            <div class="flex-row align-center">
              <font-awesome-layers class="icon-margin-right fa-lg">
                <font-awesome-icon icon="globe-asia"></font-awesome-icon>
              </font-awesome-layers>
              <span class="el-dropdown-link" style="cursor: pointer;">
                {{ $t(`lang.${$i18n.locale}`) }}
              </span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="locale in localeList" :key="locale" :command="locale">{{
                $t(`lang.${locale}`)
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
        <!--更改密碼-->
        <el-menu-item v-if="isAuthenticated" @click="edit">
          <font-awesome-layers class="icon-margin-right fa-lg">
            <font-awesome-icon icon="user-edit"></font-awesome-icon>
          </font-awesome-layers>
          <span>{{ $t('login.title_edit') }}</span>
        </el-menu-item>
        <!--登出按鈕-->
        <el-menu-item v-if="isAuthenticated" @click="logout">
          <font-awesome-layers class="icon-margin-right fa-lg">
            <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
          </font-awesome-layers>
          <span>{{ $t('navbar.logout') }}</span>
        </el-menu-item>
      </div>
    </el-menu>

    <!--這邊是手機版網頁的NavBar-->
    <nav class="navbar-container" v-else>
      <nav class="navbar">
        <div class="navbar-brand">
          <router-link class="navbar-item has-background-white" :to="{ path: 'data' }">
            <!--img :src="require('@/assets/logo.png')" /-->
            <img :src="(thsrRole) ? require('@/assets/thsr.png') : require('@/assets/logo.png')" />
          </router-link>
          <div v-if="useChanger" class="navbar-item has-background-white">
            <a 
              v-if="changerType==='storm'"
              @click="test"
              href="https://thsr-rain.web.app/data"
            >
              <!--img class="nav-logo" :src="require('@/assets/暴雨預警系統切換用圖.png')" /-->
              <font-awesome-icon icon="cloud-showers-heavy"></font-awesome-icon>
              {{ $t('navbar.storm') }}
            </a>
            <a 
              v-else-if="changerType==='slope'"
              @click="test"
              href="https://thsr-slope.web.app/data"
            >
              <font-awesome-icon icon="mountain"></font-awesome-icon>
              {{ $t('navbar.slope') }}
            </a>
          </div>
          <a
            class="navbar-burger"
            role="button"
            @click="toggleNavBurger"
            :class="{ 'is-active': showNav }"
            aria-label="menu"
            aria-expanded="false"
          >
            <div class="is-flex justify-center align-center" style="height: 100%;">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </a>
        </div>
      </nav>
      <div class="side-panel has-background-white" v-if="showNav">
        <div class="side-panel--list">
          <ul class="menu-list">
            <!--語言選擇-->
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link is-flex align-center" @click="showLanguage = !showLanguage">
                <font-awesome-layers class="icon-margin-right fa-md">
                  <font-awesome-icon icon="globe-asia"></font-awesome-icon>
                </font-awesome-layers>
                <span class="is-uppercase">{{ $t(`lang.${$i18n.locale}`) }}</span>
              </a>
              <div class="navbar-dropdown" v-if="showLanguage">
                <a
                  v-for="locale in localeList"
                  class="navbar-item is-uppercase"
                  :class="{ 'is-active': locale === selectedLanguage }"
                  :key="locale"
                  @click="handleLocaleChange(locale)"
                  >{{ $t(`lang.${locale}`) }}</a
                >
              </div>
            </div>
            <!--專案選擇-->
            <div
              class="navbar-item has-dropdown is-hoverable"
              v-if="projectList.length > 1 && isAuthenticated"
            >
              <a class="navbar-link is-uppercase" @click="showProjects = !showProjects">
                <font-awesome-layers class="icon-margin-right fa-md">
                  <font-awesome-icon icon="building"></font-awesome-icon>
                </font-awesome-layers>
                <span>{{ selectedProject.project_id || selectedProject.name }}</span>
              </a>
              <div class="navbar-dropdown" v-if="showProjects">
                <a
                  class="navbar-item is-uppercase"
                  v-for="project in projectList"
                  :key="project._id"
                  :class="{ 'is-active': project._id === selectedProject._id }"
                  @click="handleProjectChange(project._id)"
                  >{{ project.project_id || project.name }}</a
                >
              </div>
            </div>
            <!--分隔線-->
            <div class="navbar-item" v-if="isAuthenticated">
              <div class="divider"></div>
            </div>
            <!--切換邊坡/暴雨系統-->
            <div class="navbar-item has-dropdown is-hoverable" v-if="isAuthenticated && thsrRole">
              <a class="navbar-link is-flex align-center" @click="showRouteTpyeSelector = !showRouteTpyeSelector">
                <font-awesome-layers class="icon-margin-right fa-md">
                  <font-awesome-icon :icon="selectedRouteTypeIcon"></font-awesome-icon>
                </font-awesome-layers>
                <span class="is-uppercase">{{ $t(`sidebar.${selectedRouteType}`) }}</span>
              </a>
              <div class="navbar-dropdown" v-if="showRouteTpyeSelector">
                <a
                  v-for="routeType in routeTypeList"
                  class="navbar-item is-uppercase"
                  :class="{ 'is-active': routeType === selectedRouteType }"
                  :key="routeType"
                  @click="handleRouteTpyeChange(routeType)"
                  >{{ $t(`sidebar.${routeType}`) }}</a
                >
              </div>
            </div>
            <!--分隔線-->
            <div class="navbar-item" v-if="isAuthenticated && thsrRole">
              <div class="divider"></div>
            </div>
            <!--選擇版面內容-->
            <div v-if="isAuthenticated">
              <router-link
                class="navbar-item is-uppercase"
                :to="route.path"
                v-for="route in routeList"
                :key="route.path"
                :class="{ 'is-active': route.path === $route.path }"
              >
                <span>{{ $t(`sidebar.${route.name}`) }}</span>
              </router-link>
            </div>
            <!--分隔線-->
            <div class="navbar-item" v-if="isAuthenticated">
              <div class="divider"></div>
            </div>
            <!--修改帳號密碼-->
            <div
              class="navbar-item is-uppercase is-primary has-text-grey-light"
              v-if="isAuthenticated"
              @click="edit"
            >
              {{ $t('login.title_edit') }}
            </div>
            <!--登出按鈕-->
            <div
              class="navbar-item is-uppercase is-primary has-text-grey-light"
              v-if="isAuthenticated"
              @click="logout"
            >
              {{ $t('navbar.logout') }}
            </div>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { navbar, clientVersion } from '@/constants/env';
export default {
  data() {
    return {
      useChanger: navbar.useChanger,
      changerType: navbar.changerType,
      showNav: false,
      showProjects: false,
      showLanguage: false,
      showRouteTpyeSelector: false,
      defaultRouteList: [
        {
          path: '/dashboard',
          name: 'dashboard',
          icon: 'tachometer-alt',
        },
        {
          path: '/data',
          name: 'data',
          icon: 'table',
        },
        {
          path: '/chart',
          name: 'chart',
          icon: 'chart-line',
        },
        {
          path: '/curve',
          name: 'curve',
          icon: 'chart-line',
        },
        {
          path: '/map',
          name: 'map',
          icon: 'map-marked-alt',
        },
        {
          path: '/layout',
          name: 'layout',
          icon: 'info-circle',
        },
        {
          path: '/contact',
          name: 'contact',
          icon: 'envelope',
        },
        {
          path: '/monitor',
          name: 'monitor',
          icon: 'camera',
        },
      ],
      slopeRouteList: [
        {
          path: '/dashboard',
          name: 'dashboard',
          icon: 'tachometer-alt',
        },
        {
          path: '/slope/data',
          name: 'data',
          icon: 'table',
        },
        {
          path: '/slope/chart',
          name: 'chart',
          icon: 'chart-line',
        },
        {
          path: '/slope/curve',
          name: 'curve',
          icon: 'chart-line',
        },
        {
          path: '/slope/map',
          name: 'map',
          icon: 'map-marked-alt',
        },
        {
          path: '/slope/layout',
          name: 'layout',
          icon: 'info-circle',
        },
        {
          path: '/slope/export',
          name: 'export',
          icon: 'file-excel',
        },
        {
          path: '/contact',
          name: 'contact',
          icon: 'envelope',
        },
        {
          path: '/monitor',
          name: 'monitor',
          icon: 'camera',
        },
        {
          path: '/rank',
          name: 'rank',
          icon: 'chart-bar',
        },
        {
          path: '/alerts',
          name: 'alerts',
          icon: 'bell',
        },
      ],
      stormRouteList: [
        {
          path: '/storm/summary',
          name: 'summary',
          icon: 'table',
        },
        {
          path: '/storm/data',
          name: 'data',
          icon: 'table',
        },
        {
          path: '/storm/chart',
          name: 'chart',
          icon: 'chart-line',
        },
        {
          path: '/storm/snake-line',
          name: 'snakeLine',
          icon: 'chart-line',
        },
        {
          path: '/storm/export',
          name: 'export',
          icon: 'file-excel',
        },
        {
          path: '/rank',
          name: 'rank',
          icon: 'chart-bar',
        },
      ],
      selectedRouteType: 'slope',
    };
  },
  watch: {
    $route() {
      this.showNav = false;
      this.showProjects = false;
      this.showLanguage = false;
    },
    showNav(val) {
      if (val) {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
      } else {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
      }
    },
  },
  computed: {
    thsrRole() {
      return clientVersion === 'thsr-main' || clientVersion === 'thsr-rain' || clientVersion === 'test';
    },
    isAuthenticated() {
      return this.$store.getters['user/isAuthenticated'];
    },
    projectList() {
      return this.$store.getters['project/projectList'];
    },
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    selectedLanguage() {
      return this.$i18n.locale;
    },
    localeList() {
      return Object.keys(this.$i18n.messages);
    },
    routeList() {
      if(!this.thsrRole) return this.defaultRouteList;
      else if(this.selectedRouteType === 'slope') return this.slopeRouteList;
      else if(this.selectedRouteType === 'storm') return this.stormRouteList;
      else return this.defaultRouteList;
    },
    routeTypeList() {
      return ['storm', 'slope'];
    },
    selectedRouteTypeIcon(){
      if(this.selectedRouteType === 'slope') return 'mountain';
      else if(this.selectedRouteType === 'storm') return 'cloud-showers-heavy';
      else return 'tachometer-alt';
    },
  },
  methods: {
    handleLocaleChange(lang) {
      this.$i18n.locale = lang;
      this.$store.commit('env/setLocale', lang);
      if (this.$device.mobile) {
        this.toggleNavBurger();
      }
    },
    handleRouteTpyeChange(routeType){
      this.selectedRouteType = routeType;
    },
    logout() {
      this.$store
        .dispatch('user/logoutUser')
        .then(() => {
          this.$notifySuccess('登出成功');
        })
        .catch(error => this.$notifyError('登出失敗', error.response.data.message));
    },
    edit() {
      this.$router.push({ name: 'client' });
    },
    toggleNavBurger() {
      if (this.showNav) {
        this.showNav = false;
        this.showProjects = false;
        this.showLanguage = false;
      } else {
        this.showNav = true;
      }
    },
    handleProjectChange(projectId) {
      this.$store.dispatch('project/setSelectedProject', projectId);
      this.$router.go(0);
    },
    test(){
      document.cookie = 'cookieTest=testnow';
      console.log(document.cookie);
    },
  },
};
</script>

<style lang="css">
.dropdown-menu__project {
  overflow: auto;
  max-height: calc(100% - 100px);
}
</style>

<style lang="stylus">
.desktop-mode
  .dropdown-menu__project
    max-width 200px
    .el-dropdown-menu__item
      text-overflow ellipsis
      white-space nowrap
      overflow hidden
  .el-menu.el-menu--horizontal
    border-bottom 0
  .el-menu--horizontal>.el-menu-item.is-active
    border-bottom 0 !important
  .nav-logo
    height 36px
    padding 5px 0
    object-fit contain
  .nav-thsr-logo
    height 60px
    padding 5px 0
    object-fit contain
.mobile-mode
  .navbar-brand
    border-bottom 1px solid #eee
  .divider
    height 1.5px
    width 100%
    background-color #eee
    margin 5px 0
  .side-panel
    width 100%
    z-index 2001
    position absolute
    height 100vh
    top 3.25rem
    left 0
    border-top 1px solid #eee
    overflow auto
    .side-panel--header
      height 60px
    .side-panel--list
      padding 0.75rem
  .navbar-container
    display flex
    top 0
    left 0
    right 0
    .navbar
      flex-grow 1
  .navbar-item, .navbar-link
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
  .nav-title
    display flex
    align-content center
  .nav-logo
    height 60px
    padding 16px 0
    object-fit contain
</style>
