<template>
  <div style="height:100%">
    <el-form 
      class= "columns is-multiline"
      label-position="top"
      label-width="80px"
    >
      <div class="column" :style="{ height:`${44}px` }">
        <div class="Time">{{getTime}}</div>
        <div class="setting"
          v-if="!$device.mobile"
        >
          <el-button
            type="primary"
            size="small"
            @click="showSettings = true"
          >
            <font-awesome-icon icon="cog" />
          </el-button>
        </div>
        <el-drawer
          :title="$t('chart.settings')" 
          :modal="false"
          :visible.sync="showSettings"
          :size="'35%'"
        >
        <setting-panel
          :dashboardSetting="testtable"
          :projectOptions="projectOptions"
          :voltageOptions="voltageOptions"
          :imageList="imageList"
          :monitorList="monitorList"
          @SubmitUserSetting="SubmitUserSetting"
          @SubmitProjectSetting="SubmitProjectSetting"
        />
        </el-drawer>
      </div>
      <div v-for="(test,index) in testtable" :key="test" class="column" :style="{ width:`${test.width}%`,flex: 'none' } ">
        <div v-if="test.showType == 'project_name'">
          <el-card class="flex-row align-center justify-center">
            {{selectedProject.name}}
          </el-card>
        </div>
        <div v-else-if="test.showType == 'chart'">
          <mini-chart
            :selectedInstruments="getInstrument(index)"
            :instrumentOptions="instrumentOptions"
          />
        </div>
        <div v-else-if="test.showType == 'layout'">
          <simple-layout :imageIndex="test.data" :imageList="imageList"></simple-layout>
        </div>
        <div v-else-if="test.showType == 'voltage'">
          <voltage-guage 
            :selectedInstrument="voltageGaugeInstrument[index]"
          />
        </div>
        <div v-else-if="test.showType == 'richtext'">
          <rich-text
           @editing="setEditing"
          />
        </div>
        <div v-else-if="test.showType == 'CCTV'">
          <Monitor
            :selectedMonitor="monitor[index]"
          />
        </div>
        <div v-else-if="test.showType == 'test_module'">
          <TestModule></TestModule>
        </div>
        <div v-else>
          <el-card>
            {{ $t('dashboard.noneModuleType') }}
          </el-card>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import MiniChart from '../components/dashboard/MiniChart.vue';
import SettingPanel from '../components/dashboard/SettingPanel.vue';
import SimpleLayout from '../components/dashboard/Layout.vue';
import VoltageGuage from '../components/dashboard/VoltageGuage.vue';
import RichText from '../components/dashboard/RichText.vue';
import Monitor from '../components/dashboard/Monitor.vue';
import TestModule from '../components/dashboard/TestModule.vue';
import MomentTimeZone from 'moment-timezone';
import moment from 'moment';
window.moment = moment;
MomentTimeZone();

export default{
  components: {
    MiniChart,
    SettingPanel,
    SimpleLayout,
    VoltageGuage,
    RichText,
    TestModule,
    Monitor,
  },
  data() {
    return {
      time : null,
      intervalPause: false,
      editing: false,
      testtable : [],
      instruments : {},
      projectInstrumentList : null,
      showSettings : false,
      instrumentOptions: [],
      projectOptions: [],
      voltageOptions: [],
      imageList: [],
      reflashFunction: null,
      voltageGaugeInstrument: [],
      monitorList: [],
      monitor: [],
      projectPath: '',
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    getTime(){
      if(!this.time) return '';
      return this.time;
    },
  },
  async mounted() {
    if (this.selectedProject._id === '633d2757a4fbd61d20ee34ae') {
      this.$router.replace({ name: 'Dashboard' });
    } else {
      this.initCascaderSelector();
      this.initSimpleLayout();
      this.time = new Date().toLocaleString();

      this.intervalPause = false;
      this.reflashFunction = setInterval(() => {
        //console.log(`pause : ${this.intervalPause}, editing : ${this.editing}`);
        if(!this.intervalPause && !this.editing){
          this.initTable();
          this.time = new Date().toLocaleString();
          //console.log(`test : ${new Date()}`);
        }
      }, 1000 * 60 * 5);

      
      window.onblur=function(){
        this.intervalPause = true;
      };
      window.onfocus=function(){
        this.intervalPause = false;
      };
    }
  },
  destroyed() {
    window.onblur = null;
    window.onfocus = null;
  },
  methods: {
    initCascaderSelector(){
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/area-station-instrument`,
      })
        .then(res => {
          this.projectInstrumentList = res;
          const obj = res.data.area.map(area => {
            const obj = {
              label: area.name,
              disabled: area.station.length === 0,
              children: area.station.map(station => {
                const obj = {
                  label: station.name,
                  disabled: station.instrument.length === 0,
                  children: station.instrument.map(instrument => {
                    if (
                      instrument.instrument_type &&
                      instrument.instrument_type.type === 'rainGauge'
                    ) {
                      const hourInterval = moment
                        .duration(instrument.instrument_type.defaultInterval)
                        .hours();
                      const minuteInterval = moment(instrument.instrument_type.defaultInterval)
                        .subtract(hourInterval, 'hour')
                        .minute();
                      instrument.instrument_type.hourInterval = hourInterval;
                      instrument.instrument_type.minuteInterval = minuteInterval;
                      instrument.instrument_type.defaultHourInterval = hourInterval;
                      instrument.instrument_type.defaultMinuteInterval = minuteInterval;
                      // if (hourInterval > 0) {
                      //   instrument.instrument_type.isHour = true;
                      // } else {
                      //   instrument.instrument_type.isHour = false;
                      // }
                    }
                    const obj = {
                      label: instrument.latestVersion.name,
                      value: instrument._id,
                    };
                    this.instrumentOptions.push(instrument);
                    if(instrument.latestVersion.unit=='V' || instrument.latestVersion.unit=='v' || instrument.latestVersion.unit == 'Vdc'){
                      this.voltageOptions.push({
                        value: instrument._id,
                        label: instrument.latestVersion.name,
                      });
                    }
                    return obj;
                  }),
                };
                return obj;
              }),
            };
            return obj;
          });
          //this.projectOptions = [{
          //  children: obj,
          //  label: 'all',
          //}];
          this.projectOptions = obj;
          this.initTable();
        })
        .catch(error => {
          console.log(error);
          this.$notifyError(this.$t('chart.error.loading'), error.response.data.message);
        });
    },
    initTable() {
      //this.testtable
      return this.$axios({
        category: 'user',
        method: 'get',
        apiName: `${this.$store.getters['user/details']._id}/${this.selectedProject._id}/getDashboardSetting`,
      })
        .then(res => {
          this.testtable = res.data;
          this.initChart();
          this.initGauge();
          this.initMonitor();
          this.initSimpleLayout();
        })
        .catch(error => {
          console.log(error);
          this.$notifyError(this.$t('chart.error.loading'), error.response.data.message);
        });
    },
    initChart() {
      this.instruments = {};
      for(let count in this.testtable){
        if(this.testtable[count].showType === 'chart') {
          let instrument = this.getInstrumentsList(this.testtable[count]);
          this.instruments[count] = instrument;
        }
      }
    },
    initGauge(){
      this.voltageGaugeInstrument = [];
      for(let count in this.testtable){
        if(this.testtable[count].showType === 'voltage') {
          this.voltageGaugeInstrument[count] = this.testtable[count].data;
        }
      }
    },
    initMonitor(){
      this.projectPath = `${process.env.VUE_APP_IMAGE_BASE_URL}/monitor/${this.selectedProject.project_id}`;
      // monitor list為所有可顯示的監視器類表，用於設定與讀取設定
      this.monitorList = [];
      // monitor 為所有預計顯示的監視器類表
      this.monitor = [];

      try{
        this.$axios({
          category: 'project',
          method: 'get',
          apiName: `${this.selectedProject._id}/getMonitorImage`,
        }).then(res=>{
          let images = res.data.images;
          if(images){
            this.monitorList = images.map(ele=>{
              //console.log(`${this.projectPath}/${ele.name}`);
              let time = ele.time;
              let y = time.substring(0,4);
              let M = time.substring(4,6);
              let d = time.substring(6,8);
              let h = time.substring(8,10);
              let m = time.substring(10,12);
              let s = time.substring(12);
              return {
                url: `${this.projectPath}/${ele.name}`,
                content: ele.content,
                uptime: moment([y,M,d,h,m,s]).format('YYYY-MM-DD hh:mm'),
              };
            });
          }
          for(let count in this.testtable){
            if(this.testtable[count].showType === 'CCTV') {
              // 設定monitor的資料格式為字串，代表監視器的名字
              // 將第一個monitor設為預設值
              this.monitor[count] = this.monitorList[0];
              for(let monitor of this.monitorList){
                if(this.testtable[count].data === monitor.content){
                  this.monitor[count] = monitor;
                  console.log(this.monitor[count]);
                  break;
                }
              }
            }
          }
        });
        
      }
      catch(e){
        console.log(e);
        this.$notifyError();
      }
    },
    initSimpleLayout() {
      this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/image`,
      })
        .then(res => {
          this.imageList = res.data.map(ele => {
            return {
              _id: ele._id,
              name: ele.name,
              // url: ele.path,
              url: `${process.env.VUE_APP_IMAGE_BASE_URL}/layout/${ele.path}`,
              description: ele.description,
              pin: ele.pin,
            };
          });
        })
        .catch(this.$notifyError);
    },
    getInstrumentsList(table){
      if(table.data){
        return this.covertListStringToInstrumentsList(table.data);
      }
      return this.covertListStringToInstrumentsList('0..0..0');
    },
    covertListStringToInstrumentsList(instrumentListString){

      //if is tree string, return tree.
      if(instrumentListString[0] == 't') return this.covertTreeStringToInstrumentsList(instrumentListString);
      //test
      //this.covertTreeStringToInstrumentsList('t0011010001');
      //console.log(instrumentListString);
      let cut = instrumentListString.split('..');
      // cut[0] is 'area' ,cut[1] is 'station' ,cut[2] is 'instrument'
      // if get '*' ,it mean get all.
      const allarea = this.projectInstrumentList.data.area;
      let area = [];
      if(cut[0]==='*'){ //get '*'
        allarea.forEach(element => {
          area.push(element);
        });
      }
      else if(isNaN(cut[0]) || cut[0]>=allarea.length){ // NAN or large than length
        // do nothing to return emtpy list
      }
      else{ // is number and smaller than length
        area.push(allarea[cut[0]]);
      }

      let station = [];

      area.forEach(element=>{
        const allstation = element.station;
        if(cut[1]==='*'){
          allstation.forEach(element=>{
            station.push(element);
          });
        }
        else if(isNaN(cut[1]) || cut[1]>=allstation.length){
          // do nothing to return emtpy list
        }
        else{
          station.push(allstation[cut[1]]);
        }
      });

      let instruments = [];

      station.forEach(element=>{
        const allinstrument = element.instrument;
        if(cut[2]==='*'){
          allinstrument.forEach(element=>{
            instruments.push(element._id);
          });
        }
        else if(isNaN(cut[2]) || cut[2]>=allinstrument.length){
          // do nothing to return emtpy list
        }
        else{
          instruments.push(allinstrument[cut[2]]._id);
        }
      });

      return instruments;
    },
    covertTreeStringToInstrumentsList(instrumentTreeString){
      let cut = instrumentTreeString.split('t');
      let res = [];
      let treeString = this.unzipTree(cut[1]);
      for(let index in this.instrumentOptions){
        if(treeString[index] && treeString[index] == '1') res.push(this.instrumentOptions[index]._id);
      }
      return res;
    },
    getInstrument(index){
      return this.instruments[index];
    },
    SubmitUserSetting(tempTable){
      this.testtable = JSON.parse(JSON.stringify(tempTable));
      for(let i in this.testtable){
        if(this.testtable[i].showType==='chart' && !this.testtable[i].data) this.testtable[i].data = '0..0..0';
        if(this.testtable[i].showType==='layout' && !this.testtable[i].data) this.testtable[i].data = 0;
      }
      this.initChart();
      this.initGauge();
      this.initMonitor();
      return this.$axios({
        category: 'user',
        method: 'put',
        apiName: `${this.$store.getters['user/details']._id}/${this.selectedProject._id}/dashboardSetting`,
        data:{
          dashboard_config:this.testtable,
        },
      });
    },
    SubmitProjectSetting(tempTable){
      this.testtable = JSON.parse(JSON.stringify(tempTable));
      for(let i in this.testtable){
        if(this.testtable[i].showType==='chart') console.log(this.testtable[i].data);
      }
      this.initChart();
      return this.$axios({
        category: 'project',
        method: 'put',
        apiName: `${this.selectedProject._id}/dashboardSetting`,
        data:{
          dashboard_config:this.testtable,
        },
      });
    },
    unzipTree(tree){
      let res = '';
      const cut = tree.split('..');
      const bitCount = cut[0];
      const hexs = cut[1];
      let bits = '';
      for(let i = 0; i < hexs.length; i++){
        let hex = hexs[i];
        switch(hex){
          case '0':
            bits+='0000';
            break;
          case '1':
            bits+='0001';
            break;
          case '2':
            bits+='0010';
            break;
          case '3':
            bits+='0011';
            break;
          case '4':
            bits+='0100';
            break;
          case '5':
            bits+='0101';
            break;
          case '6':
            bits+='0110';
            break;
          case '7':
            bits+='0111';
            break;
          case '8':
            bits+='1000';
            break;
          case '9':
            bits+='1001';
            break;
          case 'a':
            bits+='1010';
            break;
          case 'b':
            bits+='1011';
            break;
          case 'c':
            bits+='1100';
            break;
          case 'd':
            bits+='1101';
            break;
          case 'e':
            bits+='1110';
            break;
          case 'f':
            bits+='1111';
            break;
          default:
            bits+='0000';
            break;
        }
      }
      for(let i = 0; i < bitCount; i++){
        res+=bits[i];
      }
      return res;
    },
    zipTree(tree){
      let res = '';
      const bitCount = tree.length;
      let hexs = '';

      let tmp = '';
      const remain = 4 - tree.length%4;
      for(let i = 0; i < remain; i++){
        tree+='0';
      }
      for(let i = 0; i < tree.length; i++){
        tmp+=tree[i];
        if(tmp.length==4){
          let hex;
          switch(tmp){
            case '0000':
              hex = '0';
              break;
            case '0001':
              hex = '1';
              break;
            case '0010':
              hex = '2';
              break;
            case '0011':
              hex = '3';
              break;
            case '0100':
              hex = '4';
              break;
            case '0101':
              hex = '5';
              break;
            case '0110':
              hex = '6';
              break;
            case '0111':
              hex = '7';
              break;
            case '1000':
              hex = '8';
              break;
            case '1001':
              hex = '9';
              break;
            case '1010':
              hex = 'a';
              break;
            case '1011':
              hex = 'b';
              break;
            case '1100':
              hex = 'c';
              break;
            case '1101':
              hex = 'd';
              break;
            case '1110':
              hex = 'e';
              break;
            case '1111':
              hex = 'f';
              break;
            default:
              hex = 0;
              break;
          }
          hexs+=hex;
          tmp='';
        }
      }
      res = bitCount + '..' + hexs;
      return res;
    },
    setEditing(b){
      this.editing = b;
    },
  },
};
</script>

<style lang="stylus" scoped>
.header
  height: 34.5px
  width: 100%
.Time
  display: flex
  justify-content: center
  align-items: center
  font-size: 12px
  color: black
.setting
  position: relative
  float: right
  //right: 66px
  top: -23.5px
.Title
  display: flex
  justify-content: center
  align-items: center
  font-size: 32px
.specials
  width 50px
</style>