const shapeList = [
  {
    label: '方形',
    value: 'square',
  },
  {
    label: '圓形',
    value: 'circle',
  },
  {
    label: '長方形',
    value: 'rectangle',
  },
  {
    label: '菱形',
    value: 'diamond',
  },
  {
    label: '三角形',
    value: 'triangle',
  },
  {
    label: '星形',
    value: 'star',
  },
  {
    label: '梯形',
    value: 'trapezoid',
  },
  {
    label: '五角形',
    value: 'pentagon',
  },
  {
    label: '六角形',
    value: 'hexagon',
  },
  {
    label: '十字形',
    value: 'cross',
  },
];

const sizeList = [
  {
    label: '小',
    value: 'small',
  },
  {
    label: '中',
    value: 'medium',
  },
  {
    label: '大',
    value: 'large',
  },
];

const borderColorList = [
  {
    label: '白',
    value: 'white',
  },
  {
    label: '黑',
    value: 'black',
  },
];

export { shapeList, sizeList, borderColorList };
