<template>
  <div id="mainContent" style="display: flex; flex-direction: column; height: 90vh;">
    <div style="display: flex; gap: 12px; min-height: 22%;" v-loading="loading">
      <el-card style="flex: 1; min-width: calc(50% - 6px); max-width: 50%; margin-bottom: 12px; min-height: 22%;">
        <div style="display: flex; gap: 12px; margin-bottom: 12px;">
          <div style="display: flex; flex-direction: column;">
            <span>{{ $t('chart.date.pick') }}</span>
            <el-date-picker v-model="selectedDate" type="daterange" align="right" unlink-panels range-separator="-"
              start-placeholder="開始日期" end-placeholder="結束日期" @change="updateOutputList" :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </div>

        <div style="display: flex; gap: 12px; margin-bottom: 12px;">
          <div style="display: flex; flex-direction: column;">
            <span>{{ $t('trajectory.selectInstrumentGroupX') }}</span>
            <el-select v-model="selectedInstrumentGroupX" @change="updateOutputList">
              <el-option v-for="group in instrumentGroupList" :key="group._id" :label="group.name" :value="group._id">
              </el-option>
            </el-select>
          </div>
          <div style="display: flex; flex-direction: column;">
            <span>{{ $t('trajectory.selectInstrumentGroupY') }}</span>
            <el-select v-model="selectedInstrumentGroupY" @change="updateOutputList">
              <el-option v-for="group in instrumentGroupList" :key="group._id" :label="group.name" :value="group._id">
              </el-option>
            </el-select>
          </div>
        </div>
      </el-card>

      <el-card v-show="ready" style="flex: 1; min-width: calc(50% - 6px); max-width: 50%; margin-bottom: 12px; min-height: 22%;">
        <div style="display: flex; gap: 12px; margin-bottom: 12px;">
          <div style="display: flex; flex-direction: column;">
            <span>X {{ $t('chart.xAxis.min') }}</span>
            <el-input-number v-model="customXAxisMin" :step="1" />
          </div>
          <div style="flex: 1; display: flex; flex-direction: column;">
            <span>X {{ $t('chart.xAxis.max') }}</span>
            <el-input-number v-model="customXAxisMax" :step="1" />
          </div>
        </div>
        <div style="display: flex; gap: 12px;">
          <div style="display: flex; flex-direction: column;">
            <span>Y {{ $t('chart.yAxis.min') }}</span>
            <el-input-number v-model="customYAxisMin" :step="1" />
          </div>
          <div style="display: flex; flex-direction: column;">
            <span>Y {{ $t('chart.yAxis.max') }}</span>
            <el-input-number v-model="customYAxisMax" :step="1"/>
          </div>
        </div>
      </el-card>
    </div>

    <el-card v-show="ready" class="chart-card" style="flex: 1; margin-bottom: 12px;">
      <el-button @click="scale">Auto Scale</el-button>
      <div ref="chart" class="chart-container"></div>
    </el-card>
  </div>
</template>

<style scoped>
#mainContent {
  height: 90vh;
}
.chart-card {
  flex: 1;
  max-width: 49.7%;
}
.chart-container {
  width: 100%;
  height: 500px;
  min-height: 100px;
  max-height: 600px;
}
</style>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    end.setHours(0, 0, 0, 0);
    start.setHours(0, 0, 0, 0);

    return {
      outputList: [],
      instrumentGroupList: [],
      loading: false,
      selectedInstrumentGroupX: null,
      selectedInstrumentGroupY: null,
      echartInstance: null,
      customXAxisMin: -15,
      customXAxisMax: 15,
      customYAxisMin: -15,
      customYAxisMax: 15,
      selectedDate: [start, end],
      autoScale: false,
      ready: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '最近一周',
          onClick: (picker) => picker.$emit('pick', this.calculateDateRange(7)),
        }, {
          text: '最近一個月',
          onClick: (picker) => picker.$emit('pick', this.calculateDateRange(30)),
        }, {
          text: '最近三個月',
          onClick: (picker) => picker.$emit('pick', this.calculateDateRange(90)),
        }],
      },
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    chartTitle() {
      const xGroup = this.instrumentGroupList.find(group => group._id === this.selectedInstrumentGroupX);

      let xGroupName = xGroup ? xGroup.name : '';

      if (xGroupName.includes('-X') || xGroupName.includes('-Y') ) {
        xGroupName = xGroupName.slice(0, -2);
      } else if (xGroupName.includes('-EW') || xGroupName.includes('-NS')) {
        xGroupName = xGroupName.slice(0, -3);
      }

      return `${xGroupName}`;
    },
  },
  mounted() {
    this.getInstrumentGroupList();
    this.$nextTick(() => {
      this.initChart();
      window.addEventListener('resize', this.handleResize);
    });
    this.$nextTick(() => {
      const chartContainer = this.$refs.chart;
      chartContainer.style.height = chartContainer.parentNode.clientHeight + 'px';
      this.initChart();
    });
  },
  watch: {
    customXAxisMin() {
      this.initChart();
    },
    customXAxisMax() {
      this.initChart();
    },
    customYAxisMin() {
      this.initChart();
    },
    customYAxisMax() {
      this.initChart();
    },
  },
  beforeDestroy() {
    if (this.echartInstance) {
      this.echartInstance.dispose();
    }
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    adjustChartSize() {
      const chartContainer = this.$refs.chart;
      if (chartContainer) {
        chartContainer.style.height = (window.innerHeight * 0.6) + 'px';
        if (this.echartInstance) {
          this.echartInstance.resize();
        }
      }
    },
    handleResize() {
      this.adjustChartSize();
    },
    getChartContainerHeight() {
      const chartContainer = this.$refs.chart.parentNode;
      return `${chartContainer.clientHeight}px`;
    },
    scale() {
      this.autoScale = true;
      this.echartInstance.resize();
      const newXAxisRange = this.calculateNewAxisRange(this.outputList.map(o => o.x));
      const newYAxisRange = this.calculateNewAxisRange(this.outputList.map(o => o.y));
      
      this.customXAxisMin = newXAxisRange.min;
      this.customXAxisMax = newXAxisRange.max;
      this.customYAxisMin = newYAxisRange.min;
      this.customYAxisMax = newYAxisRange.max;

      this.initChart();
    },
    calculateNewAxisRange(dataArray) {
      const min = Math.floor(Math.min(...dataArray)) - 15;
      const max = Math.round(Math.max(...dataArray)) + 15;
      return { min, max };
    },
    initChart() {
      // After chart data is loaded
      this.$nextTick(() => {
        const dates = this.generateDateList();
        this.echartInstance = echarts.init(this.$refs.chart);

        const dataWithCustomStyle = this.outputList.map((item, index) => {
          let itemColor, borderColor;

          if (index === 0) {
            itemColor = 'green';
            borderColor = 'white';
          }
          else if (index === this.outputList.length - 1) {
            itemColor = 'blue';
            borderColor = 'white';
          }
          else {
            itemColor = 'red';
            borderColor = 'red';
          }

          return {
            value: [item.x, item.y],
            symbolSize: index === 0 || index === this.outputList.length - 1 ? 8 : 1,
            itemStyle: {
              color: itemColor,
              borderColor,
              borderWidth: 1,
              opacity: 1,
            },
          };
        });

        const option = {
          title: {
            text: this.chartTitle,
            left: 'center',
          },
          tooltip: {
            trigger: 'item',
            formatter(params) {
              const dateIndex = params.dataIndex;
              const date = new Date(dates[dateIndex]);
              const dateString = `${date.getFullYear()}/${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
              return `${dateString}<br/>X: ${params.value[0]} mm<br/>Y: ${params.value[1]} m`;
            },
            axisPointer: {
              animation: false,
            },
          },
          xAxis: {
            type: 'value',
            position: 'top',
            min: this.customXAxisMin,
            max: this.customXAxisMax,
            splitLine: {
              show: true,
            },
            axisLabel: {
              formatter: '{value} mm',
            },
          },
          yAxis: {
            type: 'value',
            min: this.customYAxisMin,
            max: this.customYAxisMax,
            splitLine: {
              show: true,
            },
            axisLabel: {
              formatter: '{value} m',
            },
          },
          series: [{
            data: dataWithCustomStyle,
            type: 'line',
            smooth: true,
            symbol: 'circle',
          }],
          dataZoom: [
            {
              type: 'inside',
              xAxisIndex: [0],
              startValue: 0,
              endValue: 100,
              zoomOnMouseWheel: true,
              filterMode: 'none',
            },
            {
              type: 'inside',
              yAxisIndex: [0],
              startValue: 0,
              endValue: 100,
              zoomOnMouseWheel: true,
              filterMode: 'none',
            },
          ],
        };
        this.echartInstance.setOption(option);
        this.$nextTick(() => {
          this.echartInstance.resize();
        });
      });
    },
    mergeXYOutputs(outputX, outputY) {
      let mergedOutput = [];

      for (let key in outputX) {
        if (Object.prototype.hasOwnProperty.call(outputY, key)) {
          mergedOutput.push({
            timestamp: parseInt(key),
            x: outputX[key],
            y: outputY[key],
          });
        }
      }

      return mergedOutput;
    },
    generateDateList() {
      const startDate = new Date(this.selectedDate[0]);
      const endDate = new Date(this.selectedDate[1]);
      let dates = [];

      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        dates.push(new Date(d).setHours(0, 0, 0, 0));
      }

      return dates;
    },
    calculateDateRange(periodDays) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * periodDays);
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
      return [start, end];
    },
    getInstrumentGroupOutputByTime(selectedGroupId) {
      const dates = this.generateDateList();
      return this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/instrument-group/${selectedGroupId}/output`,
        data: { TIMESTAMP: dates },
      }).then(res => res.data.sort((b, a) => a.depth - b.depth)[0].output);
    },
    async updateOutputList() {
      this.ready = false;
      if (this.selectedInstrumentGroupX && this.selectedInstrumentGroupY) {
        this.loading = true;
        const outputX = await this.getInstrumentGroupOutputByTime(this.selectedInstrumentGroupX);
        const outputY = await this.getInstrumentGroupOutputByTime(this.selectedInstrumentGroupY);

        this.outputList = this.mergeXYOutputs(outputX, outputY);
        this.ready = true;
        this.handleResize();
        //console.log(this.outputList);
      }
      this.initChart();
      this.handleResize();
      this.loading = false;
    },
    getInstrumentGroupList() {
      this.$store
        .dispatch('data/getProjectInstrumentGroupList', {
          projectId: this.selectedProject._id,
        })
        .then(res => {
          this.instrumentGroupList = res;
          //console.log(res);
        });
    },
  },
};
</script>