<template>
  <el-form
    ref="projectForm"
    labelPosition="top"
    label-width="80px"
    v-loading="isLoading"
    :model="form"
    :rules="formRules"
    :validate-on-rule-change="false"
    :hide-required-asterisk="true"
    :inline="true"
  >
    <el-card class="mb-16">
      <el-form-item
        label="選擇專案"
        v-if="formType === 'edit'"
      >
        <el-select
          v-model="selectedProject"
          @change="changeProjectDetails"
          filterable
          default-first-option
          placeholder="選擇專案"
        >
          <el-option
            v-for="project in projectList"
            :key="project._id"
            :label="project.project_id"
            :value="project._id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        prop="client_id"
        label="選擇客戶"
      >
        <el-select
          v-model="form.client_id"
          placeholder="選擇客戶"
          collapse-tags="collapse-tags"
          multiple
          filterable
        >
          <el-option
            v-for="client in userList"
            :key="client._id"
            :label="client.email"
            :value="client._id"
          ><span
              class="is-pulled-left"
              style="margin-right: 10px;"
            >
              {{ client.name }}
            </span>
            <span class="email-style">
              {{ client.email }}
            </span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-divider content-position="left">
        <h6 class="title is-6">
          專案資訊
        </h6>
      </el-divider>
      <el-row>
        <el-form-item
          prop="name"
          label="專案名稱"
        >
          <el-input v-model="form.name"> </el-input>
        </el-form-item>
        <el-form-item label="專案ID">
          <el-input v-model="form.project_id"> </el-input>
        </el-form-item>
        <el-form-item label="地點">
          <el-input v-model="form.location"> </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="業主">
          <el-input v-model="form.client"> </el-input>
        </el-form-item>
        <el-form-item label="主承商">
          <el-input v-model="form.contractor"> </el-input>
        </el-form-item>
        <el-form-item label="監測廠商">
          <el-input v-model="form.instrument_company"> </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="開始日期">
          <el-date-picker
            v-model="form.start_date"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="專案時區">
          <el-select
            v-model="form.timezone"
            placeholder="選擇時區"
            filterable
          >
            <el-option
              v-for="timezone in timezones"
              :key="timezone.value"
              :label="timezone.label"
              :value="timezone.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="專案緯度(Lat)">
          <el-input v-model="form.lat"> </el-input>
        </el-form-item>
        <el-form-item label="專案經度(Lon)">
          <el-input v-model="form.lon"> </el-input>
        </el-form-item>
        <el-form-item label="縮放級別">
          <el-input v-model="form.zoom"> </el-input>
        </el-form-item>
      </el-row>
      <el-divider content-position="left">
        <h6 class="title is-6">
          故障提醒設定
        </h6>
      </el-divider>
      <el-row>
        <el-form-item
          prop="alert_target"
          label="警戒值"
        >
          <el-select
            v-model="form.alert_target"
            placeholder="選擇客戶"
            collapse-tags="collapse-tags"
            multiple
            filterable
          >
            <el-option
              v-for="client in userList"
              :key="client._id"
              :label="client.email"
              :value="client._id"
            ><span
                class="is-pulled-left"
                style="margin-right: 10px;"
              >{{
                client.name
              }}</span><span class="email-style">{{ client.email }}</span></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="warning_target"
          label="注意值"
        >
          <el-select
            v-model="form.warning_target"
            placeholder="選擇客戶"
            collapse-tags="collapse-tags"
            multiple
            filterable
          >
            <el-option
              v-for="client in userList"
              :key="client._id"
              :label="client.email"
              :value="client._id"
            ><span
                class="is-pulled-left"
                style="margin-right: 10px;"
              >
                {{ client.name }}
              </span>
              <span class="email-style">{{ client.email }}</span></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="action_target"
          label="行動值"
        >
          <el-select
            v-model="form.action_target"
            placeholder="選擇客戶"
            collapse-tags="collapse-tags"
            multiple
            filterable
          >
            <el-option
              v-for="client in userList"
              :key="client._id"
              :label="client.email"
              :value="client._id"
            >
              <span
                class="is-pulled-left"
                style="margin-right: 10px;"
              >
                {{ client.name }} </span><span class="email-style">{{ client.email }}</span></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="down_target"
          label="儀器故障"
        >
          <el-select
            v-model="form.down_target"
            placeholder="選擇客戶"
            collapse-tags="collapse-tags"
            multiple
            filterable
          >
            <el-option
              v-for="client in userList"
              :key="client._id"
              :label="client.email"
              :value="client._id"
            >
              <span
                class="is-pulled-left"
                style="margin-right: 10px;"
              >{{ client.name }}
              </span>
              <span class="email-style">{{ client.email }} </span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="down_target"
          label="DAT 故障"
        >
          <el-select
            v-model="form.document_message.down_target"
            placeholder="選擇客戶"
            collapse-tags="collapse-tags"
            multiple
            filterable
          >
            <el-option
              v-for="client in userList"
              :key="client._id"
              :label="client.email"
              :value="client._id"
            ><span
                class="is-pulled-left"
                style="margin-right: 10px;"
              >{{
                client.name
              }}</span><span class="email-style">{{ client.email }}</span></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item
          prop="down_target"
          label="警戒簡訊發送上限"
        >
          <el-input-number v-model="form.message.limit"></el-input-number>
        </el-form-item>
        <el-form-item
          prop="down_target"
          label="故障簡訊發送上限"
        >
          <el-input-number v-model="form.document_message.limit"></el-input-number>
        </el-form-item>
      </el-row>

      <el-divider content-position="left">
        <h6 class="title is-6">
          其他設定
        </h6>
      </el-divider>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            prop="data_config"
            label="Data List 顯示"
          >
            <el-checkbox-group v-model="form.config.data.display">
              <el-checkbox
                v-for="option in dataListOptions"
                :key="'data' + option.value"
                :label="option.value"
              >
                {{ option.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="export_config"
            label="Export 顯示"
          >
            <el-checkbox-group v-model="form.config.export.display">
              <el-checkbox
                v-for="option in exportListOptions"
                :key="'export' + option.value"
                :label="option.value"
              >{{ option.label }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item
          label="可顯示側邊欄類型"
        >
        <el-select v-model="form.allowed_sidebar" multiple placeholder="请选择">
          <el-option
            v-for="item in sidebarTypesOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item
          label="可顯示地圖圖層類型"
        >
        <el-select v-model="form.allowed_layer" multiple placeholder="请选择">
          <el-option
            v-for="item in mapLayersOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item
          label="登入後優先顯示的畫面"
        >
          <el-select v-model="form.first_display">
            <el-option
              v-for="item in firstDisplayOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item
          prop="remark_target"
          label="可新增編輯備註人員"
        >
          <el-select
            v-model="form.access.remark"
            placeholder="選擇客戶"
            collapse-tags="collapse-tags"
            multiple
            filterable
          >
            <el-option
              v-for="client in selectedClient"
              :key="client._id"
              :label="client.email"
              :value="client._id"
            ><span
                class="is-pulled-left"
                style="margin-right: 10px;"
              >{{
                client.name
              }}</span><span class="email-style">{{ client.email }}</span></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <!-- <el-row>
        <el-form-item label="為專案添加區域">
          <el-select
            v-model="form.area"
            multiple
            filterable
            default-first-option
            placeholder="選擇區域"
          >
            <el-option
              v-for="area in areaOptions"
              :key="area._id"
              :label="area.name"
              :value="area._id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-row> -->
    </el-card>
    <el-card>
      <el-button
        v-if="formType === 'create'"
        type="primary"
        :disabled="disableSubmit"
        @click="createProject"
      >
        新增專案
      </el-button>
      <template v-if="formType === 'edit'">
        <el-button
          type="danger"
          :disabled="disableSubmit"
          @click="deleteProject"
        >
          刪除專案
        </el-button>
        <el-button
          type="primary"
          @click="updateProject"
          :disabled="disableSubmit"
        >
          更新專案
        </el-button>
      </template>
    </el-card>
  </el-form>
</template>

<script>
import moment from 'moment';
import MomentTimeZone from 'moment-timezone';
MomentTimeZone();
import { coverObject } from '@/lib/base/helper';

const timezones = moment.tz
  .names()
  .reduce((memo, tz) => {
    memo.push({
      name: tz,
      offset: moment.tz(tz).utcOffset(),
    });
    return memo;
  }, [])
  .sort((a, b) => {
    return b.offset - a.offset;
  })
  .reduce((memo, tz) => {
    const timezone = tz.offset ? moment.tz(tz.name).format('Z') : '';
    memo.push({
      label: `(GMT${timezone}) ${tz.name}`,
      value: tz.name,
    });
    return memo;
  }, []);

export default {
  props: {
    formType: {
      type: String,
      default: 'create',
      required: true,
      validator: type => {
        return ['create', 'edit'].includes(type);
      },
    },
  },
  data() {
    return {
      isLoading: false,
      selectedProject: '',
      form: {
        client_id: [],
        project_id: '',
        instrument_company: '',
        client: '',
        lat: '',
        lon: '',
        zoom: '',
        name: '',
        start_date: '',
        location: '',
        contractor: '',
        first_display: 'layout',
        timezone: moment.tz.guess(),
        area: [],
        warning_target: [],
        alert_target: [],
        action_target: [],
        down_target: [],
        message: {
          limit: 0,
        },
        document_message: {
          down_target: [],
          limit: 0,
        },
        config: {
          data: {
            display: ['reading2', 'WSL', 'AL'],
          },
          export: {
            display: ['measure'],
          },
        },
        access: {
          remark: [],
        },
        allowed_sidebar: [],
        allowed_layer: [],
      },
      timezones,
      formRules: {
        name: [
          {
            required: true,
            message: '請輸入專案名稱',
            trigger: 'blur',
          },
        ],
      },
      userList: [],
      areaOptions: [],
      exportListOptions: [
        {
          label: '原讀值',
          value: 'raw',
        },
        {
          label: '量測值',
          value: 'measure',
        },
      ],
      dataListOptions: [
        {
          label: '原讀值',
          value: 'reading1',
        },
        {
          label: '量測值',
          value: 'reading2',
        },
        {
          label: '1Hr速率值',
          value: 'velocity1Hr',
        },
        {
          label: '24Hr速率值',
          value: 'velocity24Hr',
        },
        {
          label: 'WSL',
          value: 'WSL',
        },
        {
          label: 'AL',
          value: 'AL',
        },
        {
          label: '電量',
          value: 'batt',
        },
      ],
      sidebarTypesOption: [
        {
          label: '儀錶板',
          value: 'dashboard',
        },
        {
          label: 'OCC即時監測',
          value: 'occ',
        },
        {
          label: '邊坡監測',
          value: 'slope',
        },
        {
          label: '暴雨監測',
          value: 'storm',
        },
        {
          label: '關於',
          value: 'details',
        },
        {
          label: '聯絡',
          value: 'contact',
        },
        {
          label: 'CCTV',
          value: 'monitor',
        },
        {
          label: '事件紀錄',
          value: 'alerts',
        },
        {
          label: '噪音監測',
          value: 'noise',
        },
        {
          label: '數據排名表',
          value: 'rank',
        },
        {
          label: '總表',
          value: 'summary',
        },
        {
          label: '監測數據列表',
          value: 'data',
        },
        {
          label: '歷時曲線',
          value: 'chart',
        },
        {
          label: 'SnakeLine',
          value: 'snakeLine',
        },
        {
          label: '分佈曲線',
          value: 'curve',
        },
        {
          label: '地圖',
          value: 'map',
        },
        {
          label: '輸出檔案',
          value: 'export',
        },
        {
          label: '平面圖',
          value: 'layout',
        },
        {
          label: '軌跡圖',
          value: 'trajectory',
        },
        {
          label: '人工監測',
          value: 'manual',
        },
        {
          label: '新增儀器',
          value: 'manual-instrument',
        },
        {
          label: '新增數據',
          value: 'manual-edit',
        },
        {
          label: '數據列表',
          value: 'manual-data',
        },
        {
          label: '進階設定',
          value: 'AdvancedOptions',
        },

      ],
      mapLayersOption: [
        {
          label: '等高線圖',
          value: 'contour',
        },
        {
          label: '颱風路徑圖',
          value: 'typhon',
        },
        {
          label: '雷達迴波圖',
          value: 'echo',
        },
        {
          label: '累積雨量圖',
          value: 'rainfall',
        },
        {
          label: '地震報告',
          value: 'earthquake',
        },
        {
          label: '地質敏感區',
          value: 'shp',
        },
      ],
      firstDisplayOption: [
        {
          label: '儀錶板',
          value: 'dashboard',
        },
        {
          label: 'OCC即時監測',
          value: 'occ',
        },
        {
          label: '關於',
          value: 'details',
        },
        {
          label: '聯絡',
          value: 'contact',
        },
        {
          label: 'CCTV',
          value: 'monitor',
        },
        {
          label: '事件紀錄',
          value: 'alerts',
        },
        {
          label: '噪音監測',
          value: 'noise',
        },
        {
          label: '數據排名表',
          value: 'rank',
        },
        {
          label: '總表',
          value: 'summary',
        },
        {
          label: '監測數據列表',
          value: 'data',
        },
        {
          label: '歷時曲線',
          value: 'chart',
        },
        {
          label: 'SnakeLine',
          value: 'snakeLine',
        },
        {
          label: '分佈曲線',
          value: 'curve',
        },
        {
          label: '地圖',
          value: 'map',
        },
        {
          label: '輸出檔案',
          value: 'export',
        },
        {
          label: '平面圖',
          value: 'layout',
        },
        {
          label: '軌跡圖',
          value: 'trajectory',
        },
        {
          label: '新增儀器',
          value: 'manual/instrument',
        },
        {
          label: '新增數據',
          value: 'manual/edit',
        },
        {
          label: '數據列表',
          value: 'manual/data',
        },
        {
          label: '進階設定',
          value: 'AdvancedOptions',
        },
      ],
    };
  },
  computed: {
    projectList() {
      return this.$store.getters['project/projectList'];
    },
    selectedClient() {
      return this.userList.filter(ele => this.form.client_id.includes(ele._id));
    },
    disableSubmit() {
      return this.form.name.length === 0;
    },
  },
  watch: {
    '$route.query.projectId'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleQueryGetProject();
      }
    },
  },
  created() {
    this.handleQueryGetProject();
    this.handleSidebarTypesOptionDefault();
    this.getUserList();
  },
  methods: {
    handleQueryGetProject() {
      if (this.formType === 'edit' && this.$route.query.projectId) {
        this.selectedProject = this.$route.query.projectId;
        this.changeProjectDetails();
      }
    },
    changeProjectDetails() {
      const project = this.projectList.find(project => {
        return project._id === this.selectedProject;
      });
      coverObject(this.form, project);
    },
    handleSidebarTypesOptionDefault() {
      if(this.form.allowed_sidebar.length==0){
        this.form.allowed_sidebar = [
          'dashboard',
          'occ',
          'slope',
          'storm',
          'summary',
          'data',
          'chart',
          'snakeLine',
          'curve',
          'map',
          'layout',
          'export',
          'details',
          'contact',
          'monitor',
          'alerts',
          'noise',
          'rank',
          'trajectory',
        ];
      }
    },
    getUserList() {
      return this.$store
        .dispatch('data/getUserList')
        .then(res => {
          this.userList = res;
        })
        .catch(this.$notifyError);
    },
    createProject() {
      return this.$refs.projectForm.validate(async valid => {
        if (!valid) {
          return false;
        } else {
          const response = await this.$confirmCreate('此操作將更新增專案');
          if (response === 'confirm') {
            this.isLoading = true;
            this.$axios({
              category: 'admin',
              method: 'post',
              apiName: 'project',
              data: { ...this.form },
            })
              .then(() => {
                return this.$store.dispatch('project/getProjectList', true);
              })
              .then(() => {
                this.$notifySuccess('更新成功');
              })
              .catch(this.$notifyError)
              .finally(() => {
                this.isLoading = false;
              });
          }
        }
      });
    },
    updateProject() {
      return this.$refs.projectForm.validate(async valid => {
        if (!valid) {
          return false;
        } else {
          const response = await this.$confirmEdit('此操作將更新該專案');
          if (response === 'confirm') {
            this.isLoading = true;
            this.$axios({
              category: 'admin',
              method: 'put',
              apiName: `project/${this.selectedProject}`,
              data: { ...this.form },
            })
              .then(() => {
                return this.$store.dispatch('project/getProjectList');
              })
              .then(() => {
                this.$notifySuccess('更新成功');
              })
              .catch(this.$notifyError)
              .finally(() => {
                this.isLoading = false;
              });
          }
        }
      });
    },
    async deleteProject() {
      const response = await this.$confirmDelete('此操作將永久刪除該專案');
      if (response === 'confirm') {
        this.isLoading = true;
        return this.$axios({
          category: 'admin',
          method: 'delete',
          apiName: `project/${this.selectedProject}`,
        })
          .then(() => {
            this.$notifySuccess('專案已成功刪除');
          })
          .catch(this.$notifyError)
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.email-style {
  margin-right: 16px;
  color: #8492a6;
  font-size: 13px;
}
</style>
