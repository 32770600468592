<template>
  <div style="height: 100%;">
    <div class="column" style="height: 100%; width: 25%; float:left;">
      <!--el-card>
        設定類型
        <el-select v-model="selectionType" placeholder="請選擇">
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-card-->
      <el-card>
        <div>選擇專案</div>
        <el-select v-model="form.selectProject" clearable placeholder="請選擇">
          <el-option
            v-for="item in projectList"
            :key="item.project_id"
            :label="item.project_id"
            :value="item.project_id">
          </el-option>
        </el-select>
        
        <el-tooltip effect="light" placement="right-end">
          <i class="el-icon-info" style="padding:10px;"></i>
          <div slot="content">
            選擇專案進行設定。
            <!--<br/>
            當專案為空時，可新增專案。-->
          </div>
        </el-tooltip>
      </el-card>
      <el-card>
        <div> 
          專案：{{ form.selectProject }}
          <div>專案用戶設定</div>
          <el-select v-model="form.projectClients" multiple placeholder="請選擇" :disabled="!form.selectProject">
            <el-option
              v-for="item in clientOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-tooltip effect="light" placement="right-end">
            <i class="el-icon-info" style="padding:10px;"></i>
            <div slot="content">
              將用戶加入選定的專案。
              <br/>
              專案內的用戶才可以查閱該專案。
            </div>
          </el-tooltip>
          <div>
            <el-button @click="updateProject" :disabled="!form.selectProject">更新專案用戶</el-button>
          </div>
        </div>
        <!--div v-else> 
          <div>新增專案</div>
          <el-input v-model="newProjectName" placeholder="請輸入專案名稱"></el-input>
          <el-button :disabled="!newProjectName" @click="newProject" :loading="uploadProject">新增專案</el-button>
        </div-->
        <hr>
        <el-form
          ref="newClient"
          :model="newClient"
          :inline="true"
          :hide-required-asterisk="true"
        >
          <div>用戶設定</div>
          <el-tabs v-model="instruments_setting.activeTab">
            <el-tab-pane label="儀器設定" name="details" :lazy="true">
              <div style="padding: 10px;">
                <div style="padding-bottom: 10px;">用戶名稱</div>
                <el-form-item required prop="name">
                  <el-input 
                    v-model="newClient.name" 
                    placeholder="請輸入用戶名稱"
                    @keyup.enter.native="submitClient"
                  ></el-input>
                </el-form-item>
              </div>
              <div style="padding: 10px;">
                <div style="padding-bottom: 10px;">用戶email</div>
                <el-form-item required prop="email">
                  <el-input 
                    v-model="newClient.email" 
                    placeholder="請輸入用戶email"
                    @keyup.enter.native="submitClient"
                  ></el-input>
                </el-form-item>
                <el-tooltip effect="light" placement="right-end">
                  <i class="el-icon-info" style="padding:10px;"></i>
                  <div slot="content">
                    用戶email，作為用戶的登入帳號。
                  </div>
                </el-tooltip>
              </div>
              <div style="padding: 10px;">
                <div style="padding-bottom: 10px;">用戶電話</div>
                <el-form-item required prop="phone">
                  <el-input 
                    v-model="newClient.phone" 
                    placeholder="請輸入用戶電話"
                    @keyup.enter.native="submitClient"
                  ></el-input>
                </el-form-item>
                <el-tooltip effect="light" placement="right-end">
                  <i class="el-icon-info" style="padding:10px;"></i>
                  <div slot="content">
                    用戶電話，作為用戶的初始密碼。
                  </div>
                </el-tooltip>
              </div>
              <div>
                <el-button @click="submitClient">新增用戶</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-form>
      </el-card>
    </div>
    <div class="column" style="height: 100%; width: 75%; float:left;">
      <el-card>
        <!-- area-setting -->
        <div style="display: inline-block;">
          <div class="area-station-setting">
            <div>設定專案區域</div>
            <el-select v-model="form.selectedAreas" multiple clearable placeholder="選擇區域" :disabled="!form.selectProject"> 
              <el-option
                v-for="item in areas"
                :key="item._id"
                :label="item.name"
                :value="item._id">
              </el-option>
            </el-select>
            <hr>
            <div>新增區域</div>
            <el-input v-model="newAreaName" placeholder="請輸入新的區域名稱"></el-input>
            <el-button :disabled="!newAreaName" @click="newArea" :loading="uploadArea">新增區域</el-button>
          </div>
          
          <div class="area-station-setting">
            <div>設定區域測站</div>
            選擇區域
            <el-select v-model="form.selectedAreaToAddStation" clearable placeholder="請選擇">
              <el-option
                v-for="item in areas"
                :key="item._id"
                :label="item.name"
                :value="item._id">
              </el-option>
            </el-select>
            選擇欲加入區域的測站
            <el-select v-model="form.selectedStations" multiple clearable placeholder="選擇測站" :disabled="!form.selectedAreaToAddStation">
              <el-option
                v-for="item in stations"
                :key="item._id"
                :label="item.name"
                :value="item._id">
              </el-option>
            </el-select>
            <hr>
            <div>新增測站</div>
            <el-input v-model="newStationName" placeholder="請輸入新的測站名稱"></el-input>
            <el-button :disabled="!newStationName" @click="newStation" :loading="uploadStation">新增測站</el-button>
          </div>

          <div class="area-station-setting">
            <div>設定測站儀器</div>
            選擇測站
            <el-select v-model="form.selectedStaionToAddInstrument" clearable placeholder="請選擇">
              <el-option
                v-for="item in stations"
                :key="item._id"
                :label="item.name"
                :value="item._id">
              </el-option>
            </el-select>
            選擇欲加入測站的儀器
            <el-select v-model="form.selectedInstruments" multiple clearable placeholder="選擇儀器" :disabled="!form.selectedStaionToAddInstrument">
              <el-option
                v-for="item in userInstruments"
                :key="item._id"
                :label="item.latestVersion.name"
                :value="item._id">
              </el-option>
            </el-select>
          </div>
        </div>

        <div style="display: block;">
          <el-button @click="updateAreaStation">
            上傳設定
          </el-button>
          <el-tooltip effect="light" placement="right-end">
            <i class="el-icon-info" style="padding:10px;"></i>
            <div slot="content">
              上傳專案/區域/測站/儀器的關聯設定。
            </div>
          </el-tooltip>
        </div>

        <hr>
        <!-- instrumnet-list -->
        <div v-if="userInstruments && userInstruments.length > 0" v-loading="instruments_setting.isUpdating"> 
          <el-tabs v-model="instruments_setting.activeTab">
          <el-tab-pane label="儀器設定" name="details" :lazy="true">
            <div class="spreadsheet-container flex-row">
              <hot-table id="hot-container" ref="hot" root="hot" :settings="instruments_hotTable_settings" />
            </div>
            </el-tab-pane>
            <!--el-tab-pane label="簡訊設定" name="message" :lazy="true">
              <message-settings-table :instrument-list="userInstruments" />
            </el-tab-pane-->
          </el-tabs>
        </div>
      </el-card>
    </div>
    <!--div class="column">
      <el-card>
        <el-form-item>
          
        </el-form-item>
      </el-card>
    </div-->
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue';
import Handsontable from 'handsontable';
//import MessageSettingsTable from '@/components/instrument/MessageSettingsTable';
import 'handsontable/dist/handsontable.full.css';
export default {
  name: 'AdvancedOptions',
  components: {
    HotTable,
    //MessageSettingsTable,
  },
  computed: {
    projectList() {
      return this.$store.getters['project/projectList'];
    },
    clientOptions() {
      return this.clients.map(ele=>{
        return {
          value: ele._id,
          label: ele.name,
        };
      });
    },
    selectedProject() {
      return this.projectList.find(ele=>ele.project_id === this.form.selectProject);
    },
    instruments_hotTable_settings() {
      const vm = this;
      return {
        data: this.userInstruments,
        fixedColumnsLeft: 1,
        colHeaders: this.instruments_setting.columnsHeader,
        rowHeaders: true,
        manualRowResize: true,
        manualColumnResize: true,
        manualRowMove: true,
        customBorders: true,
        columnSorting: true,
        height: 300,
        width: '100%',
        undo: true,
        columns: this.instruments_setting.columns,
        async beforeChange(changes, source) {
          const objectList = this.getSourceData();
          const dataList = this.getData();

          const updates = changes.map(([row, prop, oldValue, newValue]) => {
            const obj = objectList.find(
              instrument => instrument.latestVersion.name === dataList[row][0],
            );

            return {
              instrument_id: obj._id,
              version_id: obj.latestVersion._id,
              [(prop.substring(prop.indexOf('.')+1) === 'name') ? 'newName' : prop.substring(prop.indexOf('.')+1)]: newValue,
              name: dataList[row][0],
            };
          });
          vm.updateInstrument(updates, source);
        },
        contextMenu: {
          items: {
            separator: Handsontable.plugins.ContextMenu.SEPARATOR,
            copy: {
              name: '复制',
            },
            undo: {
              name: '撤消',
            },
            separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
            remove_row: {
              name: '删除行',
            },
          },
        },
        filters: true,
        dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
        licenseKey: 'non-commercial-and-evaluation',
      };
    },
  },
  data() {
    return {
      typeOptions: [
        {
          label: '用戶',
          value: 'user',
        }, 
        {
          label: '專案',
          value: 'project',
        }, 
        {
          label: '儀器',
          value: 'instrument',
        }, 
        {
          label: '區域/測站',
          value: 'area/station',
        },
      ],
      selectionType: '',

      form: {
        selectProject: '',
        projectClients: [],

        selectedAreas: [],
        selectedAreaToAddStation: '',
        selectedStations: [],
        selectedStaionToAddInstrument: '',
        selectedInstruments: [],
      },
      newClient: {},

      newProjectName: '',
      uploadProject: false,
      newAreaName: '',
      uploadArea: false,
      newStationName: '',
      uploadStation: false,

      clients: [],

      areas: [],
      stations: [],

      instruments_setting: {
        isUpdating: false,
        activeTab: 'details',
        columnsHeader: [
          '儀器名稱',
          'unit',
          'offset',
          'R0',
          'T0',
          'K',
          'F0',
          'F1',
          'F2',
          'F3',
          'F4',
          'F5',
          'AL(-)',
          'AL(+)',
          'WL(-)',
          'WL(+)',
          'WSL(-)',
          'WSL(+)',
          '儀器故障警報計數基準',
          '儀器故障警報計數上限',
          '警報計數上限',
          '啟用速率',
          '1小時速率 AL(-)',
          '1小時速率 AL(+)',
          '1小時速率 WL(-)',
          '1小時速率 WL(+)',
          '1小時速率 WSL(-)',
          '1小時速率 WSL(+)',
          '24小時速率 AL(-)',
          '24小時速率 AL(+)',
          '24小時速率 WL(-)',
          '24小時速率 WL(+)',
          '24小時速率 WSL(-)',
          '24小時速率 WSL(+)',
          '速率警報計數上限',
          '座標',
          '類別',
          '監視器',
          'TRA_no',
        ],
        columns: [
          {
            data: 'latestVersion.name',
          },
          {
            data: 'latestVersion.unit',
          },
          {
            data: 'latestVersion.offset',
          },
          {
            data: 'latestVersion.R0',
          },
          {
            data: 'latestVersion.T0',
          },
          {
            data: 'latestVersion.K',
          },
          {
            data: 'latestVersion.F0',
          },
          {
            data: 'latestVersion.F1',
          },
          {
            data: 'latestVersion.F2',
          },
          {
            data: 'latestVersion.F3',
          },
          {
            data: 'latestVersion.F4',
          },
          {
            data: 'latestVersion.F5',
          },
          {
            data: 'latestVersion.AL_minus',
          },
          {
            data: 'latestVersion.AL_plus',
          },
          {
            data: 'latestVersion.WL_minus',
          },
          {
            data: 'latestVersion.WL_plus',
          },
          {
            data: 'latestVersion.WSL_minus',
          },
          {
            data: 'latestVersion.WSL_plus',
          },
          {
            data: 'latestVersion.down_count_base',
          },
          {
            data: 'latestVersion.down_count_limit',
          },
          {
            data: 'latestVersion.alert_count_limit',
          },
          {
            data: 'latestVersion.allowRate',
          },
          {
            data: 'latestVersion.rate_1Hr_config.AL_minus',
          },
          {
            data: 'latestVersion.rate_1Hr_config.AL_plus',
          },
          {
            data: 'latestVersion.rate_1Hr_config.WL_minus',
          },
          {
            data: 'latestVersion.rate_1Hr_config.WL_plus',
          },
          {
            data: 'latestVersion.rate_1Hr_config.WSL_minus',
          },
          {
            data: 'latestVersion.rate_1Hr_config.WSL_plus',
          },

          {
            data: 'latestVersion.rate_24Hr_config.AL_minus',
          },
          {
            data: 'latestVersion.rate_24Hr_config.AL_plus',
          },
          {
            data: 'latestVersion.rate_24Hr_config.WL_minus',
          },
          {
            data: 'latestVersion.rate_24Hr_config.WL_plus',
          },
          {
            data: 'latestVersion.rate_24Hr_config.WSL_minus',
          },
          {
            data: 'latestVersion.rate_24Hr_config.WSL_plus',
          },
          {
            data: 'latestVersion.rate_alert_count_limit',
          },
          {
            data: 'latestVersion.coordinates',
          },
          {
            data: 'latestVersion.class',
          },
          {
            data: 'latestVersion.select_monitor',
          },
          {
            data: 'latestVersion.TRA_no',
          },
        ],
      },
      userInstruments: [],
      colConfigs: [],
      tableData: [],
      
    };
  },
  watch: {
    'form.selectProject'(newValue, oldValue) {
      if (this.form.selectProject.length === 0) {
        this.form.selectedAreas = [];
        return;
      }
      if (newValue !== oldValue && this.form.selectProject) {
        this.getAdvancedUserDataWithProject();
        this.setProjectClient();
        this.getAreaInstrument();
        this.getAllInstrument();
      }
    },
    //'form.selectedAreas'(newValue, oldValue) {
    //  this.updateSetting()
    //},
    'form.selectedAreaToAddStation'(newValue, oldValue) {
      if (this.form.selectedAreaToAddStation.length === 0) {
        this.form.selectedStations = [];
        return;
      }
      let index = this.areas.findIndex(ele=>ele._id === this.form.selectedAreaToAddStation);
      if(index<0) {
        this.$notifyError('發生錯誤');
        return;
      }
      this.form.selectedStations = this.areas[index].station;
      //this.updateSetting()
    },
    'form.selectedStations'(newValue, oldValue) {
      let index = this.areas.findIndex(ele=>ele._id === this.form.selectedAreaToAddStation);
      if(this.form.selectedAreaToAddStation.length === 0){
        return;
      }
      else if(index<0) {
        this.$notifyError('發生錯誤');
        return;
      }
      this.areas[index].station = this.form.selectedStations;
      //this.updateSetting()
    },
    'form.selectedStaionToAddInstrument'(newValue, oldValue) {
      if (this.form.selectedStaionToAddInstrument.length === 0) {
        this.form.selectedInstruments = [];
        return;
      }
      let index = this.stations.findIndex(ele=>ele._id === this.form.selectedStaionToAddInstrument);
      if(index<0) {
        this.$notifyError('發生錯誤');
        return;
      }
      this.form.selectedInstruments = this.stations[index].instrument;
    },
    'form.selectedInstruments'(newValue, oldValue) {
      let index = this.stations.findIndex(ele=>ele._id === this.form.selectedStaionToAddInstrument);
      if(this.form.selectedStaionToAddInstrument.length === 0){
        return;
      }
      else if(index<0) {
        this.$notifyError('發生錯誤');
        return;
      }
      this.stations[index].instrument = this.form.selectedInstruments;
    },
  },
  async mounted(){
    await this.getProjectList();
    await this.getAdvancedUserData();
    await this.getAllInstrument();
  },
  methods: {
    async getProjectList() {
      const res = await this.$store.dispatch('project/getProjectList', true);
      //console.log(res);


    },
    async newProject() {
      try{
        this.uploadProject = true;
        await this.$axios({
          category: 'advanced',
          method: 'post',
          apiName: 'newProject',
          data: {
            projectName: this.newProjectName,
          },
        });
        this.$notifySuccess('新增成功');
        await this.getProjectList();
        
        let selected = this.projectList.find(ele => ele.project_id === this.newProjectName);
        this.form.selectProject = selected ? selected.project_id : '';

        this.uploadProject = false;
      }
      catch(e){
        this.$notifyError(e);
      }
    },
    async getAdvancedUserData() {
      const result = await this.$axios({
        category: 'advanced',
        method: 'get',
        apiName: 'getAdvancedUserData',
      });
      this.clients = result.data.clients;
      this.areas = result.data.areas;
      this.stations = result.data.stations;
    },
    async getAdvancedUserDataWithProject() {
      const result = await this.$axios({
        category: 'advanced',
        method: 'get',
        apiName: `getAdvancedUserData/${this.selectedProject._id}`,
      });
      this.clients = result.data.clients;
      this.areas = result.data.areas;
      this.stations = result.data.stations;
    },
    async submitClient() {
      const result = await this.$axios({
        category: 'advanced',
        method: 'post',
        apiName: 'addClient',
        data: this.newClient,
      });
      this.clients = result.data;
      this.$notifySuccess('新增成功');
    },
    setProjectClient() {
      //const selectProject = this.projectList.find(ele=>ele.project_id === this.form.selectProject);
      //console.log(selectProject);
      let clients = [];
      //console.log(selectProject.client_id);
      //console.log(this.clients);
      this.selectedProject.client_id.forEach(ele => {
        let index = this.clients.findIndex(c=>{
          return c._id === ele;
        });
        if(index >= 0) clients.push(ele);
        //console.log(index);
        //if(ele._id === ) clients.push(ele);
      });
      this.form.projectClients = clients;
      //console.log(this.form.projectClients);
    },
    async updateProject() {
      try{
        this.uploadProject = true;
        await this.$axios({
          category: 'advanced',
          method: 'put',
          apiName: 'updateProject',
          data: {
            projectName: this.form.selectProject,
            clients: this.form.projectClients,
            client_mesk: this.clients.map(ele => ele._id),
          },
        });
        this.$notifySuccess('更新成功');
        await this.getProjectList();
        this.setProjectClient();

        this.uploadProject = false;
      }
      catch(e){
        this.$notifyError(e);
      }
    },
    async getAreaInstrument() {
      const result = await this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/area-station-instrument`,
      });
      //console.log(result);
      this.form.selectedAreas = result.data.area.map(ele=>ele._id);
    },
    async getAllInstrument() {
      const result = (this.selectedProject) ? await this.$axios({
        category: 'advanced',
        method: 'get',
        apiName: `getUserALLInstruments/${this.selectedProject._id}`,
      }) : await this.$axios({
        category: 'advanced',
        method: 'get',
        apiName: 'getUserALLInstruments/',
      });
      //console.log(result);
      this.userInstruments = result.data;
      this.updateTable();
    },
    async updateInstrument(updateList, mode = 'edit') {
      this.instruments_setting.isUpdating = true;
      return this.$axios({
        category: 'admin',
        method: 'put',
        apiName: 'bulk/instrument',
        data: updateList,
      })
        .then(() => {
          if (mode === 'UndoRedo.undo') {
            this.$notifyInfo('資料已復原');
          } else {
            this.$notifySuccess('資料已更新');
          }
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.instruments_setting.isUpdating = false;
        });
    },
    
    async newArea() {
      try{
        this.uploadArea = true;
        const result = await this.$axios({
          category: 'advanced',
          method: 'post',
          apiName: 'addArea',
          data: {
            areaName: this.newAreaName,
          },
        });
        this.$notifySuccess('新增成功');

        this.areas = result.data;

        this.uploadArea = false;

        console.log(this.areas);
      }
      catch(e){
        this.$notifyError(e);
        this.uploadArea = false;
      }
    },

    async newStation() {
      try{
        this.uploadStation = true;
        const result = await this.$axios({
          category: 'advanced',
          method: 'post',
          apiName: 'addStation',
          data: {
            stationName: this.newStationName,
          },
        });
        this.$notifySuccess('新增成功');

        this.stations = result.data;

        this.uploadStation = false;
      }
      catch(e){
        this.$notifyError(e);
        this.uploadStation = false;
      }
    },
    
    async updateAreaStation() {
      try{
        if(!this.form.selectProject){
          this.$notifyError('未選擇專案');
          return;
        }
      
        this.uploadProject = true;
        const res = await this.$axios({
          category: 'advanced',
          method: 'put',
          apiName: 'updateAreaStaion',
          data: {
            projectName: this.form.selectProject,
            projectAreas: this.form.selectedAreas,
            areas: this.areas,
            stations: this.stations,
          },
        });
        this.$notifySuccess('更新成功');
        //await this.getProjectList();
        //this.setProjectClient();
      
        this.areas = res.data.areas;
        this.stations = res.data.stations;

        this.uploadProject = false;
      }
      catch(e){
        this.$notifyError(e);
        this.uploadProject = false;
      }
    },
    
    updateTable() {
      if(this.selectedProject)
        setTimeout(() => {
          this.$refs.hot.hotInstance.loadData(this.userInstruments);
        }, 100);
    },
  },
};
</script>

<style lang="stylus">
.area-station-setting
  padding-right 20px
  display inline-flex
  flex-direction column
  align-items flex-start
</style>