<template>
  <el-popover
    v-model="showPopover"
    :disabled="disablePopover"
    :trigger="triggerType"
    :popper-options="{boundariesElement:'image-container'}"
    append-to-body= "false"
    title
    placement="top"
    popper-class="p-16 pb-8"
  >
    <div v-if="marker.instrument.length" class="content">
      <!-- <div v-if="marker.instrument.length > 1">
        <el-table :data="marker.instrument">
          <el-table-column :label="$t('data.instrument.name')" prop="data[0].name" width="160" />
          <el-table-column :label="$t('data.measurements')" width="150">
            <template slot-scope="scope">
              {{ getLatestReading(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('data.update')" prop="data[0].created_at" width="140">
            <template slot-scope="scope">
              {{ scope.row.last_reading.created_at | moment('YYYY-MM-DD HH:mm a') }}
            </template>
          </el-table-column>
        </el-table>
      </div>-->
      <!-- <div v-else>
        <div v-for="instrument in marker.instrument" :key="instrument._id" class="flex-column mb-16">
          <h5>{{ instrument.data[0].name }}</h5>
          <p class="is-marginless">
            Last: {{ instrument.last_reading[instrument.display_output] }} {{ instrument.data[0].unit }}
          </p>
          <p>
            {{ instrument.last_reading.created_at | moment('YYYY-MM-DD HH:mm a') }}
          </p>
        </div>
      </div>-->
      <font-awesome-icon @click="showPopover = false" class="icon-close" icon="times" />
      <div class="list-container">
        <div
          v-for="instrument in marker.instrument"
          :key="instrument._id"
          class="flex-column mb-16"
        >
          <div v-if="instrument.last_reading">
            <router-link
              class="Link"
              :to="{ path: 'chart', query: { instrument: instrument._id } }"
            >
              {{ instrument.data[instrument.data.length - 1].name }}
            </router-link>
            <!--h5>{{ instrument.data[instrument.data.length - 1].name }}</h5-->
            <p class="is-marginless">
              Last: {{ instrument.last_reading[instrument.display_output] }}
              {{ instrument.data[instrument.data.length - 1].unit }}
            </p>
            <p v-if="instrument.velocity1Hr" class="is-marginless">
              1Hr Rate: {{ instrument.velocity1Hr }}
              {{ `${instrument.data[instrument.data.length - 1].unit}/1Hr` }}
            </p>
            <p v-if="instrument.velocity24Hr" class="is-marginless">
              24Hr Rate: {{ instrument.velocity24Hr }}
              {{ `${instrument.data[instrument.data.length - 1].unit}/24Hr` }}
            </p>
            <p>{{ $moment(instrument.last_reading.TIMESTAMP).format('YYYY-MM-DD HH:mm a') }}</p>
            <!--p>刷新時間: {{ new Date().getTime() }}</p-->
          </div>
          <div v-else>
            <h5>{{ instrument.data[instrument.data.length - 1].name }}</h5>
            {{ $t('data.none') }}
          </div>
        </div>
      </div>
      <!-- <p>Last: {{ marker.instrument.last_reading[marker.instrument.display_output] }} {{ marker.instrument.data[0].unit }}</p>
      <p>
        {{ marker.instrument.last_reading.created_at | moment('YYYY-MM-DD HH:mm a') }}
      </p>-->
    </div>
    <div v-else class="content">
      <p class="has-text-weight-bold">{{ $t('data.none') }}</p>
    </div>
    <div class="flex-row justify-end">
      <el-button v-if="!disableSelect" @click="selectPin(marker)" type="primary" size="mini">
        選擇
      </el-button>
      <el-button v-if="!disableEdit" @click="deletePin(marker._id)" type="danger" size="mini">
        刪除
      </el-button>
    </div>
    
    <div
      slot="reference"
      ref="pin"
      :class="[`icon-${marker.shape} is-${marker.size} is-border-${marker.border_color}`]"
      :style="pinStyle"
      @click.stop="togglePopover"
      class="focus-point-pin">
    </div>
    
    <div slot="reference"
      v-if="PinArrowEnable"
      ref="arrow"
      :class="[`icon-long-arrow-right`]"
      :style="ArrowStyle"
      class="ArrowAngle">
    </div>

  </el-popover>
  
    
</template>

<script>
export default {
  props: {
    marker: {
      type: Object,
      required: true,
    },
    statusColor: {
      type: String,
      required: true,
    },
    disablePopover: {
      type: Boolean,
      default: false,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    disableSelect: {
      type: Boolean,
      default: true,
    },
    triggerType: {
      type: String,
      default: 'manual',
    },
  },
  data() {
    return {
      pin: null,
      arrow: null,
      arrowData: null,
      showPopover: false,
    };
  },
  computed: {
    pinStyle() {
      if (!this.pin) {
        return null;
      }
      const { width, height } = this.pin;
      return {
        left: this.s(this.marker.x, width),
        top: this.s(this.marker.y, height),
        // background: this.backgroundColor,
        color: this.statusColor,
      };
    },
    ArrowStyle(){
      //let testX = 1.5532,testY = -0.1152;
      //let offsetx=-7 , offsety = -45;
      //const { width, height } = this.pin;
      //console.log(`width ${width} height ${height}`);
      //console.log(this.pin);
      //const fontW = 7;
      //const fontH = 14;

      //let pass = true;
      if (!this.pin||!this.arrowData) {
        return null;
      }

      let fontSize = 100 * this.arrowData.force;
      const { width, height } = this.arrow;
      //const { xpin, ypin } = this.pin;
      const angle = (this.arrowData.angle) * Math.PI / 180;
      const cosa = Math.cos(angle).toFixed(4);
      const sina = Math.sin(angle).toFixed(4);
      const xoffset = width * cosa * (this.arrowData.force) / 2 - width / 2;
      const yoffset = height * sina * (this.arrowData.force) / 2 - height / 2;
      //console.log(`${this.marker.y}`);
      return {
        transform: `
          rotate(${angle}rad) 
          scale(${fontSize}%, ${100}%) 
          `,// translate(${this.s(this.marker.x, width)}, ${this.s(this.marker.y, height)}) // scale(${fontSize * (Math.abs(cosa)) + 100}%, ${fontSize * (Math.abs(sina)) + 100}%) 
        left: `calc(${this.marker.x}% + ${xoffset}px)`,
        top : `calc(${this.marker.y}% + ${yoffset}px)`,
        //`calc(${w}% - ${mw / 2}px)`
      };
    },
    PinArrowEnable(){
      return this.marker.vector_enable;
    },
  },
  mounted() {
    // setInterval(() => {
    this.pin = this.$refs.pin.getBoundingClientRect();
    if(this.marker.vector_enable)
    {
      this.arrow = this.$refs.arrow.getBoundingClientRect();
      this.arrowData = {
        force: this.getForce(),
        angle: this.getAngle(),
      };
    }
    //   console.log(this.pin);
    // }, 1000);
  },
  // beforeDestroy() {
  //   console.log('before destroy')
  //   this.showPopover = false
  //   console.log(this.showPopover)
  // },
  methods: {
    getLatestReading(instrument) {
      if (this.lodash.isEmpty(instrument.last_reading)) {
        return this.$t('data.none');
      } else {
        return `${instrument.last_reading[instrument.display_output]} ${
          instrument.data[instrument.data.length - 1].unit
        }`;
      }
    },
    c(c, mw, w) {
      return Math.round(((c - mw) / w) * 100);
    },
    s(w, mw) {
      return `calc(${w}% - ${mw / 2}px)`;
    },
    togglePopover() {
      if (this.triggerType === 'manual') {
        this.showPopover = !this.showPopover;
      }
      //console.log(`${this.triggerType}`);
    },
    selectPin(marker) {
      this.$emit('select', marker);
      this.showPopover = false;
    },
    deletePin(markerId) {
      this.$emit('delete', markerId);
    },
    getAngle(){
      const getReading = instrumentID => {
        let result = null;
        this.marker.instrument.forEach(element => {
          if(element._id===instrumentID) {
            result = element.last_reading.reading2;
            //console.log(`${element.data[element.data.length-1].name} : ${result}`);
            return;
          }
        });
        return result;
      };
      let x = getReading(this.marker.vector_x);
      let y = getReading(this.marker.vector_y);
      let angle = Math.atan2(y,x) / Math.PI * 180;
      if(angle<0) angle = angle + 360;
      //angle = 360 - angle;
      angle += this.marker.angle_offset;
      //console.log(`get angle ${angle}`);
      return angle;
    },
    getForce(){
      //return Math.ceil(Math.random() * 100 + 50)/100;
      const unitBuffList = {null:1,'度':1,'分':60,'秒':3600};
      let unitBuff = unitBuffList[this.marker.angle_unit];
      if(!unitBuff) unitBuff = 1;
      const getReading = instrumentID => {
        let result = null;
        this.marker.instrument.forEach(element => {
          if(element._id===instrumentID) {
            result = element.last_reading.reading2 / unitBuff;
            return;
          }
        });
        return result;
      };
      const min_R = 0;
      const max_R = 1;//7.071067812
      const min_step_force = 150;
      const max_step_force = 800;
      const max_force = 1500;
      let x = getReading(this.marker.vector_x);
      let y = getReading(this.marker.vector_y);
      let r = Math.sqrt(x*x+y*y);
      let force = r / (max_R - min_R) * (max_step_force - min_step_force) + min_step_force;
      //console.log(`get r ${r}`);
      if(r<min_R) return 0;
      if(force>=max_force) return max_force/100;
      //console.log(`get force ${force}`);
      return (force)/100;
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/focus-point';
</style>

<style lang="stylus" scoped>
.is-editing
  .focus-point-pin
    color red !important
.icon-close
  position absolute
  top 8px
  right 8px
  cursor pointer
.list-container
  max-height 50vh
  overflow auto
.ArrowAngle
  color black
  position absolute
  z-index 11
.Link
  color black
  font-size 18px
  font-weight bold
</style>
