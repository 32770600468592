<template>
  <div class="flex-column" style="padding:0 20px;">
    <h6 class="title is-6">
      {{ $t('dashboard.showModuleCount') }}
    </h6>
    <div class="flex-row align-center">
      <el-select v-model="newBloctLimit" @change="updateBlockCount">
        <el-option
          v-for="i in getBlockCountSelectOptionList"
          :key="i"
          :value="i"
          :label="i"
        />
      </el-select>
    </div>
    <h6 class="title is-6" style="margin-top: 20px;">
      {{ $t('dashboard.editModule') }}
    </h6>
    <div v-for="(block,index) in tempSetting" :key="block">
      <div class="flex-row align-center" :style="{padding:'10px'}">
        <div class="label">{{index}}</div>
        <div style="width:30%">
          <el-select v-model="block.showType">
            <el-option
              v-for="showType in showTypes"
              :key="showType"
              :value="showType"
              :label="showType"
            />
          </el-select>
        </div>
        <div style="width:60%">
          <el-slider
            v-model="block.width"
            :min = "0"
            :max = "100"
            show-input
            style="margin-left: 5px;"
          >
          </el-slider>
        </div>
      </div>
      <div v-if="block.showType==='chart'" style="margin-left: 24px;">
        <el-cascader
          v-model="chartInstrumentData[index]"
          :props="props"
          :options="projectOptions"
          :show-all-levels="false"
          @change="updateChartInstrumentSelection(index)"
        />
      </div>
      <div v-else-if="block.showType==='layout'" style="margin-left: 24px;">
        <el-select v-model="block.data">
            <el-option
              v-for="image in imageData"
              :key="image"
              :value="image.value"
              :label="image.label"
            />
          </el-select>
      </div>
      <div v-if="block.showType==='voltage'" style="margin-left: 24px;">
        <el-select v-model="block.data">
          <el-option
            v-for="option in voltageOptions"
            :key="option"
            :value="option.value"
            :label="option.label"
          />
        </el-select>
      </div>
    </div>
    <div class="flex-row align-center" :style="{padding:'20px'}">
      <el-button @click="saveSettingForUser" type="primary" size="mini">
        {{ $t('dashboard.save' )}}
      </el-button>
      <el-button v-if="isAdmin" @click="saveSettingForProject" type="primary" size="mini">
        {{ $t('dashboard.updateToThisProject' )}}
      </el-button>
    </div>
  </div>  
</template>

<script>

export default{
  props: {
    dashboardSetting: {
      type: Array,
      required: true,
    },
    projectOptions: {
      type: Array,
      require: true,
    },
    voltageOptions: {
      type: Array,
      require: true,
    },
    imageList: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      tempSetting: [],
      chartInstrumentData: {},
      voltageGaugeInstrumentData: {},
      blockCountLimit: 15,
      newBloctLimit: 0,
      showTypes: [
        'project_name',
        'chart',
        'layout',
        'voltage',
        'richtext',
      ],
      props: {
        multiple: true,
        //expandTrigger: 'hover',
      },
      imageData: [],
    };
  },
  computed: {
    getBlockCountSelectOptionList(){
      let res = [];
      for(let i = 0; i < this.blockCountLimit; i++){
        res.push(i);
      }
      return res;
    },
    isAdmin(){
      return this.$store.getters['user/isAdmin'];
    },
  },
  mounted(){
    this.init();
  },
  methods: {
    init(){
      //console.log(this.dashboardSetting);
      //console.log(this.projectOptions);
      this.tempSetting = JSON.parse(JSON.stringify(this.dashboardSetting));
      this.newBloctLimit = this.tempSetting.length;

      
      //this.optionInit();
      for(let i in this.tempSetting){
        if(this.tempSetting[i].showType === 'chart'){
          this.chartInstrumentData[i] = this.covertListStringToInstrumentsList(this.tempSetting[i].data);
        }
      }
      this.imageInit();
    },
    optionInit(){
      this.projectOptions.forEach(area=>{
        area.value = [];
        area.children.forEach(station=>{
          station.value = [];
          station.children.forEach(instrument=>{
            area.value.push(instrument.value);
            station.value.push(instrument.value);
          });
        });
      });
    },
    imageInit(){
      let count = -1;
      this.imageData = this.imageList.map(image=>{
        count++;
        return {
          label: image.name,
          value: count,
        };
      });
      console.log(this.imageData);
    },
    saveSettingForUser(){
      console.log('save!!');
      this.$emit('SubmitUserSetting',this.tempSetting);
    },
    saveSettingForProject(){
      console.log('save!!');
      this.$emit('SubmitProjectSetting',this.tempSetting);
    },
    updateBlockCount(){
      if(this.newBloctLimit<3){
        this.$notifyError('儀錶板格數不可低於3格', '儀錶板格數不可低於3格');
        this.newBloctLimit = this.tempSetting.length;
        return;
      }
      const oriBlockCount = this.tempSetting.length;
      //console.log(`oriBlockCount: ${oriBlockCount} ,newBloctLimit: ${this.newBloctLimit}`);
      if(oriBlockCount > this.newBloctLimit){
        for(let i = 0; i < oriBlockCount - this.newBloctLimit; i++){
          this.tempSetting.pop();
        }
      }
      else{
        for(let i = 0; i < this.newBloctLimit - oriBlockCount; i++){
          this.tempSetting.push(
            {
              width : 100,
              showType : 'test',
            },
          );
        }
      }
      //console.log(this.tempSetting);
    },
    covertListStringToInstrumentsList(instrumentListString){
      if(instrumentListString[0]=='t') return this.covertTreeStringToInstrumentsList(instrumentListString);
      this.covertTreeStringToInstrumentsList('t10..de0');
      
      const cut = instrumentListString.split('..');
      let area = 0, station = 0, instrument = 0;

      if(cut[0] === '*') {
        let res = [];
        this.projectOptions.forEach(areaNode=>{
          areaNode.forEach(stationNode=>{
            stationNode.forEach(instrumentNode=>{
              res.push([undefined,undefined,instrumentNode.value]);
            });
          });
        });
        return res;
      }
      if(cut[0] && !isNaN(cut[0])){
        area = cut[0];
      }
      if(cut[1] === '*') {
        let res = [];
        let areaNode = this.projectOptions[area];
        areaNode.forEach(stationNode=>{
          stationNode.forEach(instrumentNode=>{
            res.push([undefined,undefined,instrumentNode.value]);
          });
        });
        return res;
      }
      if(cut[1] && !isNaN(cut[1])){
        station = cut[1];
      }
      if(cut[2] === '*') {
        let res = [];
        let areaNode = this.projectOptions[area];
        let stationNode = areaNode.children[station];
        stationNode.children.forEach(instrumentNode=>{
          res.push([undefined,undefined,instrumentNode.value]);
        });
        return res;
      }
      if(cut[2] && !isNaN(cut[2])){
        instrument = cut[2];
      }
      return [[undefined,undefined,this.projectOptions[area].children[station].children[instrument].value]];
    },
    covertTreeStringToInstrumentsList(instrumentTreeString){
      let cut = instrumentTreeString.split('t');
      let tree = this.unzipTree(cut[1]);
      let instrumentList = [];

      this.projectOptions.forEach(area=>{
        area.children.forEach(station=>{
          station.children.forEach(instrument=>{
            instrumentList.push(instrument.value);
          });
        });
      });

      let res = [];
      for(let index in instrumentList){
        if(tree[index] && tree[index] == '1') res.push([undefined,undefined,instrumentList[index]]);
      }

      console.log(res);
      return res;
    },
    covertInstrumentListToListString(instrumentList){
      let res = '';
      let instrumentListJustId = [];
      instrumentList.forEach(ele=>{
        instrumentListJustId.push(ele[2]);
      });

      this.projectOptions.forEach(area=>{
        area.children.forEach(station=>{
          station.children.forEach(instrument=>{
            if(~instrumentListJustId.indexOf(instrument.value) != 0) res+='1';
            else res+='0';
          });
        });
      });
      res = 't' + this.zipTree(res);
      console.log(res);
      return res;

      //console.log(instrumentList);
      //const len = instrumentList.length;
      //if(len<=1) return '*..*..*';
      //let areaNum = '*',stationNum = '*',instrumentNum = '*';
      //this.projectOptions[0].children.forEach((area,index)=>{
      //  if(area.value===instrumentList[1]){
      //    areaNum = index;
      //    if(len==2) return;
      //    area.children.forEach((station,index)=>{
      //      if(station.value===instrumentList[2]){
      //        stationNum = index;
      //        if(len==3) return;
      //        station.children.forEach((instrument,index)=>{
      //          if(instrument.value===instrumentList[3]) instrumentNum = index;
      //          return;
      //        });
      //        return;
      //      }
      //      return;
      //    });
      //  }
      //});
      //return `${areaNum}..${stationNum}..${instrumentNum}`;
    },
    updateChartInstrumentSelection(index){
      //this.tempSetting.data = this.covertInstrumentListToListString(this.chartInstrumentData[index]);
      let res = this.covertInstrumentListToListString(this.chartInstrumentData[index]);
      console.log(res);
      this.tempSetting[index].data = res;
    },
    unzipTree(tree){
      let res = '';
      const cut = tree.split('..');
      const bitCount = cut[0];
      const hexs = cut[1];
      let bits = '';
      for(let i = 0; i < hexs.length; i++){
        let hex = hexs[i];
        switch(hex){
          case '0':
            bits+='0000';
            break;
          case '1':
            bits+='0001';
            break;
          case '2':
            bits+='0010';
            break;
          case '3':
            bits+='0011';
            break;
          case '4':
            bits+='0100';
            break;
          case '5':
            bits+='0101';
            break;
          case '6':
            bits+='0110';
            break;
          case '7':
            bits+='0111';
            break;
          case '8':
            bits+='1000';
            break;
          case '9':
            bits+='1001';
            break;
          case 'a':
            bits+='1010';
            break;
          case 'b':
            bits+='1011';
            break;
          case 'c':
            bits+='1100';
            break;
          case 'd':
            bits+='1101';
            break;
          case 'e':
            bits+='1110';
            break;
          case 'f':
            bits+='1111';
            break;
          default:
            bits+='0000';
            break;
        }
      }
      for(let i = 0; i < bitCount; i++){
        res+=bits[i];
      }
      return res;
    },
    zipTree(tree){
      let res = '';
      const bitCount = tree.length;
      let hexs = '';

      let tmp = '';
      const remain = 4 - tree.length%4;
      for(let i = 0; i < remain; i++){
        tree+='0';
      }
      for(let i = 0; i < tree.length; i++){
        tmp+=tree[i];
        if(tmp.length==4){
          let hex;
          switch(tmp){
            case '0000':
              hex = '0';
              break;
            case '0001':
              hex = '1';
              break;
            case '0010':
              hex = '2';
              break;
            case '0011':
              hex = '3';
              break;
            case '0100':
              hex = '4';
              break;
            case '0101':
              hex = '5';
              break;
            case '0110':
              hex = '6';
              break;
            case '0111':
              hex = '7';
              break;
            case '1000':
              hex = '8';
              break;
            case '1001':
              hex = '9';
              break;
            case '1010':
              hex = 'a';
              break;
            case '1011':
              hex = 'b';
              break;
            case '1100':
              hex = 'c';
              break;
            case '1101':
              hex = 'd';
              break;
            case '1110':
              hex = 'e';
              break;
            case '1111':
              hex = 'f';
              break;
            default:
              hex = 0;
              break;
          }
          hexs+=hex;
          tmp='';
        }
      }
      res = bitCount + '..' + hexs;
      return res;
    },
  },
};
</script>

<style lang="stylus">
.el-cascader__tags .el-tag > span
  flex-basis auto
.el-drawer__body
  overflow auto
</style>

<style lang="stylus" scoped>
  .label
    width: 24px
</style>