<template lang="html">
  <div>
    <enhanced-table
      title="專案列表"
      :table-data="projectList"
      :col-configs="colConfigs"
    >
      <template v-slot:actions="{ colConfig }">
        <el-table-column v-bind="colConfig">
          <template v-slot="{ row }">
            <div class="flex-row justify-end">
              <el-button
                type="warning"
                size="small"
                @click="editProjectArea(row)"
              >
                設定區域
              </el-button>
              <el-button type="primary" size="small" @click="goToEdit(row)">
                編輯
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
    </enhanced-table>
    <el-dialog :visible.sync="showProjectAreaForm" :title="selectedProject.project_id">
      <project-area-form :project-id="selectedProject._id"></project-area-form>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import EnhancedTable from '@/components/common/EnhancedTable';
import ProjectAreaForm from '@/components/project/ProjectAreaForm';
export default {
  components: {
    EnhancedTable,
    ProjectAreaForm,
  },
  data() {
    const vm = this;
    return {
      showProjectAreaForm: false,
      selectedProject: '',
      colConfigs: [
        {
          label: '專案名稱',
          prop: 'project_id',
        },
        {
          label: '地點',
          prop: 'location',
        },
        {
          label: '區域',
          prop: 'area',
          'show-overflow-tooltip': true,
          formatter(row, column, cellValue) {
            return cellValue
              .map(station => {
                const result = vm.areaList.find(ele => {
                  return ele._id === station;
                });
                if (result) {
                  return result.name;
                } else {
                  return station;
                }
              })
              .join(', ');
          },
        },
        {
          label: '開始時間',
          prop: 'start_date',
          formatter(row, column, cellValue) {
            return cellValue ? moment(cellValue).format('YYYY/MM/DD') : '';
          },
        },
        { slot: 'actions' },
      ],
    };
  },
  computed: {
    projectList() {
      return this.$store.getters['project/projectList'];
    },
    areaList() {
      return this.$store.getters['data/areaList'];
    },
  },
  mounted() {
    this.getAreaList(true);
  },
  methods: {
    getAreaList(isUpdate = false) {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getAreaList', isUpdate)
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    editProjectArea(row) {
      this.selectedProject = row;
      this.showProjectAreaForm = true;
    },
    dateFormat(row, column) {
      const date = row[column.property];
      if (!date) {
        return '';
      }
      return moment(date).format('YYYY/MM/DD');
    },
    goToEdit(row) {
      // this.$router.push({ name: 'admin-project', query: { projectId: row._id } });
      this.$router.push({
        query: Object.assign({}, this.$route.query, { projectId: row._id }),
      });
      this.$emit('change-tab', 'edit');
    },
  },
};
</script>
