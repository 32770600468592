<template>
  <el-card>
    <el-form
      ref="form"
      :model="form"
      :validate-on-rule-change="false"
      :rules="registerRules"
      :inline="true"
      :hide-required-asterisk="true"
      label-position="top"
      label-width="80px"
    >
      <el-row v-if="formType === 'edit'">
        <el-form-item>
          <label slot="label" class="label">選擇客戶</label>
          <el-select
            v-model="selectedClient"
            filterable="filterable"
            :filter-method="filterMethod"
            default-first-option="default-first-option"
            style="width:610px;"
            placeholder="選擇客戶"
            @change="updateFormDetails"
          >
            <el-option
              v-for="client in filteredClientList"
              :key="client._id"
              :label="client.email"
              :value="client._id"
            >
              <span class="is-pulled-left" style="margin-right: 10px;">{{
                client.name
              }}</span
              ><span
                style="margin-right: 16px;color: #8492a6; font-size: 13px;"
                >{{ client.email }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item prop="email">
          <label slot="label" class="label">電子郵箱</label>
          <el-input
            v-model="form.email"
            @keyup.enter.native="submitForm"
            placeholder="電子郵箱"
            type="email"
            style="width:300px;"
          />
        </el-form-item>
        <el-form-item>
          <label slot="label" class="label">姓名</label>
          <el-input
            v-model="form.name"
            @keyup.enter.native="submitForm"
            placeholder="電子郵箱"
            style="width:300px;"
          />
        </el-form-item>
        <el-form-item>
          <label slot="label" class="label">單位搜尋</label>
          <el-select v-model="selectCompany" placeholder="請選擇" @change="companyFilter">
            <el-option
              v-for="item in companyList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item>
          <label slot="label" class="label">單位</label>
          <el-input
            v-model="form.company"
            @keyup.enter.native="submitForm"
            placeholder="單位"
            style="width:300px;"
          />
        </el-form-item>
        <el-form-item prop="country">
          <label slot="label" class="label">區域碼</label>
          <el-select
            v-model="form.country.code"
            :filter-method="searchCountry"
            class="mr-8"
            filterable="filterable"
            style="width:90px;"
          >
            <el-option
              v-for="country in filteredCountryCodeList"
              :key="country.ISO + country.Code"
              :label="`+${country.Code}`"
              :value="country.Code"
            >
              <div class="flex-row justify-between">
                <div class="flex-row">
                  <span class="is-uppercase mr-16 has-text-weight-bold"
                    >{{ country.ISO }} </span
                  ><span>{{ country.Name }}</span>
                </div>
                <span
                  style="margin-right: 16px;color: #8492a6; font-size: 13px;"
                  >+{{ country.Code }}</span
                >
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="phone">
          <label slot="label" class="label">聯絡電話</label>
          <el-input
            v-model="form.phone"
            @keyup.enter.native="submitForm"
            placeholder="聯絡電話"
            style="width:200px;"
          />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item prop="password">
          <label slot="label" class="label">密碼</label>
          <el-input
            v-model="form.password"
            :minlength="6"
            @keyup.enter.native="submitForm"
            placeholder="密碼"
            type="password"
            style="width:610px;"
          />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item prop="passwordCheck">
          <label slot="label" class="label">確認密碼</label>
          <el-input
            v-model="form.passwordCheck"
            :minlength="6"
            @keyup.enter.native="submitForm"
            placeholder="確認密碼"
            type="password"
            style="width:610px;"
          />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item>
          <label slot="label" class="label">用戶權限</label>
          <el-select v-model="form.roles" placeholder="請選擇">
            <el-option
              v-for="item in rolesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-checkbox v-model="form.messageEnable.sms">
          使用SMS訊息
        </el-checkbox>
        <el-checkbox v-model="form.messageEnable.email">
          使用Email訊息
        </el-checkbox>
        <el-checkbox v-model="form.messageEnable.line">
          使用Line訊息
        </el-checkbox>
      </el-row>
    </el-form>
    <el-button type="primary" @click="submitForm" :disabled="disableSubmit">
      {{ formType === "create" ? "新增客戶" : "更新客戶" }}
    </el-button>
    <el-button type="primary" @click="disableClient" :disabled="disableSubmit" v-if="formType != 'create'">
      {{ "凍結客戶" }}
    </el-button>
  </el-card>
</template>

<script>
import { countryCodeList } from '@/lib/country';
import { coverObject } from '@/lib/base/helper';
import { clientVersion } from '../../constants/env';
export default {
  name: 'RegisterPage',
  props: {
    formType: {
      type: String,
      default: 'create',
      required: true,
      validator: type => {
        return ['create', 'edit'].includes(type);
      },
    },
  },
  data() {
    return {
      countryCodeList,
      filteredCountryCodeList: countryCodeList,
      form: {
        email: '',
        password: '',
        passwordCheck: '',
        phone: '',
        name: '',
        company: '',
        country: {
          code: '',
        },
        messageEnable: {
          email: true,
          sms: true,
          line: true,
        },
        roles: 'client',
      },
      selectedClient: '',
      // allClientList儲存全部用戶名單 
      // clientList 儲存單位搜尋後的名單
      // filteredClientList 儲存"選擇客戶"欄位輸入搜尋後的名單
      allClientList: [],
      clientList: [],
      filteredClientList: [],

      companyList: [],
      isLoading: false,
      selectCompany: '',
    };
  },
  computed: {
    registerRules() {
      const validatePass = (rule, value, callback) => {
        if (value.length < 5) callback(new Error('密碼不能小於6位'));
        else callback();
      };
      const validatePassCheck = (rule, value, callback) => {
        if (value === '') callback(new Error('請再次輸入密碼'));
        else if (value !== this.form.password)
          callback(new Error('兩次輸入密碼不一致!'));
        else callback();
      };
      if (this.formType === 'create') {
        return {
          email: [
            { required: true, message: '請輸入郵箱', trigger: 'blur' },
            {
              type: 'email',
              message: '請輸入正確的郵箱',
              trigger: 'blur',
            },
          ],
          password: [
            { required: true, trigger: 'blur', validator: validatePass },
          ],
          passwordCheck: [
            { required: true, trigger: 'blur', validator: validatePassCheck },
          ],
        };
      } else {
        return {
          email: [
            { required: true, message: '請輸入郵箱', trigger: 'blur' },
            {
              type: 'email',
              message: '請輸入正確的郵箱',
              trigger: 'blur',
            },
          ],
        };
      }
    },
    disableSubmit() {
      if (this.formType === 'create') {
        return (
          this.form.email.length === 0 ||
          this.form.password.length === 0 ||
          this.form.passwordCheck.length === 0
        );
      } else {
        return this.form.email.length === 0;
      }
    },
    thsrRole() {
      return clientVersion === 'thsr-main' || clientVersion === 'test';
    },
    rolesOptions() {
      if(this.thsrRole){
        return [
          {
            value: 'client',
            label: '一般用戶',
          }, {
            value: 'admin',
            label: '管理員',
          }, {
            value: 'occ',
            label: 'OCC',
          }, {
            value: 'thsr',
            label: '高鐵用戶',
          }, 
        ];
      }
      else{
        return [
          {
            value: 'client',
            label: '一般用戶',
          }, {
            value: 'admin',
            label: '管理員',
          }, 
        ];
      }
    },
  },
  mounted() {
    if (this.formType === 'edit') {
      this.getUserList();
    }
  },
  methods: {
    updateFormDetails() {
      this.resetForm();
      const client = this.clientList.find(client => {
        return client._id === this.selectedClient;
      });
      coverObject(this.form, client);
    },
    resetForm() {
      this.form = {
        email: '',
        password: '',
        passwordCheck: '',
        phone: '',
        name: '',
        company: '',
        country: {
          code: '',
        },
        messageEnable: {
          email: true,
          sms: true,
          line: true,
        },
        roles: 'client',
      };
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.formType === 'create') {
            const response = await this.$confirmCreate('此操作將新增用戶');
            if (response === 'confirm') {
              this.registerUser();
            }
          } else {
            const response = await this.$confirmCreate('此操作將更新用戶');
            if (response === 'confirm') {
              this.updateUser();
            }
          }
        } else {
          return false;
        }
      });
    },
    searchCountry(search) {
      let list = this.countryCodeList;
      if (search) {
        list = this.countryCodeList.filter(country => {
          return Object.keys(country).some(key => {
            return (
              String(country[key])
                .toLowerCase()
                .indexOf(search) > -1
            );
          });
        });
      }
      this.filteredCountryCodeList = list;
    },
    getUserList() {
      return this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'client',
      })
        .then(res => {
          this.allClientList = res.data;
          this.clientList = this.allClientList;
          this.filteredClientList = this.clientList;
          this.companyList = [];
          this.selectCompany = '';
          this.clientList.forEach(ele=>{
            if(!this.companyList.includes(ele.company)) {
              this.companyList.push(ele.company);
            }
          });
          this.companyList = this.companyList.map(ele=>{
            return {
              value:  ele,
              label:  ele,
            };
          });
        })
        .catch(this.$notifyError);
    },
    registerUser() {
      this.isLoading = true;
      return this.$axios({
        category: 'admin',
        method: 'post',
        apiName: 'register',
        data: this.form,
      })
        .then(() => {
          this.$notifySuccess('註冊成功');
          this.resetForm();
          this.getUserList();
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateUser() {
      this.isLoading = true;
      return this.$axios({
        category: 'admin',
        method: 'put',
        apiName: `client/${this.selectedClient}`,
        data: this.form,
      })
        .then(() => {
          this.$notifySuccess('更新成功');
          this.getUserList();
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    filterMethod(search) {
      console.log(search);
      let list = this.clientList;
      if (search) {
        list = this.clientList.filter(client => {
          let clientObj = Object.keys(client);
          return clientObj.some(
            key => {
              return (
                String(client[key])
                  .toLowerCase()
                  .indexOf(search) > -1
              );
            },
          );
        });
      }
      this.filteredClientList = list;
    },
    disableClient() {
      this.isLoading = true;
      return this.$axios({
        category: 'admin',
        method: 'put',
        apiName: `disableClient/${this.selectedClient}`,
        data: this.form,
      })
        .then(res => {
          let datas = res.data.map(ele=>{
            let project_alert = 
              (ele.origin.alert_target.length - ele.set.alert_target.length) > 0 ||
              (ele.origin.warning_target.length - ele.set.warning_target.length) > 0 ||
              (ele.origin.action_target.length - ele.set.action_target.length) > 0 || 
              (ele.origin.down_target.length - ele.set.down_target.length) > 0 ||
              (ele.origin['document_message.down_target'].length - ele.set['document_message.down_target'].length) > 0;
            return {
              project_name: ele.project,
              project_client: (ele.origin.client_id.length - ele.set.client_id.length) > 0,
              project_alert,
            };
          });
          
          let project_client_count = 0;
          let project_alert_count = 0;
          for(let data of datas){
            if(data.project_client) project_client_count++;
            if(data.project_alert) project_alert_count++;
          }
          this.$notify({
            title: '凍結成功',
            message: `將人員${this.form.name}從${project_client_count}個專案中移除，並將人員從${project_alert_count}個專案中移除簡訊設定。`,
            type: 'info',
          });
          this.resetForm();
          this.getUserList();
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    companyFilter() {
      let index = -1;
      for(let i in this.companyList){
        if(this.companyList[i].value == this.selectCompany){
          index = i;
          break;
        } 
      }
      if(index == 0 || index == -1){
        this.clientList = this.allClientList;
        return;
      }
      this.clientList = this.allClientList.filter(ele=>ele.company == this.selectCompany);
      this.filterMethod();
    },
  },
};
</script>
