<template>
  <div class="enhanced-table-container">
    <el-card
      class="is-borderless"
      shadow="never"
    >
      <div class="table-header flex-row align-center justify-between">
        <div
          class="table-title"
          v-if="title"
        >
          <span>{{ title }}</span>
        </div>
        <slot name="header" />
        <div
          v-if="radioConfigs"
        >
          <el-radio-group v-model="radioValue" :style="radioOption">
            <el-radio v-for="(item, index) in radioConfigs" :key="index" :label="item.value">{{ item.text }}</el-radio>
          </el-radio-group>
        </div>
        <el-input
          v-if="showSearch"
          v-model="searchInput"
          class="search-input"
          placeholder="搜索..."
        />
      </div>
      <el-table
        ref="table"
        v-loading="isLoading"
        v-bind="tableOptions"
        :data="showingTableData"
        @sort-change="handleSortChange"
        @selection-change="changeSelectRows"
        @row-click="handleRowClick"
        @cell-click="handleCellClick"
        :row-class-name="rowClassName"
      >
        <!-- region 选择框-->
        <!-- <el-table-column type="selection" v-if="multiSelect"></el-table-column> -->
        <!--endregion -->
        <!-- region 数据列 -->
        <template v-for="colConfig in colConfigs">
          <slot
            v-if="colConfig.slot"
            :name="colConfig.slot"
            :col-config="colConfig"
          ></slot>
          <component
            v-else-if="colConfig.component"
            :is="colConfig.component"
            :col-config="colConfig"
            :key="colConfig.label"
          ></component>
          <el-table-column
            v-if="colConfig.urlBtn===true"
            v-bind="colConfig"
            :key="colConfig.label"
          >
            <template slot-scope="scope">
              <router-link
                class="button is-small"
                :to="{ path: scope.row[scope.column.property].urlBtn, query: { instrument: scope.row[scope.column.property].prop, startDate: scope.row['startDate'] } }"
              >
                {{ scope.row[scope.column.property].block }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
            v-else-if="colConfig.urlBtn==='chart'"
            v-bind="colConfig"
            :key="colConfig.label"
          >
            <template slot-scope="scope">
              <router-link
                class="button is-small"
                :to="{ path: 'chart', query: { instrument: scope.row[scope.column.property], startDate: scope.row['startDate'] } }"
              >
                {{ colConfig.block }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
            v-else-if="colConfig.urlBtn==='snakeline'"
            v-bind="colConfig"
            :key="colConfig.label"
          >
            <template slot-scope="scope">
              <router-link
                class="button is-small"
                :to="{ path: '/storm/snake-line', query: { instrument: scope.row[scope.column.property], event: scope.row['startTime'] } }"
              >
                {{ colConfig.block }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            v-bind="colConfig"
            :sortable="checkHasProperty(colConfig, 'sortable') ? colConfig.sortable : true"
            :key="colConfig.label"
            :formatter="colConfig.formatter"
          ></el-table-column>
        </template>
        <!--endregion-->
      </el-table>
    </el-card>
    <div
      class="pagination-container flex-row align-center justify-between"
      v-if="showPagination || showTotal"
    >
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        :hide-on-single-page="true"
        :size="paginationSize"
        :total="filteredTableData.length"
      ></el-pagination>
      <span
        class="table-total"
        v-if="showTotal"
      >
        Total: {{ showingTableData.length }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'enhanced-table',
  props: {
    title: {
      type: String,
      required: false,
    },
    colConfigs: Array,
    tableData: {
      type: Array,
      default: () => [],
    },
    tableOptions: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    radioConfigs: Array,
    rightRadioOption: {
      type: Boolean,
      default: false,
    },
    // multiSelect: {
    //   type: Boolean,
    //   default: false,
    // },
    defaultRadioValue: {
      default: '',
    },
  },
  data() {
    return {
      searchInput: '',
      currentPage: 1,
      paginationSize: 9,
      multipleSelection: [],
      tempTableData: [],
      currentSort: {},
      radioValue: this.defaultRadioValue,
    };
  },
  computed: {
    filteredTableData() {
      let tableData = [...this.tempTableData];
      //console.log(this.tempTableData);
      const search = this.searchInput.toLowerCase();
      if (search.length > 0) {
        tableData = this.search(tableData, search);
      }

      const radioSerch = this.radioValue;
      if(radioSerch){
        if(radioSerch === 'other'){
          for(let v of this.radioConfigs){
            if(v.value === '' || v.value === 'other') continue;
            let _ = this.search(tableData, v.value);
            for(let __ of _){
              let index = tableData.indexOf(__);

              if (index > -1) {
                tableData.splice(index, 1);
              }
            }
          }
        }else{
          tableData = this.search(tableData, radioSerch);
        }
      }
      return tableData;
    },
    showingTableData() {
      if (this.showPagination) {
        return this.filteredTableData.filter((ele, index) => {
          return index >= (this.currentPage - 1) * 10 && index < this.currentPage * 10;
        });
      } else {
        return this.filteredTableData;
      }
    },
    radioOption() {
      if(this.rightRadioOption) return 'text-align: right;';
      else return '';
    },
  },
  mounted() {
    if (this.tableData.length > 0) {
      this.tempTableData = [...this.tableData];
    }
  },
  watch: {
    tableData(newValue) {
      this.tempTableData = [...newValue];
    },
  },
  methods: {
    search(table,search){
      table = table.filter(data => {
        return Object.keys(data).some(key => {
          if(typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] !== null){
            return Object.keys(data[key]).some(objKeyInData=>{
              return (
                this.check(String(data[key][objKeyInData]), search)
              );
            });
          }
          return (
            this.check(String(data[key]), search)
          );
        });
      });
      return table;
    },
    check(data, search){
      if(typeof search === 'object' && search instanceof RegExp) {
        return data.match(search);
      }
      else if(search.indexOf('|') > -1){
        search = search.split('|');
        return search.find(ele => data.toLowerCase().indexOf(ele) > -1);
      }
      return data
        .toLowerCase()
        .indexOf(search) > -1;
    },
    rowClassName({ row }){
      return row.storm ? 'row-highlight' : 'row-normal';
    },
    handleSortChange({ prop, order }) {
      this.currentSort = {
        prop,
        order,
      };
      this.currentPage = 1;
      this.prop = prop;
      if (order === 'descending') {
        this.tempTableData.sort(this.descendingSort);
      } else if (order === 'ascending') {
        this.tempTableData.sort(this.ascendingSort);
      } else {
        this.tempTableData = [...this.tableData];
      }
    },
    descendingSort(a, b) {
      if (a[this.currentSort.prop] < b[this.currentSort.prop]) {
        return 1;
      }
      if (a[this.currentSort.prop] > b[this.currentSort.prop]) {
        return -1;
      }
      return 0;
    },
    ascendingSort(a, b) {
      if (a[this.currentSort.prop] < b[this.currentSort.prop]) {
        return -1;
      }
      if (a[this.currentSort.prop] > b[this.currentSort.prop]) {
        return 1;
      }
      return 0;
      // return a[this.currentSort.prop] - b[this.currentSort.prop];
    },
    checkHasProperty(object, key) {
      return Object.prototype.hasOwnProperty.call(object, key);
    },
    changeSelectRows(val) {
      this.multipleSelection = val;
      this.$emit('selection-change', val);
    },
    handleRowClick(row, column, event) {
      this.$emit('row-click', { row, column, event });
    },
    handleCellClick(row, column, cell, event) {
      this.$emit('cell-click', { row, column, cell, event });
    },
  },
};
</script>

<style lang="scss">
.table-title {
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  margin-right: 16px;
}
.enhanced-table-container {
  width: 100%;
  .table-header {
    .table-header-left {
      flex-grow: 1;
    }
    .search-input {
      margin-left: auto;
      width: 250px;
    }
  }
  .table-footer {
    margin-top: 20px;
  }
}
.pagination-container {
  margin-top: 20px;
}
.enhanced-table-container + .enhanced-table-container {
  margin-top: 16px;
}
.cell-font-weight-bold {
   .cell {
    font-weight: bold;
  }
}

.el-table .row-highlight {
  background: rgb(122, 237, 255); /* 符合条件的行背景为红色 */
}

.el-table .row-normal {
  background: white; /* 不符合条件的行背景为白色 */
}
</style>
