import moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import { saveLocalData } from '@/lib/base/localData';
window.moment = moment;
MomentTimeZone();

export const state = {
  locale: 'en-US',
};


export const getters = {
  timezone() {
    return moment.tz.guess();
  },
};

export const mutations = {
  setLocale(state, lang) {
    state.locale = lang;
    saveLocalData('locale', lang);
  },
};

export const actions = {
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
