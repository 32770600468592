<template>
  <el-dialog :visible="dialogVisible" :title="file.name" :destroy-on-close="true" @close="closeDialog" @open="resetForm" width="70%">
    <!-- <edit-form
      ref="edit"
      v-if="actionType === 'EditForm'"
      v-on:reload="reloadList"
      :editingProject="editingProject"
      :file="file"
      :projectList="projectList"
      :fileList="fileList"
    />-->
    <create-form
      ref="create"
      v-if="actionType === 'CreateForm'"
      :editing-project="editingProject"
      :file="file"
      :project-list="projectList"
      :project="project"
      @reload="reloadList"
    />
    <!-- <view-form :file="file" v-if="actionType === 'ViewForm'" :projectList="projectList" /> -->
    <edit-image ref="editImage" v-if="actionType === 'EditImage'" :file="file" :project-list="projectList" />
    <vector-form
      ref="vector"
      v-if="actionType === 'VectorForm'"
      :editing-project="editingProject"
      :file="file"
      :project-list="projectList"
      :project="project"
      @reload="reloadList"
    />
  </el-dialog>
</template>

<script>
import CreateForm from '@/components/pin/CreateForm';
// import EditForm from '@/components/pin/EditForm'
// import ViewForm from '@/components/pin/ViewForm'
import EditImage from '@/components/pin/EditImage';
import VectorForm from '@/components/pin/VectorForm';

export default {
  components: {
    // EditForm,
    // ViewForm,
    CreateForm,
    EditImage,
    VectorForm,
  },
  props: {
    editingProject: {
      type: String,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
      default: 'edit',
    },
    fileList: {
      type: Array,
      required: true,
    },
    file: {
      type: Object,
      required: true,
    },
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    projectList: {
      type: Array,
      required: true,
    },
    project: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dirty: false,
    };
  },
  methods: {
    reloadList() {
      this.$emit('reload');
    },
    closeDialog() {
      this.$emit('close');
    },
    resetForm() {
      if (!this.dirty) {
        this.dirty = true;
        return;
      }
      if (this.actionType === 'EditForm') {
        if (this.$refs.edit) this.$refs.edit.initState();
      } else if (this.actionType === 'CreateForm') {
        if (this.$refs.create) this.$refs.create.initState();
      } else if (this.actionType === 'EditImage') {
        if (this.$refs.editImage) {
          this.$refs.editImage.initState();
        }
      } else if (this.actionType === 'VectorForm') {
        if (this.$refs.vector) {
          this.$refs.vector.initState();
        }
      }
      
    },
  },
};
</script>
