<template>
  <div class="container" ref="mapDiv">
    <div ref="toolbar" class="esri-widget esri-component custom-toolbar" style="background-color: transparent;">
      <p><el-button style="width: 110px;" @click="showWarn('now')">即時狀態</el-button></p>
      <p><el-button style="width: 110px; text-align: center; padding-left: 5px;" @click="showWarn('dayago')">七日內告警邊坡</el-button></p>
    </div>
  </div>
</template>

<script>
import { loadModules } from 'esri-loader';
import moment from 'moment';

export default {
  props: {
    projectData: {
      type: Object,
      default: () => ({}),
    },
  },
  name: 'MapComponent',
  data() {
    return {
      map: null,
      view: null,
      resData: null,
      PictureMarkerSymbol: null,
      PopupTemplate: null,
      Graphic: null,
      dayago: 7,
      redCloudgreen: require('@/assets/imgur/9Tcpp5d.png'),
      yellowCloudgreen: require('@/assets/imgur/LVwjlqa.png'),
      purpleCloudgreen: require('@/assets/imgur/5jXdygt.png'),
      orangeCloudgreen: require('@/assets/imgur/Z0bqNJc.png'),
      
      redCloudyellow: require('@/assets/imgur/5VM2v4g.png'),
      yellowCloudyellow: require('@/assets/imgur/YseKz7N.png'),
      purpleCloudyellow: require('@/assets/imgur/25LCtbs.png'),
      orangeCloudyellow: require('@/assets/imgur/mTtmTuG.png'),

      redCloudred: require('@/assets/imgur/NjtSmNC.png'),
      yellowCloudred: require('@/assets/imgur/LJKbwgx.png'),
      purpleCloudred: require('@/assets/imgur/WJBOw9S.png'),
      orangeCloudred: require('@/assets/imgur/LzzDYT7.png'),

      greenMarkpic: require('@/assets/imgur/YFM5Dy8.png'),
      redMarkpic: require('@/assets/imgur/1ZgpXEY.png'),
      yellowMarkpic: require('@/assets/imgur/pwS4LcL.png'),
      geryMarkpic: require('@/assets/imgur/CDr09FQ.png'),
      heavy_rain: require('@/assets/imgur/DA6hgRc.png'),
      torrential_rain: require('@/assets/imgur/gVFqPfm.png'),
      super_torrential_rain: require('@/assets/imgur/51n7s67.png'),
      extreme_torrential_rain: require('@/assets/imgur/V8sXx7R.png'),
      cwa_location: [
        { id: '蘆竹C0C620', lat: 25.084275, lon: 121.265767, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '新埔C0D380', lat: 24.84745, lon: 121.03834, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '寶山C0D580', lat: 24.735006, lon: 121.025192, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '峨嵋C0D430', lat: 24.6905, lon: 121.017189, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '竹南C0E420', lat: 24.708978, lon: 120.888897, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '頭份C0E730', lat: 24.688247, lon: 120.912206, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '大河C0E850', lat: 24.618069, lon: 120.948578, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '明德C0E550', lat: 24.583486, lon: 120.885411, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '後龍C0E540', lat: 24.606489, lon: 120.783933, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '南勢C0E601', lat: 24.572486, lon: 120.734842, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '苑裡C0E830', lat: 24.439714, lon: 120.653053, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '大甲C0E930', lat: 24.347667, lon: 120.640403, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '芬園C0G620', lat: 24.015678, lon: 120.6213, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: '員林C0G650', lat: 23.946503, lon: 120.585597, status: 'GREEN', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
      ],
      rain_location: [
        { id: 'TK28+489', lat: 25.045541, lon: 121.358978, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK31+778', lat: 25.058802, lon: 121.32953, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK66+215', lat: 24.865008, lon: 121.085469, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK70+730', lat: 24.840836, lon: 121.073438, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK77+531', lat: 24.806539, lon: 121.057912, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK78+553', lat: 24.783855, lon: 121.052813, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK81+358', lat: 24.771875, lon: 121.031551, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK83+585', lat: 24.756861, lon: 121.012448, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK86+400', lat: 24.74293, lon: 120.989688, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK88+298', lat: 24.721568, lon: 120.975465, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK93+151', lat: 24.714818, lon: 120.949359, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK93+889', lat: 24.669592, lon: 120.943558, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK96+942', lat: 24.654929, lon: 120.923997, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK98+832', lat: 24.63084, lon: 120.907514, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK99+815', lat: 24.615938, lon: 120.881343, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK100+438', lat: 24.59448, lon: 120.855503, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK107+180', lat: 24.576511, lon: 120.85509, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK117+501', lat: 24.529675, lon: 120.778129, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK118+763', lat: 24.50968, lon: 120.76296, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK123+611', lat: 24.48815, lon: 120.740359, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK125+846', lat: 24.463813, lon: 120.733528, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK126+285', lat: 24.441764, lon: 120.732152, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK147+112', lat: 24.27148, lon: 120.692476, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK171+959', lat: 24.056395, lon: 120.636527, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
        { id: 'TK172+801', lat: 24.028986, lon: 120.63442, status: '', warnStatus: 'GREEN', HR_1: 0, HR_3: 0, HR_24: 0, update_time: '' },
      ],
      locations: [
        { id: 'TK28+489', lat: 25.045541, lon: 121.328978, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK31+784', lat: 25.058802, lon: 121.29953, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK66+215', lat: 24.865008, lon: 121.055469, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK70+730', lat: 24.840836, lon: 121.043438, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK77+533', lat: 24.806539, lon: 121.027912, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK78+551', lat: 24.783855, lon: 121.022813, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK81+358', lat: 24.771875, lon: 121.001551, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK83+585', lat: 24.756861, lon: 120.982448, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK86+400', lat: 24.74293, lon: 120.959688, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK88+298', lat: 24.721568, lon: 120.945465, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK93+100', lat: 24.714818, lon: 120.919359, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK93+500', lat: 24.691449, lon: 120.915552, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK93+800', lat: 24.669592, lon: 120.913558, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK96+942', lat: 24.654929, lon: 120.893997, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK98+832', lat: 24.63084, lon: 120.877514, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK99+815', lat: 24.615938, lon: 120.851343, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK100+438', lat: 24.59448, lon: 120.825503, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK107+180', lat: 24.576511, lon: 120.80509, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK117+501', lat: 24.529675, lon: 120.748129, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK118+763', lat: 24.50968, lon: 120.73296, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK123+611', lat: 24.48815, lon: 120.710359, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK125+846', lat: 24.463813, lon: 120.703528, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK126+285', lat: 24.441764, lon: 120.702152, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK147+112', lat: 24.27148, lon: 120.662476, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK171+959', lat: 24.056395, lon: 120.606527, status: 'GREEN', warnStatus: 'GREEN' },
        { id: 'TK172+801', lat: 24.028986, lon: 120.60442, status: 'GREEN', warnStatus: 'GREEN' },
      ],
      stationName: [
        { name: '28+', lat: 25.045704, lon: 121.328976 }, { name: '31+', lat: 25.058469, lon: 121.299572 }, { name: '66+', lat: 24.860143, lon: 121.055316 },
        { name: '70+', lat: 24.820836, lon: 121.043438 }, { name: '77+', lat: 24.761307, lon: 121.027784 }, { name: '78+', lat: 24.753496, lon: 121.022314 }, { name: '81+', lat: 24.736875, lon: 121.001551 },
        { name: '83+', lat: 24.727479, lon: 120.983733 }, { name: '86+', lat: 24.713044, lon: 120.95956 }, { name: '88+', lat: 24.701184, lon: 120.94498 }, { name: '93+1', lat: 24.664703, lon: 120.919198 },
        { name: '93+5', lat: 24.661471, lon: 120.916567 }, { name: '93+8', lat: 24.659423, lon: 120.915394 }, { name: '96+', lat: 24.639965, lon: 120.894054 }, { name: '98+', lat: 24.63084, lon: 120.877514 },
        { name: '99+', lat: 24.625869, lon: 120.869438 }, { name: '100+', lat: 24.62448, lon: 120.865503 }, { name: '107+', lat: 24.596512, lon: 120.80506 }, { name: '117+', lat: 24.526223, lon: 120.738033 },
        { name: '118+', lat: 24.516351, lon: 120.732776 }, { name: '123+', lat: 24.478164, lon: 120.71033 }, { name: '125+', lat: 24.458881, lon: 120.703375 }, { name: '126+', lat: 24.455672, lon: 120.701916 },
        { name: '147+', lat: 24.27057, lon: 120.661981 }, { name: '171+', lat: 24.05661, lon: 120.606116 }, { name: '172+', lat: 24.049436, lon: 120.604517 },
      ],
      locationMap: {},
      rainData: [],
      messages: [],
      legendDiv: null,
      rain_messages: [],
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    userRole() {
      return this.$store.getters['user/details'].roles;
    },
    useStorm() {
      return this.selectedProject._id=='633d2757a4fbd61d20ee34ae' && (this.userRole === 'admin'|| this.userRole === 'thsr');
    },
  },
  async mounted() {
    this.resData = this.projectData;
    this.locations.forEach(loc => {
      this.locationMap[loc.id] = loc;
    });

    const option = {
      url: 'https://js.arcgis.com/4.14/init.js',
      css: 'https://js.arcgis.com/4.14/esri/themes/light/main.css',
    };

    // ESRI 模塊
    const [Map, MapView, Graphic, PictureMarkerSymbol, PopupTemplate] = await loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/Graphic',
      'esri/symbols/PictureMarkerSymbol',
      'esri/PopupTemplate',
    ], option);

    this.Graphic = Graphic;
    this.PictureMarkerSymbol = PictureMarkerSymbol;
    this.PopupTemplate = PopupTemplate;

    // 創建地圖
    this.map = new Map({
      basemap: 'topo-vector',
      layers: [],
    });

    // 創建地圖視圖
    this.view = new MapView({
      container: this.$refs.mapDiv,
      map: this.map,
      zoom: 9,
      center: [121.196619, 24.509756],
      constraints: {
        rotationEnabled: false,
        maxZoom: 18,
      },
    });

    this.view.ui.remove('zoom');
    this.getProject();

    this.map.on('load', function () {
      this.loading = false;
    });

    //this.view.when(() => {
    //  console.log('view');
    //});

    // 處理滑鼠滑過標記時的行為
    this.view.on('pointer-move', (event) => {
      this.view.hitTest(event).then((response) => {
        if (response.results.length > 0 && response.results[0].graphic && response.results[0].graphic.popupTemplate) {
          this.view.popup.open({
            features: [response.results[0].graphic],
            location: response.results[0].graphic.geometry,
          });
        } else {
          this.view.popup.close();
        }
      });
    });

    this.view.on('click', (event) => {
      this.view.hitTest(event).then((response) => {
        if (response.results.length > 0 && response.results[0].graphic && response.results[0].graphic.popupTemplate
          && !response.results[0].graphic.popupTemplate.title.includes('RAIN')) {
          this.$confirm(`要跳轉到地圖頁面嗎? (測站：${response.results[0].graphic.popupTemplate.title})`, '提示', {
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.toMap(response.results[0].graphic.popupTemplate.title);
          }).catch(() => {
            //none
          });
        }
      });
    });
    this.addCustomToolbar();
    if(!this.useStorm) {
      this.addMarker();
      this.showWarn('now');
      return;
    }
    this.getRainData();
    this.getRainMessage(this.dayago);
    //console.log(this.view.graphics);
  },
  methods: {
    showWarn(sw) {
      if (sw === 'dayago') {
        this.legendAdded = false;
        this.view.ui.remove(this.legendDiv);
        this.legendDiv = null;
        this.view.graphics.removeAll();
        this.addWarnMarker();
        this.addWarnRainMarker();
        this.addWarnCwaMarker();
      } else if (sw === 'now'){
        this.legendAdded = false;
        this.view.ui.remove(this.legendDiv);
        this.legendDiv = null;
        this.view.graphics.removeAll();
        this.addMarker();
        this.addRainMarker();
        this.addCwaMarker();
      }
    },
    addCustomToolbar() {
      const toolbarDiv = this.$refs.toolbar;
      this.view.ui.add(toolbarDiv, 'top-right');
    },
    getRainMessage(daysAgo) {
      // Calculate the date 'daysAgo' days ago from today
      const targetDate = new Date();
      targetDate.setDate(targetDate.getDate() - daysAgo);
      targetDate.setHours(0, 0, 0, 0);
      
      return this.$axios({
        method: 'get',
        apiName: 'admin/sms',
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      })
        .then(res => {
          res.data.map(data => {
            if (data.content.includes('燈')) {
              const createdAt = new Date(data.created_at);
              if (createdAt.getTime() >= targetDate.getTime()) {
                const pos = data.content.indexOf('雨量站');
                const content = data.content.slice(pos + 3);  // 3為"雨量站"的長度
                let cwaContent = data.content.slice(pos + 3, pos + 11);
                if (content.includes('TK')) {
                  const regex = /(TK\d+)\+\d/;
                  const result = content.match(regex);
                  if (result) {
                    let temp = content.includes('TK93') ? result[0] : result[1];
                    let obj = {
                      content,
                      'TIMESTAMP': data.created_at,
                      'station': temp,
                      'warnStatus': content.includes('黃燈') ? 'AL' : 'WSL',
                    };
                    this.rain_messages.push(obj);
                  }
                } else if (content.includes('C0') || content.includes('C1')) {
                  let obj = {
                    content,
                    'TIMESTAMP': data.created_at,
                    'station': cwaContent,
                    'warnStatus': data.content.includes('黃燈') ? 'AL' : 'WSL',
                  };
                  this.rain_messages.push(obj);
                }
              }
            }
          });
        })
        .catch(this.$notifyError)
        .finally(() => {
          //console.log(this.rain_messages);
          this.getWarnRain();
        });
    },
    getMessage(daysAgo) {
      return this.$axios({
        method: 'get',
        apiName: 'sms',
        params: {
          daysAgo,
        },
      })
        .then(res => {
          res.data.map(data => {
            if (data.content.includes('高鐵邊坡') && data.content.includes('超過')) {
              const regex = /(TK\d+)\+\d/;
              const result = data.content.match(regex);
              if (result) {
                let temp = data.content.includes('TK93') ? result[0] : result[1];
                if (data.content.includes('TK93')) {
                  let str = parseInt(result[0][result[0].length - 1]);
                  if (str >= 7 && str < 9)
                    temp = 'TK93+8';
                  else if (str >= 1 && str < 3)
                    temp = 'TK93+1';
                  else if (str >= 5 && str < 7)
                    temp = 'TK93+5';
                }
                let obj = {
                  'content': data.content,
                  'TIMESTAMP': data.created_at,
                  'station': temp,
                  'warnStatus': data.content.includes('警戒') ? 'AL' : 'WSL',
                };
                this.messages.push(obj);
              }
            }
          });
        })
        .catch(this.$notifyError)
        .finally(() => {
          //console.log('done');
          this.getWarnStation();
        });
    },
    getWarnStation() {
      this.messages.map(ele => {
        const match = this.locations.find(loc => loc.id.includes(ele.station));
        if (match) {
          //console.log(match.id);
          match.warnStatus = ele.warnStatus;
        }
      });
    },
    getWarnRain() {
      this.rain_messages.map(ele => {
        const match = this.rain_location.find(loc => loc.id.includes(ele.station));
        if (match) {
          //console.log(match.id);
          if (match.warnStatus != 'WSL')
            match.warnStatus = ele.warnStatus;
        }
        const cwaMatch = this.cwa_location.find(loc => loc.id.includes(ele.station));
        if (cwaMatch) {
          //console.log(match.id);
          if (cwaMatch.warnStatus != 'WSL')
            cwaMatch.warnStatus = ele.warnStatus;
        }
      });
    },
    getRainStatus() {
      this.rainData.map(ele => {
        ele.status =
          (ele['HR_24'] > 80 || ele['HR_1'] > 40) ?
            (ele['HR_24'] > 200 || ele['HR_3'] > 100) ?
              (ele['HR_24'] > 350 || ele['HR_3'] > 200) ?
                (ele['HR_24'] > 500) ?
                  'EXTREME_TORRENTIAL_RAIN' :
                  'SUPER_TORRENTIAL_RAIN' :
                'TORRENTIAL_RAIN' :
              'HEAVY_RAIN' :
            ele.status;
        const matchingLocation = this.rain_location.find(loc => loc.id === ele.name);
        if (matchingLocation) {
          matchingLocation.status = ele.status;
          matchingLocation.HR_1 = ele.HR_1;
          matchingLocation.HR_3 = ele.HR_3;
          matchingLocation.HR_24 = ele.HR_24;
          matchingLocation.last_updated = ele.TIMESTAMP;
        }
        
        const cwaMatch = this.cwa_location.find(loc => ele.name.includes(loc.name));
        if (cwaMatch) {
          cwaMatch.status = ele.status;
          cwaMatch.HR_1 = ele.HR_1;
          cwaMatch.HR_3 = ele.HR_3;
          cwaMatch.HR_24 = ele.HR_24;
          cwaMatch.last_updated = ele.TIMESTAMP;
        }
      });
    },
    getRainData() {
      this.$axios({
        category: 'project',
        method: 'get',
        apiName: `5ec2aed45ff71e000adc6119/data/last/storm/${Date.now()}`,
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      })
        .then(res => {
          //console.log(res);
          res.data.map(data => {
            if (!data.name.includes('188')) {
              let obj = {
                'name': data.name,
                'status': data.status,
                'TIMESTAMP': data.TIMESTAMP,
                'HR_1': data['mm/1HR'],
                'HR_3': data['mm/3HR'],
                'HR_24': data['mm/24HR'],
              };
              this.rainData.push(obj);
            }
          });
        })
        .catch((e) => {
          console.log(e);
        }).finally(() => {
          this.getRainStatus();
          this.addRainMarker();
          this.addMarker();
          this.showWarn('now');
        });
      //console.log(this.rainData);
    },
    toMap(name) {
      console.log(this.selectedProject._id);
      this.stationName.forEach(t => {
        if (name.includes(t.name)) {
          let lat = t.lat;
          let lon = t.lon;
          this.$router.push({
            name: 'slope-map',
            params: { lat, lon },
          });
        }
      });
    },
    addWarnMarker () {
      // 創建標記並加入地圖
      let graphics = [];
      this.locations.forEach(loc => {
        let url = this.greenMarkpic;
        if (loc.warnStatus === 'AL') {
          url = this.yellowMarkpic;
        } else if (loc.warnStatus === 'WSL') {
          url = this.redMarkpic;
        } else if (loc.status === 'down') {
          url = this.geryMarkpic;
        }

        let marker = new this.PictureMarkerSymbol({
          url,
          width: '13px',
          height: '13px',
        });

        let point = {
          type: 'point',
          longitude: loc.lon,
          latitude: loc.lat,
        };

        let popupTemplate = new this.PopupTemplate({
          title: loc.id,
        });

        let pointGraphic = new this.Graphic({
          geometry: point,
          symbol: marker,
          popupTemplate,
        });

        this.view.popup = {
          collapseEnabled: false,
          dockOptions: {
            buttonEnabled: false,
          },
          actions: [],
        };
        graphics.push(pointGraphic);
      });
      this.view.graphics.addMany(graphics);
    },
    addMarker() {
      // 創建標記並加入地圖
      let graphics = [];
      this.locations.forEach(loc => {
        let url = this.greenMarkpic;
        if (loc.status === 'AL') {
          url = this.yellowMarkpic;
        } else if (loc.status === 'WSL') {
          url = this.redMarkpic;
        } else if (loc.status === 'down') {
          url = this.geryMarkpic;
        }
        let marker = new this.PictureMarkerSymbol({
          url,
          width: '13px',
          height: '13px',
        });

        let point = {
          type: 'point',
          longitude: loc.lon,
          latitude: loc.lat,
        };

        let popupTemplate = new this.PopupTemplate({
          title: loc.id,
        });

        let pointGraphic = new this.Graphic({
          geometry: point,
          symbol: marker,
          popupTemplate,
        });

        this.view.popup = {
          collapseEnabled: false,
          dockOptions: {
            buttonEnabled: false,
          },
          actions: [],
        };
        graphics.push(pointGraphic);
      });
      this.view.graphics.addMany(graphics);
    },
    addWarnRainMarker() {
      let graphics = [];
      let legendAdded = false;

      this.rain_location.forEach(loc => {
        let reg = /(TK\d+)\+\d/;
        let res = loc.id.match(reg);
        let url = null;
        if (loc.warnStatus === 'AL') {
          this.locations.forEach(ele => {
            if (ele.id.includes(res[0])) {
              this.view.graphics.items.map(items => {
                if (items.popupTemplate.title === ele.id) {
                  items.symbol.url = ele.warnStatus === 'GREEN' ? this.yellowCloudgreen : ele.warnStatus === 'AL' ? this.yellowCloudyellow : ele.warnStatus === 'WSL' ? this.yellowCloudred : null;
                  items.symbol.width = '28px';
                  items.symbol.height = '28px';
                } 
              });
            }
          });
          url = this.heavy_rain;
        } else if (loc.warnStatus === 'WSL') {
          this.locations.forEach(ele => {
            if (ele.id.includes(res[0])) {
              this.view.graphics.items.map(items => {
                if (items.popupTemplate.title === ele.id) {
                  items.symbol.url = ele.warnStatus === 'GREEN' ? this.redCloudgreen : ele.warnStatus === 'AL' ? this.redCloudyellow : ele.warnStatus === 'WSL' ? this.redCloudred : null;
                  items.symbol.width = '28px';
                  items.symbol.height = '28px';
                } 
              });
            }
          });
          url = this.super_torrential_rain;
        }

        if (url) {
          if (!legendAdded) {
            const existingLegend = document.getElementById('custom-legend');
            if (!existingLegend) {
              this.legendDiv = document.createElement('div');
              this.legendDiv.id = 'custom-legend';
              this.legendDiv.className = 'esri-widget';
              this.legendDiv.style = 'width:95px';
              const legendContent = `
            <ul>
              <li class="center-item"><img src=${this.heavy_rain} alt="Icon 1" class="icon-text">黃燈 </li>
              <li class="center-item"><img src=${this.super_torrential_rain} alt="Icon 2" class="icon-text">紅燈 </li>
            </ul>
          `;
              this.legendDiv.innerHTML = legendContent;
              this.view.ui.add(this.legendDiv, 'bottom-right');
            }
            legendAdded = true;
          }

          let marker = new this.PictureMarkerSymbol({
            url,
            width: '25px',
            height: '25px',
          });

          let point = {
            type: 'point',
            longitude: loc.lon,
            latitude: loc.lat,
          };
          let popupTemplate = new this.PopupTemplate({
            title: `${loc.id}-RAIN`,
          });

          let pointGraphic = new this.Graphic({
            geometry: point,
            symbol: marker,
            popupTemplate,
          });
          graphics.push(pointGraphic);
        }
      });
      //this.view.graphics.addMany(graphics);
    },
    addRainMarker() {
      let graphics = [];
      let legendAdded = false;

      this.rain_location.forEach(loc => {
        let reg = /(TK\d+)\+\d/;
        let res = loc.id.match(reg);
        let url = null;
        if (loc.status === 'HEAVY_RAIN') {
          this.locations.forEach(ele => {
            if (ele.id.includes(res[0])) {
              this.view.graphics.items.map(items => {
                if (items.popupTemplate.title === ele.id) {
                  items.symbol.url = ele.status === 'GREEN' ? this.yellowCloudgreen : ele.status === 'AL' ? this.yellowCloudyellow : ele.status === 'WSL' ? this.yellowCloudred : null;
                  items.symbol.width = '28px';
                  items.symbol.height = '28px';
                } 
              });
            }
          });
          url = this.heavy_rain;
        } else if (loc.status === 'TORRENTIAL_RAIN') {
          this.locations.forEach(ele => {
            if (ele.id.includes(res[0])) {
              this.view.graphics.items.map(items => {
                if (items.popupTemplate.title === ele.id) {
                  items.symbol.url = ele.status === 'GREEN' ? this.redCloudgreen : ele.status === 'AL' ? this.redCloudyellow : ele.status === 'WSL' ? this.redCloudred : null;
                  items.symbol.width = '28px';
                  items.symbol.height = '28px';
                } 
              });
            }
          });
          url = this.torrential_rain;
        } else if (loc.status === 'SUPER_TORRENTIAL_RAIN') {
          this.locations.forEach(ele => {
            if (ele.id.includes(res[0])) {
              this.view.graphics.items.map(items => {
                if (items.popupTemplate.title === ele.id) {
                  items.symbol.url = ele.status === 'GREEN' ? this.orangeCloudgreen : ele.status === 'AL' ? this.orangeCloudyellow : ele.status === 'WSL' ? this.orangeCloudred : null;
                  items.symbol.width = '28px';
                  items.symbol.height = '28px';
                } 
              });
            }
          });
          url = this.super_torrential_rain;
        } else if (loc.status === 'EXTREME_TORRENTIAL_RAIN') {
          this.locations.forEach(ele => {
            if (ele.id.includes(res[0])) {
              this.view.graphics.items.map(items => {
                if (items.popupTemplate.title === ele.id) {
                  items.symbol.url = ele.status === 'GREEN' ? this.purpleCloudgreen : ele.status === 'AL' ? this.purpleCloudyellow : ele.status === 'WSL' ? this.purpleCloudred : null;
                  items.symbol.width = '28px';
                  items.symbol.height = '28px';
                } 
              });
            }
          });
          url = this.extreme_torrential_rain;
        }

        if (url) {
          if (!legendAdded) {
            const existingLegend = document.getElementById('custom-legend');
            if (!existingLegend) {
              this.legendDiv = document.createElement('div');
              this.legendDiv.id = 'custom-legend';
              this.legendDiv.className = 'esri-widget';
              this.legendDiv.style = 'width:95px';
              const legendContent = `
            <ul>
              <li class="center-item"><img src=${this.heavy_rain} alt="Icon 1" class="icon-text">大雨 </li>
              <li class="center-item"><img src=${this.torrential_rain} alt="Icon 2" class="icon-text">豪雨 </li>
              <li class="center-item"><img src=${this.super_torrential_rain} alt="Icon 3" class="icon-text">大豪雨 </li>
              <li class="center-item"><img src=${this.extreme_torrential_rain} alt="Icon 4" class="icon-text">超大豪雨 </li>
            </ul>
          `;
              this.legendDiv.innerHTML = legendContent;
              this.view.ui.add(this.legendDiv, 'bottom-right');
            }
            legendAdded = true;
          }

          let marker = new this.PictureMarkerSymbol({
            url,
            width: '25px',
            height: '25px',
          });

          let point = {
            type: 'point',
            longitude: loc.lon,
            latitude: loc.lat,
          };

          var popupContent = `<p style="padding-top: 10px;">${loc.HR_1}mm/1h<br>${loc.HR_3}mm/3h<br>${loc.HR_24}mm/24h</p>
          ${moment(loc.last_updated).format('YYYY-MM-DD HH:mm')}`;

          let popupTemplate = new this.PopupTemplate({
            title: `${loc.id}-RAIN`,
            content: popupContent,
          });

          let pointGraphic = new this.Graphic({
            geometry: point,
            symbol: marker,
            popupTemplate,
          });
          graphics.push(pointGraphic);
        }
      });
      //this.view.graphics.addMany(graphics);
      //this.loading = false;
    },
    addWarnCwaMarker() {
      let graphics = [];
      let legendAdded = false;

      this.cwa_location.forEach(loc => {
        let url = null;
        if (loc.warnStatus === 'AL') {
          url = this.heavy_rain;
        } else if (loc.warnStatus === 'WSL') {
          url = this.super_torrential_rain;
        }

        if (url) {
          if (!legendAdded) {
            const existingLegend = document.getElementById('custom-legend');
            if (!existingLegend) {
              this.legendDiv = document.createElement('div');
              this.legendDiv.id = 'custom-legend';
              this.legendDiv.className = 'esri-widget';
              this.legendDiv.style = 'width:95px';
              const legendContent = `
            <ul>
              <li class="center-item"><img src=${this.heavy_rain} alt="Icon 1" class="icon-text">黃燈 </li>
              <li class="center-item"><img src=${this.super_torrential_rain} alt="Icon 2" class="icon-text">紅燈 </li>
            </ul>
          `;
              this.legendDiv.innerHTML = legendContent;
              this.view.ui.add(this.legendDiv, 'bottom-right');
            }
            legendAdded = true;
          }

          let marker = new this.PictureMarkerSymbol({
            url,
            width: '25px',
            height: '25px',
          });

          let point = {
            type: 'point',
            longitude: loc.lon,
            latitude: loc.lat,
          };
          let popupTemplate = new this.PopupTemplate({
            title: loc.id,
          });

          let pointGraphic = new this.Graphic({
            geometry: point,
            symbol: marker,
            popupTemplate,
          });
          graphics.push(pointGraphic);
        }
      });
      this.view.graphics.addMany(graphics);
    },
    addCwaMarker() {
      let graphics = [];
      let legendAdded = false;

      this.cwa_location.forEach(loc => {
        let url = null;
        if (loc.status === 'HEAVY_RAIN') {
          url = this.heavy_rain;
        } else if (loc.status === 'TORRENTIAL_RAIN') {
          url = this.torrential_rain;
        } else if (loc.status === 'SUPER_TORRENTIAL_RAIN') {
          url = this.super_torrential_rain;
        } else if (loc.status === 'EXTREME_TORRENTIAL_RAIN') {
          url = this.extreme_torrential_rain;
        }

        if (url) {
          if (!legendAdded) {
            const existingLegend = document.getElementById('custom-legend');
            if (!existingLegend) {
              this.legendDiv = document.createElement('div');
              this.legendDiv.id = 'custom-legend';
              this.legendDiv.className = 'esri-widget';
              this.legendDiv.style = 'width:95px';
              const legendContent = `
            <ul>
              <li class="center-item"><img src=${this.heavy_rain} alt="Icon 1" class="icon-text">大雨 </li>
              <li class="center-item"><img src=${this.torrential_rain} alt="Icon 2" class="icon-text">豪雨 </li>
              <li class="center-item"><img src=${this.super_torrential_rain} alt="Icon 3" class="icon-text">大豪雨 </li>
              <li class="center-item"><img src=${this.extreme_torrential_rain} alt="Icon 4" class="icon-text">超大豪雨 </li>
            </ul>
          `;
              this.legendDiv.innerHTML = legendContent;
              this.view.ui.add(this.legendDiv, 'bottom-right');
            }
            legendAdded = true;
          }

          let marker = new this.PictureMarkerSymbol({
            url,
            width: '25px',
            height: '25px',
          });

          let point = {
            type: 'point',
            longitude: loc.lon,
            latitude: loc.lat,
          };

          var popupContent = `<p style="padding-top: 10px;">${loc.HR_1}mm/1h<br>${loc.HR_3}mm/3h<br>${loc.HR_24}mm/24h</p>
          ${moment(loc.last_updated).format('YYYY-MM-DD HH:mm')}`;

          let popupTemplate = new this.PopupTemplate({
            title: loc.id,
            content: popupContent,
          });

          let pointGraphic = new this.Graphic({
            geometry: point,
            symbol: marker,
            popupTemplate,
          });
          graphics.push(pointGraphic);
        }
      });
      this.view.graphics.addMany(graphics);
      //this.loading = false;
    },
    getStatus(area) {
      if (!area.name.includes('邊坡')) {
        //console.log(area);
        this.locations.forEach(t => {
          if (area.name.includes(t.id)) {
            area.station.forEach(instruments => {
              let status = { AL: false, WSL: false };
              let total_count = 0;
              let down_count = 0;
              instruments.instrument.forEach(item => {
                total_count++;
                //console.log(item.status);
                if (item.status === 'AL') {
                  status.AL = true;
                } else if (item.status === 'WSL') {
                  status.WSL = true;
                } else if (item.status === 'down') {
                  down_count++;
                }
              });
              if (status.WSL) {
                t.status = 'WSL';
              } else if (status.AL) {
                t.status = 'AL';
              }
              if (total_count === down_count) {
                t.status = 'down';
              }
            });
          }
        });
      }
    },
    getProject() {
      this.resData.area.map(area => {
        this.getStatus(area);
      });
      this.getMessage(this.dayago);
    },
  },
};
</script>

<style scoped>
::v-deep [class*="esri-popup--is-docked-top-"] .esri-popup__footer,
[class*="esri-popup--aligned-bottom-"] .esri-popup__footer {
  border-bottom: solid 1px #6e6e6e4d;
}

::v-deep .esri-popup__header {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px;
  background-color: #fff;
  color: #fff;
}

::v-deep .esri-popup--shadow {
  box-shadow: 0 1px 4px rgb(155, 155, 155) !important;
}

::v-deep .esri-popup__header-title {
  background-color: #409EFF !important;
}

::v-deep .esri-popup__header-container,
.esri-popup__header-container--button {
  outline: none !important;
}

::v-deep .esri-popup__icon,
.esri-icon-close {
  color: #000000 !important;
}

::v-deep .esri-view-width-xlarge .esri-popup__main-container {
  width: 160px !important;
  border-radius: 5px !important;
}

::v-deep .esri-ui .esri-popup {
  width: 160px !important;
  border-radius: 5px !important;
}

::v-deep .esri-popup__button {
  background-color: transparent !important;
  outline: none;
  display: none;
}

::v-deep .esri-popup__header-title {
  font-weight: 600 !important;
}

::v-deep .esri-view .esri-view-surface--inset-outline:focus::after {
  outline: auto 0px Highlight !important;
  outline: auto 0px -webkit-focus-ring-color !important;
}
</style>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  max-height: 555px;
}
</style>

<style>
.center-item {
  display: flex;
  align-items: center;
  padding-left: 2px;
}

.icon-text {
  padding-left: 5px;
  padding-right: 2px;
}

.esri-view .esri-view-surface--touch-none:focus::after {
  outline: none !important;
}
</style>