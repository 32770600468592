<template>
  <div class="image-marker-container" @mousedown="onMouseDown($event)" @mouseup="onMouseUp">
    <el-image class="imgSize" :src="file.url"/>
    <div class="marker-container" v-show="showMarkerContainer" @click.left="openImageDialog">
      <template v-if="showMarker">
        <pin-marker
          ref="pinMarkers"
          v-for="marker in file.pin"
          v-show="marker.instrument"
          :key="marker._id"
          :class="{ 'is-editing': isEditing(marker._id) }"
          :marker="marker"
          :status-color="getStatusColor(marker.instrument)"
          :disable-popover="disablePopover"
          :disable-edit="disableEdit"
          :disable-select="disableSelect"
          :trigger-type="triggerType"
          @select="selectPin"
          @delete="deletePin"
        />
      </template>
      <slot />
    </div>
    <el-dialog
      :visible.sync="imageDialogVisible"
      custom-class="image-dialog"
      :before-close="handleDialogClose"
    >
      <div
        class="image-zoom-container"
        ref="imageZoomContainer"
        @mousedown="startDragging"
        @mousemove="drag"
        @mouseup="stopDragging"
        @mouseleave="stopDragging"
        @wheel="handleMouseWheel"
      >
        <img :src="file.url" :style="{ transform: 'translate(' + xPos + 'px, ' + yPos + 'px) scale(' + zoomScale + ')' }" />
        <div class="marker-container" :style="{ transform: 'translate(' + xPos + 'px, ' + yPos + 'px) scale(' + zoomScale + ')' }">
          <template v-if="showMarker && file.pin && file.pin.length > 0">
            <pin-marker
              ref="pinMarkers"
              v-for="marker in file.pin"
              v-show="marker.instrument"
              :key="marker._id"
              :class="{ 'is-editing': isEditing(marker._id) }"
              :marker="marker"
              :status-color="getStatusColor(marker.instrument)"
              :disable-popover="disablePopover"
              :disable-edit="disableEdit"
              :disable-select="disableSelect"
              :trigger-type="triggerType"
              @select="selectPin"
              @delete="deletePin"
            />
          </template>
          <slot />
        </div>
        <div class="zoom-controls">
          <el-button icon="el-icon-zoom-in" circle @click="zoomIn" />
          <el-button icon="el-icon-zoom-out" circle @click="zoomOut" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { COLORS } from '@/constants/mapSettings.js';
import PinMarker from '@/components/pin/PinMarker';
export default {
  components: {
    PinMarker,
  },
  props: {
    editing: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    project: {
      type: String,
      required: false,
      default: '',
    },
    file: {
      type: Object,
      required: true,
    },
    disablePopover: {
      type: Boolean,
      default: false,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    disableSelect: {
      type: Boolean,
      default: true,
    },
    triggerType: {
      type: String,
      default: 'manual',
    },
  },
  data() {
    return {
      showMarkerContainer: true,
      showMarker: false,
      imageDialogVisible: false,
      zoomScale: 1,
      dragging: false,
      xPos: 0,
      yPos: 0,
      lastX: 0,
      lastY: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.showMarker = true;
    });
  },
  methods: {
    onMouseDown(event) {
      if(event.button === 2) {
        this.showMarkerContainer = false;
      }else if(event.button === 0) {
        this.showMarkerContainer = true;
      }
    },
    onMouseUp(event) {
      if(!this.showMarkerContainer) {
        setTimeout(() => {
          this.showMarkerContainer = true;
        }, 1000);
      }
    },
    isEditing(markerId) {
      if (this.editing && this.editing._id) {
        return this.editing.marker === markerId;
      } else {
        return false;
      }
    },
    selectPin(marker) {
      this.$emit('select', marker);
    },
    getStatusColor(instrument) {
      const statusList = ['WSL', 'WL', 'AL', 'GREEN'];
      const styleList = [COLORS.SUSPENSION, COLORS.WARNING, COLORS.ALERT, COLORS.GREEN];
      const instrumentStatusList = [...new Set(instrument.map(ele => ele.status))];
      const index = statusList.findIndex(ele => {
        return instrumentStatusList.includes(ele);
      });
      if (index >= 0) return styleList[index];
      return COLORS.GREY;
    },
    deletePin(pinId) {
      this.$axios({
        category: 'project',
        method: 'delete',
        apiName: `${this.project}/deleteImagePin`,
        data: {
          pinId,
        },
      })
        .then(() => {
          this.$notifySuccess('刪除成功');
        })
        .then(() => {
          this.$emit('reload');
        })
        .catch(error => {
          this.$notifyError('刪除失敗', error.response.data.message);
        });
    },
    openImageDialog() {
      if (!this.imageDialogVisible) {
        this.closeAllPopovers();
        this.imageDialogVisible = true;
      }
    },
    handleDialogClose(done) {
      if (!this.imageDialogVisible) return;
      this.imageDialogVisible = false;
      this.resetZoomAndPosition();
      this.closeAllPopovers();
      done();
    },
    closeAllPopovers() {
      if (this.$refs.pinMarkers && this.$refs.pinMarkers.length > 0) {
        this.$refs.pinMarkers.forEach(pinMarker => {
          pinMarker.showPopover = false;
        });
      }
    },
    resetZoomAndPosition() {
      this.zoomScale = 1;
      this.xPos = 0;
      this.yPos = 0;
    },
    zoomIn() {
      this.closeAllPopovers();
      this.zoomScale += 0.1;
    },
    zoomOut() {
      if (this.zoomScale > 1) {
        this.closeAllPopovers();
        this.zoomScale -= 0.1;
      }
    },
    handleMouseWheel(event) {
      event.preventDefault();
      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    startDragging(event) {
      this.dragging = true;
      this.lastX = event.clientX;
      this.lastY = event.clientY;
    },
    drag(event) {
      if (!this.dragging) return;
      this.closeAllPopovers();
      const deltaX = event.clientX - this.lastX;
      const deltaY = event.clientY - this.lastY;
      this.xPos += deltaX;
      this.yPos += deltaY;
      this.lastX = event.clientX;
      this.lastY = event.clientY;
    },
    stopDragging() {
      this.dragging = false;
    },
  },
};
</script>


<style lang="stylus" scoped>
.imgSize >>> img {
  max-height: 550px;
  max-width: 100%;
}
.marker-index
  position absolute
  top 0
  left 0
  margin-left 6px
  color white
  font-size 14px
.image-marker-container
  position relative
  display inline-block
  img
    object-fit contain
  .marker-container
    position absolute
    width 100%
    height 100%
    top 0
    left 0
.image-dialog
  .image-zoom-container
    position relative
    img
      object-fit contain
      max-width: 100%
      max-height: 90vh
    .zoom-controls
      position absolute
      bottom: 10px
      right: 10px
.focus-point
  display inline-block
.info-image
  height 100%
  width 100%
  object-fit contain
.image-container
  position relative
</style>
