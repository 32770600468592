<template>
  <el-card class="example" v-loading="isLoading" shadow="always">
    <div class="setting"
      v-if="!$device.mobile"
    >
      <el-button
        icon="el-icon-edit"
        size="mini"
        type="success"
        @click="Editing"
      >
        <!--font-awesome-icon icon="cog" /-->
      </el-button>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :append-to-body="false"
    >
      <div class="dialog-editor">
        <quill-editor
          class="editor"
          ref="myTextEditor"
          :value="editorTextTmp"
          :options="editorOption"
          @change="onEditorChange($event)"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetRichTextEditor">取 消</el-button>
        <el-button type="primary" @click="saveRichTextEditor">确 定</el-button>
      </span>
    </el-dialog>
    <div class="output ql-snow">
      <div class="ql-editor" v-html="content"></div>
    </div>
  </el-card>
</template>

<script>
import dedent from 'dedent';
import hljs from 'highlight.js';
import debounce from 'lodash/debounce';
import { quillEditor } from 'vue-quill-editor';
// highlight.js style
import 'highlight.js/styles/tomorrow.css';
// import theme style
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

export default {
  name: 'quill-example-snow',
  title: 'Theme: snow',
  components: {
    quillEditor,
  },
  data() {
    return {
      selectedProject: '',
      dialogVisible: false,
      editorTextTmp: '',
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value,
          },
        },
      },
      content: dedent`
        test`,
    };
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill;
    },
    contentCode() {
      return hljs.highlightAuto(this.content).value;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.selectedProject = this.$store.state.project.selectedProject._id;
      this.getProjectInfoFromDB();
    },
    getProjectInfoFromDB() {
      this.$axios({
        method: 'get',
        apiName: `/project/${this.selectedProject}/getProjectInformationOnDashboard`,
      }).then(res => {
        if(res.data){
          if(res.data!='')
            this.content = res.data;
          else
            this.content = dedent``;
        }else{
          this.content = dedent``;
        }
        this.editorTextTmp = this.content;
      }).catch(error => {
        this.$notifyError(this.$t('chart.error.loading'), error.response.data.message);
      });
    },
    sendProjectInfoToDB() {
      console.log('send');
      this.$axios({
        method: 'put',
        apiName: `/project/${this.selectedProject}/setProjectInformationOnDashboard`,
        data:{
          project_information_on_dashboard: this.content,
        },
      }).then(res => {
        if(!res.data){
          //console.log(res.data);
          this.content = res.data;
        }
      }).catch(error => {
        this.$notifyError(this.$t('chart.error.loading'), error.response.data.message);
      });
    },
    Editing() {
      this.dialogVisible = true;
      this.$emit('editing',true);
      console.log('editing');
    },
    saveRichTextEditor() {
      let len = this.mbStringLength(this.editorTextTmp);
      console.log(`user input ${len/1024/1024}MB document`);
      if(len > 1024 * 1024 * 1){
        this.$notifyError('文字內容不可超過1MB');
        return;
      }
      this.content = this.editorTextTmp;
      this.dialogVisible = false;
      this.sendProjectInfoToDB();
      this.$emit('editing',false);
    },
    resetRichTextEditor() {
      this.editorTextTmp = this.content;
      this.dialogVisible = false;
      this.$emit('editing',false);
    },
    onEditorChange: debounce(function(value) {
      this.editorTextTmp = value.html;
    }, 466),
    onEditorBlur(editor) {
      //console.log('editor blur!', editor);
    },
    onEditorFocus(editor) {
      //console.log('editor focus!', editor);
    },
    onEditorReady(editor) {
      //console.log('editor ready!', editor);
    },
    mbStringLength(s) {
      let totalLength = 0;
      let i;
      let charCode;
      for (i = 0; i < s.length; i++) {
        charCode = s.charCodeAt(i);
        if (charCode < 0x007f) {
          totalLength = totalLength + 1;
        } else if ((0x0080 <= charCode) && (charCode <= 0x07ff)) {
          totalLength += 2;
        } else if ((0x0800 <= charCode) && (charCode <= 0xffff)) {
          totalLength += 3;
        }
      }
      //alert(totalLength);
      return totalLength; //bytes to bits
    },
  },
};
</script>

<style lang="scss" scoped>
  .example {
    display: flex;
    flex-direction: column;
    .editor {
      //height: 30rem;
      height: 25rem;
      overflow-y: visible;
    }
    .output {
      width: 100%;
      min-height: 24rem;
      margin: 0;
      border: 1px solid #ccc;
      overflow-y: auto;
      resize: vertical;
      &.code {
        padding: 1rem;
        min-height: 16rem;
      }
      &.ql-snow {
        min-height: 24rem;
      }
    }
  }
</style>

<style lang="stylus" scoped>
.setting
  position: relative
  float: right
  //right: 66px
  top: -8px
.dialog-editor
  height: 30rem
</style>