<template>
  <div>
    <el-card v-loading="loading">
      <el-button @click="dialogVisible = true">
        新增孔位
      </el-button>
      <el-select v-model="selectInstrumentGroup" placeholder="請選擇孔位" @change="changeInstrumentGroup">
        <el-option
          v-for="item in instrumentGroupList"
          :key="item.name"
          :label="item.name"
          :value="item.name">
        </el-option>
      </el-select>
      <el-select v-model="instrumentGroupType" @change="changeInstrumentGroupType">
        <el-option
          v-for="item in instrumentGroupTypeOption"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="upload">
        上傳孔位
      </el-button>
      <span>初值時間 {{ startDate }}</span>
      <hr>
      <hot-table id="hot-container" ref="hot" root="hot" :settings="settings" />
    </el-card>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :show-close="false"
    >
      <span>請輸入孔位</span>
      <el-input v-model="dialogValue.name"></el-input>
      <span>請輸入深度</span>
      <el-input v-if="!dialogValue.advancedType" v-model="dialogValue.depth"></el-input>
      <div v-else>
        <span>最大值</span>
        <el-input v-model="dialogValue.axis.max"></el-input>
        <span>最小值</span>
        <el-input v-model="dialogValue.axis.min"></el-input>
      </div>
      <span>請輸入間距</span>
      <el-input v-model="dialogValue.step"></el-input>
      <span>請輸入初值時間</span>
      <el-date-picker 
        v-model="dialogValue.startDate"
        :placeholder="$t('chart.date.start')"
        :picker-options="datePickerOptions"
        type="datetime"
        format="yyyy/MM/dd HH:mm"
      />
      <hr>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="newGroup">確 定</el-button>
        <el-button @click="dialogValue.advancedType = !dialogValue.advancedType">進階模式</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { HotTable } from '@handsontable/vue';
export default {
  components: {
    HotTable,
  },
  props: {
    instrumentList: {
      required: true,
    },
    getInstrumentType: {
      required: true,
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    settings() {
      const vm = this;
      return {
        data: this.instrumentsInGroup,
        fixedColumnsLeft: 1,
        colHeaders: this.columnsHeader,
        rowHeaders: true,
        manualRowResize: true,
        manualColumnResize: true,
        manualRowMove: true,
        customBorders: true,
        columnSorting: true,
        height: 600,
        width: '100%',
        columns: this.columns,
        licenseKey: 'non-commercial-and-evaluation',
        async beforeChange(changes, source) {
          // [[row, prop, oldVal, newVal], ...]
          //changes[0][3] = 10;
          for(let change of changes){
            if(change[1]=='startDate'){
              if(!isNaN(Number(change[3])) || isNaN(new Date(change[3]))){
                vm.$notifyError('請輸入正確的日期');
                change[3] = '';
              }
              else{
                change[3] = moment(change[3]).format('yyyy-MM-DD HH:mm');
              }
            }
          }
        },
      };
    },
  },
  data(){
    return {
      columns: [
        {
          data: 'name',
        },
        {
          data: 'depth',
        },
        {
          data: 'area',
        },
        {
          data: 'station',
        },
        {
          data: 'unit',
        },
        {
          data: 'A+',
        },
        {
          data: 'A-',
        },
        {
          data: 'F1',
        },
        {
          data: 'WL_plus',
        },
        {
          data: 'WL_minus',
        },
        {
          data: 'AL_plus',
        },
        {
          data: 'AL_minus',
        },
        {
          data: 'WSL_plus',
        },
        {
          data: 'WSL_minus',
        },
      ],
      columnsHeader: [
        '儀器名稱',
        '深度',
        '區域',
        '測站',
        '單位',
        'A+',
        'A-',
        'F1',
        '注意值(+)',
        '注意值(-)',
        '警戒值(+)',
        '警戒值(-)',
        '行動值(+)',
        '行動值(-)',
      ],

      loading: false,

      selectInstrumentGroup: '',
      instrumentGroup: null,
      instrumentGroupType: 'VERTICAL_DISTRIBUTION',
      instrumentGroupList: [],

      instrumentsInGroup: [],

      instrumentGroupTypeOption : [
        {
          value: 'VERTICAL_DISTRIBUTION',
          label: '垂直分佈',
        },
        {
          value: 'HORIZONTAL_DISTRIBUTION',
          label: '水平分佈',
        },
      ],

      dialogVisible: false,
      dialogValue: {
        advancedType: false,
        name: '',
        depth: 0,
        step: 0,
        startDate: moment().format('yyyy-MM-DD HH:mm'),

        axis: { max: 0, min: 0},
      },

      startDate: moment().format('yyyy-MM-DD HH:mm'),
    };
  },
  async mounted() {
    this.getInstrumentGroupList();
    
  },
  methods:{
    async getInstrumentGroupList(){
      this.loading = true;
      this.instrumentGroupList = [];
      await this.getGroup();
      this.instrumentGroupList = this.instrumentGroupList.map(group=>{
        let isManual = true;

        // 如果儀器組包含非manual的內容，
        let instruments = group.instrumentList.map(groupIns=>{
          let index = this.instrumentList.findIndex(ins => `${groupIns.instrument}` === `${ins._id}`);
          if(index >= 0){
            return {
              ...this.instrumentList[index],
              depth: groupIns.depth,
            };
          }
          else{
            isManual = false;
            return null;
          }
        });
        if(!isManual) return null;
        return {
          instruments,
          type: group.type,
          name: group.name,
          startDate: instruments[0].startDate,
          axis: (group.type === 'VERTICAL_DISTRIBUTION') ? group.yAxis : group.xAxis,
        };
      }).filter(group => group);
      
      this.loading = false;
    },
    async newGroup(){
      try{
        this.dialogVisible = false;

        let nameValue = this.dialogValue.name;
        //let nameValue = await this.$prompt('請輸入孔位', '提示', {
        //  confirmButtonText: '確定',
        //  cancelButtonText: '取消',
        //});
        
        let depthValue = this.dialogValue.depth;
        //let depthValue = await this.$prompt('請輸入深度', '提示', {
        //  confirmButtonText: '確定',
        //  cancelButtonText: '取消',
        //});
        if(isNaN(Number(depthValue))) {
          console.log(depthValue);
          throw new Error('Parameter is not a number!');
        }
        depthValue = Number(depthValue);

        let spacingValue = this.dialogValue.step;
        //let spacingValue = await this.$prompt('請輸入間距', '提示', {
        //  confirmButtonText: '確定',
        //  cancelButtonText: '取消',
        //});
        if(isNaN(Number(spacingValue))) throw new Error(`Parameter is not a number! ${spacingValue}`);
        spacingValue = Number(spacingValue);

        if(spacingValue<=0) throw new Error(`間隔必須是正整數! ${spacingValue}`);

        let instruments = [];

        let axis = this.dialogValue.advancedType ? { max: Number(this.dialogValue.axis.max), min: Number(this.dialogValue.axis.min), unit: 'm' } : { max: 0, min: depthValue * -1, unit: 'm'};

        if(isNaN(axis.min) || isNaN(axis.max)) throw new Error(`Parameter is not a number! ${axis}`);
        if(axis.min >= axis.max) {
          if(this.dialogValue.advancedType) throw new Error('最大值必須大於最小值!');
          if(!this.dialogValue.advancedType) throw new Error(`深度必須是正整數! ${depthValue}`);
        }

        for(let num = axis.min; num <= axis.max; num += spacingValue){
          instruments.push({
            name: `${nameValue}_${Math.abs(num)}`,
            depth: num,
            //area: cur.area,
            //station: cur.station,
            //startDate: moment(ele.startDate).format('yyyy-MM-DD HH:mm'),
            //unit: ele.unit,
            //R0: ele.R0,
            //offset: ele.offset,
            //T0: ele.T0,
            //K : ele.K,
            //F0: ele.F0,
            //F1: ele.F1,
            //F2: ele.F2,
            //F3: ele.F3,
            //F4: ele.F4,
            //F5: ele.F5,
            //'A+': ele['A+'],
            //'A-': ele['A-'],
            //WL_plus: ele.WL_plus,
            //WL_minus: ele.WL_minus,
            //AL_plus: ele.AL_plus,
            //AL_minus: ele.AL_minus,
            //WSL_plus: ele.WSL_plus,
            //WSL_minus: ele.WSL_minus,
            //instrument_type: cur.instrument_type,
          });
        }

        this.$message({
          type: 'success',
          message: '新增孔位' + nameValue,
        });
        this.instrumentGroupList.push({
          instruments,
          type: 'VERTICAL_DISTRIBUTION',
          name: nameValue,
          startDate: this.dialogValue.startDate,
          axis,
        });
      }
      catch(e){
        console.log(e);
        if(e.toString() === 'cancel'){
          this.$message({
            type: 'info',
            message: '取消新增孔位',
          });
        }
        else{
          this.$notifyError(e);
        }
      }
    },
    async getGroup(){
      let res = await this.$store
        .dispatch('data/getProjectInstrumentGroupList', {
          projectId: this.selectedProject._id,
        });
      this.instrumentGroupList = res;
    },
    changeInstrumentGroup(){
      //console.log(this.instrumentGroup);
      this.instrumentGroup = this.instrumentGroupList.find(ele => ele.name === this.selectInstrumentGroup);
      this.instrumentsInGroup = this.instrumentGroup.instruments.sort((a, b) => b.depth - a.depth);
      this.instrumentGroupType = this.instrumentGroup.type;
      this.startDate = this.instrumentGroup.startDate;
      this.updateTable();
    },
    changeInstrumentGroupType(){
      this.instrumentGroup.type = this.instrumentGroupType;
    },
    isPass(){
      let ispass = true;
      let isnopass = [];
      this.instrumentsInGroup = this.instrumentsInGroup.filter(ele=>ele.name);
      for(let ins of this.instrumentsInGroup){
        //console.log(ins);
        let ispassInthisIns = (ins.name) && (ins.depth || ins.depth === 0) && (ins.area) && (ins.station) && (ins.unit) && (ins['A+'] || ins['A+'] === 0) && (ins['A-'] || ins['A-'] === 0) && (ins.F1 || ins.F1 === 0);
        ispass &&= ispassInthisIns;
        if(!ispassInthisIns) {
          isnopass.push(ins);
          //console.log(!(ins.name));
          //console.log(!(ins.depth || ins.depth === 0));
          //console.log(!(ins.area));
          //console.log(!(ins.station));
          //console.log(!(ins.unit));
          //console.log(!(ins['A+'] || ins['A+'] === 0));
          //console.log(!(ins['A-'] || ins['A-'] === 0));
          //console.log(!(ins.F1 || ins.F1 === 0));
        }
      }
      //console.log(ispass);
      //console.log(isnopass);
      if(!ispass){
        this.$notifyError('仍有欄位尚未填妥');
      }
      this.updateTable();
      
      return ispass;
    },
    handleClose(done) {
      console.log('do nothing');
    },
    async upload(){
      if(!this.isPass()) return;

      this.loading = true;

      let instrumentList = this.instrumentsInGroup.map(ele=>{
        return {
          ...ele,
          startDate: this.startDate,
        };
      });

      //console.log(instrumentList);

      console.log(this.instrumentGroup);

      await this.$axios({
        category: 'manual',
        method: 'put',
        apiName: 'addManualInstrumentGroup',
        data: {
          name: this.selectInstrumentGroup,
          instrumentList,
          projectId: this.selectedProject._id,
          instrumentType: this.getInstrumentType,
          type: this.instrumentGroupType,
          yAxis: this.instrumentGroupType === 'VERTICAL_DISTRIBUTION' ? this.instrumentGroup.axis : { min: -10, max: 10, unit: instrumentList[0].unit}, 
          xAxis: this.instrumentGroupType !== 'VERTICAL_DISTRIBUTION' ? this.instrumentGroup.axis : { min: -10, max: 10, unit: instrumentList[0].unit}, 
        },
      }).then(()=>{
        this.$notifySuccess('上傳成功');
        this.loading = false;
      });
    },
    updateTable() {
      if(!this.loading)
        setTimeout(() => {
          this.$refs.hot.hotInstance.loadData(this.instrumentsInGroup);
        }, 100);
    },
  },
};
</script>

<style>

</style>