<template>
  <div
    :style="{'background-color': getDotColor}"
    class="dot"
  />
</template>

<script>
import { COLORS } from '@/constants/mapSettings.js';
export default {
  props: {
    status: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    getDotColor() {
      if (this.status) {
        return this.getStatusColor();
      } else if (this.color) {
        return this.getColor();
      } else {
        return null;
      }
    },
  },
  methods: {
    getColor() {
      switch (this.color) {
        case 'green':
          return COLORS.GREEN;
        case 'red':
          return COLORS.SUSPENSION;
        case 'yellow':
          return COLORS.ALERT;
        case 'blue':
          return COLORS.WARNING;
        default:
          return null;
      }
    },
    getStatusColor(status) {
      const statusList = ['WSL', 'AL', 'WL', 'GREEN'];
      const styleList = [COLORS.SUSPENSION, COLORS.ALERT, COLORS.WARNING, COLORS.GREEN];
      const styleIndex = statusList.findIndex(ele => {
        return ele.includes(status);
      });
      return styleList[styleIndex];
    },
  },
};
</script>
