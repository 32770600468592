<template>
  <div>
    <h2 class="title">{{ $t('login.title_edit') }}</h2>
    <el-form
      ref="form"
      :model="form"
      :rules="loginRules"
      labelPosition="top"
      label-width="80px"
      :hide-required-asterisk="true"
    >
      <el-form-item prop="email">
        <label class="label" slot="label">{{ $t('login.email') }}</label>
        <el-input
          placeholder="Email"
          type="email"
          size="large"
          v-model="form.email"
          @keyup.enter.native="submitLoginForm"
        ></el-input>
      </el-form-item>
      <el-form-item prop="newpassword">
        <label class="label" slot="label">{{ $t('login.npsd') }}</label>
        <el-input
          v-model="form.newpassword"
          placeholder="Password"
          type="password"
          :minlength="6"
          @keyup.enter.native="submitLoginForm"
        ></el-input>
      </el-form-item>
      <el-form-item prop="psdCheck">
        <label class="label" slot="label">{{ $t('login.psdCheck') }}</label>
        <el-input
          v-model="form.psdCheck"
          placeholder="Password"
          type="password"
          :minlength="6"
          @keyup.enter.native="submitLoginForm"
        ></el-input>
      </el-form-item>
      <a
        class="button mb-16 is-info is-primary is-fullwidth is-medium"
        @click="submitLoginForm"
        :class="{ 'is-loading': isValidating }"
        >{{ $t('login.title_edit') }}</a
      >
      <span class="tips has-text-grey">Please contact geosupply.tw@gmail.com for demo pass</span>
    </el-form>
  </div>
</template>

<script>
import { clientVersion } from '../../constants/env';
export default {
  name: 'ClientPage',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error(this.$t('login.psdValidate')));
      } else {
        callback();
      }
    };
    const checkValidatePass = (rule, value, callback) => {
      if (value != this.form.newpassword) {
        callback(new Error(this.$t('login.psdCheckValidate')));
      } else {
        callback();
      }
    };
    return {
      form: {
        email: null,
        //password: null,
        newpassword: null,
        lastPassword: null,
      },
      loginRules: {
        email: [
          { required: true, message: '請輸入郵箱', trigger: 'blur' },
          {
            type: 'email',
            message: '請輸入正確的郵箱',
            trigger: 'blur',
          },
        ],
        //password: [{ required: true, trigger: 'blur', validator: validatePass }],
        newpassword: [{ required: true, trigger: 'blur', validator: validatePass }],
        psdCheck: [{ required: true, trigger: 'blur', validator: checkValidatePass }],
      },
      isValidating: false,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['user/isAuthenticated'];
    },
  },
  mounted() {
    if (this.$route.query.form){
      let form = this.$route.query.form;
      if(form.email) this.form.email = form.email;
      if(form.password) this.form.lastPassword = form.password;
    }
    //if (this.isAuthenticated) {
    //  this.$router.push(`/${this.$store.state.locale}/data`);
    //}
  },
  methods: {
    submitLoginForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.editUser();
        } else {
          return false;
        }
      });
    },
    async editUser() {
      this.isValidating = true;
      //首先，檢驗新密碼是否是電話
      return this.$axios({
        category: 'user',
        apiName: 'check',
        method: 'post',
        data: this.form,
      })
      //接著，進行密碼更改
        .then( res => {
          return this.$axios({
            category: 'user',
            apiName: 'resetPassword',
            method: 'put',
            data: this.form,
          });
          //console.log(res);
          //return;
        })
      //還有暴雨系統的密碼也要一起改
      //暴雨的不修改了
        //.then( res => {
        //  let thsrRole = clientVersion === 'thsr-main' || clientVersion === 'test';
        //  if(thsrRole)
        //    return this.$axios({
        //      category: 'user',
        //      apiName: 'resetPassword',
        //      method: 'put',
        //      data: this.form,
        //      useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
        //    });
        //  //console.log(res);
        //  //return;
        //})
        .then(() => {
          this.$notifySuccess('修改成功');
          if (this.$route.query && this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push({ name: 'layout' });
            // this.$router.push({ name: 'data' });
          }
        })
        .catch(error => {
          if(error.response.status === 400){
            //在這裡，收到400代表新密碼認證失敗，新密碼不可為電話
            this.$notifyError('密碼不能與手機號相同，請修改密碼');
          }
          else if(error.response.status === 401){
            //在這裡，收到401代表未輸入新密碼
            this.$notifyError('請填入新密碼');
          }
          else{
            this.$notifyError(error);
          }
        })
        .finally(() => {
          this.isValidating = false;
        });
    },
  },
};
</script>
