import axios from 'axios';
import url from './url';
import store from '@/store/index.js';
import { NotifyError } from '@/plugins/notify';
import { getLocalData } from '@/lib/base/localData';
import { apiUrl, clientVersion } from '@/constants/env';

let thsrRole = clientVersion === 'thsr-main' || clientVersion === 'test';
let baseURL = 'http://localhost:8000/';

if (process.env.NODE_ENV === 'production') {
  baseURL = apiUrl;
}

/**
 * 呼叫lambda函式，並可以將資料存在 APP 內(但是不包含讀取)
 * @param {String} category lambda的API類別
 * @param {String} apiName 該API的名稱
 * @param {Object} data 傳入該API的資料
 * @param {Object} method [post,get] API的方法等
 * @param {Object} headers request的header
 *
 * @returns {Promise<Object>} API回傳結果
 */
function callAxios({ category, apiName, data, params, headers = {}, method, useUrl = null ,useJWT = null}) {
  method = method.toLowerCase();
  const domainUrl = url.function[category] || '';
  const apiUrl = domainUrl + apiName;
  const usingUrl = useUrl != null;
  const sendHeaders = Object.assign(headers, {
    Authorization: getLocalData(useJWT || ((thsrRole && usingUrl) ? 'thsr-rain':'jwt')),
    Accept: 'application/json',
  });
  useUrl = (usingUrl) ? useUrl : baseURL;
  return axios({
    baseURL: useUrl,
    method,
    url: apiUrl,
    data,
    params,
    headers: sendHeaders,
  }).catch(error => {
    if (error && ['ECONNRESET', 'ETIMEDOUT'].includes(error.code)) {
      return delay(25).then(() => {
        return callAxios({
          category,
          apiName,
          data,
          params,
          headers,
          method,
          useUrl,
        });
      });
    }
    if ([401].includes(error.response.status)) {
      NotifyError('請重新登入');
      store.dispatch('user/logoutUser');
    }
    // trelloReport(apiUrl, error);
    return Promise.reject(error);
  });
}

/**
 * 包裹setTimeout的工具函式
 * @param {Number} delayMs delay的毫秒數
 * @returns {Promise} API回傳結果
 */
function delay(delayMs) {
  return new Promise(resolve => {
    setTimeout(resolve, delayMs);
  });
}

export { callAxios };
