<template>
  <div class="areablock-container">
    <div class="instrument-info">
      <span
        class="dot"
        :style="{
          backgroundColor: getStatusColor(areaBlockStatus),
        }"
      ></span>
      <span style="color: #ffffff;">{{ area.name }}  ({{ lastUpdateTime }})</span> 
      <span style="color: #303133; font-weight: bold; padding-left: 1.2rem;">{{ area.no }}</span>
    </div>
    <div class="instrument-grid">
      <div
        v-for="(instrument, index) in instruments"
        :key="index"
        class="instrument-card"
      >
        <span
          class="dot"
          :style="{
            backgroundColor: getStatusColor(instrument.status),
          }"
        ></span>
        <div class="instrument-data" :title="`${instrument.instrument_data}`">
          <span :style="informInsturmentStyle(instrument.informStatus)"> {{ shortenInsData(instrument.instrument_data) }} </span>
          <router-link
            v-if="use_chart_btn"
            class="button chart-btn"
            :to="{ path: 'chart', query: { instrument: instrument._id } }"
          >
            chart
          </router-link>
          <el-button class="button inform-btn" :style="informBtnStyle(instrument.informStatus)" v-if="instrument.informStatus" @click="inform(instrument)">
            {{ instrument.informStatus }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus">
.areablock-container{
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 1px solid #409EFF;
  margin-bottom: 12px;
}
</style>

<style scoped>
.instrument-data {
  width: 100%; /* or whatever width you want */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 93%;
}
.instrument-grid {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.instrument-info{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #409EFF;
}
.instrument-card {
  display: flex;
  align-items: center;
  margin: 1px;
  padding: 1px;
  padding-right: 0px;
  padding-left: 1px;
  box-sizing: border-box;
  height: auto;
  width: 305pt;
}
.button.chart-btn {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 75%;
  height: auto;
}
.button.inform-btn {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 80%;
  height: auto;
}
</style>

<script>
import moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import { dotList, getStatusColor } from '@/lib/base/systemAlert';
window.moment = moment;
MomentTimeZone();
export default {
  props: {
    alert: {
      require: true,
    },
    close_alert: {
      require: true,
    },
    area: {
      type: Object,
      require: true,
    },
    use_chart_btn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    areaBlockStatus() {
      let result = 'GREEN';
      for(let ins of this.instruments){
        //status == 'WSL' || status == 'AL' || status == 'WL'
        if(ins.status === 'WSL') result = 'WSL';
        else if(ins.status === 'AL' && result !== 'WSL') result = 'AL';
        else if(ins.status === 'WL' && result !== 'WSL' && result !== 'AL') result = 'WL';
      }
      return result;
    },
  },
  data() {
    return {
      loading: false,
      instruments: [],
      refreshFunc: null,
      lastUpdateTime: null,
    };
  },
  mounted() {
    this.update();
    this.refreshFunc = setInterval(() => {
      this.update();
    }, 1000 * 60 * 10);
  },
  beforeDestroy() {
    clearInterval(this.refreshFunc);
  },
  methods: {
    getStatusColor,
    update() {
      this.loading = true;
      this.getInstrumentsOutput();
    },
    getInstrumentsOutput(){
      this.instruments = [];
      return this.$axios({
        category: 'occ',
        method: 'get',
        apiName: `${this.area._id}/getAreaInstruments`,
        //useUrl: this.useUrl ? 'https://api-thsr-rain.geosupply.com.tw/' : null,
      }).then(async res=>{
        //console.log(res.data);
        for(let station of res.data.station){
          for(let instrument of station.instrument){
            this.instruments.push(await this.instrumentCompute(instrument));
          }
        }
        this.lastUpdateTime = moment(this.instruments[0].last_reading.TIMESTAMP)
          .format('YYYY-MM-DD HH:mm');
        this.loading = false;
      });
    },
    async instrumentCompute(instrument){
      let last_reading = null;
      let rate_reading = null;
      if(instrument.last_reading) last_reading = instrument.last_reading.reading2;
      if(last_reading) last_reading = last_reading.toFixed(2);
      if(instrument.last_reading && instrument.rate1Hr_reading) rate_reading = instrument.rate1Hr_reading.reading2 - last_reading;
      if(rate_reading) rate_reading = rate_reading.toFixed(2);
      instrument.instrument_data = `${instrument.data[0].name} ${last_reading}(${instrument.data[0].unit})/${rate_reading}(${instrument.data[0].unit}/1hr)`;
      
      if(this.isAlert(instrument)){
        let isInform = await this.isInform(instrument);
        instrument.informStatus = (isInform) ? '已通報' : '通報';
        if(!isInform) this.alert(instrument.data[0].name);
        else this.close_alert(instrument.data[0].name);
      }
      
      return instrument;
    },
    async isInform(instrument){
      let id = instrument._id;
      let result = await this.$axios({
        category: 'occ',
        method: 'get',
        apiName: `inform/${id}`,
      });
      //console.log(result.data);
      if(result.data.inform!=null) return true;
      return false;
    },
    informInsturmentStyle(status){
      if(status==='通報') return 'background-color: gray; color: white;';
      return '';
    },
    informBtnStyle(status){
      if(status==='通報') return 'background-color: gray; color: white;';
      return '';
    },
    shortenInsData(data) {
      return data.length > 40 ? `${data.substring(0, 37)}...` : data;
    },
    isAlert(instrument){
      return (instrument.alert_count >= (instrument.data[0].alert_count_limit || 2)) && this.alertStatus(instrument.status);
    },
    //alert(text){
    //  alert(text);
    //},
    alertStatus(status){
      return status == 'WSL' || status == 'AL' || status == 'WL';
    },
    inform(instrument){
      //console.log(`inform! ${instrument.data[0].name}`);
      if(instrument.informStatus === '已通報') return;
      this.close_alert(instrument.data[0].name);
      this.$axios({
        category: 'occ',
        method: 'put',
        apiName: `inform/${instrument._id}`,
      }).then(()=>{
        return this.isInform(instrument);
      }).then(isInform=>{
        instrument.informStatus = (isInform) ? '已通報' : '未通報';
      }).catch(e=>console.log(e));
    },
  },
};
</script>