<template>
  <div style="height:100%;">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :inline="true"
      :hide-required-asterisk="true"
      class="columns is-multiline"
      label-position="top"
      label-width="80px"
    >
      <div class="column">
        <el-card v-loading="initLoading || chartLoading" style="height:100%" class="data-selector">
          <el-form-item required prop="instrument" style="width:100%">
            <label slot="label" class="label">
              {{ $t('chart.instrument.pick') }}
            </label>
            <el-cascader
              v-model="form.instrument"
              size="large"
              :key="propsKey"
              :props="props"
              :options="projectOptions"
              :collapse-tags="true"
              :show-all-levels="false"
              :placeholder="$t('chart.instrument.pick')"
              style="width:280px"
            >
            </el-cascader>
          </el-form-item>
        </el-card>
      </div>
      <div class="column">
        <el-card style="height:100%" v-loading="chartLoading" class="data-selector">
          <el-form-item required prop="date">
            <div class="flex-row align-center justify-between">
              <label slot="label" class="label">{{ $t('chart.date.pick') }}</label>
              <div class="is-pulled-right">
                <el-button
                  v-for="option in pickDateList"
                  :key="option.value"
                  :class="{ 'is-plain': dateActiveIndex !== option.value }"
                  @click="setDateActiveIndex(option.value)"
                  size="mini"
                  type="primary"
                >
                  {{ option.label }}
                </el-button>
              </div>
            </div>
            <!-- this.$device.mobile -->
            <el-date-picker 
              v-if="!$device.mobile"
              v-model="form.date"
              :start-placeholder="$t('chart.date.start')"
              :end-placeholder="$t('chart.date.end')"
              :picker-options="datePickerOptions"
              :default-time="['00:00:00']"
              @change="setDate()"
              type="datetimerange"
              format="yyyy/MM/dd HH:mm"
              size="large"
            />
            <div v-if="$device.mobile">
              <el-date-picker 
                v-model="form.date[0]"
                :placeholder="$t('chart.date.start')"
                :picker-options="datePickerOptions"
                :default-time="['00:00:00']"
                :clearable="false"
                type="datetime"
                format="yyyy/MM/dd HH:mm"
              />
              <el-date-picker 
                v-model="form.date[1]"
                :placeholder="$t('chart.date.end')"
                :picker-options="datePickerOptions"
                :default-time="['00:00:00']"
                :clearable="false"
                type="datetime"
                format="yyyy/MM/dd HH:mm"
              />
            </div>
          </el-form-item>
        </el-card>
      </div>
      <div class="column">
        <el-card style="height:100%" v-loading="chartLoading" class="data-selector">
          <el-form-item>
            <div class="flex-row align-center justify-between">
              <label slot="label" class="label">{{ $t('chart.interval.pick') }}</label>
              <div class="is-pulled-right">
                <span v-for="interval in intervalList" :key="interval" style="padding:5px">
                  <el-tooltip :content="$t(`chart.interval.tooltip.${interval}`)" :disabled="$t(`chart.interval.tooltip.${interval}`)==''" placement="top">
                    <el-button
                      :class="{ 'is-plain': form.interval !== interval }"
                      @click="form.interval = interval"
                      size="mini"
                      type="primary"
                      :disabled="timeOverThanLimit && interval === 'all'"
                    >
                      {{ $t(`chart.interval.${interval}`) }}
                    </el-button>
                  </el-tooltip>
                </span>
              </div>
            </div>
            <el-time-select
              v-model="form.intervalInitialTime"
              class="mr-8"
              style="width:280px"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '24:00',
              }"
              placeholder="选择时间"
              @change="afterSetTimeInterval"
            >
            </el-time-select>
          </el-form-item>
        </el-card>
      </div>
      <div class="column">
        <el-card class="data-selector" v-loading="initLoading" style="height:100%;display: flex;flex-direction: column;justify-content: center;">
          <el-button
            @click="submitForm"
            :disabled="chartLoading"
            style="height:80px;width:100%;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, 微软雅黑, Arial, sans-serif !important;"
            class="label"
          >
            {{ chartLoading ? $t('chart.execute.loading') : $t('chart.execute.btn') }}
          </el-button>
        </el-card>
      </div>
    </el-form>
    <div>
      <el-card class="chart-option" shadow="always">
        <el-row>
          <div class="flex-row align-center justify-between">
            <div>
              <span v-if="autoRefresh" class="is-size-7"
                >{{ $t('chart.lastFetch') }}:
                {{ $moment(lastRefreshTimestamp).format('YYYY/MM/DD HH:mm') }}</span
              >
            </div>
            <div class="flex-row align-center justify-end">
              <el-date-picker
                v-model="selectedComputeDate"
                type="datetime"
                :placeholder="$t('chart.date.pick')"
                format="yyyy/MM/dd HH:mm"
                size="small"
                style="margin-right: 10px;"
              ></el-date-picker>
              <el-tooltip :content="$t('chart.info.compare')" placement="top">
                <el-button
                  :plain="!useCompare"
                  @click="toggleCompare"
                  type="primary"
                  size="small"
                >
                  {{ $t('chart.compare') }}
                </el-button>
              </el-tooltip>
              <el-tooltip :content="$t('chart.info.group')" placement="top">
                <el-button
                  :plain="!useUnitGrouping"
                  :disabled="useTenGrouping || useEachGrouping"
                  @click="toggleUseUnitGroup"
                  type="primary"
                  size="small"
                >
                  {{ $t('chart.group') }}
                </el-button>
              </el-tooltip>
              <el-tooltip v-if="testRole" :content="$t('chart.info.tenGroup')" placement="top">
                <el-button
                  :plain="!useTenGrouping"
                  :disabled="useUnitGrouping || useEachGrouping"
                  @click="toggleUseTenGroup"
                  type="primary"
                  size="small"
                >
                  {{ $t('chart.tenGroup') }}
                </el-button>
              </el-tooltip>
              <el-tooltip v-if="testRole" :content="$t('chart.info.eachGroup')" placement="top">
                <el-button
                  :plain="!useEachGrouping"
                  :disabled="useUnitGrouping || useTenGrouping"
                  @click="toggleUseEachGroup"
                  type="primary"
                  size="small"
                >
                  {{ $t('chart.eachGroup') }}
                </el-button>
              </el-tooltip>
              <el-tooltip v-if="testRole" :content="$t('chart.info.rainGroup')" placement="top">
                <el-button
                  :plain="!useRainGrouping"
                  :disabled="!useTenGrouping && !useEachGrouping"
                  @click="toggleRainGrouping"
                  type="primary"
                  size="small"
                >
                  {{ $t('chart.rainGroup') }}
                </el-button>
              </el-tooltip>
              <el-tooltip :content="$t('chart.info.output')" placement="top">
                <el-button
                  @click="toggleOutput"
                  type="primary"
                  size="small"
                  :plain="true"
                >
                  {{ $t('chart.output') }}
                </el-button>
              </el-tooltip>
              <el-tooltip :content="$t('chart.info.output-2')" placement="top">
                <el-button
                  @click="toggleOutput_type2"
                  type="primary"
                  size="small"
                  :plain="true"
                >
                  {{ $t('chart.output-2') }}
                </el-button>
              </el-tooltip>
              <el-tooltip :content="$t('chart.refresh')" placement="top">
                <el-button
                  :plain="!autoRefresh"
                  @click="toggleAutoRefresh"
                  type="primary"
                  size="small"
                >
                  <font-awesome-icon v-if="autoRefresh" icon="check" />
                  <font-awesome-icon v-else icon="redo" />
                </el-button>
              </el-tooltip>
            </div>
          </div> 
        </el-row>
      </el-card>
    </div>
    <div>
      <ChartComponent
        v-for="(item, index) in unitGroups" :key="index"
        @getRemarks="getRemarks"
        @submitForm="submitForm"
        :selectedInstrument="item.selectedInstrument"
        :instrumentRemarkList="item.instrumentRemarkList"
        :chartData="item.chartData"
        :date="form.date"
        :groupName="item.unitGroupName"
        :groupOption="item.groupOption"

        :dataLoading="chartLoading"
        :hadSubmitted="hadSubmitted"
        :rainOption="rainOption"
        @settingShare="settingShare"
        ref="chart"
        style="padding-top: 20px;"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import chartColorPalettes from './chartColorPalettes.js';
import ChartComponent from './chart.vue';
import { clientVersion } from '../../constants/env';
import 'highcharts/css/highcharts.css';
window.moment = moment;
MomentTimeZone();

export default {
  name: 'PageChart',
  components: {
    ChartComponent,
  },
  props: {
    useUrl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prev_instrument_len: 0,
      propsKey: 1000,
      hotSettings: {
        data: [],
        colHeaders: [],
        licenseKey: 'non-commercial-and-evaluation',
        colWidths: 200,
        height: 400,
        readOnly: true,
      },
      project: null,
      pickDateList: [
        {
          label: '1D',
          value: 0,
        },
        {
          label: '7D',
          value: 1,
        },
        {
          label: '1M',
          value: 2,
        },
      ],
      props: {
        multiple: true,
        //expandTrigger: 'hover',
        label: 'label',
        value: 'value',
        children: 'children',
      },
      remark: {
        formType: 'create',
        editing: {},
        instrument: '',
        timestamp: '',
        show: false,
      },
      initLoading: true,
      chartLoading: false,
      hadSubmitted: false,
      autoRefresh: false,
      showRemark: true,
      showSettings: false,
      alertInstrument: {
        _id: null,
        showTarget: ['WSL', 'AL', 'WL'],
      },
      lastRefreshTimestamp: 0,
      refreshFunction: '',
      form: {
        instrument: [],
        date: [
          moment()
            .subtract(7, 'd')
            .startOf('day')
            .utc()
            .format(),
          moment()
            .utc()
            .format(),
        ],
        interval: 'all',
        intervalInitialTime: '00:00',
        selectedArea: '',
        selectedStation: '',
      },
      rules: {
        instrument: [
          {
            type: 'array',
            required: true,
            message: this.$t('chart.error.instrument'),
            trigger: 'change',
          },
        ],
        date: [
          {
            type: 'array',
            required: true,
            message: this.$t('chart.error.date'),
            trigger: 'change',
          },
        ],
      },
      chartData: {},
      instrumentRemarkList: [],
      instrumentOptions: [],
      projectOptions: [],
      intervalList: ['hour', 'daily', 'all'],
      chartActiveIndex: 1,
      dateActiveIndex: 1,
      chartDateRangeList: [
        {
          name: '6H',
          day: null,
          hours: 6,
        },
        {
          name: '1D',
          day: 1,
          hours: null,
        },
        {
          name: '7D',
          day: 7,
          hours: null,
        },
      ],
      colorPalettes: chartColorPalettes,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      options: {
        series: [],
        xAxis: {
          min: undefined,
          max: undefined,
          type: 'datetime',
        },
        yAxis: [],
      },
      oldYAxis: [],
      unitIndex: [],
      showVelocity1Hr: false,
      showVelocity24Hr: false,
      enable1HrVelocity: false,
      enable24HrVelocity: false,
      activelySetTimeIntervals: false,

      res: null,

      //分組相關
      useUnitGrouping: false,
      useTenGrouping: false,
      useRainGrouping: false,
      useEachGrouping: false,

      unitGroups: [],
      //數據比較相關
      selectedComputeDate: moment().subtract(7, 'd').startOf('day').valueOf(),
      rainOption: {},
    };
  },
  computed: {
    timezone() {
      return this.$store.getters['env/timezone'];
    },
    hasAccess() {
      return {
        remark: this.$store.getters['project/hasRemarkAccess'],
      };
    },
    selectedInstrument() {
      const instrumentList = [];
      this.form.instrument.forEach(array => {
        array.forEach(id => {
          if (id && id.trim().length && !instrumentList.includes(id)) {
            const instrument = this.instrumentOptions.find(elem => {
              return elem._id === id;
            });
            if(!((this.showVelocity1Hr || this.showVelocity24Hr) && !instrument.latestVersion.allowRate))
              instrumentList.push(instrument);
          }
        });
      });
      return instrumentList;
    },
    selectedProject() {
      if(this.project) return this.project;
      return this.$store.getters['project/selectedProject'];
    },
    userRole() {
      return this.$store.getters['user/details'].roles;
    },
    thsrRole() {
      return clientVersion === 'thsr-main' || clientVersion === 'test';
    },
    testRole() {
      return clientVersion === 'test';
    },
    occRole() {
      return this.userRole === 'occ';
    },
    timeOverThanLimit() {
      const milliseconds0 = (new Date(this.form.date[0])).getTime();
      const milliseconds1 = (new Date(this.form.date[1])).getTime();

      // 計算兩個日期之間的時間差（以毫秒為單位）
      const timeDiff = milliseconds1 - milliseconds0;

      // 將時間差轉換為天數
      const daysDiff = timeDiff / (1000 * 60 * 60 * 24);

      // 計算兩個日期之間的月數
      const monthsDiff = Math.floor(daysDiff / 30.44);

      // 返回 true 表示時間差超過 3 個月，否則返回 false
      return monthsDiff > 3;
    },
  },
  async mounted() {
    if(this.$route.query.startDate && !this.$route.query.endDate) this.form.date = [
      moment(this.$route.query.startDate)
        .subtract(7, 'd')
        .startOf('day')
        .utc()
        .format(),
      moment(this.$route.query.startDate)
        .utc()
        .format(),
    ];
    else if(this.$route.query.startDate && this.$route.query.endDate){
      this.form.date = [
        moment(this.$route.query.endDate)
          .utc()
          .format(),
        moment(this.$route.query.startDate)
          .utc()
          .format(),
      ];
    }
    if(this.useUrl){
      //console.log('yes yes yes yes');
      this.setProject()
        .then(() => {
          this.initCascaderSelector();
        });
    }
    else{
      this.initCascaderSelector();
    }
    //this.checkProjectVelocityOption();
  },
  beforeDestroy() {
    clearInterval(this.refreshFunction);
  },
  methods: {
    setProject() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: '',
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      })
        .then(res => {
          this.project = res.data[0];
        })
        .catch(this.$notifyError);
    },
    checkProjectVelocityOption(){
      //const showList = this.selectedProject.config.data.display;
      //if(showList.includes('velocity1Hr')) this.enable1HrVelocity = true;
      //if(showList.includes('velocity24Hr')) this.enable24HrVelocity = true;
      this.enable1HrVelocity = false;
      this.enable24HrVelocity = false;
      const instrumentList = this.selectedInstrument;
      instrumentList.forEach(ins=>{
        if(ins.latestVersion.allowRate) {
          this.enable1HrVelocity = true;
          this.enable24HrVelocity = true;
        }
      });
      if(!this.enable1HrVelocity) this.showVelocity1Hr = false;
      if(!this.enable24HrVelocity) this.showVelocity24Hr = false;
    },
    initCascaderSelector() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/area-station-instrument`,
        useUrl: this.useUrl ? 'https://api-thsr-rain.geosupply.com.tw/' : null,
      })
        .then(async res => {

          const occ = (this.occRole) ? (await this.getOCCInstrumentsAndAreas()) : null;

          this.projectOptions = res.data.area.map(area => {
            const obj = {
              label: area.name,
              disabled: area.station.length === 0,
              children: area.station.map(station => {
                const obj = {
                  label: station.name,
                  disabled: station.instrument.length === 0,
                  children: station.instrument.map(instrument => {
                    if (
                      instrument.instrument_type &&
                      instrument.instrument_type.type === 'rainGauge'
                    ) {
                      const hourInterval = moment
                        .duration(instrument.instrument_type.dataInterval)
                        .hours();
                      const minuteInterval = moment(instrument.instrument_type.dataInterval)
                        .subtract(hourInterval, 'hour')
                        .minute();
                      const hourIntervalStep = moment
                        .duration(instrument.instrument_type.defaultInterval)
                        .hours();
                      const minuteIntervalStep = moment(instrument.instrument_type.defaultInterval)
                        .subtract(hourInterval, 'hour')
                        .minute();
                      
                      instrument.instrument_type.defaultHourInterval = hourInterval;
                      instrument.instrument_type.defaultMinuteInterval = minuteInterval;
                      instrument.instrument_type.hourIntervalStep = hourIntervalStep;
                      instrument.instrument_type.minuteIntervalStep = minuteIntervalStep;
                      // if (hourInterval > 0) {
                      //   instrument.instrument_type.isHour = true;
                      // } else {
                      //   instrument.instrument_type.isHour = false;
                      // }
                    }
                    const obj = {
                      label: instrument.latestVersion.name,
                      value: instrument._id,
                    };

                    if(this.occRole && !this.instrumentIncludeCheck(instrument, occ)) return null;

                    this.instrumentOptions.push(instrument);
                    return obj;
                  }).filter(ele => ele),
                };
                if(obj.children.length===0) return null;
                return obj;
              }).filter(ele => ele),
            };

            if(obj.children.length === 0) return null;
            return obj;
          }).filter(ele => ele);
          const instrumentId = this.$route.query.instrument;
          if (
            instrumentId &&
            this.instrumentOptions.some(ele => {
              return ele._id === instrumentId;
            })
          ) {
            this.form.instrument.push([undefined, undefined, instrumentId]);
            //this.submitForm();
          }
        })
        .then(() => {
          this.initLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.$notifyError(this.$t('chart.error.loading'), error.response.data.message);
        });
    },
    strictFilterInstrumentList() {
      const instrumentList = [];
      this.form.instrument.forEach(ele => {
        ele.forEach(instrument => {
          if (instrument && instrument.trim().length && !instrumentList.includes(instrument))
            instrumentList.push(instrument);
        });
      });
      return instrumentList;
    },
    onRemarkFormClose() {
      this.remark.show = false;
      this.remark.editing = {};
    },
    submitForm(prop={}) {
      // console.log(this.form.instrument.length);
      if (this.form.instrument.length < this.prev_instrument_len) {
        // this.propsKey++;
      }
      this.prev_instrument_len = this.form.instrument.length;
      if(![null,undefined].includes(prop.name)){
        let index = this.unitGroupInclude(this.unitGroups, prop.name);
        //console.log(`test now ${index}`);
        //console.log(this.unitGroups);
        //console.log(prop);
        this.unitGroups[index].groupOption.showVelocity1Hr = prop.groupOption.showVelocity1Hr || this.unitGroups[index].groupOption.showVelocity1Hr;
        this.unitGroups[index].groupOption.showVelocity24Hr = prop.groupOption.showVelocity24Hr || this.unitGroups[index].groupOption.showVelocity24Hr;
        //this.showVelocity1Hr = this.unitGroups[index].groupOption.showVelocity1Hr;
        //this.showVelocity24Hr = this.unitGroups[index].groupOption.showVelocity24Hr;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.hadSubmitted = true;
          this.updateData('yAxisRule_optionPrecedeRescale');
        } else {
          return false;
        }
      });
      //this.checkAndLogSelectedAreas();
    },
    async updateData(type = 'yAxisRule_beAsIs') {
      if (!this.hadSubmitted) return;
      if (this.chartLoading) {
        this.$notify({
          title: '運作中',
          message: '下載數據中，請勿執行其他操作',
          dangerouslyUseHTMLString: true,
          type: 'warning',
        });
        return;
      }
      this.checkProjectVelocityOption();
      this.chartLoading = true;
      await this.getRemarks();
      await this.getOutput();
      this.rainGaugeUnitInit();
      this.setGroups();
      this.getInstrumentsData();
      this.groupsUpdate(type);
      if (this.selectedInstrument.length) {
        this.chartLoading = false;
      }
    },
    async getOutput() {
      const instrumentList = this.strictFilterInstrumentList();
      //const showVelocity
      if (this.form.instrument.length <= 0) return;
      return this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/output`,
        data: {
          date: this.form.date,
          interval: this.form.interval,
          intervalInitialTime: this.form.intervalInitialTime,
          instrument: instrumentList,
          timezone: this.timezone,
        },
        useUrl: this.useUrl ? 'https://api-thsr-rain.geosupply.com.tw/' : null,
      })
        .then(res => {
          this.lastRefreshTimestamp = Date.now();
          this.res = res;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getInstrumentsData() {
      this.selectedInstrument.forEach(instrument => {
        const instrumentName = instrument.latestVersion.name;
        if (!this.chartData[instrumentName]) {
          this.chartData[instrumentName] = {};
        }
        let option = this.findInstrumentOption(instrument);
        if(option && option.showVelocity24Hr && instrument.latestVersion.allowRate){
          this.getInstrumentVelocityData(this.res.data, instrument, 24);
        }
        else if(option && option.showVelocity1Hr && instrument.latestVersion.allowRate){
          this.getInstrumentVelocityData(this.res.data, instrument, 1);
        }
        else
          this.getInstrumentData(this.res.data, instrument);

        //執行時間比較
        if(this.useCompare && !(instrument.instrument_type && instrument.instrument_type.type === 'rainGauge')){
          const time = this.checkTimeLimit(this.selectedComputeDate, this.form.date[0], this.form.date[1]);
          this.selectedComputeDate = time;
          const output = this.getInstrumentOutput(instrument, time);
          if(output!=null)
            this.timeCompare(output, instrument);
        }
      });
      this.unitGroups.forEach((group,index)=>{
        let chartDatas = {};
        group.selectedInstrument.forEach(instrument=>{
          const instrumentName = instrument.latestVersion.name;
          chartDatas[instrumentName] = this.chartData[instrumentName];
        });
        this.unitGroups[index].chartData = chartDatas;
      });
    },
    findInstrumentOption(instrument){
      for(let i in this.unitGroups){
        let group = this.unitGroups[i];
        for(let ins of group.selectedInstrument){
          const instrumentName = ins.latestVersion.name;
          if(instrumentName === instrument.latestVersion.name) return group.groupOption;
        }
      }
      return null;
    },
    async getRemarks(next) {
      const [startDate, endDate] = this.form.date;
      const instrumentList = this.strictFilterInstrumentList();
      return this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/instrument/remark?type=multiple`,
        data: {
          instrument: instrumentList,
          start_date: startDate,
          end_date: endDate,
        },
        useUrl: this.useUrl ? 'https://api-thsr-rain.geosupply.com.tw/' : null,
      })
        .then(res => {
          this.instrumentRemarkList = res.data;
          if(next) {
            this.setGroups();
            this.getInstrumentsData();
            //this.groupsUpdate();
            next();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    rainGaugeUnitInit() {
      //用於分別雨量計之單位
      this.selectedInstrument.forEach(ele=>{
        if(ele.instrument_type && ele.instrument_type.type === 'rainGauge') {
          ele.latestVersion.unit = `${ele.instrument_type.unit}/24H`;
          this.rainOption[`${ele.instrument_type.defaultHourInterval}:${ele.instrument_type.defaultMinuteInterval}`] = {
            //預設
            //hourInterval: ele.instrument_type.defaultHourInterval,
            //minuteInterval: ele.instrument_type.defaultMinuteInterval,
            //強制使用1HR，THSR的話使用10min
            hourInterval: this.thsrRole ? 0 : 1,
            minuteInterval: this.thsrRole ? 10 : 0,
          };
        }
      });
    },
    setGroups(){
      if(this.useUnitGrouping){
        this.setUnitGroups();
      }
      else if(this.useTenGrouping){
        this.setTenGroups();
      }
      else if(this.useEachGrouping){
        this.setEachGroups();
      }
      else{
        let selectedInstruments = this.selectedInstrument;
        let old_Groups = this.unitGroups;
        this.unitGroups = [
          {
            unitGroupName: 'ALL',
            selectedInstrument: selectedInstruments,
            instrumentRemarkList: this.instrumentRemarkList,
            //chartData: chartDatas,
            groupOption: {
              //(old_Groups && old_Groups[0]) ? old_Groups[0].groupOption
              showVelocity1Hr : (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity1Hr  : false,
              showVelocity24Hr: (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity24Hr : false,
            },
          },
        ];
      }
    },
    setUnitGroups(){
      if(this.useUnitGrouping){
        let unitInstrumentList = [];
        this.selectedInstrument.forEach(ele=>{
          let unit = ele.latestVersion.unit;
          let inList = -1;
          for(let i in unitInstrumentList){
            if(unitInstrumentList[i].unit === unit){
              inList = i;
              break;
            }
          }
          if(inList>=0) unitInstrumentList[inList].instruments.push(ele);
          else unitInstrumentList.push(
            {
              instruments: [ele],
              unit,
            },
          );
        });

        let old_Groups = this.unitGroups;
        let optionInherit = this.unitGroups && this.unitGroups[0].unitGroupName === 'ALL';
        this.unitGroups = [];
        
        unitInstrumentList.forEach(ele=>{
          let selectedInstruments = ele.instruments;
          
          let unitGroupIndex = optionInherit ? 0 : this.unitGroupInclude(old_Groups, ele.unit);
          this.unitGroups.push(
            {
              unitGroupName: ele.unit,
              selectedInstrument: selectedInstruments,
              instrumentRemarkList: this.instrumentRemarkList,
              //chartData: chartDatas,
              groupOption: {
                //(old_Groups && old_Groups[0]) ? old_Groups[0].groupOption
                showVelocity1Hr : (old_Groups && old_Groups[unitGroupIndex]) ? old_Groups[unitGroupIndex].groupOption.showVelocity1Hr  : false,
                showVelocity24Hr: (old_Groups && old_Groups[unitGroupIndex]) ? old_Groups[unitGroupIndex].groupOption.showVelocity24Hr : false,
              },
            },
          );
        });
      }
      else{
        let selectedInstruments = this.selectedInstrument;
        let old_Groups = this.unitGroups;
        this.unitGroups = [
          {
            unitGroupName: 'ALL',
            selectedInstrument: selectedInstruments,
            instrumentRemarkList: this.instrumentRemarkList,
            //chartData: chartDatas,
            groupOption: {
              //(old_Groups && old_Groups[0]) ? old_Groups[0].groupOption
              showVelocity1Hr : (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity1Hr  : false,
              showVelocity24Hr: (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity24Hr : false,
            },
          },
        ];
      }
    },
    setTenGroups(){
      if(this.useTenGrouping && this.useRainGrouping){
        let tenInstrumentList = [];
        let count = 0;

        const rainInstrumentList = [];

        this.selectedInstrument.forEach(ele=>{
          if(ele.instrument_type && ele.instrument_type.type === 'rainGauge') {
            rainInstrumentList.push(ele);
          }
          else{
            if(count%10!=0) tenInstrumentList[tenInstrumentList.length-1].instruments.push(ele);
            else tenInstrumentList.push(
              {
                instruments: [ele],
              },
            );
            count++;
          }
        });

        tenInstrumentList = tenInstrumentList.map(ele=>{
          return {
            instruments: ele.instruments.concat(rainInstrumentList),
          };
        });

        let old_Groups = this.unitGroups;
        let optionInherit = this.unitGroups && this.unitGroups[0].unitGroupName === 'ALL';
        this.unitGroups = [];
        
        tenInstrumentList.forEach((ele,index)=>{
          let selectedInstruments = ele.instruments;
          
          let unitGroupIndex = optionInherit ? 0 : this.unitGroupInclude(old_Groups, index);
          this.unitGroups.push(
            {
              unitGroupName: index,
              selectedInstrument: selectedInstruments,
              instrumentRemarkList: this.instrumentRemarkList,
              //chartData: chartDatas,
              groupOption: {
                //(old_Groups && old_Groups[0]) ? old_Groups[0].groupOption
                showVelocity1Hr : (old_Groups && old_Groups[unitGroupIndex]) ? old_Groups[unitGroupIndex].groupOption.showVelocity1Hr  : false,
                showVelocity24Hr: (old_Groups && old_Groups[unitGroupIndex]) ? old_Groups[unitGroupIndex].groupOption.showVelocity24Hr : false,
              },
            },
          );
        });
      }
      else if(this.useTenGrouping){
        let tenInstrumentList = [];
        let count = 0;
        this.selectedInstrument.forEach(ele=>{
          if(count%10!=0) tenInstrumentList[tenInstrumentList.length-1].instruments.push(ele);
          else tenInstrumentList.push(
            {
              instruments: [ele],
            },
          );
          count++;
        });

        let old_Groups = this.unitGroups;
        let optionInherit = this.unitGroups && this.unitGroups[0].unitGroupName === 'ALL';
        this.unitGroups = [];
        
        tenInstrumentList.forEach((ele,index)=>{
          let selectedInstruments = ele.instruments;
          
          let unitGroupIndex = optionInherit ? 0 : this.unitGroupInclude(old_Groups, index);
          this.unitGroups.push(
            {
              unitGroupName: index,
              selectedInstrument: selectedInstruments,
              instrumentRemarkList: this.instrumentRemarkList,
              //chartData: chartDatas,
              groupOption: {
                //(old_Groups && old_Groups[0]) ? old_Groups[0].groupOption
                showVelocity1Hr : (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity1Hr  : false,
                showVelocity24Hr: (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity24Hr : false,
              },
            },
          );
        });
      }
      else{
        this.useRainGrouping = false;
        let selectedInstruments = this.selectedInstrument;
        let old_Groups = this.unitGroups;
        this.unitGroups = [
          {
            unitGroupName: 'ALL',
            selectedInstrument: selectedInstruments,
            instrumentRemarkList: this.instrumentRemarkList,
            //chartData: chartDatas,
            groupOption: {
              //(old_Groups && old_Groups[0]) ? old_Groups[0].groupOption
              showVelocity1Hr : (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity1Hr  : false,
              showVelocity24Hr: (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity24Hr : false,
            },
          },
        ];
      }
    },
    setEachGroups(){
      if(this.useEachGrouping && this.useRainGrouping){
        let eachInstrumentList = [];

        const rainInstrumentList = [];

        this.selectedInstrument.forEach(ele=>{
          if(ele.instrument_type && ele.instrument_type.type === 'rainGauge') {
            rainInstrumentList.push(ele);
          }
          else{
            eachInstrumentList.push(
              {
                instruments: [ele],
              },
            );
          }
        });

        eachInstrumentList = eachInstrumentList.map(ele=>{
          return {
            instruments: ele.instruments.concat(rainInstrumentList),
          };
        });

        let old_Groups = this.unitGroups;
        let optionInherit = this.unitGroups && this.unitGroups[0].unitGroupName === 'ALL';
        this.unitGroups = [];
        
        eachInstrumentList.forEach((ele,index)=>{
          let selectedInstruments = ele.instruments;
          
          let unitGroupIndex = optionInherit ? 0 : this.unitGroupInclude(old_Groups, index);
          this.unitGroups.push(
            {
              unitGroupName: index,
              selectedInstrument: selectedInstruments,
              instrumentRemarkList: this.instrumentRemarkList,
              //chartData: chartDatas,
              groupOption: {
                //(old_Groups && old_Groups[0]) ? old_Groups[0].groupOption
                showVelocity1Hr : (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity1Hr  : false,
                showVelocity24Hr: (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity24Hr : false,
              },
            },
          );
        });
      }
      else if(this.useEachGrouping){
        let eachInstrumentList = [];
        this.selectedInstrument.forEach(ele=>{
          eachInstrumentList.push(
            {
              instruments: [ele],
            },
          );
        });

        let old_Groups = this.unitGroups;
        let optionInherit = this.unitGroups && this.unitGroups[0].unitGroupName === 'ALL';
        this.unitGroups = [];
        
        eachInstrumentList.forEach((ele,index)=>{
          let selectedInstruments = ele.instruments;
          
          let unitGroupIndex = optionInherit ? 0 : this.unitGroupInclude(old_Groups, index);
          this.unitGroups.push(
            {
              unitGroupName: index,
              selectedInstrument: selectedInstruments,
              instrumentRemarkList: this.instrumentRemarkList,
              //chartData: chartDatas,
              groupOption: {
                //(old_Groups && old_Groups[0]) ? old_Groups[0].groupOption
                showVelocity1Hr : (old_Groups && old_Groups[unitGroupIndex]) ? old_Groups[unitGroupIndex].groupOption.showVelocity1Hr  : false,
                showVelocity24Hr: (old_Groups && old_Groups[unitGroupIndex]) ? old_Groups[unitGroupIndex].groupOption.showVelocity24Hr : false,
              },
            },
          );
        });
      }
      else{
        this.useRainGrouping = false;
        let selectedInstruments = this.selectedInstrument;
        let old_Groups = this.unitGroups;
        this.unitGroups = [
          {
            unitGroupName: 'ALL',
            selectedInstrument: selectedInstruments,
            instrumentRemarkList: this.instrumentRemarkList,
            //chartData: chartDatas,
            groupOption: {
              //(old_Groups && old_Groups[0]) ? old_Groups[0].groupOption
              showVelocity1Hr : (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity1Hr  : false,
              showVelocity24Hr: (old_Groups && old_Groups[0]) ? old_Groups[0].groupOption.showVelocity24Hr : false,
            },
          },
        ];
      }
    },
    unitGroupInclude(unitGroups,unit){
      let result = -1;
      for(let index in unitGroups){
        if(unitGroups[index].unitGroupName == unit){
          result = index;
          break;
        }
      }
      return result;
    },
    oldGroupsInit(){
      if(this.unitGroups.length==1 && this.unitGroups[0].unitGroupName == 'ALL') return [];
      return this.unitGroups;
    },
    groupsUpdate(type){
      this.$nextTick(() => {
        for(let i in this.unitGroups){
          if(this.$refs.chart && this.$refs.chart[i])
          {
            this.$refs.chart[i].update(type);
            //this.$refs.chart[i].$refs.highcharts.options.xAxis[0].alignTicks = true;
          }
          //console.log(`high ${i}`);
        }
      });
    },
    getInstrumentData(data, instrument) {
      const instrumentName = instrument.latestVersion.name;
      const isRainGauge = instrument.instrument_type && instrument.instrument_type.type === 'rainGauge';
      
      const result = data.filter(ele => ele.instrument_id === instrument._id);
      this.chartData[instrumentName].data = result.map(obj => {
        return {
          x: Date.parse(obj.TIMESTAMP),
          y: obj[instrument.display_output],
          instrument: instrument._id,
        };
      }).filter(obj => {
        const date = new Date(obj.x);
        const timezoneOffset = date.getTimezoneOffset();
        const hourTimezoneOffset = Math.floor(timezoneOffset / 60);
        const minuteTimezoneOffset = timezoneOffset - hourTimezoneOffset * 60;
        // form.intervalInitialTime
        let [hour, minute] = this.form.intervalInitialTime.split(':');
        hour = Number(hour);
        minute = Number(minute);
        //console.log(`${date.getHours()} ${date.getMinutes()}`);
        date.setHours(date.getHours() + hourTimezoneOffset + 8, date.getMinutes() + minuteTimezoneOffset);
        //console.log(`${date.getHours()} ${date.getMinutes()}`);
        if(this.form.interval === 'all'){
          return true;
        }
        else if(isRainGauge){
          return true;
        }
        else if(this.form.interval === '10min' && date.getMinutes() % 10 === minute){
          return true;
        }
        else if(this.form.interval === '15min' && date.getMinutes() % 15 === minute){
          return true;
        }
        else if(this.form.interval === 'hour' && date.getMinutes() === minute){
          return true;
        }
        else if(this.form.interval === 'daily' && date.getHours() === hour && date.getMinutes() === minute){
          return true;
        }
        //else if(){
        //  return true;
        //}
        return false;
      });
    },
    // timeInterval表示抓取多久時間間格的速率，預設為1HR
    getInstrumentVelocityData(data, instrument, timeInterval, isOutOfTimeInterval) {
      if(!timeInterval) timeInterval = 1;
      const instrumentName = instrument.latestVersion.name;
      const result = data.filter(ele => ele.instrument_id === instrument._id);
      
      this.chartData[instrumentName].data = [];
      
      let timeTable = {};

      for(let data of result){
        const time = new Date(data.TIMESTAMP).getTime();
        timeTable[time] = data;
      }

      result.forEach(obj => {
        //if()
        let getCompareData;
        const time = new Date(obj.TIMESTAMP);
        const compareTime = new Date(obj.TIMESTAMP);
        compareTime.setHours(time.getHours() - timeInterval);
        if(timeTable[compareTime.getTime()]) getCompareData = timeTable[compareTime.getTime()];
        //for(let data of result){
        //  const thisTime = new Date(data.TIMESTAMP);
        //  thisTime.setHours(thisTime.getHours() + timeInterval);
        //  if(time.getTime() == thisTime.getTime()){
        //    getCompareData = data;
        //    break;
        //  }
        //}
        if (getCompareData && !(isOutOfTimeInterval && time.getMinutes() != 0)) {
          this.chartData[instrumentName].data.push(
            {
              x: Date.parse(obj.TIMESTAMP),
              y: (obj[instrument.display_output] - getCompareData[instrument.display_output]),
              instrument: instrument._id,
            },
          );
        }
      });
      if (this.form.interval === 'daily') {
        const all = this.$lodash.cloneDeep(this.chartData[instrumentName].data);
        const only00 = all.filter(obj => {
          const date = new Date(obj.x);
          return (date.getHours() === 0 && date.getMinutes() === 0);
        });
        this.chartData[instrumentName].data = [];
        let start = new Date(this.form.date[0]);
        let end = new Date(this.form.date[1]);
        //console.log(only00);
        //console.log(start);
        //console.log(end);
        //console.log(only00.length);
        start.setHours(0,0);
        while(start < end){
          let index = only00.findIndex(ele=>{
            return ele.x === start.getTime();
          });
          if(index>-1){
            //console.log(`${index} ${start.getDate()}`);
            this.chartData[instrumentName].data.push(only00[index]);
            start.setDate(start.getDate() + 1);
          }
          else{
            const now = this.$lodash.cloneDeep(start);
            while(index<0 && start < end){
              start.setDate(start.getDate() + 1);
              index = only00.findIndex(ele=>{
                return ele.x === start.getTime();
              });
            }
            //console.log(`${index} ${now.getDate()}`);
            if(index<0) continue;
            const newdata = {
              x: Date.parse(now),
              y: only00[index].y,
              instrument: only00[index].instrument,
            };
            this.chartData[instrumentName].data.push(newdata);
            start.setDate(now.getDate() + 1);
          }
        }
      }
    },
    getInstrumentOutput(instrument, timestamp){
      const instrumentName = instrument.latestVersion.name;
      const data = this.chartData[instrumentName].data;
      let result = null;
      for(let d of data){
        let time = d.x;
        let reading2 = d.y;
        if(new Date(time).getTime() - new Date(timestamp).getTime() == 0) {
          result = {
            time,
            reading2,
            instrument,
          };
          break;
        }
      }
      return result;
    },
    timeCompare(timeOutputData, instrument){
      const outputReading2 = timeOutputData.reading2;
      const instrumentName = instrument.latestVersion.name;
      const result = this.chartData[instrumentName].data;
      this.chartData[instrumentName].data = result.map(obj => {
        return {
          x: obj.x,
          y: obj.y - outputReading2,
          instrument: obj.instrument,
        };
      });
    },
    checkTimeLimit(inputTime, lowerLimit, upperLimit) {
      const inputTimestamp = new Date(inputTime).getTime();
      const lowerLimitTimestamp = new Date(lowerLimit).getTime();
      const upperLimitTimestamp = new Date(upperLimit).getTime();
        
      if (inputTimestamp < lowerLimitTimestamp) {
        return lowerLimit;
      } else if (inputTimestamp > upperLimitTimestamp) {
        return upperLimit;
      } else {
        return inputTime;
      }
    },
    afterSetTimeInterval(){
      this.activelySetTimeIntervals = true;
      //this.submitForm();
    },
    setDate(){
      if(this.timeOverThanLimit){
        this.form.interval = 'daily';
      }
    },
    setDateActiveIndex(index) {
      this.dateActiveIndex = index;
      const dateRange = [
        [
          moment()
            .startOf('day')
            .subtract(1, 'd')
            .utc()
            .format(),
          moment()
            .utc()
            .format(),
        ],
        [
          moment()
            .startOf('day')
            .subtract(7, 'd')
            .utc()
            .format(),
          moment()
            .utc()
            .format(),
        ],
        [
          moment()
            .startOf('day')
            .subtract(1, 'month')
            .utc()
            .format(),
          moment()
            .utc()
            .format(),
        ],
      ];
      this.form.date = dateRange[index];
      this.activelySetTimeIntervals = false;
      //this.submitForm();
      if(this.timeOverThanLimit){
        this.form.interval = 'daily';
      }
    },
    toggleUseUnitGroup(){
      if(this.chartLoading) return;
      this.useUnitGrouping = !this.useUnitGrouping;
      this.chartLoading = true;
      this.setUnitGroups();
      this.getInstrumentsData();
      this.groupsUpdate('yAxisRule_optionPrecedeRescale');
      this.chartLoading = false;
    },
    toggleUseTenGroup(){
      if(this.chartLoading) return;
      this.useTenGrouping = !this.useTenGrouping;
      this.chartLoading = true;
      this.setTenGroups();
      this.getInstrumentsData();
      this.groupsUpdate('yAxisRule_optionPrecedeRescale');
      this.chartLoading = false;
    },
    toggleRainGrouping(){
      if(this.chartLoading) return;
      this.useRainGrouping = !this.useRainGrouping;
      if(this.useTenGrouping){
        this.chartLoading = true;
        this.setTenGroups();
        this.getInstrumentsData();
        this.groupsUpdate('yAxisRule_optionPrecedeRescale');
        this.chartLoading = false;
      }
      else if(this.useEachGrouping){
        this.chartLoading = true;
        this.setEachGroups();
        this.getInstrumentsData();
        this.groupsUpdate('yAxisRule_optionPrecedeRescale');
        this.chartLoading = false;
      }
    },
    toggleUseEachGroup(){
      if(this.chartLoading) return;
      this.useEachGrouping = !this.useEachGrouping;
      this.chartLoading = true;
      this.setEachGroups();
      this.getInstrumentsData();
      this.groupsUpdate('yAxisRule_optionPrecedeRescale');
      this.chartLoading = false;
    },
    toggleAutoRefresh() {
      this.autoRefresh = !this.autoRefresh;
      if (this.autoRefresh) {
        this.refreshFunction = setInterval(() => {
          if (this.hadSubmitted && this.unitGroups.length && !this.chartLoading) {
            this.form.date[1] = moment()
              .utc()
              .format();
            this.updateData('yAxisRule_beAsIs');
          }
        }, 1000 * 60 * 5);
        if (this.hadSubmitted && this.unitGroups.length && !this.chartLoading) {
          this.updateData('yAxisRule_beAsIs');
        }
      } else {
        clearInterval(this.refreshFunction);
      }
    },
    toggleCompare() {
      this.useCompare = !this.useCompare;
      const time = this.checkTimeLimit(this.selectedComputeDate, this.form.date[0], this.form.date[1]);
      this.selectedComputeDate = time;
      if (this.hadSubmitted && this.unitGroups.length && !this.chartLoading) {
        this.updateData('yAxisRule_optionPrecedeRescale');
      }
    },
    exportSVG(chart) {
      const width = chart.chartWidth;
      const height = chart.chartHeight;

      // 手動構建 SVG 結構
      const svgHeader = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">`;
      const svgFooter = '</svg>';

      // 獲取圖表內部結構
      const innerSVG = chart.container.innerHTML;

      // 拼接完整的 SVG
      const fullSVG = svgHeader + innerSVG + svgFooter;

      // 解析 SVG 並移除內聯樣式
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(fullSVG, 'image/svg+xml');
      const elements = svgDoc.querySelectorAll('[style]');
      elements.forEach(el => el.removeAttribute('style'));

      // 添加外部樣式表引用
      const style = document.createElementNS('http://www.w3.org/2000/svg', 'style');
      const styles = require('!!raw-loader!@/assets/highcharts.css');
      //console.log(styles.default);
      style.textContent = styles.default;
      svgDoc.querySelector('svg').insertBefore(style, svgDoc.querySelector('svg').firstChild);

      // 將修改後的 SVG 轉回字符串
      const cleanedSvg = new XMLSerializer().serializeToString(svgDoc);

      // 保存或顯示 SVG
      //this.svgs.push(cleanedSvg);
      //this.drawSVGsOnCanvas([cleanedSvg],'onlyTest.png',chart.chartWidth,[chart.chartHeight]);
    
      return cleanedSvg;
    },
    toggleOutput() {
      // 獲取SVG表示的Highcharts圖表
      const svgs = [];
      let canvasWidth = 0; 
      let svgHeight = []; 
      if(this.$refs.chart && this.$refs.chart.length > 0){
        for(let chart of this.$refs.chart){
          canvasWidth = (chart.$refs.highcharts.chart.chartWidth > canvasWidth) ? chart.$refs.highcharts.chart.chartWidth : canvasWidth;
          svgHeight.push(chart.$refs.highcharts.chart.chartHeight);
          //const originOption = chart.$refs.highcharts.options;
          const option = {
            chart: {
              height: chart.$refs.highcharts.chart.chartHeight,
              width: chart.$refs.highcharts.chart.chartWidth,
              styledMode: true,
            },
            xAxis: {
              labels: undefined,
              title: undefined,
            },
            yAxis: {
              labels: undefined,
              gridLineColor: undefined,
              title: undefined,
            },
            tooltip: {
              enabled: false,
            },
            plotOptions: undefined,
          };
          //console.log(chart.chartOptions);
          //let svg = chart.$refs.highcharts.chart.getSVG(option);
          let svg = this.exportSVG(chart.$refs.highcharts.chart);
          console.log(svg);
          //svg = this.stripInlineStyles(svg);
          svgs.push(svg);
        }
      }
      //let time = moment().format('YYYYMMDD');
      //let area = this.getArea();
      let instrumentList = this.strictFilterInstrumentList();
      //console.log(instrumentList);
      //console.log(this.selectedInstrument);
      instrumentList = instrumentList.map(ins=>{
        let index = this.selectedInstrument.findIndex(ele=>ele._id === ins);
        if(index>-1) return this.selectedInstrument[index];
        else return null;
      }).filter(ele=>ele);
      let pngName = this.getPngName(instrumentList);
      this.drawSVGsOnCanvas(svgs, pngName, canvasWidth, svgHeight);
    },
    toggleOutput_type2() {
      // 獲取SVG表示的Highcharts圖表
      if(this.$refs.chart && this.$refs.chart.length > 0){
        console.log(this.unitGroups);
        for(let index in this.unitGroups){
          const group = this.unitGroups[index];
          const chart = this.$refs.chart[index];
          //console.log(group.selectedInstrument);
          //let time = moment().format('YYYYMMDD');
          let pngName = this.getPngName(group.selectedInstrument);
          //let pngName = `${time}`;
          //if(area!='') pngName = `${area} ${time}`;

          const canvasHeight = chart.$refs.highcharts.chart.chartHeight;
          const canvasWidth = chart.$refs.highcharts.chart.chartWidth;
          const option = {
            chart: {
              height: canvasHeight,
              width: canvasWidth,
            },
          };
          //const svg = chart.$refs.highcharts.chart.getSVG(option);
          const svg = this.exportSVG(chart.$refs.highcharts.chart);
          this.drawSVGsOnCanvas([svg], pngName, canvasWidth, [canvasHeight]);
        }
      }
    },
    drawSVGsOnCanvas(svgs, pngName, width = 600, heights) {
      //const canvas = this.$refs.canvas;
      const canvas = document.createElement('canvas');
      
      const totalHeight = heights ? heights.reduce(
        (cur,acc) => {
          return acc + cur;
        }
        ,0) : 400 * svgs.length;
      canvas.width = width;
      canvas.height = totalHeight;
      const ctx = canvas.getContext('2d');
      
      // 逐一繪製每張SVG圖形
      let loadedCount = 0; // 記錄已經載入的SVG數量
      let addHeight = 0;
      for (let i = 0; i < svgs.length; i++) {
        const svg = new Blob([svgs[i]], { type: 'image/svg+xml;charset=utf-8' });
        const DOMURL = window.URL || window.webkitURL || window;
        const url = DOMURL.createObjectURL(svg);
        let height = heights[i];
        const adder = addHeight;
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, adder, width, height);
          DOMURL.revokeObjectURL(url);

          loadedCount++;
          if (loadedCount === svgs.length) {
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = canvas.toDataURL('image/png');

            link.setAttribute('download', `${pngName}.png`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        };
        img.src = url;
        addHeight += height;
      }
    },
    getArea() {
      //console.log('test');
      //console.log(this.projectOptions);
      const instrumentList = this.strictFilterInstrumentList();

      let getAreas = [];
      this.projectOptions.forEach(area=>{
        const areaName = area.label;
        let getStations = 0;
        area.children.forEach(station => {
          //const stationName = station.label;
          let getStation = false;
          station.children.forEach(instrument => {
            const instrumentID = instrument.value;
            if(instrumentList.includes(instrumentID)) getStation = true;
          });
          if(getStation) getStations++;
        });
        if(getStations > 0) getAreas.push(areaName);
      });

      if(getAreas.length > 1) return '';
      if(getAreas.length == 1) return getAreas[0];

      return '';
    },
    getStations() {
      const instrumentList = this.strictFilterInstrumentList();

      let getAreas = [];
      this.projectOptions.forEach(area=>{
        const areaName = area.label;
        let getStations = [];
        area.children.forEach(station => {
          //const stationName = station.label;
          let getStation = false;
          station.children.forEach(instrument => {
            const instrumentID = instrument.value;
            if(instrumentList.includes(instrumentID)) getStation = true;
          });
          if(getStation) {
            let stationName = station.label;
            if(stationName.includes('土壤濕度計') || stationName.includes('MC')){
              stationName = 'MC';
            }
            else if(stationName.includes('位移計') || stationName.includes('TM')){
              stationName = 'TM';
            }
            else if(stationName.includes('雨量') || stationName.includes('RG') || stationName.includes('RAIN')){
              stationName = 'RG';
            }
            else if(stationName.includes('監測桿') || stationName.includes('傾斜桿') || stationName.includes('STI')){
              stationName = 'STI';
            }
            else if(stationName.includes('荷重計') || stationName.includes('LC')){
              stationName = 'LC';
            }
            else if(stationName.includes('水壓計') || stationName.includes('ELP')){
              stationName = 'ELP';
            }
            else if(stationName.includes('陣列式傾斜儀') || stationName.includes('SAA')){
              stationName = 'SAA';
            }
            else if(stationName.includes('水位計') || stationName.includes('DS')){
              stationName = 'DS';
            }
            else stationName = '';
            if(!getStations.includes(stationName)) getStations.push(stationName);
          }
        });
        if(getStations.length > 0) getAreas.push({
          area: areaName,
          stations: getStations,
        });
      });

      if(getAreas.length > 1) return '';
      if(getAreas.length == 1) return getAreas[0];

      return '';
    },
    getPngName(instruments) {
      let getAreas = [];
      let time = moment().format('YYYYMMDD');

      if(instruments.length == 1) return `${instruments[0].latestVersion.name} ${time}`;

      this.projectOptions.forEach(area=>{
        const areaName = area.label;
        let getStations = [];
        area.children.forEach(station => {
          //const stationName = station.label;
          let getStation = false;
          station.children.forEach(instrument => {
            const instrumentID = instrument.value;
            if(instruments.findIndex(ele=>{
              if(instrumentID===ele) return true;
              if(instrumentID==ele._id) return true;
              if(instrumentID==ele.latestVersion.name) return true;
              return false;
            })>-1) getStation = true;
          });
          if(getStation) {
            let stationName = station.label;
            if(stationName.includes('土壤濕度計') || stationName.includes('土壤溼度計') || stationName.includes('MC')){
              stationName = 'MC';
            }
            else if(stationName.includes('位移計') || stationName.includes('TM')){
              stationName = 'TM';
            }
            else if(stationName.includes('雨量') || stationName.includes('RG') || stationName.includes('RAIN')){
              stationName = 'RG';
            }
            else if(stationName.includes('監測桿') || stationName.includes('傾斜桿') || stationName.includes('STI')){
              stationName = 'STI';
            }
            else if(stationName.includes('荷重計') || stationName.includes('LC')){
              stationName = 'LC';
            }
            else if(stationName.includes('水壓計') || stationName.includes('ELP')){
              stationName = 'ELP';
            }
            else if(stationName.includes('陣列式傾斜儀') || stationName.includes('SAA')){
              stationName = 'SAA';
            }
            else if(stationName.includes('水位計') || stationName.includes('DS')){
              stationName = 'DS';
            }
            else stationName = '';
            if(!getStations.includes(stationName)) getStations.push(stationName);
          }
        });
        if(getStations.length > 0) getAreas.push({
          area: areaName,
          stations: getStations,
        });
      });

      if(getAreas.length > 1) return time;
      if(getAreas.length == 1) {
        let pngName = '';
        pngName = `${getAreas[0].area} `;
        getAreas[0].stations.forEach(ele=>{
          let stationName = ele;
          if(ele.includes('土壤濕度計') || ele.includes('MC')){
            stationName = 'MC';
          }
          else if(ele.includes('位移計') || ele.includes('TM')){
            stationName = 'TM';
          }
          else if(ele.includes('雨量') || ele.includes('RG') || ele.includes('RAIN')){
            stationName = 'RG';
          }
          else if(ele.includes('監測桿') || ele.includes('傾斜桿') || ele.includes('STI')){
            stationName = 'STI';
          }
          else if(ele.includes('荷重計') || ele.includes('LC')){
            stationName = 'LC';
          }
          else if(ele.includes('水壓計') || ele.includes('ELP')){
            stationName = 'ELP';
          }
          else if(ele.includes('陣列式傾斜儀') || ele.includes('SAA')){
            stationName = 'SAA';
          }
          else if(ele.includes('水位計') || ele.includes('DS')){
            stationName = 'DS';
          }
          else stationName = '';
          pngName += `${stationName} `;
        });
        pngName += `${time}`;
        return pngName;
      }

      return time;
    },
    settingShare(setting){
      for(let chart of this.$refs.chart){
        chart.directUploadSetting(setting);
      }
    },

    //取得OCC可用的儀器
    async getOCCInstrumentsAndAreas() {
      //project/:projectId/data/last/:timestamp
      let res = await this.$axios({
        category: 'occ',
        method: 'get',
        apiName: `${this.selectedProject._id}/getAllAreas`,
      });
      let areaBlocks = res.data;
      //areaBlocks = areaBlocks.map(ele=>{
      //  return {
      //    ...ele,
      //    no: this.getNoByArea(ele),
      //  };
      //});
      return areaBlocks;
    },
    instrumentIncludeCheck(instrument, area_station_instrument, compare_tag = '_id'){
      for(let area of area_station_instrument){
        for(let station of area.station){
          for(let ins of station.instrument){
            if(ins[compare_tag] === instrument[compare_tag]) return true;
          }
        }
      }
      return false;
    },
  },
};
</script>

<style lang="stylus">
.el-scrollbar__wrap 
  height: 360px !important
.el-cascader__tags .el-tag > span
  flex-basis auto
.el-drawer__body
  overflow auto
.chart-option .el-card__body{
  height: 42.5px;
  padding: 5px !important;
}
.data-selector .el-card__body{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.highcharts-axis-labels {
  font-weight: 700;
  font-size: 13px;
}

.highcharts-grid-top {
  .highcharts-grid-line {
      stroke: #e6e6e6;
    }
}

.highcharts-grid-1 {
  .highcharts-grid-line {
    stroke: #96F8EC;
  }  
}

.highcharts-grid-2 {
  .highcharts-grid-line {
    stroke: #D2B6D2;
  }  
}

.highcharts-grid-3 {
  .highcharts-grid-line {
    stroke: #98F898;
  }  
}

.highcharts-grid-4 {
  .highcharts-grid-line {
    stroke: #FF94A6;
  }  
}

.highcharts-grid-5 {
  .highcharts-grid-line {
    stroke: #F3F196;
  }  
}

.highcharts-WL-plot {
  stroke: #4188ff !important;
  fill: #4188ff;
  stroke-width: 2px;
}

.highcharts-AL-plot {
  stroke: #ffad00 !important;
  fill: #ffad00;
  stroke-width: 2px;
}

.highcharts-WSL-plot {
  stroke: #ff0000 !important;
  fill: #ff0000;
  stroke-width: 2px;
}

.highcharts-tooltip-box .highcharts-label-box {
  fill: #f7f7f7 !important;
  fill-opacity: 0.4 !important;
}

.tooltip-box .highcharts-label-box {
  fill: #f7f7f7 !important;
  fill-opacity: 0.4 !important;
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 1px;
}

</style>

<style lang="stylus" scoped>
.el-input-number
  max-width 150px
.el-form-item__label
  font-weight bold
.chart-container
  min-height 400px
.el-radio-button.is-active
  z-index 10
.el-row
  margin-bottom 1.5rem
.interval-button
  margin-left 10px
.input-label-right
  margin-left 10px
.button-primary
  color #FFF
  background-color #409EFF
  border-color #409EFF
  box-shadow -1px 0 0 0 #409EFF
.date-range-seperate
  margin 0 1rem
.card-header__bar
  border-style solid
  border-width 4px 0 0
</style>
