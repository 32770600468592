<template>
  <el-form ref="form" label-position="top" :inline="true" class="flex-column" style="height:100%">
    <div v-if="outputList.length > 0 && currentInstrumentGroup.type === 'VERTICAL_DISTRIBUTION'">
      
      <el-row class="chart-wrapper mt-16">
        <!-- <draggable-resizable :draggable="false" > -->
        <div class="columns">
          <div class="column vertical-curve">
            <div class="card" :style="chartContainerStyle">
              <v-chart ref="chart" style="width:100%; height:100%;" :autoresize="true" :options="chartOptions"></v-chart>
            </div>
          </div>
          <div class="column vertical-option">
            <el-row>
              <el-card>
                <el-form-item :label="$t('Curve')">
                  <el-select v-model="form.instrumentGroup" filterable @change="getInstrumentGroupOutputByTime">
                    <el-option v-for="instrumentGroup in instrumentGroupList" :key="instrumentGroup._id"
                      :value="instrumentGroup._id" :label="instrumentGroup.name"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('datetime')">
                  <div class="columns is-multiline">
                    <div class="column is-narrow" v-for="(date, index) in form.date" :key="index">
                      <el-date-picker v-model="form.date[index]" type="datetime" format="yyyy-MM-dd HH:mm"
                        value-format="timestamp" :picker-options="datePickerOptions" @change="getInstrumentGroupOutputByTime" />
                    </div>
                    <div class="column is-narrow">
                      <el-button class="ml-8" type="primary" icon="el-icon-plus" circle @click="onAddNewDate"></el-button>
                    </div>
                    <el-checkbox v-model="checked" @change="handleChange" style="margin-left: 10px;">
                      {{ $t('chart.table') }}
                    </el-checkbox>
                    <el-checkbox v-model="autoScale" @change="handleChange" style="margin-left: 10px;">
                      {{ $t('chart.autoScale') }}
                    </el-checkbox>
                    <div style="padding: 10px;">
                      <el-button @click="addSeven" class="">
                        {{ $t('chart.addSeven') }}
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-card>
            </el-row>
            <el-card class="curve-option">
              <el-row class="flex-row align-center justify-end">
                <el-button :v-model="computeFlag" @click="activeCompute" class="">
                  {{ $t('Compare') }}
                </el-button>
                <el-date-picker v-model="computeDate" type="datetime" format="yyyy-MM-dd HH:mm" value-format="timestamp"
                  :picker-options="datePickerOptions" @change="compute" class="" />
              </el-row>
            </el-card>
            <div class="card p-16 flex-column" style="width:100%">
              <div class="flex-row align-center">
                <div class="flex-column">
                  <label class="label">x {{ $t('chart.xAxis.min') }}</label>
                  <el-input-number v-model="currentInstrumentGroup.xAxis.min"></el-input-number>
                </div>
                <div class="flex-column ml-16">
                  <label class="label">x {{ $t('chart.xAxis.max') }}</label>
                  <el-input-number v-model="currentInstrumentGroup.xAxis.max"></el-input-number>
                </div>
              </div>
              <div class="flex-row align-center mt-16">
                <div class="flex-column">
                  <label class="label">y {{ $t('chart.yAxis.min') }}</label>
                  <el-input-number v-model="currentInstrumentGroup.yAxis.min"></el-input-number>
                </div>
                <div class="flex-column ml-16">
                  <label class="label">y {{ $t('chart.yAxis.max') }}</label>
                  <el-input-number v-model="currentInstrumentGroup.yAxis.max"></el-input-number>
                </div>
              </div>
            </div>
            <el-card style="margin-top: 12px;" v-if="showTable">
              <el-button type="primary" @click="exportToExcel">Export to Excel</el-button>
              <hot-table :settings="hotSettings" ref="hot1" style="margin-top: 12px"></hot-table>
            </el-card>
          </div>
        </div>
      </el-row>
    </div>
    <div v-else>
      <el-row>
        <el-card>
          <el-form-item :label="$t('Curve')">
            <el-select v-model="form.instrumentGroup" filterable @change="getInstrumentGroupOutputByTime">
              <el-option v-for="instrumentGroup in instrumentGroupList" :key="instrumentGroup._id"
                :value="instrumentGroup._id" :label="instrumentGroup.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('datetime')">
            <div class="columns is-multiline">
              <div class="column is-narrow" v-for="(date, index) in form.date" :key="index">
                <el-date-picker v-model="form.date[index]" type="datetime" format="yyyy-MM-dd HH:mm"
                  value-format="timestamp" :picker-options="datePickerOptions" @change="getInstrumentGroupOutputByTime" />
              </div>
              <div class="column is-narrow">
                <el-button class="ml-8" type="primary" icon="el-icon-plus" circle @click="onAddNewDate"></el-button>
              </div>
              <el-checkbox v-model="checked" @change="handleChange" style="margin-left: 10px;">
                {{ $t('chart.table') }}
              </el-checkbox>
              <el-checkbox v-model="autoScale" @change="handleChange" style="margin-left: 10px;">
                {{ $t('chart.autoScale') }}
              </el-checkbox>
              <div style="padding: 10px;">
                <el-button @click="addSeven" class="">
                  {{ $t('chart.addSeven') }}
                </el-button>
              </div>
            </div>
          </el-form-item>
        </el-card>
      </el-row>
      <el-row v-if="outputList.length > 0 && currentInstrumentGroup.type === 'HORIZONTAL_DISTRIBUTION'">
        <el-card class="curve-option">
          <el-row class="flex-row align-center justify-end">
            <el-button :v-model="computeFlag" @click="activeCompute" class="">
              {{ $t('Compare') }}
            </el-button>
            <el-date-picker v-model="computeDate" type="datetime" format="yyyy-MM-dd HH:mm" value-format="timestamp"
              :picker-options="datePickerOptions" @change="compute" class="" />
          </el-row>
        </el-card>
      </el-row>
      <el-row v-if="outputList.length > 0" class="chart-wrapper mt-16">
        <!-- <draggable-resizable :draggable="false" > -->
        <div class="columns">
          <div class="column">
            <div class="card" :style="chartContainerStyle">
              <v-chart ref="chart" style="width:100%; height:100%;" :autoresize="true" :options="chartOptions"></v-chart>
            </div>
          </div>
          <div class="column vertical-option" v-if="currentInstrumentGroup.type === 'VERTICAL_DISTRIBUTION'">
            <el-card class="curve-option">
              <el-row class="flex-row align-center justify-end">
                <el-button :v-model="computeFlag" @click="activeCompute" class="">
                  {{ $t('Compare') }}
                </el-button>
                <el-date-picker v-model="computeDate" type="datetime" format="yyyy-MM-dd HH:mm" value-format="timestamp"
                  :picker-options="datePickerOptions" @change="compute" class="" />
              </el-row>
            </el-card>
            <div class="card p-16 flex-column" style="width:100%">
              <div class="flex-row align-center">
                <div class="flex-column">
                  <label class="label">x {{ $t('chart.xAxis.min') }}</label>
                  <el-input-number v-model="currentInstrumentGroup.xAxis.min"></el-input-number>
                </div>
                <div class="flex-column ml-16">
                  <label class="label">x {{ $t('chart.xAxis.max') }}</label>
                  <el-input-number v-model="currentInstrumentGroup.xAxis.max"></el-input-number>
                </div>
              </div>
              <div class="flex-row align-center mt-16">
                <div class="flex-column">
                  <label class="label">y {{ $t('chart.yAxis.min') }}</label>
                  <el-input-number v-model="currentInstrumentGroup.yAxis.min"></el-input-number>
                </div>
                <div class="flex-column ml-16">
                  <label class="label">y {{ $t('chart.yAxis.max') }}</label>
                  <el-input-number v-model="currentInstrumentGroup.yAxis.max"></el-input-number>
                </div>
              </div>
            </div>
            <el-card style="margin-top: 12px;" v-if="showTable">
              <el-button type="primary" @click="exportToExcel">Export to Excel</el-button>
              <hot-table :settings="hotSettings" ref="hot2" style="margin-top: 12px"></hot-table>
            </el-card>
          </div>
        </div>

        <!-- </draggable-resizable> -->
      </el-row>
      <el-row v-if="outputList.length > 0 && currentInstrumentGroup.type === 'HORIZONTAL_DISTRIBUTION'" class="mt-16">
        <div class="column is-paddingless">
          <div class="card p-16 flex-column" style="width:100%">
            <div class="flex-row align-center">
              <div class="flex-column">
                <label class="label">x {{ $t('chart.xAxis.min') }}</label>
                <el-input-number v-model="currentInstrumentGroup.xAxis.min"></el-input-number>
              </div>
              <div class="flex-column ml-16">
                <label class="label">x {{ $t('chart.xAxis.max') }}</label>
                <el-input-number v-model="currentInstrumentGroup.xAxis.max"></el-input-number>
              </div>
            </div>
            <div class="flex-row align-center mt-16">
              <div class="flex-column">
                <label class="label">y {{ $t('chart.yAxis.min') }}</label>
                <el-input-number v-model="currentInstrumentGroup.yAxis.min"></el-input-number>
              </div>
              <div class="flex-column ml-16">
                <label class="label">y {{ $t('chart.yAxis.max') }}</label>
                <el-input-number v-model="currentInstrumentGroup.yAxis.max"></el-input-number>
              </div>
            </div>
          </div>
          <el-card style="margin-top: 12px;" v-if="showTable">
            <el-button type="primary" @click="exportToExcel">Export to Excel</el-button>
            <hot-table :settings="hotSettings" ref="hot3" style="margin-top: 12px"></hot-table>
          </el-card>
        </div>
      </el-row>
    </div>
    
    
  </el-form>
</template>

<script>
import moment from 'moment';
import { debounce } from 'lodash';
import { HotTable } from '@handsontable/vue';
import * as XLSX from 'xlsx';

export default {
  components: {
    HotTable,
  },
  data() {
    return {
      hotSettings: {
        data: [],
        columns: [],
        colHeaders: [],
        licenseKey: 'non-commercial-and-evaluation',
        colWidths: 200,
        height: 500,
        readOnly: true,
      },
      form: {
        instrumentGroup: '',
        date: [
          moment()
            .startOf('day')
            .format('x'),
          // moment()
          //   .subtract(1, 'd')
          //   .startOf('day')
          //   .format('x'),
        ],
      },
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      checked: false,
      chartContainerWidth: null,
      outputList: [],
      instrumentGroupList: [],
      chartContainerStyle: null,
      computeDate: null,
      computeFlag: false,
      oriOutputList: [],
      showTable: false,
      tableData: [],
      autoScale: false,
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    currentInstrumentGroup() {
      return this.instrumentGroupList.find(ele => ele._id === this.form.instrumentGroup);
    },
    formattedDateList() {
      return this.form.date.map(timestamp => {
        return moment(Number(timestamp)).format('YYYY/MM/DD HH:mm');
      });
    },
    series() {
      const series = this.form.date.map((timestamp, index) => {
        const seriesData = this.outputList.reduce((acc, curr) => {
          if (curr.output[timestamp] != null) {
            if (this.currentInstrumentGroup.type === 'HORIZONTAL_DISTRIBUTION') {
              acc.push([
                curr.depth,
                curr.output[timestamp],
                moment(Number(timestamp)).format('YYYY/MM/DD HH:mm'),
              ]);
            } else {
              acc.push([
                curr.output[timestamp],
                curr.depth,
                moment(Number(timestamp)).format('YYYY/MM/DD HH:mm'),
              ]);
            }
          }
          return acc;
        }, []);
        return {
          // name: instrumentGroup.name,
          // name: this.formattedDateList[index],
          name: this.formattedDateList[index],
          type: 'line',
          smooth: true,
          lineStyle: {
            width: 3,
            // shadowColor: 'rgba(0,0,0,0.4)',
            // shadowBlur: 10,
            // shadowOffsetY: 10,
          },
          data: seriesData,
        };
      });
      this.handleChange();
      return series;
    },
    chartOptions() {
      const vm = this;
      const number = this.series.length;
      return {
        title: {
          text: this.currentInstrumentGroup.name,
          left: 'center',
          padding: 15,
        },
        legend: {
          // data: this.formattedDateList,
          orient: 'horizontal', // 'vertical'
          x: 'center', // 'center' | 'left' | {number},
          y: 'bottom', // 'center' | 'bottom' | {number}
          left: 10,
          //bottom: -10,
          //itemHeight:60,
          //itemWidth: number > 8 ? number > 12 ? 25 : 25 : 25,
          itemHeight: number > 8 ? number > 15 ? 9 : 10 : 14,
          textStyle: {
            fontSize: number > 8 ? number > 15 ? 9 : 11 : 12,
          },
          formatter (label) {
            let date = new Date(label);
            let m = date.getMinutes();
            let h = date.getHours();
            return (m == 0 && h == 0) ? moment(date).format('yyyy/MM/DD') : label;
          },
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'cross',
            animation: false,
          },
          formatter(params) {
            let target = params.data;
            let depth = 0;
            let output = 0;
            let datetime = datetime;
            const yLabel =
              vm.currentInstrumentGroup.type === 'HORIZONTAL_DISTRIBUTION'
                ? vm.$t('Distance')
                : vm.$t('Depth');
            if (vm.currentInstrumentGroup.type === 'HORIZONTAL_DISTRIBUTION') {
              [depth, output, datetime] = target;
            } else {
              [output, depth, datetime] = target;
            }
            if (output === 'undefined') {
              output = vm.$t('No Data');
            }
            return datetime + `<br>${yLabel}: ` + depth + `<br>${vm.$t('Value')}: ` + output;
          },
        },
        series: this.series,
        dataZoom: [
          {
            bottom: '0%',
            type: 'inside',
          },
        ],
        xAxis: this.chartXAxis,
        yAxis: this.chartYAxis,
        grid:{
          left: 60,
          right: 60,
          bottom: 85,
          //containLabel: true,
        },
        backgroundColor: '#FFFFFF',
      };
    },
    chartXAxis() {
      if (this.currentInstrumentGroup.type === 'HORIZONTAL_DISTRIBUTION') {
        return this.chartCategoryTemplate;
      } else {
        return this.chartValueTemplate;
      }
    },
    chartYAxis() {
      if (this.currentInstrumentGroup.type === 'HORIZONTAL_DISTRIBUTION') {
        return this.chartValueTemplate;
      } else {
        return this.chartCategoryTemplate;
      }
    },
    chartValueTemplate() {
      const axis =
        this.currentInstrumentGroup.type === 'HORIZONTAL_DISTRIBUTION' ? 'yAxis' : 'xAxis';

      return {
        type: 'value',
        axisLine: { onZero: false },
        position: 'top',
        axisLabel: {
          formatter: `{value} ${this.currentInstrumentGroup[axis].unit}`,
          //fontSize: 14, // 调整 Y 轴字体大小
        },
        min: this.autoScale ? null : this.currentInstrumentGroup[axis].min,
        max: this.autoScale ? null : this.currentInstrumentGroup[axis].max,
        boundaryGap: false,
      };
    },
    chartCategoryTemplate() {
      const axis =
        this.currentInstrumentGroup.type === 'HORIZONTAL_DISTRIBUTION' ? 'xAxis' : 'yAxis';

      return {
        type: 'value',
        axisLine: { onZero: false },
        axisLabel: {
          formatter: `{value} ${this.currentInstrumentGroup[axis].unit}`,
          //fontSize: 14, // 调整 X 轴字体大小
        },
        min: this.currentInstrumentGroup[axis].min,
        max: this.currentInstrumentGroup[axis].max,
        boundaryGap: false,
      };
    },
  },
  mounted() {
    this.getInstrumentGroupList();
    this.$nextTick(() => {
      this.getChartContainerStyle();
    });
    window.addEventListener('resize', this.resizeFunc());
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFunc());
  },
  watch: {
    'currentInstrumentGroup.type'() {
      this.getChartContainerStyle();
      this.resizeFunc();
    },
    'form.date'(newValue) {
      const nullIndex = newValue.findIndex(timestamp => {
        return timestamp == null;
      });
      if (nullIndex !== -1) {
        this.form.date.splice(nullIndex, 1);
      }
      this.resizeFunc();
    },
  },
  methods: {
    handleChange() {
      if (!this.checked || this.form.instrumentGroup === '') {
        this.showTable = false;
        return;
      }

      let selectedDate = this.form.date;

      // 更新表格列配置
      this.tableColumns = [
        { data: 'depth', title: '深度' },
        ...selectedDate.map(data => {
          const dateObj = new Date(parseInt(data));
          const formattedDate = dateObj.getFullYear() + '-'
            + ('0' + (dateObj.getMonth() + 1)).slice(-2) + '-'
            + ('0' + dateObj.getDate()).slice(-2) + ' '
            + ('0' + dateObj.getHours()).slice(-2) + ':'
            + ('0' + dateObj.getMinutes()).slice(-2) + ':'
            + ('0' + dateObj.getSeconds()).slice(-2);

          return {
            data: String(data),
            title: formattedDate,
          };
        }),
      ];

      let temp = [];
      let depthMap = {};
      selectedDate.forEach(date => {
        this.outputList.forEach(data => {
          let obj = {
            'prop': String(date),
            'depth': data.depth,
            'reading2': data.output[String(date)],
          };
          temp.push(obj);
        });
      });

      temp.forEach(item => {
        if (!depthMap[item.depth]) {
          depthMap[item.depth] = { depth: item.depth };
          selectedDate.forEach(date => {
            depthMap[item.depth][String(date)] = null; // 確保使用字符串作為鍵
          });
        }
        depthMap[item.depth][String(item.prop)] = item.reading2; // 確保鍵是字符串
      });

      // 設置 hotTable 數據
      this.hotSettings.data = Object.values(depthMap);
      this.hotSettings.columns = this.tableColumns; // 使用更新後的列配置
      this.hotSettings.colHeaders = this.tableColumns.map(column => column.title);
      this.showTable = true;
      this.updateTable();
    },
    exportToExcel() {
      if (!this.checked || this.form.instrumentGroup === '') {
        return;
      }

      const headerKeys = this.tableColumns.map(col => col.data);
      const headerNames = this.tableColumns.map(col => col.title);
      const dataToExport = this.hotSettings.data.map(row => {
        return headerKeys.reduce((newRow, key, index) => {
          newRow[headerNames[index]] = row[key];
          return newRow;
        }, {});
      });

      const ws = XLSX.utils.json_to_sheet(dataToExport, { header: headerNames, skipHeader: false });
      XLSX.utils.sheet_add_aoa(ws, [headerNames], { origin: 'A1' });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '深度');

      let fileName;
      fileName = `export_${this.currentInstrumentGroup.name}.xlsx`;
      XLSX.writeFile(wb, fileName);
    },
    getChartContainerStyle() {
      this.$nextTick(() => {
        const chartWrapperWidth = document.querySelector('.el-form').offsetWidth;
        if (
          this.currentInstrumentGroup &&
          this.currentInstrumentGroup.type === 'VERTICAL_DISTRIBUTION'
        ) {
          const height = /*chartWrapperWidth * 0.5 * 670 / 605*/ window.innerHeight * 0.85;
          this.chartContainerStyle = {
            width: '100%',
            height: '100%',
            // width: '100%',
            'min-height':  height + 'px',
          };
        } else {
          const height = (chartWrapperWidth * 0.8) / 3;
          this.chartContainerStyle = {
            // width: width + 'px',
            width: '100%',
            // height: '100%',
            height: height + 'px',
            'min-height': '400px',
          };
        }
      });
    },
    onAddNewDate() {
      const currentTimestamp = moment()
        .startOf('day')
        .format('x');
      this.form.date.push(currentTimestamp);
    },
    getInstrumentGroupList() {
      this.$store
        .dispatch('data/getProjectInstrumentGroupList', {
          projectId: this.selectedProject._id,
        })
        .then(res => {
          // res.forEach(ele => {
          //   ele.type = 'HORIZONTAL_DISTRIBUTION';
          // });
          this.instrumentGroupList = res;
        });
    },
    getInstrumentGroupOutputByTime() {
      if (!this.form.instrumentGroup) return;
      this.outputList = [];
      this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/instrument-group/${this.form.instrumentGroup}/output`,
        data: {
          TIMESTAMP: this.form.date,
        },
      }).then(res => {
        const outputList = res.data.sort((a, b) => a.depth - b.depth);
        this.oriOutputList = outputList;
        this.outputList = this.$lodash.cloneDeep(this.oriOutputList);
        return this.compute();
      });
      this.handleChange();
    },
    activeCompute() {
      this.computeFlag = !this.computeFlag;
      return this.compute();
    },
    async compute() {
      if (!this.computeFlag) {
        this.outputList = this.$lodash.cloneDeep(this.oriOutputList);
        return;
      }

      //console.log(`compute! ${this.computeDate}`);
      let res = await this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/instrument-group/${this.form.instrumentGroup}/output`,
        data: {
          TIMESTAMP: [this.computeDate],
        },
      });
      //console.log(res.data);
      //console.log(this.outputList);
      this.outputList = this.oriOutputList.map(ele => {
        let output = {};
        for (let [key, value] of Object.entries(ele.output)) {
          output[key] = value - Object.values(this.findDepth(ele.depth, res.data).output)[0];
        }
        return {
          ...ele,
          output,
        };
      });
      //console.log(this.outputList);
      this.handleChange();
    },
    findDepth(depth, datas) {
      for (let i in datas) {
        if (depth == datas[i].depth) return datas[i];
      }
      return -1;
    },

    addSeven() {
      let start = moment.unix(this.form.date[0]/1000);
      this.form.date = [start.startOf('day').format('x')];
      for(let i = 0;i < 7 - 1; i++){
        start = start.subtract(1, 'd');
        this.form.date.push(start.startOf('day').format('x'));
      }
      this.getInstrumentGroupOutputByTime();
    },
    resizeFunc() {
      debounce(this.getChartContainerStyle, 50);
      //console.log(this.$refs.chart);
      //this.$refs.chart.resize();
    },
    
    updateTable() {
      setTimeout(() => {
        if(this.$refs.hot1) this.$refs.hot1.hotInstance.loadData(this.hotSettings.data);
        if(this.$refs.hot2) this.$refs.hot2.hotInstance.loadData(this.hotSettings.data);
        if(this.$refs.hot3) this.$refs.hot3.hotInstance.loadData(this.hotSettings.data);
      }, 100);
    },
  },
};
</script>

<style lang="stylus">
.curve-option .el-card__body{
  padding: 5px !important;
}
.column.vertical-curve{
  flex: none;
  width: 25%;
}
.column.vertical-option{
  flex: none;
  width: 67%;
}
</style>
