<template>
  <el-form
    ref="instrumentForm"
    :model="form"
    :validate-on-rule-change="false"
    :hide-required-asterisk="true"
    :inline="true"
    label-position="top"
    label-width="80px"
  >
    <el-row>
      <el-form-item
        prop="station"
        label="測站"
      >
        <el-select v-model="form.station" filterable>
          <el-option
            v-for="station in stationList"
            :key="station._id"
            :value="station._id"
            :label="station.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item
        prop="instrument"
        label="儀器"
      >
        <el-select
          v-model="form.instrument"
          multiple
          filterable
          default-first-option
          placeholder="輸入並Enter新增"
        >
          <el-option
            v-for="instrument in instrumentList"
            :key="instrument._id"
            :label="instrument.name"
            :value="instrument._id"
          />
        </el-select>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item
        prop="search"
        label="儀器搜尋"
      >
        <el-input v-model="searchInput" placeholder="請輸入欲搜尋的內容" @input="instrumentFilter"></el-input>
      </el-form-item>
      <el-form-item label="使用正則表達式 ex: TK\d+\+\d+ ">
        <el-switch v-model="searchUseReg" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
    </el-row>
    <el-button
      :disabled="!selectedStation"
      @click="submitForm"
      type="primary"
    >
      更新測站
    </el-button>
  </el-form>
</template>

<script>
export default {
  props: {
    stationId: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      form: {
        station: '',
        instrument: [],
      },
      stationList: [],
      instrumentList: [],
      allInstrumentList: [],
      searchInput: '',
      searchUseReg: false,
    };
  },
  computed: {
    selectedStation() {
      const selectedStationId = this.form.station;
      const selectedStation = this.stationList.find(ele => ele._id === selectedStationId);
      return selectedStation;
    },
    instrumentIdList() {
      return this.instrumentList.map(instrument => instrument._id);
    },
  },
  watch: {
    stationId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form.station = newValue;
      }
    },
    'form.station'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form.instrument = [];
        this.setStationInstrument();
      }
    },
  },
  async mounted() {
    if (this.stationId) {
      this.form.station = this.stationId;
    }
    await this.getStationList();
    await this.getInstrumentList();
    this.setStationInstrument();
  },
  methods: {
    setStationInstrument() {
      if (this.selectedStation) {
        this.form.instrument = this.selectedStation.instrument;
      }
    },
    getStationList(isUpdate = false) {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getStationList', isUpdate)
        .then(res => {
          this.stationList = res;
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    getInstrumentList(isUpdate = false) {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getInstrumentList', isUpdate)
        .then(res => {
          this.allInstrumentList = res;
          this.instrumentList = this.$lodash.cloneDeep(this.allInstrumentList);
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitForm() {
      this.$refs.instrumentForm.validate(async valid => {
        if (valid) {
          this.updateStationInstrument();
        } else {
          return false;
        }
      });
    },
    async addInstrumentToStation(instrumentIdList) {
      if (!instrumentIdList.length) return;
      return this.$axios({
        category: 'admin',
        method: 'post',
        apiName: `station/${this.form.station}/instrument`,
        data: {
          instrument: instrumentIdList,
        },
      }).catch(this.$notifyError);
    },
    async removeInstrumentFromStation(instrumentIdList) {
      if (!instrumentIdList.length) return;
      return this.$axios({
        category: 'admin',
        method: 'delete',
        apiName: `station/${this.form.station}/instrument`,
        data: {
          instrument: instrumentIdList,
        },
      }).catch(this.$notifyError);
    },
    async createStationNewInstrument(instrumentIdList) {
      if (!instrumentIdList.length) return;
      return this.$axios({
        category: 'admin',
        method: 'post',
        apiName: 'station',
        data: {
          station: this.form.station,
          instrument: instrumentIdList,
        },
      }).catch(this.$notifyError);
    },
    async updateStationInstrument() {
      const removedInstrument = this.selectedStation.instrument.filter(instrument => {
        return !this.form.instrument.includes(instrument);
      });

      const newAddedInstrument = this.form.instrument.filter(instrument => {
        return !this.instrumentIdList.includes(instrument);
      });

      const pushedInstrument = this.form.instrument.filter(instrument => {
        return !this.selectedStation.instrument.includes(instrument) && this.instrumentIdList.includes(instrument);
      });

      if (!newAddedInstrument.length && !removedInstrument.length && !pushedInstrument.length) {
        return this.$notifyInfo('沒有改動');
      }

      let message = '此操作將';
      if (newAddedInstrument.length) {
        message += `新增 ${newAddedInstrument.length} 個儀器並加至該測站\n`;
      }
      if (removedInstrument.length) {
        message += `從該測站移除 ${removedInstrument.length} 個儀器\n`;
      }
      if (pushedInstrument.length) {
        message += `更新 ${pushedInstrument.length} 個儀器至該測站\n`;
      }
      const response = await this.$confirmCreate(message);
      console.log(response);
      if (response === 'confirm') {
        this.isLoading = true;
        Promise.all([
          this.createStationNewInstrument(newAddedInstrument),
          this.removeInstrumentFromStation(removedInstrument),
          this.addInstrumentToStation(pushedInstrument),
        ])
          .then(async () => {
            this.$notifySuccess('新增成功');
            await this.getInstrumentList(true);
            await this.getStationList(true);
          })
          .catch(error => {
            this.$notifyError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    instrumentFilter() {
      //let tmp = this.$lodash.cloneDeep(this.allInstrumentList);
      if(this.searchUseReg){
        this.instrumentList = this.allInstrumentList.filter(ele=>{
          let reg = new RegExp(this.searchInput);
          return reg.test(ele.name);
        });
      }
      else{
        this.instrumentList = this.allInstrumentList.filter(ele=>{
          return ele.name.includes(this.searchInput);
        });
      }
    },
  },
};
</script>
