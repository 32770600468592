<template>
  <el-card>
    <el-form
      ref="stationForm"
      :model="form"
      :validate-on-rule-change="false"
      :hide-required-asterisk="true"
      :inline="true"
      label-position="top"
      label-width="80px"
    >
      <el-row>
        <!-- <el-form-item prop="area" label="區域">
          <el-select v-model="form.area">
            <el-option
              v-for="area in areaList"
              :key="area._id"
              :value="area._id"
              :label="area.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="station" label="測站">
          <el-select
            v-model="form.station"
            filterable
            default-first-option
            placeholder="測站"
            @change="submitForm"
          >
            <el-option
              v-for="station in stationList"
              :key="station._id"
              :label="station.name"
              :value="station._id"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item :label="$t('chart.instrument.pick')">
          <!-- <el-select v-model="form.instrument" filterable @change="getEventList">
            <el-option
              v-for="instrument in instrumentList"
              :key="instrument._id"
              :label="instrument.latestVersion.name"
              :value="instrument._id"
            ></el-option>
          </el-select> -->
          <el-cascader
            v-model="form.instrument"
            style="min-width:300px"
            ref="cascader"
            filterable
            clearable
            :options="instrumentOptions"
            :collapse-tags="true"
            :show-all-levels="false"
            :placeholder="$t('chart.instrument.pick')"
            @change="getEventList"
          ></el-cascader>
        </el-form-item>
        <el-form-item prop="date" label="查詢時間">
          <el-date-picker
            v-model="form.date"
            type="datetimerange"
            format="yyyy/MM/dd HH:mm"
            value-format="timestamp"
            :start-placeholder="$t('chart.date.start')"
            :end-placeholder="$t('chart.date.end')"
            :picker-options="datePickerOptions"
            :default-time="['00:00:00']"
            @change="getEventList"
          />
          <!-- <el-date-picker
            v-model="form.date"
            :start-placeholder="$t('chart.date.start')"
            :end-placeholder="$t('chart.date.end')"
            :default-time="['00:00:00']"
            @change="submitForm"
            type="datetime"
            value-format="timestamp"
            format="yyyy/MM/dd HH:mm"
          /> -->
        </el-form-item>
        <el-form-item prop="onlyActive" label="僅顯示進行中">
          <el-switch
            v-model="form.onlyActive"
            @change="excludeNotHappening"
          >
          </el-switch>
        </el-form-item>
        <el-form-item prop="showTable" label="顯示表格">
          <el-switch
            v-model="form.showTable"
            @change="showTable"
          >
          </el-switch>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="Event">
          <el-select v-model="form.event" :loading="loadingEvent" @change="changeEvent">
            <el-option
              v-for="event in eventList"
              :key="event.startTime"
              :label="event.label"
              :value="event.startTime"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-row>
    </el-form>
    <div class="flex-row align-center justify-center">
      <v-chart :options="chartOptions" :auto-resize="true"></v-chart>
      <v-chart :options="chartOptions2" :auto-resize="true"></v-chart>
      <!--el-button @click="getFB0046001Data()">按下去</el-button>
      <el-button @click="getFB0046001AvgData()">平均版</el-button-->
    </div>
    <!-- <vue-json-pretty :data="chartOriginalData" /> -->
    <el-card v-if="form.showTable">
      <el-button type="primary" @click="exportToExcel">Export to Excel</el-button>
      <hot-table :settings="hotSettings" ref="hot" style="margin-top: 12px"></hot-table>
    </el-card>
  </el-card>
</template>

<script>
import colorPalette from '@/constants/colorPalette.js';
import moment from 'moment';
import { HotTable } from '@handsontable/vue';
// import VueJsonPretty from 'vue-json-pretty';

const getCurveLineFromValue = (x, y, splitCount) => {
  let result = [];
  for (let i = 0; i < splitCount; i++) {
    const currentX = x * Math.cos((Math.PI / 180) * i);
    const currentY = y * Math.sin((Math.PI / 180) * i);
    result.push([currentX, currentY]);
  }
  return result;
};

const alertStandard = {
  threeHourRainFallIntercept: 25,
  oneDayRainFallIntercept: 190,
};

const WSLStandard = {
  threeHourRainFallIntercept: 40,
  oneDayRainFallIntercept: 285,
};

export default {
  components: {
    HotTable,
  },
  data() {
    return {
      project: null,
      loadingEvent: false,
      // date: 1590159052000,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      // chartData: [],
      chartOriginalData: [],
      colorPalette,
      form: {
        area: '',
        station: '',
        instrument: null,
        event: null,
        date: [
          this.$moment()
            .subtract(6, 'month')
            .startOf('day')
            .format('x'),
          this.$moment().format('x'),
        ],
        onlyActive: false,
        showTable: false,
      },
      curveLineData: {
        alert: getCurveLineFromValue(
          alertStandard.oneDayRainFallIntercept,
          alertStandard.threeHourRainFallIntercept,
          90,
        ),
        WSL: getCurveLineFromValue(
          WSLStandard.oneDayRainFallIntercept,
          WSLStandard.threeHourRainFallIntercept,
          90,
        ),
      },
      // instrumentList: [],
      eventList: [],
      // areaList: [],
      // stationList: [],
      instrumentOptions: [],
      extra: false,
      extraData: null,
      tmp: null,  
      hotSettings: {
        data: [],
        colHeaders: [],
        licenseKey: 'non-commercial-and-evaluation',
        colWidths: 200,
        height: 400,
        autoColumnSize: true,
        readOnly: true,
      },
    };
  },
  computed: {
    chartOptions() {
      let series = [
        {
          type: 'line',
          data: this.chartData,
        },
        {
          data: this.curveLineData.alert,
          type: 'line',
          smooth: true,
          showSymbol: false,
          lineStyle: { color: colorPalette.COLOR_YELLOW },
          tooltip: {
            show: false,
          },
        },
        {
          data: this.curveLineData.WSL,
          type: 'line',
          smooth: true,
          showSymbol: false,
          lineStyle: { color: colorPalette.COLOR_RED },
          tooltip: {
            show: false,
          },
        },
      ];
      if(this.extra){
        series.push({
          type: 'line',
          data: this.extraChartData,
        });
      }

      return {
        xAxis: {
          min: 0,
          // max: 450,
          max: 500,
          // scale: false,
          name: '24小時累積雨量',
          nameLocation: 'middle',
        },
        yAxis: {
          min: 0,
          max: 60,
          // scale: false,
          name: '3小時平均雨量',
        },
        dataZoom: [
          {
            startValue: 0,
            bottom: '0%',
            type: 'inside',
          },
        ],
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
            },
            restore: {},
            saveAsImage: {},
          },
        },
        tooltip: {
          axisPointer: {
            type: 'cross',
            animation: false,
            // label: {
            //   backgroundColor: '#505765',
            // },
          },
          formatter(params) {
            let target = params.data;
            if (!Array.isArray(params.data)) {
              target = params.data.value;
            }
            const [
              oneDayAccumulatedRainFall,
              threeHourMeanRainFall,
              TIMESTAMP,
              threeHourRainFallIntercept,
              oneDayRainFallIntercept,
            ] = target;
            return (
              TIMESTAMP +
              '<br>3小時平均雨量: ' +
              threeHourMeanRainFall.toFixed(4) +
              '<br>24小時累計雨量: ' +
              oneDayAccumulatedRainFall.toFixed(4) +
              '<br>3小時平均雨量截距: ' +
              threeHourRainFallIntercept.toFixed(4) +
              '<br>24小時累計雨量截距: ' +
              oneDayRainFallIntercept.toFixed(4)
            );
          },
        },
        series,
        backgroundColor: '#FFFFFF',
      };
    },
    chartOptions2() {
      return {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          name: '時間',
        },
        yAxis: {
          min: 0,
          // max: 120,
          // scale: false,
          name: '每10分鐘雨量',
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
            },
            restore: {},
            saveAsImage: {},
          },
        },
        dataZoom: [
          {
            startValue: 0,
            bottom: '0%',
            type: 'inside',
          },
        ],
        tooltip: {
          axisPointer: {
            type: 'cross',
            animation: false,
            label: {
              backgroundColor: '#505765',
            },
          },
        },
        series: [
          {
            type: 'bar',
            data: this.chartData2,
          },
        ],
        backgroundColor: '#FFFFFF',
      };
    },
    selectedProject() {
      return this.project;
    },
    selectedEvent() {
      if (!this.form.event) return {};
      return this.eventList.find(event => event.startTime === this.form.event) || {};
    },
    chartData() {
      if (!this.selectedEvent.outputs) return [];
      const outputs = this.$lodash.cloneDeep(this.selectedEvent.outputs);

      return outputs.map(output => {
        const {
          oneDayAccumulatedRainFall,
          threeHourMeanRainFall,
          TIMESTAMP,
          threeHourRainFallIntercept,
          oneDayRainFallIntercept,
        } = output;
        const value = [
          oneDayAccumulatedRainFall,
          threeHourMeanRainFall,
          this.$moment(TIMESTAMP).format('YYYY-MM-DD HH:mm'),
          threeHourRainFallIntercept,
          oneDayRainFallIntercept,
        ];
        if (
          threeHourRainFallIntercept > WSLStandard.threeHourRainFallIntercept ||
          oneDayRainFallIntercept > WSLStandard.oneDayRainFallIntercept
        ) {
          return {
            value,
            itemStyle: {
              color: colorPalette.COLOR_RED,
            },
          };
        } else if (
          threeHourRainFallIntercept > alertStandard.threeHourRainFallIntercept ||
          oneDayRainFallIntercept > alertStandard.oneDayRainFallIntercept
        ) {
          return {
            value,
            itemStyle: {
              color: colorPalette.COLOR_YELLOW,
            },
          };
        } else {
          return {
            value,
            itemStyle: {
              color: colorPalette.COLOR_GREEN,
            },
          };
        }
      });
    },
    chartData2() {
      if (!this.selectedEvent.outputs) return [];
      return this.selectedEvent.outputs.map(output => {
        const { TIMESTAMP, reading2 } = output;
        return [this.$moment(TIMESTAMP).format('YYYY-MM-DD HH:mm'), reading2];
      });
    },

    extraChartData() {
      if(this.extra){
        console.log(this.extraData);
        const outputs = this.$lodash.cloneDeep(this.extraData);
        return outputs.map(output => {
          const {
            TIMESTAMP,
            FB0046001Output,
          } = output;

          if(FB0046001Output){
            const {
              oneDayAccumulatedRainFall,
              threeHourMeanRainFall,
              threeHourRainFallIntercept,
              oneDayRainFallIntercept,
              extra,
            } = FB0046001Output;

            const value = [
              oneDayAccumulatedRainFall,
              threeHourMeanRainFall,
              this.$moment(extra.TIMESTAMP).format('YYYY-MM-DD HH:mm'),
              threeHourRainFallIntercept,
              oneDayRainFallIntercept,
            ];
            return {
              value,
              itemStyle: {
                color: colorPalette.COLOR_BLACK,
              },
            };
          }
          else{
            //const {
            //  oneDayAccumulatedRainFall,
            //  threeHourMeanRainFall,
            //  threeHourRainFallIntercept,
            //  oneDayRainFallIntercept,
            //} = output;
            //
            //const value = [
            //  oneDayAccumulatedRainFall,
            //  threeHourMeanRainFall,
            //  this.$moment(TIMESTAMP).format('YYYY-MM-DD HH:mm'),
            //  threeHourRainFallIntercept,
            //  oneDayRainFallIntercept,
            //];
            //return {
            //  value,
            //  itemStyle: {
            //    color: colorPalette.COLOR_BLACK,
            //  },
            //};
            return null;
          }
        }).filter(ele => ele);
    
      }

      return null;
    },
  },
  async mounted() {
    this.setProject()
      .then(() => {
        this.initCascaderSelector().then(()=>{
          console.log(this.$route.query.instrument);
          if(this.$route.query.instrument){
            this.form.instrument = [undefined, this.$route.query.instrument._id];
            this.getEventList().then(()=>{
              this.form.event = this.$route.query.startDate;
            });
          }
        });
      });
  },
  methods: {
    setProject() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: '',
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      })
        .then(res => {
          this.project = res.data[0];
        })
        .catch(this.$notifyError);
    },
    initCascaderSelector() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/station-instrument`,
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      })
        .then(res => {
          this.instrumentOptions = res.data.map(station => {
            const obj = {
              label: station.name,
              children: station.instrument.map(instrument => {
                const obj = {
                  label: instrument.name,
                  value: instrument._id,
                };
                return obj;
              }),
            };
            return obj;
          });
        })
        .then(() => {
          this.initLoading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getEventList() {
      this.loadingEvent = true;
      this.eventList = [];
      this.form.event = null;
      //const instrument = this.$refs.cascader.getCheckedNodes(true)[0].value;
      const instrument = this.form.instrument[1];
      
      return this.getEventListWithInstrumentId(instrument)
        .then(events => {
          this.eventList = events;
          if (this.eventList.length > 0) {
            this.form.event = this.eventList[0].startTime;
          }
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.loadingEvent = false;
          if(this.form.showTable){
            this.updateTable();
          }
        });
    },
    async getEventListWithInstrumentId(instrument_id){
      let [startTime, endTime] = this.form.date;
      const res = await this.$axios({
        category: 'event',
        method: 'get',
        apiName: `/project/${this.selectedProject._id}/instrument/${instrument_id}/event?startTime=${startTime}&endTime=${endTime}`,
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      });

      let events = res.data.map(event => {
        if (event.endTime) {
          event.label = `${this.$moment(event.startTime).format(
            'YYYY-MM-DD HH:mm',
          )} ~ ${this.$moment(event.endTime).format('YYYY-MM-DD HH:mm')}`;
        } else {
          event.label = `${this.$moment(event.startTime).format('YYYY-MM-DD HH:mm')} (進行中)`;
        }
        return event;
      });
      return events;
    },
    
    getInstrumentList() {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getProjectInstrumentListWithLatestVersion', {
          projectId: this.selectedProject._id,
        })
        .then(res => {
          this.instrumentList = res;
          this.form.instrument = res[0]._id;
          // this.form.instrument = '5ec5560908a34a000ad96293';
          // this.form.instrument = '5ec2b1d9395b95000a1acdd7';
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getFB0046001Data() {
      const res = await this.$axios({
        category: 'storm',
        method: 'get',
        apiName: `FB0046001/${this.selectedEvent._id}`,
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      });
      //console.log(res.data);
      this.extra = true;
      this.extraData = res.data;
    },
    async getFB0046001AvgData() {
      const res = await this.$axios({
        category: 'storm',
        method: 'get',
        apiName: `FB0046001Avg/${this.selectedEvent._id}`,
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      });
      //console.log(res.data);
      this.extra = true;
      this.extraData = res.data;
    },
    computedOutputs(outputs, extra) {
      const originalInterval = 10;
      //let oneHourRainFallSum = 0;
      let threeHourRainFallSum = 0;
      let oneDayRainFallSum = 0;

      const oneHourIntervalDifference = 60 / originalInterval;
      // get 3 hours mean difference
      const threeHourIntervalDifference = oneHourIntervalDifference * 3;
      const oneDayIntervalDifference = oneHourIntervalDifference * 24;

      const lastIndex = outputs.length - 1;
      //for (let i = lastIndex; i > lastIndex - oneHourIntervalDifference && i >= 0; i--) {
      //  oneHourRainFallSum += outputs[i].reading2;
      //}
      //console.log('now compute threeHourRainFallSum');
      //console.log(`extra time is ${moment(extra.TIMESTAMP).format('YYYY-MM-DD HH:mm')}`);
      for (let i = lastIndex; i > lastIndex - threeHourIntervalDifference + oneHourIntervalDifference && i >= 0; i--) {
        threeHourRainFallSum += outputs[i].reading2;
        //console.log(`${i} ${moment(outputs[i].TIMESTAMP).format('YYYY-MM-DD HH:mm')}`);
      }
      // 加上1Hr後的預測值
      threeHourRainFallSum += extra.reading2;

      //console.log('now compute threeHourRainFallSum');
      //console.log(`extra time is ${moment(extra.TIMESTAMP).format('YYYY-MM-DD HH:mm')}`);
      for (let i = lastIndex; i > lastIndex - oneDayIntervalDifference + oneHourIntervalDifference && i >= 0; i--) {
        oneDayRainFallSum += outputs[i].reading2;
        //console.log(`${i} ${moment(outputs[i].TIMESTAMP).format('YYYY-MM-DD HH:mm')}`);
      }
      // 加上1Hr後的預測值
      oneDayRainFallSum += extra.reading2;

      const factor = 0.1404;

      let oneDayAccumulatedRainFall = Number(oneDayRainFallSum.toFixed(4));
      let threeHourMeanRainFall = Number((threeHourRainFallSum / 3).toFixed(4));
      //let hourAccumulatedRainFall = Number(oneHourRainFallSum.toFixed(4));
      let threeHourRainFallIntercept = Math.pow(
        Math.pow(factor, 2) * Math.pow(oneDayRainFallSum, 2) + Math.pow(threeHourRainFallSum / 3, 2),
        0.5,
      );
      let oneDayRainFallIntercept = threeHourRainFallIntercept / factor;

      console.log({ oneDayAccumulatedRainFall, threeHourMeanRainFall, threeHourRainFallIntercept, oneDayRainFallIntercept });
      return { oneDayAccumulatedRainFall, threeHourMeanRainFall, threeHourRainFallIntercept, oneDayRainFallIntercept };
    },
    async excludeNotHappening() {
      if(this.tmp && !this.form.onlyActive){
        //若存在暫存資料tmp，且關閉"僅限進行中"，則恢復暫存資料
        this.instrumentOptions = this.tmp.instrumentOptions;
        
      }
      else if(this.form.onlyActive){
        //若開啟"僅限進行中"，則將區域/測站/事件存入暫存資料中，並暫停顯示非進行中的區域/測站/事件
        this.tmp = {};
        this.tmp.instrumentOptions = this.instrumentOptions;
        this.tmp.eventList = await this.loadAllInstrumentEvent();
        console.log(this.tmp.eventList);
        let includeHappeningInstrument = this.tmp.eventList.map(ins=>{
          let events = ins.events.filter(event=>{
            return event.label.includes('進行中');
          });
          if(events.length<=0) return null;
          else return {
            instrument: ins.instrument,
            events,
          };
        }).filter(ins => ins);
        //console.log(includeHappeningInstrument);
        //console.log(this.$lodash.cloneDeep(this.instrumentOptions));
        this.instrumentOptions = this.instrumentOptions.reduce((pre,ele)=>{
          //console.log(ele);
          const instruments = ele.children.filter(instrument=>{
            if(includeHappeningInstrument.findIndex(ins=>{
              return ins.instrument === instrument.value;
            })>=0){
              return true;
            }
            else false;
          });
          if(instruments.length>0){
            pre.push({
              children: instruments,
              label: ele.label,
            });
          }
          return pre;
        },[]);
        //console.log(this.instrumentOptions);
      }
    },
    async loadAllInstrumentEvent() {
      let instrumentsList = this.instrumentOptions.reduce((pre, cur) => {
        let stationInstrument = cur.children.map(ele=>{
          return ele.value;
        });
        for(let ins of stationInstrument){
          pre.push(ins);
        }
        return pre;
      },[]);
      
      let instrumentsEvents = [];

      for(let instrument of instrumentsList){
        let res = await this.getEventListWithInstrumentId(instrument);
        instrumentsEvents.push({
          instrument,
          events: res,
        });
      }
      return instrumentsEvents;
    },
    changeEvent() {
      if(this.form.showTable){
        this.showTable();
      }
    },
    showTable() {
      console.log('showTable');
      let colHeaders = ['Date'];
      let allDates = new Set();
      let dataMap = {};

      if (!this.selectedEvent.outputs) return {
        data: [],
        colHeaders: [],
        licenseKey: 'non-commercial-and-evaluation',
        colWidths: 200,
        height: 400,
        autoColumnSize: true,
        readOnly: true,
      };
      const outputs = this.$lodash.cloneDeep(this.selectedEvent.outputs);
      dataMap['3小時平均雨量'] = {};
      dataMap['24小時累計雨量'] = {};
      dataMap['3小時平均雨量截距'] = {};
      dataMap['24小時累計雨量截距'] = {};
      colHeaders.push('3小時平均雨量');
      colHeaders.push('24小時累計雨量');
      colHeaders.push('3小時平均雨量截距');
      colHeaders.push('24小時累計雨量截距');
      outputs.forEach(output => {
        const {
          oneDayAccumulatedRainFall,
          threeHourMeanRainFall,
          TIMESTAMP,
          threeHourRainFallIntercept,
          oneDayRainFallIntercept,
        } = output;
        allDates.add(TIMESTAMP);
        dataMap['3小時平均雨量'][TIMESTAMP] = threeHourMeanRainFall;
        dataMap['24小時累計雨量'][TIMESTAMP] = oneDayAccumulatedRainFall;
        dataMap['3小時平均雨量截距'][TIMESTAMP] = threeHourRainFallIntercept;
        dataMap['24小時累計雨量截距'][TIMESTAMP] = oneDayRainFallIntercept;
      });

      if(this.extra){
        const extraOutputs = this.$lodash.cloneDeep(this.extraData);
        dataMap['預測-3小時平均雨量'] = {};
        dataMap['預測-24小時累計雨量'] = {};
        dataMap['預測-3小時平均雨量截距'] = {};
        dataMap['預測-24小時累計雨量截距'] = {};
        colHeaders.push('預測-3小時平均雨量');
        colHeaders.push('預測-24小時累計雨量');
        colHeaders.push('預測-3小時平均雨量截距');
        colHeaders.push('預測-24小時累計雨量截距');
        extraOutputs.forEach(output => {
          if(output.FB0046001Output) {
            const {
              oneDayAccumulatedRainFall,
              threeHourMeanRainFall,
              threeHourRainFallIntercept,
              oneDayRainFallIntercept,
            } = output.FB0046001Output;
            const {
              TIMESTAMP,
            } = output.FB0046001Output.extra;
            allDates.add(TIMESTAMP);
            dataMap['預測-3小時平均雨量'][TIMESTAMP] = threeHourMeanRainFall;
            dataMap['預測-24小時累計雨量'][TIMESTAMP] = oneDayAccumulatedRainFall;
            dataMap['預測-3小時平均雨量截距'][TIMESTAMP] = threeHourRainFallIntercept;
            dataMap['預測-24小時累計雨量截距'][TIMESTAMP] = oneDayRainFallIntercept;
          }
        });
      }

      let sortedDates = Array.from(allDates).sort();

      // 對於每個日期檢查每個儀器是否有數據
      let data = sortedDates.map(date => {
        return colHeaders.slice(1).map(header => dataMap[header][date] ?? '');
      });

      // 將日期列加到數據陣列的開頭
      data = sortedDates.map((date, index) => [date, ...data[index]]);

      console.log(`only test ${colHeaders.length}`);
      console.log(`only test ${data.length}`);
      this.hotSettings = {
        ...this.hotSettings,
        data,
        colHeaders,
      };
      console.log(this.form.showTable);
      this.updateTable();
    },
    updateTable() {
      setTimeout(() => {
        this.$refs.hot.hotInstance.loadData(this.hotSettings.data);
      }, 100);
    },
  },
};
</script>
