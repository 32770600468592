<template>
  <div>
    <el-card v-loading="isLoading || isUpdating">
      <label class="label">選擇專案</label>
      <el-select
        v-model="selectedProject"
        @change="readProjectImage"
        filterable
        style="margin-bottom: 1rem"
      >
        <el-option
          v-for="item in projectList"
          :key="item.value"
          :label="item.project_id"
          :value="item._id"
        />
      </el-select>
      <el-upload
        ref="upload"
        v-if="selectedProject"
        :http-request="uploadImage"
        :before-upload="onBeforeUpload"
        :on-success="uploadSuccess"
        :before-remove="removeImage"
        :on-remove="readProjectImage"
        :file-list="fileList"
        action="123"
        multiple
        name="image"
        accept="image/jpeg,image/png"
        class="upload-demo"
        list-type="picture-card"
      >
        <el-button size="small" type="primary">
          点击上传
        </el-button>
        <div slot="tip" class="el-upload__tip">
          <!-- 只能上传jpg/png文件，且不超过500kb -->
        </div>
      </el-upload>
      <!-- <el-button @click="submitForm()">
        點擊上傳照片
      </el-button> -->

      <div class="columns is-multiline">
        <div v-for="file in fileList" :key="file._id" class="column is-6">
          <el-card :body-style="{ padding: '0px' }">
            <div slot="header">
              <div class="flex-row justify-between">
                <div class="flex-column">
                  <span class="image-title is-marginless">{{ file.name }}</span>
                  <span class="is-size-7 is-marginless">{{ file.description }}</span>
                </div>
                <!-- <el-button @click.native="showDialog(file, 'EditImage')" type="primary">
                  編輯圖片訊息
                </el-button> -->
                <div class="flex-row">
                  <el-button @click.native="showDialog(file, 'CreateForm')" type="primary">
                    新增燈號
                  </el-button>
                  <el-button @click.native="showDialog(file, 'VectorForm')" type="primary">
                    設定向量
                  </el-button>
                  <el-button
                    @click.native="showDialog(file, 'EditImage')"
                    icon="el-icon-edit"
                    size="mini"
                    type="success"
                  />
                </div>
              </div>
            </div>
            <div class="image-container">
              <image-marker
                :project="selectedProject"
                :file="file"
                :disable-popover="false"
                @reload="readProjectImage"
              />
              <!-- <single-info :file="file" :disablePopover="false" /> -->
            </div>
            <!-- <div class="content" style="padding: 14px;">
              <div class="flex-row justify-end">
                <el-button @click.native="showDialog(file, 'EditForm')">
                  編輯
                </el-button>
                <el-button @click.native="showDialog(file, 'CreateForm')">
                  新增
                </el-button>
                <el-button @click.native="showDialog(file, 'ViewForm')">
                  查看
                </el-button>
              </div>
            </div> -->
          </el-card>
        </div>
      </div>
    </el-card>
    <info-dialog
      v-if="dialogVisible"
      :dialog-visible="dialogVisible"
      :file="currentFile"
      :file-list="fileList"
      :action-type="actionType"
      :project-list="projectList"
      :project="selectedProject"
      :editing-project="selectedProject"
      @reload="readProjectImage"
      @close="dialogVisible = false"
    />
  </div>
</template>

<script>
// import SingleInfo from '@/components/pin/SingleInfo'
import ImageMarker from '@/components/pin/ImageMarker';
import InfoDialog from '@/components/pin/InfoDialog';

export default {
  components: {
    // SingleInfo,
    ImageMarker,
    InfoDialog,
  },
  data() {
    return {
      selectedProject: '',
      currentFile: {},
      fileList: [],
      dialogVisible: false,
      isLoading: false,
      isUpdating: false,
      instrumentList: [],
      actionType: 'edit',
    };
  },
  computed: {
    projectList() {
      return this.$store.getters['project/projectList'];
    },
    selectedProjectDetails() {
      return this.projectList.find(ele => ele._id === this.selectedProject);
    },
  },
  watch: {
    fileList(newValue, oldValue) {
      if (this.currentFile && newValue.length && oldValue.length) {
        this.currentFile = newValue.find(file => {
          return file._id === this.currentFile._id;
        });
      }
    },
  },
  async mounted() {
    // this.isLoading = true;
    this.selectedProject = this.$store.state.project.selectedProject._id;
    // this.getProjectList()
    // .then(async () => {
    //   this.selectedProject = this.projectList[0]._id;
    this.readProjectImage();
    // })
    // .finally(() => {
    //   this.isLoading = false;
    // });
  },
  methods: {
    showDialog(file, type) {
      this.dialogVisible = true;
      this.currentFile = file;
      this.actionType = type;
    },
    readProjectImage() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject}/image`,
      }).then(res => {
        if (res.data) {
          this.fileList = res.data.map(ele => {
            return {
              _id: ele._id,
              name: ele.name,
              url: `${process.env.VUE_APP_IMAGE_BASE_URL}/layout/${ele.path}`,
              description: ele.description,
              pin: ele.pin,
            };
          });
        } else this.fileList = [];
      });
    },
    uploadImage(param) {
      this.isisUpdating = true;
      const file = param.file;
      const formData = new FormData();
      formData.append('image', file);

      this.$axios({
        method: 'post',
        apiName: `/admin/project/${this.selectedProject}/image`,
        data: formData,
      })
        .then(res => {
          param.onSuccess(res, file);
        })
        .catch(error => {
          param.onError(error);
        });
    },
    submitForm() {
      this.$refs.upload.submit(); // this.$refs.icon 是上面定义的  ref="icon"
    },
    onBeforeUpload(file) {
      const isIMAGE = file.type === 'image/jpeg' || 'image/png';
      const isLt5M = file.size < 5 * 1024 * 1024;
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!');
      }
      return isIMAGE && isLt5M;
    },
    async uploadSuccess() {
      this.$notifySuccess('圖片上傳成功');
      await this.$refs.upload.clearFiles();
      await this.readProjectImage();
      this.isUpdating = false;
    },
    async removeImage(file) {
      await this.$confirm('是否删除此照片？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          return this.$axios({
            method: 'delete',
            apiName: `/admin/project/${this.selectedProject}/image/${file._id}`,
          })
            .then(() => {
              this.$notifySuccess('刪除成功');
              this.readProjectImage();
              return true;
            })
            .catch(error => {
              this.$notifyError('刪除失敗', error.response.data.message);
            });
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="stylus">
.image-title
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
.el-upload-list__item-thumbnail
  object-fit cover
.focus-point
  display inline-block
.info-image
  height 100%
  width 100%
  // max-height 500px
  object-fit contain
.image-container
  position relative
</style>
