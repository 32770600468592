<template>
  <el-dialog
    :visible="true"
    :center="true"
    @close="closeModal"
    title="檔案設定"
  >
    <el-form
      ref="form"
      :model="form"
      v-loading="isLoading"
      label-position="top"
    >
      <el-form-item
        label="時間間隔"
        prop="days"
      >
        <el-input-number
          v-model="form.days"
          :min="0"
          :max="30"
          controls-position="right"
          placeholder="天"
          label="days"
        />
        <label for="days">天</label>
      </el-form-item>
      <el-form-item
        prop="hours"
      >
        <el-input-number
          v-model="form.hours"
          :min="0"
          :max="24"
          controls-position="right"
          placeholder="小時"
          label="hours"
        />
        <label for="hours">小時</label>
      </el-form-item>
      <el-form-item prop="minutes">
        <el-input-number
          v-model="form.minutes"
          :min="0"
          :max="60"
          controls-position="right"
          placeholder="分鐘"
        />
        <label for="minutes">分鐘</label>
      </el-form-item>
      <el-form-item prop="seconds">
        <el-input-number
          v-model="form.seconds"
          :min="0"
          :max="60"
          controls-position="right"
          placeholder="秒"
        />
        <label for="seconds">秒</label>
      </el-form-item>
      <el-form-item label="是否開始簡訊通知">
        <el-switch v-model="form.enabled" />
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="closeModal">取消</el-button>
      <el-button
        @click="submitForm"
        type="primary"
      >確認</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    documentDetails: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      form: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        enabled: false,
      },
    };
  },
  mounted() {
    const dataIntervalInMilliseconds = this.documentDetails.data_interval;
    const { days, hours, minutes, seconds } = this.convertMiliseconds(dataIntervalInMilliseconds);
    this.form.days = days;
    this.form.hours = hours;
    this.form.minutes = minutes;
    this.form.seconds = seconds;
    this.form.enabled = this.documentDetails.message.enabled;
  },
  methods: {
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.updateDocumentSettings();
        } else {
          return false;
        }
      });
    },
    convertMiliseconds(miliseconds, format) {
      const totalSeconds = parseInt(Math.floor(miliseconds / 1000));
      const totalMinutes = parseInt(Math.floor(totalSeconds / 60));
      const totalHours = parseInt(Math.floor(totalMinutes / 60));
      const days = parseInt(Math.floor(totalHours / 24));

      const seconds = parseInt(totalSeconds % 60);
      const minutes = parseInt(totalMinutes % 60);
      const hours = parseInt(totalHours % 24);

      switch (format) {
        case 's':
          return totalSeconds;
        case 'm':
          return totalMinutes;
        case 'h':
          return totalHours;
        case 'd':
          return days;
        default:
          return { days, hours, minutes, seconds };
      }
    },
    updateDocumentSettings() {
      const { _id } = this.documentDetails;
      const { days, hours, minutes, seconds, enabled } = this.form;
      // eslint-disable-next-line camelcase
      const data_interval = (seconds + minutes * 60 + hours * 60 * 60 + days * 60 * 60 * 24) * 1000;
      const data = {
        data_interval,
        'message.enabled': enabled,
      };
      this.$axios({
        category: 'admin',
        method: 'post',
        apiName: `document/${_id}`,
        data,
      }).then(() => {
        this.$notifySuccess('更新成功');
        this.$emit('success');
        this.closeModal();
      });
    },
    closeModal() {
      if (this.isSubmitting) return;
      this.$emit('close');
    },
  },
};
</script>
