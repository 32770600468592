<template>
  <div>
    <h2 class="title">{{ $t('login.title') }}</h2>
    <el-form
      ref="form"
      :model="form"
      :rules="loginRules"
      labelPosition="top"
      label-width="80px"
      :hide-required-asterisk="true"
    >
      <el-form-item prop="email">
        <label class="label" slot="label">{{ $t('login.email') }}</label>
        <el-input
          placeholder="Email"
          type="email"
          size="large"
          v-model="form.email"
          @keyup.enter.native="submitLoginForm"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <label class="label" slot="label">{{ $t('login.psd') }}</label>
        <el-input
          v-model="form.password"
          placeholder="Password"
          type="password"
          :minlength="6"
          @keyup.enter.native="submitLoginForm"
        ></el-input>
      </el-form-item>
      <a
        class="button mb-16 is-info is-primary is-fullwidth is-medium"
        @click="submitLoginForm"
        :class="{ 'is-loading': isValidating }"
        >{{ $t('login.title') }}</a
      >
      <span class="tips has-text-grey">Please contact geosupply.tw@gmail.com for demo pass</span>
    </el-form>
  </div>
</template>

<script>
import { clientVersion } from '../../constants/env';
export default {
  name: 'LoginPage',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error(this.$t('login.psdValidate')));
      } else {
        callback();
      }
    };
    return {
      form: {
        email: null,
        password: null,
      },
      loginRules: {
        email: [
          { required: true, message: '請輸入郵箱', trigger: 'blur' },
          {
            type: 'email',
            message: '請輸入正確的郵箱',
            trigger: 'blur',
          },
        ],
        password: [{ required: true, trigger: 'blur', validator: validatePass }],
      },
      isValidating: false,
      project: null,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['user/isAuthenticated'];
    },
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push(`/${this.$store.state.locale}/data`);
    }
  },
  methods: {
    submitLoginForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loginUser();
        } else {
          return false;
        }
      });
    },
    getProjectList() {
      return this.$store.dispatch('project/getProjectList').catch(error => {
        throw new Error(error);
      });
    },
    async loginUser() {
      this.isValidating = true;
      return this.$store
        .dispatch('user/loginUser', this.form)
        .then(() => {
          return this.getProjectList();
        })
        .then(() => {
          this.$notifySuccess('登入成功');
          if (this.$route.query && this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            //this.$router.push({ name: (clientVersion === 'thsr-main' || clientVersion === 'thsr-rain' || clientVersion === 'test') ? 'Dashboard' : 'layout' });
            this.$router.push({ name: this.selectedProject.first_display });
          }
        })
        .catch(error => {
          if(error.response.data.message === '密碼不能與手機號相同'){
            this.$notifyInfo('提示','密碼不能與手機號相同，請修改密碼');
            //this.$notifyInfo('提示','請修改密碼');
            this.$router.push({ name: 'client',query: { form: this.form } });
          }
          else if(error.response.data.message === '基於安全需求，請修改密碼'){
            this.$notifyInfo('提示','基於安全需求，我們要求3月/6月/9月/12月修改一次密碼，請修改密碼。');
            //this.$notifyInfo('提示','請修改密碼');
            this.$router.push({ name: 'client',query: { form: this.form } });
          }
          else{
            this.$notifyError(error);
          }
        })
        .finally(() => {
          this.isValidating = false;
        });
    },
  },
};
</script>
