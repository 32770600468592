<template>
  <area-list @change-tab="handleChangeTab">
  </area-list>
</template>

<script>
import AreaList from '@/components/area/List';
export default {
  components: {
    AreaList,
  },
  data() {
    return {
      selectedTab: 'list',
    };
  },
  methods: {
    handleChangeTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>
