<template>
  <div>
    <div v-if="hadSubmitted" class="columns">
      <div class="columns-chart">
        <el-card v-loading="loading" class="chart-container" shadow="always">
          <el-row>
            <div class="flex-row align-center justify-end">
              <el-button v-if="testRole"
                type="primary"
                size="small"
                @click="settingShare"
                :plain="true"
              >
                {{ $t('chart.sync') }}
              </el-button>
              <el-button
                type="primary"
                size="small"
                @click="toggleShowTable"
                :plain="!showTable"
              >
                {{ $t('chart.table') }}
              </el-button>
              <!--enableRain1Day-->
              <el-tooltip
                :content="$t('chart.info.trendline')"
                class="ml-8"
                placement="top"
              >
                <el-button @click="toggleTrendline" :plain="!enableTrendline" type="primary" size="small">
                  {{$t('chart.trendline')}}
                </el-button>
              </el-tooltip>
              <el-tooltip
                :content="$t('chart.info.rain1Day')"
                class="ml-8"
                placement="top"
              >
                <el-button @click="toggleShowRain1Day" :plain="!enableRain1Day" type="primary" size="small">
                  {{$t('chart.rain1Day')}}
                </el-button>
              </el-tooltip>
              <el-tooltip
                v-if="enable1HrVelocity"
                :content="$t('chart.velocity1Hr')"
                class="ml-8"
                placement="top"
              >
                <el-button @click="toggleShowVelocity1Hr" :plain="!showVelocity1Hr" type="primary" size="small">
                  <!--font-awesome-icon v-if="!showRemark" icon="eye" /-->
                  <!--font-awesome-icon icon="eye-slash" /-->
                  {{'1Hr'}}
                </el-button>
              </el-tooltip>
              <el-tooltip
                v-if="enable24HrVelocity"
                :content="$t('chart.velocity24Hr')"
                class="ml-8"
                placement="top"
              >
                <el-button @click="toggleShowVelocity24Hr" :plain="!showVelocity24Hr" type="primary" size="small">
                  <!--font-awesome-icon v-if="!showRemark" icon="eye" /-->
                  <!--font-awesome-icon icon="eye-slash" /-->
                  {{'24Hr'}}
                </el-button>
              </el-tooltip>
              <el-tooltip
                :content="showRemark ? $t('chart.remark.hide') : $t('chart.remark.show')"
                class="ml-8"
                placement="top"
              >
                <el-button @click="toggleShowRemark" type="primary" size="small">
                  <font-awesome-icon v-if="!showRemark" icon="eye" />
                  <font-awesome-icon v-if="showRemark" icon="eye-slash" />
                </el-button>
              </el-tooltip>
              <el-divider direction="vertical" />
              <el-tooltip :content="$t('chart.rescale')" class="ml-8" placement="top">
                <el-button @click="rescaleChartBtn('yAxisRule_rescale')" type="primary" size="small">
                  <font-awesome-icon icon="sync-alt" />
                </el-button>
              </el-tooltip>
              <el-divider direction="vertical" />
              <el-button
                v-for="(button, index) in chartDateRangeList"
                :key="button.name"
                :class="{ 'is-plain': index !== chartActiveIndex }"
                @click.prevent="set_x_AxisTickInterval(index, button.day, button.hours)"
                size="small"
                type="primary"
              >
                {{ button.name }}
              </el-button>
              <el-button
                slot="reference"
                :class="{
                  'is-plain': chartActiveIndex !== chartDateRangeList.length,
                }"
                @click="showSettings = true"
                type="primary"
                size="small"
              >
                <font-awesome-icon icon="cog" />
              </el-button>
            </div>
          </el-row>
          <highcharts ref="highcharts" :options="chartOptions" />
        </el-card>
      </div>
    </div>
    <remark-form
      v-if="remark.show"
      :editing-remark="remark.editing"
      :instrument="remark.instrument"
      :timestamp="remark.timestamp"
      :form-type="remark.formType"
      @close="onRemarkFormClose"
      @success="onRemarkUpdate"
    />
    <el-drawer
      :title="$t('chart.settings')"
      :visible.sync="showSettings"
      :modal="false"
      :size="$device.mobile ? '100%' : '30%'"
      :before-close="handleCloseDrawer"
    >
      <div class="flex-column" style="padding:0 20px;">
        <h6 class="title is-6">
          {{ $t('chart.date.interval') }}
        </h6>
        <div class="flex-row">
          <div class="flex-row">
            <el-input-number
              v-model="customize.xAxisTickInterval.day"
              :min="0"
              :placeholder="$t('chart.date.day')"
              size="small"
            /><span class="input-label-right">{{ $t('chart.date.day') }}</span>
          </div>
          <div class="flex-row" style="margin-left: 10px;">
            <el-input-number
              v-model="customize.xAxisTickInterval.hours"
              :min="0"
              :max="24"
              :placeholder="$t('chart.date.hour')"
              size="small"
            /><span class="input-label-right">{{ $t('chart.date.hour') }}</span>
          </div>
        </div>
        <h6 class="title is-6" style="margin-top: 20px;">
          {{ $t('chart.instrument.alert') }}
        </h6>
        <div class="flex-row align-center">
          <el-select v-model="alertInstrument._id" @change="updateYAxis">
            <el-option
              v-for="instrument in selectedInstrument"
              :key="instrument._id"
              :value="instrument._id"
              :label="instrument.latestVersion.name"
            />
          </el-select>
          <el-checkbox-group
            v-model="alertInstrument.showTarget"
            @change="updateYAxis"
            style="margin-left: 20px;"
          >
            <el-checkbox label="WL" />
            <el-checkbox label="AL" />
            <el-checkbox label="WSL" />
          </el-checkbox-group>
        </div>
        <div v-if="!loading" class="flex-column" style="padding: 20px 0;">
          <el-card
            v-for="unit of cloneOptions.yAxis"
            :key="unit.id"
            v-show="!unit.duplicated"
            style="margin-bottom: 10px;"
            shadow="hover"
            :style="{ 'border-top': `4px solid ${unit.gridLineColor}` }"
          >
            <div class="content">
              <div class="flex-row align-center justify-between" style="margin-bottom: 0.8em;">
                <h4 class="is-marginless">
                  {{ unit.name }}
                </h4>
                <div class="flex-row">
                  <el-tooltip
                    :content="unit.labels.enabled ? $t('chart.yAxis.hide') : $t('chart.yAxis.show')"
                    placement="top"
                  >
                    <el-button
                      @click="unit.labels.enabled = !unit.labels.enabled"
                      type="primary"
                      size="mini"
                    >
                      <font-awesome-icon v-if="unit.labels.enabled" icon="eye-slash" />
                      <font-awesome-icon v-if="!unit.labels.enabled" icon="eye" />
                    </el-button>
                  </el-tooltip>
                  <el-tooltip :content="$t('chart.yAxis.position')" placement="top">
                    <el-button
                      :disabled="!unit.labels.enabled"
                      @click="unit.opposite = changeLabelSide(unit.opposite)"
                      type="primary"
                      size="mini"
                    >
                      <font-awesome-icon icon="redo" />
                    </el-button>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="flex-row wrap">
              <div class="flex-column" style="padding:5px;">
                <label class="label">{{ $t('chart.yAxis.max') }}</label>
                <el-input-number v-model="unit.max" :precision="2" size="medium" @change="handleOptionChange();" />
              </div>
              <div class="flex-column" style="padding:5px;">
                <label class="label">{{ $t('chart.yAxis.min') }}</label>
                <el-input-number v-model="unit.min" :precision="2" size="medium" @change="handleOptionChange();" />
              </div>
              <div class="flex-column" style="padding:5px;">
                <label class="label">{{ $t('chart.yAxis.interval') }}</label>
                <el-input-number
                  v-model="unit.tickInterval"
                  :precision="3"
                  :min="0.001"
                  size="medium"
                  @change="handleOptionChange();"
                />
              </div>
              <template v-if="unit.instrument_type && unit.instrument_type.type === 'rainGauge'">
                <div class="flex-column" style="padding:5px;">
                  <label class="label">{{ $t('chart.date.hour') }}</label>
                  <el-input-number
                    v-model="rainOption[`${unit.instrument_type.defaultHourInterval}:${unit.instrument_type.defaultMinuteInterval}`].hourInterval"
                    size="medium"
                    :min="0"
                    :max="24"
                    :step="unit.instrument_type.hourIntervalStep || 1"
                    :step-strictly="true"
                    :placeholder="$t('chart.date.hour')"
                    @change="rescaleChart"
                  />
                </div>
                <div class="flex-column" style="padding:5px;">
                  <label class="label">{{ $t('chart.date.minute') }}</label>
                  <el-input-number
                    v-model="rainOption[`${unit.instrument_type.defaultHourInterval}:${unit.instrument_type.defaultMinuteInterval}`].minuteInterval"
                    :min="0"
                    :max="60"
                    :step="unit.instrument_type.minuteIntervalStep || 1"
                    :step-strictly="true"
                    :placeholder="$t('chart.date.minute')"
                    @change="rescaleChart"
                    size="medium"
                  />
                </div>
              </template>
              
              <div class="flex-column" style="padding:5px;">
                <label class="label">{{ $t('chart.yAxis.endOnTick') }}</label>
                <el-switch
                  v-model="unit.endOnTick"
                  @change="handleChange"
                >
                </el-switch>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </el-drawer>

    <el-card v-if="showTable">
      <el-button type="primary" @click="exportToExcel">Export to Excel</el-button>
      <hot-table :settings="hotSettings" ref="hot" style="margin-top: 12px"></hot-table>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import { chartColorPalettes, GRID_LINE_COLOR_LIST, TOP_GRID_LINE_COLOR } from './chartColorPalettes.js';
import { symbols } from './symbol.js';
import { getStatusColor } from '@/lib/base/systemAlert.js';
import RemarkForm from '@/components/remark/CreateForm.vue';
import { HotTable } from '@handsontable/vue';
import * as XLSX from 'xlsx';
import { clientVersion } from '../../constants/env';
import 'highcharts/css/highcharts.css';

MomentTimeZone();
export default {
  name: 'ChartComponents',
  components: {
    RemarkForm,
    HotTable,
  },
  props: {
    hadSubmitted:{
      required: true,
      type: Boolean,
    },
    dataLoading:{
      required: true,
      type: Boolean,
    },
    selectedInstrument:{
      require: true,
    },
    chartData:{
      require: true,
    },
    instrumentRemarkList: {
      default: true,
    },
    groupName:{
      require: true,
      type: String,
    },
    groupOption:{
      require: true,
    },
    rainOption:{
      default: {},
    },
    date: {
      type: Date,
    },
  },
  data(){
    return {
      autoRefresh: false,
      showRemark: true,
      showSettings: false,
      showTable: false,
      hotSettings: {
        data: [],
        colHeaders: [],
        licenseKey: 'non-commercial-and-evaluation',
        colWidths: 200,
        height: 400,
        autoColumnSize: true,
        readOnly: true,
      },
      alertInstrument: {
        _id: null,
        showTarget: ['WSL', 'AL'],
      },
      remark: {
        formType: 'create',
        editing: {},
        instrument: '',
        timestamp: '',
        show: false,
      },
      lastRefreshTimestamp: 0,
      refreshFunction: '',
      chartLoading: true,
      chartReady: false,
      chartDateRangeList: [
        {
          name: '6H',
          day: null,
          hours: 6,
        },
        {
          name: '1D',
          day: 1,
          hours: null,
        },
        {
          name: '7D',
          day: 7,
          hours: null,
        },
      ],
      chartActiveIndex: 1,
      colorPalettes: chartColorPalettes,
      customize: {
        xAxisTickInterval: {
          day: 1,
          hours: null,
        },
      },
      options: {
        series: [],
        xAxis: {
          min: undefined,
          max: undefined,
          type: 'datetime',
        },
        yAxis: [],
      },
      oldYAxis: [],
      unitIndex: [],
      showVelocity1Hr: false,
      showVelocity24Hr: false,
      enable1HrVelocity: false,
      enable24HrVelocity: false,

      enableRain1Day: false,
      //目前的功能是數值等同於isUpdate，
      //若數值為true(isUpdate=true)，則使用警戒值作為生成上下限的參考，
      //若數值為false(isUpdate=false)，則僅使用自身作為上下限的參考
      rescaleChartStateMachine: true, 
      //rainOption: {},
      enableTrendline: false,
      //userExtremesFlag: false,

      userYAxisOptions: {},
      optionChange: false,
    };
  },
  computed:{
    hasAccess() {
      return {
        remark: this.$store.getters['project/hasRemarkAccess'],
      };
    },
    loading() {
      return this.dataLoading || this.chartLoading;
    },
    chartOptions() {
      const vm = this;
      const series = this.$lodash.cloneDeep(this.options.series);
      if (this.enableTrendline){
        for(let serie of this.options.series){
          if(serie.type != 'line') continue;
          series.push({
            type: 'trendline',
            linkedTo: serie.id,
            unit: serie.unit,
            tooltip: {
              valueSuffix: ` ${serie.unit}`,
            },
          });
        }
      }

      let yAxis = vm.options.yAxis;
      if(yAxis.length > 0) {
        yAxis[yAxis.length-1].gridLineColor = TOP_GRID_LINE_COLOR;
        yAxis[yAxis.length-1].className = 'highcharts-grid-top';
      }

      return {
        title: {
          text: this.$t('chart.title.historyChart'),
        },
        exporting: {
          enabled: false,
        //  scale: 3,
        //  allowHTML: false, // 禁用內聯樣式
        //  // sourceWidth: 1200
        //},
        //boost: {
        //  useGPUTranslations: true,
        },
        time: {
          timezone: this.timezone,
        },
        chart: {
          zoomType: 'xy',
          height: 500,
          spacingLeft: 50,
          spacingRight: 30,
          styledMode: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            turboThreshold: 0,
            cursor: 'pointer',
            point: {
              events: {
                click(event) {
                  console.log('click');
                  if (vm.hasAccess.remark) {
                    vm.remark.instrument = event.point.instrument;
                    vm.remark.timestamp = event.point.x;
                    if (event.point.remark) {
                      const remark = event.point.remark;
                      vm.remark.formType = 'edit';
                      vm.remark.editing = remark;
                    } else {
                      vm.remark.formType = 'create';
                    }
                    vm.remark.show = true;
                  }
                },
              },
            },
            scatter: {
              tooltip: {
                dateTimeLabelFormats: '%d/%m/%Y %H:%M',
              },
            },
          },
          scatter: {
            showInLegend: false,
            tooltip: {
              crosshairs: true,
              xDateFormat: '%d/%m/%Y %H:%M',
            },
          },
        },
        tooltip: {
          // useHTML: true,
          xDateFormat: '%d/%m/%Y %H:%M',
          valueDecimals: 3,
          shared: true,
          className: 'tooltip-box',
          formatter(tooltip) {
            if (!vm.showRemark || !this.points) {
              return tooltip.defaultFormatter.call(this, tooltip);
            }
            const remarks = this.points
              .map(ele => {
                if (ele.point.remark) {
                  return {
                    content: ele.point.remark.content,
                    color: ele.point.color,
                  };
                }
              })
              .filter(ele => ele !== undefined);
            if (remarks.length > 0) {
              const defaultTooltip = tooltip.defaultFormatter.call(this, tooltip);
              remarks.forEach((remark, index) => {
                if (index === 0) {
                  const gap = '<span style="margin-top:8px"></span></br>';
                  defaultTooltip.push(gap);
                }
                const remarkHtml = `<span style="color:${remark.color}">▲</span><span style="font-weight:bold; font-size:14px"> ${remark.content}</span><br/>`;
                //const remarkHtml = remark.content;
                defaultTooltip.push(remarkHtml);
              });
              return defaultTooltip;
            }
            return tooltip.defaultFormatter.call(this, tooltip);
          },
        },
        yAxis,
        xAxis: {
          ...vm.options.xAxis,
          min: this.$moment(this.date[0]).valueOf(),
          startOnTick: true,
          title: {
            text: 'Date',
            align: 'low',
          },
          endOnTick: true,
          showFirstLabel: true,
          gridLineWidth: 1,
          labels: {
            style: {
              fontWeight: 700,
              fontSize: '13px',
            },
            formatter() {
              // if (this.isFirst || this.isLast) {
              if (this.isFirst) {
                const timestamp = vm.$moment(this.value);
                const hour = timestamp.hour();
                const minute = timestamp.minute();
                if (hour === 0 && minute === 0) {
                  return vm.$moment(this.value).format('YYYY/MM/DD');
                } else {
                  return vm.$moment(this.value).format('YYYY/MM/DD HH:mm');
                }
              } else {
                return this.axis.defaultLabelFormatter.call(this);
              }
            },
          },
          dateTimeLabelFormats: {
            millisecond: '%m/%d %H:%M',
            second: '%m/%d %H:%M',
            minute: '%m/%d %H:%M',
            hour: '%m/%d %H:%M',
            day: '%m/%d',
            week: '%m/%d',
            month: '%m/%d',
            year: '%m/%d',
          },
          tickInterval:
            (this.customize.xAxisTickInterval.day * 24 + this.customize.xAxisTickInterval.hours) *
            3600 *
            1000,
        },
        series,
        legend: {
          maxHeight: 80,
        },
      };
    },
    timezone() {
      return this.$store.getters['env/timezone'];
    },
    testRole() {
      return clientVersion === 'test';
    },
  },
  mounted(){
    this.checkProjectVelocityOption();
    this.update('yAxisRule_optionPrecedeRescale');
  },
  watch: {
    selectedInstrument: {
      handler(newValue, oldValue) {
        if (newValue.length !== oldValue.length) {
          this.updateChartData();
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    if (this.refreshFunction) {
      clearInterval(this.refreshFunction);
    }
  },
  methods: {
    updateChartData() {
      this.generateSeries();
      //this.generateYAxis(true);
      //this.rescaleChart();
    },
    updatetableData() {
      if (this.showTable) {
        let colHeaders = ['Date'];
        let allDates = new Set();
        let dataMap = {};

        // 日期和初始化數據映射
        this.options.series.forEach(inst => {
          //console.log(inst);
          if (!inst.name.includes('WL') && !inst.name.includes('AL') && !inst.name.includes('WSL') && !dataMap[inst.name]) {
            colHeaders.push(inst.name);
            dataMap[inst.name] = {};

            if (inst.data.length) {
              inst.data.forEach(item => {
                let date = new Date(item.x);
                let formattedDate = date.getFullYear() + '-' +
                  ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                  ('0' + date.getDate()).slice(-2) + ' ' +
                  ('0' + date.getHours()).slice(-2) + ':' +
                  ('0' + date.getMinutes()).slice(-2) + ':' +
                  ('0' + date.getSeconds()).slice(-2);
                allDates.add(formattedDate);
                dataMap[inst.name][formattedDate] = item.y;
              });
            }
          }
        });

        let sortedDates = Array.from(allDates).sort();

        // 對於每個日期檢查每個儀器是否有數據
        let data = sortedDates.map(date => {
          return colHeaders.slice(1).map(header => dataMap[header][date] ?? '');
        });

        // 將日期列加到數據陣列的開頭
        data = sortedDates.map((date, index) => [date, ...data[index]]);

        this.hotSettings = {
          ...this.hotSettings,
          colHeaders,
          data,
        };
        this.updateTable();
      }
    },
    toggleShowTable() {
      this.showTable = !this.showTable;
      this.updatetableData();
    },
    exportToExcel() {
      let data = this.hotSettings.data;
      let colHeaders = this.hotSettings.colHeaders;

      data = [colHeaders].concat(data);
      let currentDate = new Date().toISOString().slice(0,10).replace(/-/g, '');

      let filename = '';
      if (colHeaders.length === 2) {
        filename = `export_${colHeaders[1]}_${currentDate}.xlsx`;
      } else {
        filename = `export_${currentDate}.xlsx`;
      }

      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      XLSX.writeFile(workbook, filename);
    },
    setOption(){
      //console.log(this.groupOption);
      if(this.groupOption!={}){
        this.showVelocity1Hr = this.groupOption.showVelocity1Hr;
        this.showVelocity24Hr = this.groupOption.showVelocity24Hr;
      }
    },
    update(type = 'yAxisRule_beAsIs'){
      this.setOption();
      this.chartReady = false;
      this.chartLoading = true;
      if(this.hadSubmitted && !this.dataLoading){
        if([NaN,undefined,null].includes(this.selectedInstrument[0])) return;
        this.alertInstrument._id = this.selectedInstrument[0]._id;
        this.rescaleChart(type);
      }
    },
    rescaleChart(type = 'yAxisRule_beAsIs') {
      return this.generateYAxis(true)
        .then(() => {
          return this.generateSeries();
        })
        .then(() => {
          this.getAxisData(type);
        });
    },
    rescaleChartBtn(type = 'yAxisRule_beAsIs'){
      if(type == 'yAxisRule_rescale') this.rescaleChartStateMachine = !this.rescaleChartStateMachine;
      this.oldYAxis = [];
      return this.generateYAxis(true)
        .then(() => {
          this.generateSeries();
        })
        .then(() => {
          this.getAxisData(type);
        });
    },
    checkProjectVelocityOption(){
      //const showList = this.selectedProject.config.data.display;
      //if(showList.includes('velocity1Hr')) this.enable1HrVelocity = true;
      //if(showList.includes('velocity24Hr')) this.enable24HrVelocity = true;
      this.enable1HrVelocity = false;
      this.enable24HrVelocity = false;
      const instrumentList = this.selectedInstrument;
      instrumentList.forEach(ins=>{
        if(ins.latestVersion.allowRate) {
          this.enable1HrVelocity = true;
          this.enable24HrVelocity = true;
        }
      });
      if(!this.enable1HrVelocity) this.showVelocity1Hr = false;
      if(!this.enable24HrVelocity) this.showVelocity24Hr = false;
    },
    updateYAxis() {
      this.generateYAxis(true).then(() => {
        this.getAxisData('yAxisRule_optionPrecedeAlert').then(()=>{
          this.handleChange();
        });
      });
    },
    generateYAxis(isUpdate = false) {
      return new Promise(resolve => {
        this.unitIndex = [];
        const unitList = this.selectedInstrument.map(ele => ele.latestVersion.unit);
        const indexList = [];
        unitList.forEach((unit, index) => {
          if (unitList.indexOf(unit) === index) {
            indexList.push(index);
          }
        });
        this.options.yAxis = this.selectedInstrument
          .map((ele, index) => {
            const showUnit = indexList.includes(index);
            if (!showUnit) return;
            const ref = ele.latestVersion;
            let obj = {};
            if (!isUpdate) {
              obj = this.oldYAxis.find(old => old.name === ref.unit);
            }
            let unitFormat = ref.unit;
            if (ele.instrument_type && ele.instrument_type.type === 'rainGauge') {
              let rainOption = this.rainOption[`${ele.instrument_type.defaultHourInterval}:${ele.instrument_type.defaultMinuteInterval}`];
              unitFormat = `${ele.instrument_type.unit}/`;
              let hourInterval = rainOption.hourInterval;
              let minuteInterval = rainOption.minuteInterval;
              if(this.enableRain1Day){
                unitFormat = `${ele.instrument_type.unit}/${this.$i18n.t('chart.day')}`;
              } else if (hourInterval > 0 && minuteInterval > 0) {
                unitFormat = `${ele.instrument_type.unit}/${hourInterval}H ${minuteInterval}M`;
              } else if (hourInterval > 0) {
                unitFormat = `${ele.instrument_type.unit}/${hourInterval}H`;
              } else if (minuteInterval > 0) {
                unitFormat = `${ele.instrument_type.unit}/${minuteInterval}M`;
              }
            }
            if(this.showVelocity1Hr && ele.latestVersion.allowRate) unitFormat = `${unitFormat}/1Hr`;
            if(this.showVelocity24Hr && ele.latestVersion.allowRate) unitFormat = `${unitFormat}/24Hr`;
            let format = `{value}${unitFormat}`;
            if (this.$lodash.isEmpty(obj)) {
              let plotLines = [];
              if (ele._id === this.alertInstrument._id) {
                this.alertInstrument.showTarget.forEach(target => {
                  const plus_value = this.getAlertValue(`${target}_plus`, ele);
                  const minus_value = this.getAlertValue(`${target}_minus`, ele);
                  if (![null, undefined].includes(plus_value)) {
                    plotLines.push({
                      color: getStatusColor(target),
                      width: 2,
                      value: plus_value,
                      className: this.getStatusClassName(target),
                    });
                  }
                  if (![null, undefined].includes(minus_value)) {
                    plotLines.push({
                      color: getStatusColor(target),
                      width: 2,
                      value: minus_value,
                      className: this.getStatusClassName(target),
                    });
                  }
                });
              }

              const oldYAxis = this.oldYAxis.find(ele => ele.name === unitFormat);

              obj = {
                name: unitFormat,
                instrument_name: ref.name,
                instrument_type: ele.instrument_type,
                title: {
                  align: 'high',
                  offset: 0,
                  text: this.$t('chart.measurements'),
                  rotation: 0,
                  y: -10,
                },
                labels: {
                  enabled: showUnit,
                  format,
                  style: {
                    fontWeight: 700,
                    fontSize: '13px',
                  },
                },
                opposite: this.unitIndex.length % 2,
                tickInterval: oldYAxis ? oldYAxis.tickInterval : undefined,
                plotLines,
                max: oldYAxis ? oldYAxis.max : undefined,
                min: oldYAxis ? oldYAxis.min : undefined,
                startOnTick: false,
                endOnTick: false,
                gridLineColor: this.getGridLineColor(),
                className: this.getYGridClassName(),
                id: index,
              };
            }
            const config = (this.showVelocity1Hr&&ref.rate_1Hr_config) ? ref.rate_1Hr_config : (this.showVelocity24Hr&&ref.rate_24Hr_config) ? ref.rate_24Hr_config : ref;
            let useWSL = this.alertInstrument.showTarget.includes('WSL');
            let useAL = this.alertInstrument.showTarget.includes('AL');
            let useWL = this.alertInstrument.showTarget.includes('WL');
            let plus = (useWSL && config.WSL_plus) || (useAL && config.AL_plus) || (useWL && config.WL_plus);
            let minus = (useWSL && config.WSL_minus) || (useAL && config.AL_minus) || (useWL && config.WL_minus);
            this.unitIndex.push({
              unit: ref.unit,
              index: this.unitIndex.length,
              plus,
              minus,
            });
            return obj;
          })
          .filter(ele => ele);
        resolve();
      });
    },
    generateSeries() {
      return new Promise(resolve => {
        this.options.series = this.selectedInstrument.map((instrument, index) => {
          const ref = instrument.latestVersion;
          const yAxisIndex = this.unitIndex.findIndex(record => {
            return record.unit === ref.unit;
          });

          const dataLength = this.chartData[ref.name].data.length;
          const data = [...this.chartData[ref.name].data];

          if (this.showRemark) {
            const remarkList = this.instrumentRemarkList.find(
              remark => remark.instrument_id === instrument._id,
            );
            //  find corresponding data from list and replace it with data wf remark
            if (remarkList) {
              remarkList.remark.forEach(remark => {
                const dataFitRemarkIndex = data.findIndex((ele, index) => {
                  if (moment(remark.start_date).isSame(ele.x)) {
                    return true;
                  } else if (index !== dataLength - 1) {
                    const nextEle = data[index + 1];
                    if (moment(remark.start_date).isBetween(ele.x, nextEle.x)) {
                      return true;
                    }
                  }
                });
                data[dataFitRemarkIndex] = {
                  ...data[dataFitRemarkIndex],
                  remark,
                  marker: {
                    enabled: true,
                    width: 20,
                    height: 20,
                    fillColor: '#FFFFFF',
                    symbol: `url(${symbols[remark.type]})`,
                  },
                };
              });
            }
          }

          let unitFormat = ref.unit;
          if (instrument.instrument_type && instrument.instrument_type.type === 'rainGauge') {
            let rainOption = this.rainOption[`${instrument.instrument_type.defaultHourInterval}:${instrument.instrument_type.defaultMinuteInterval}`];
            if(this.enableRain1Day){
              unitFormat = `${instrument.instrument_type.unit}/日`;
            } else if ( rainOption.hourInterval > 0 && rainOption.minuteInterval > 0 ) {
              unitFormat = `${instrument.instrument_type.unit}/${rainOption.hourInterval}H ${rainOption.minuteInterval}M`;
            } else if (rainOption.hourInterval > 0) {
              unitFormat = `${instrument.instrument_type.unit}/${rainOption.hourInterval}H`;
            } else if (rainOption.minuteInterval > 0) {
              unitFormat = `${instrument.instrument_type.unit}/${rainOption.minuteInterval}M`;
            }
          }
          if(this.showVelocity1Hr) unitFormat = `${unitFormat}/1Hr`;
          if(this.showVelocity24Hr) unitFormat = `${unitFormat}/24Hr`;
          const obj = {
            name: ref.name,
            data,
            unit: unitFormat,
            type: instrument.chart_type,
            instrument_type: instrument.instrument_type,
            yAxis: yAxisIndex,
            tooltip: {
              valueSuffix: ` ${unitFormat}`,
            },
            color: this.colorPalettes[index],
            id: ref.name,
          };
          return obj;
        });

        this.options.series.forEach(series => {
          if (series.instrument_type && series.instrument_type.type === 'rainGauge') {
            // let instrumentInterval, lastN;
            if(this.enableRain1Day){
              let data = this.$lodash.cloneDeep(series.data);
              series.data = data.map((ele, index)=>{
                if (!this.$lodash.has(ele, 'originalY')) {
                  ele.originalY = ele.y;
                }
                let result = this.$lodash.cloneDeep(ele);
                if (index > 2) {
                  const totalData = this.accumulate1DayRainGaugeSum(
                    data,
                    index,
                    series.instrument_type.defaultInterval,
                  );
                  
                  result.y = totalData;
                }
                return result;
              }).filter((ele, index)=>{
                const time = new Date(ele.x);
                const hour = time.getHours();
                const minute = time.getMinutes();
                return hour == 0 && minute == 0;
              });
            }
            else{
              let lastN;
              let rainOption = this.rainOption[`${series.instrument_type.defaultHourInterval}:${series.instrument_type.defaultMinuteInterval}`];
              const hourInterval = rainOption.hourInterval * 3600000;
              const minuteInterval = rainOption.minuteInterval * 60 * 1000;
              lastN = (hourInterval + minuteInterval) / series.instrument_type.defaultInterval;
              series.data.forEach((ele, index) => {
                if (!this.$lodash.has(ele, 'originalY')) {
                  ele.originalY = ele.y;
                }
                if (index > 2) {
                  const totalData = this.accumulatedRainGaugeSum({
                    data: series.data,
                    lastN,
                    index,
                  });
                  ele.y = totalData;
                }
              });
            }
          }
        });

        const columnCharts = this.options.series.filter(ele => ele.type === 'column');
        
        columnCharts.forEach(series => {
          const newObj = this.$lodash.cloneDeep(series);
          newObj.type = 'scatter';
          newObj.data = newObj.data.filter(data => {
            return data.remark;
          });
          this.options.series.push(newObj);
        });
        const instrument = this.selectedInstrument.find(
          instrument => instrument._id === this.alertInstrument._id,
        );
        this.alertInstrument.showTarget.forEach(target => {
          if(instrument){
            const plus_value = this.getAlertValue(`${target}_plus`, instrument);
            const minus_value = this.getAlertValue(`${target}_minus`, instrument);
            if (![null, undefined].includes(plus_value) || ![null, undefined].includes(minus_value)) {
              this.options.series.push({
                name: target,
                color: getStatusColor(target),
                className: this.getStatusClassName(target),
              });
            }
          }
        });
        resolve();
        this.updatetableData();
      });
    },
    getAxisData(yAxisRule_type) {
      return new Promise(resolve => {
        const chart = this.$refs.highcharts.chart;
        this.unitIndex.forEach(ele => {
          const extremes = chart.yAxis[ele.index].getExtremes();
          console.log(yAxisRule_type);
          console.log(`optionChange ${this.optionChange}`);
          console.log(`this.options.yAxis ${this.options.yAxis[ele.index].max} ${this.options.yAxis[ele.index].min} ${this.options.yAxis[ele.index].tickInterval}`);
          console.log(`this.cloneOptions.yAxis ${this.cloneOptions && this.cloneOptions.yAxis && this.cloneOptions.yAxis[ele.index] && this.cloneOptions.yAxis[ele.index].max} ${this.cloneOptions && this.cloneOptions.yAxis && this.cloneOptions.yAxis[ele.index] && this.cloneOptions.yAxis[ele.index].min} ${this.cloneOptions && this.cloneOptions.yAxis && this.cloneOptions.yAxis[ele.index] && this.cloneOptions.yAxis[ele.index].tickInterval}`);
          console.log(`this.userYAxisOptions[index] ${this.userYAxisOptions[ele.unit]} ${this.userYAxisOptions[ele.unit] && this.userYAxisOptions[ele.unit].max} ${this.userYAxisOptions[ele.unit] && this.userYAxisOptions[ele.unit].min} ${this.userYAxisOptions[ele.unit] && this.userYAxisOptions[ele.unit].tickInterval}`);
          console.log(`unitData ${ele.plus} ${ele.minus}`);
          console.log(`extremes ${extremes.max} ${extremes.min}`);
          console.log(`tickInterval ${chart.yAxis[ele.index].tickInterval}`);
          console.log(`this.rescaleChartStateMachine ${this.rescaleChartStateMachine}`);
          switch(yAxisRule_type){
            case 'yAxisRule_beAsIs':
              this.yAxisRule_beAsIs(ele.index, chart.yAxis[ele.index].tickInterval);
              break;
            case 'yAxisRule_beShared':
              this.yAxisRule_beShared(ele.index, ele.unit, chart.yAxis[ele.index].tickInterval);
              break;
            case 'yAxisRule_rescale':
              this.yAxisRule_rescale(ele.index, ele.unit, extremes, chart.yAxis[ele.index].tickInterval, ele);
              break;
            case 'yAxisRule_optionPrecedeAlert':
              this.yAxisRule_optionPrecedeAlert(ele.index, ele.unit, extremes, chart.yAxis[ele.index].tickInterval, ele);
              break;
            case 'yAxisRule_optionPrecedeRescale':
              this.yAxisRule_optionPrecedeRescale(ele.index, ele.unit, extremes, chart.yAxis[ele.index].tickInterval, ele);
              break;
          }
        });
        this.oldYAxis = this.chartOptions.yAxis;
        // console.log(this.options.yAxis);
        this.checkProjectVelocityOption();
        this.cloneOptionsYAxis();

        this.chartReady = true;
        if (this.selectedInstrument.length) {
          this.chartLoading = false;
        }
        resolve();
      });
    },
    accumulatedRainGaugeSum({ data, lastN = 3, index }) {
      const subtractData = data[index - lastN] ? data[index - lastN].originalY : 0;
      let totalData = 0;
      while (lastN >= 0) {
        const addData = data[index - lastN] ? data[index - lastN].originalY : 0;
        totalData += addData;
        lastN = lastN - 1;
      }
      totalData = totalData - subtractData;
      return totalData;
    },
    accumulate1DayRainGaugeSum(data, index, interval){
      //若interval為10分鐘，則數值應為 1000 * 60 * 10
      //一天有 1000 * 60 * 60 * 24 ms
      //以此數值除以interval，10分鐘的interval會有144筆數據
      let dataCount = 1000 * 60 * 60 * 24 / interval;
      let totalData = 0;
      while (dataCount > 0) {
        const addData = data[index - dataCount] ? data[index - dataCount].originalY : 0;
        totalData += addData;
        dataCount = dataCount - 1;
      }
      return totalData;
    },
    set_x_AxisTickInterval(index, day, hours) {
      this.setChartActiveIndex(index);
      this.customize.xAxisTickInterval.day = day;
      this.customize.xAxisTickInterval.hours = hours;
      this.updatetableData();
    },
    setChartActiveIndex(index) {
      this.chartActiveIndex = index;
    },
    toggleShowRemark() {
      this.showRemark = !this.showRemark;
      this.generateSeries();
    },
    toggleShowVelocity1Hr() {
      this.showVelocity1Hr = !this.showVelocity1Hr;
      this.showVelocity24Hr = false;
      this.groupOption.showVelocity24Hr = this.showVelocity24Hr;
      this.groupOption.showVelocity1Hr = this.showVelocity1Hr;
      this.$emit('submitForm', {name: this.groupName, groupOption: this.groupOption});
    },
    toggleShowVelocity24Hr() {
      this.showVelocity24Hr = !this.showVelocity24Hr;
      this.showVelocity1Hr = false;
      this.groupOption.showVelocity24Hr = this.showVelocity24Hr;
      this.groupOption.showVelocity1Hr = this.showVelocity1Hr;
      this.$emit('submitForm', {name: this.groupName, groupOption: this.groupOption});
    },
    toggleShowRain1Day() {
      this.enableRain1Day = !this.enableRain1Day;
      this.rescaleChartBtn('yAxisRule_beAsIs');
    },
    changeLabelSide(value) {
      // 0 at left, 1 at right
      return value ? 0 : 1;
    },
    getAlertValue(target, instrument){
      if(this.showVelocity1Hr && instrument.latestVersion.rate_1Hr_config) return instrument.latestVersion.rate_1Hr_config[target];
      if(this.showVelocity24Hr && instrument.latestVersion.rate_24Hr_config) return instrument.latestVersion.rate_24Hr_config[target];
      return instrument.latestVersion[target];
    },
    onRemarkUpdate() {
      let vm = this;
      this.$emit('getRemarks',() => {
        vm.generateSeries();
      });
    },
    onRemarkFormClose() {
      this.remark.show = false;
      this.remark.editing = {};
    },
    toggleTrendline() {
      if(this.selectedInstrument.length>1) {
        this.$notifyError(this.$i18n.t('chart.trendlineError'));
        return;
      }
      this.enableTrendline = !this.enableTrendline;
    },
    getGridLineColor() {
      return GRID_LINE_COLOR_LIST[this.unitIndex.length % GRID_LINE_COLOR_LIST.length];
    },
    getYGridClassName() {
      const classNameList = [
        'highcharts-grid-1',
        'highcharts-grid-2',
        'highcharts-grid-3',
        'highcharts-grid-4',
        'highcharts-grid-5',
      ];
      return classNameList[this.unitIndex.length % classNameList.length];
    },
    getStatusClassName(status) {
      const classNameList = [
        'highcharts-WL-plot',
        'highcharts-AL-plot',
        'highcharts-WSL-plot',
      ];
      const classIndex = classNameList.findIndex(ele => {
        return ele.includes(status);
      });
      return classNameList[classIndex];
    },
    settingShare(){
      let setting = {
        customize: this.$lodash.cloneDeep(this.customize),
        'alertInstrument.showTarget': this.$lodash.cloneDeep(this.alertInstrument.showTarget),
        enableRain1Day: this.enableRain1Day,
        units: [],
      };
      for(let _ of this.options.yAxis){
        const unit = this.$lodash.cloneDeep(_);
        let hourInterval = null;
        let minuteInterval = null;
        if(unit.instrument_type && unit.instrument_type.type === 'rainGauge'){
          hourInterval = this.rainOption[`${unit.instrument_type.defaultHourInterval}:${unit.instrument_type.defaultMinuteInterval}`].hourInterval;
          minuteInterval = this.rainOption[`${unit.instrument_type.defaultHourInterval}:${unit.instrument_type.defaultMinuteInterval}`].minuteInterval;
        }
        setting.units.push({
          name: unit.name,
          'labels.enabled': unit.labels.enabled,
          opposite: unit.opposite,
          min: unit.min,
          max: unit.max,
          tickInterval: unit.tickInterval,
          hourInterval,
          minuteInterval,
        });
      }
      this.$emit('settingShare',setting);
    },
    directUploadSetting(setting){
      // customize
      // alertInstrument.showTarget
      // unit.name
      // unit.labels.enabled
      // unit.opposite
      // unit.min
      // unit.max
      // unit.tickInterval
      // rainOption[`${unit.instrument_type.defaultHourInterval}:${unit.instrument_type.defaultMinuteInterval}`].hourInterval
      // rainOption[`${unit.instrument_type.defaultHourInterval}:${unit.instrument_type.defaultMinuteInterval}`].minuteInterval
      // unit.instrument_type.minuteIntervalStep
      this.customize = setting.customize;
      if(this.enableRain1Day != setting.enableRain1Day){
        this.enableRain1Day = setting.enableRain1Day;
        this.rescaleChartBtn('yAxisRule_beAsIs');
      }
      this.alertInstrument.showTarget = setting['alertInstrument.showTarget'];
      for(let unit of setting.units){
        let index = this.options.yAxis.findIndex(ele=>{
          return ele.name === unit.name;
        });
        if(index>-1){
          //console.log(unit);
          if(!this.userYAxisOptions[unit.name]){
            this.userYAxisOptions[unit.name] = {};
          }
          this.userYAxisOptions[unit.name].max = unit.max;
          this.userYAxisOptions[unit.name].min = unit.min;
          this.userYAxisOptions[unit.name].tickInterval = unit.tickInterval;

          let _ = this.options.yAxis[index];
          this.options.yAxis[index].labels.enabled = unit['labels.enabled'];
          this.options.yAxis[index].opposite = unit.opposite;
          this.options.yAxis[index].min = unit.min;
          this.options.yAxis[index].max = unit.max;
          this.options.yAxis[index].tickInterval = unit.tickInterval;
          

          if(_.instrument_type && _.instrument_type.type === 'rainGauge'){
            this.rainOption[`${_.instrument_type.defaultHourInterval}:${_.instrument_type.defaultMinuteInterval}`].hourInterval = unit.hourInterval;
            this.rainOption[`${_.instrument_type.defaultHourInterval}:${_.instrument_type.defaultMinuteInterval}`].minuteInterval = unit.minuteInterval;
          }
        }
      }
      this.cloneOptionsYAxis();
    },    
    cloneOptionsYAxis() {
      console.log('cloneOptionsYAxis');
      this.cloneOptions = {};
      this.cloneOptions.yAxis = this.$lodash.cloneDeep(this.options.yAxis);
      console.log(this.$lodash.cloneDeep(this.cloneOptions.yAxis));
    },
    handleCloseDrawer(done){
      console.log('handleCloseDrawer');
      //console.log(this.$lodash.cloneDeep(this.cloneOptions.yAxis));
      this.options.yAxis = this.$lodash.cloneDeep(this.cloneOptions.yAxis);
      //this.cloneOptions.yAxis = null;
      if(this.optionChange){
        this.unitIndex.forEach(ele=>{
          const index = ele.index;
          this.userYAxisOptions[ele.unit] = {
            max: this.cloneOptions.yAxis[index].max,
            min: this.cloneOptions.yAxis[index].min,
            interval: this.cloneOptions.yAxis[index].interval,
            startOnTick: this.cloneOptions.yAxis[index].startOnTick,
            endOnTick: this.cloneOptions.yAxis[index].endOnTick,
            opposite: this.cloneOptions.yAxis[index].opposite,
            tickInterval: this.cloneOptions.yAxis[index].tickInterval,
            //labelsEnabled: this.cloneOptions.yAxis.labels.enabled,
          };

          //console.log('setting!');
          //console.log(`this.options.yAxis ${this.options.yAxis[ele.index].max} ${this.options.yAxis[ele.index].min}`);
          //console.log(`this.cloneOptions.yAxis ${this.cloneOptions && this.cloneOptions.yAxis && this.cloneOptions.yAxis[ele.index] && this.cloneOptions.yAxis[ele.index].max} ${this.cloneOptions && this.cloneOptions.yAxis && this.cloneOptions.yAxis[ele.index] && this.cloneOptions.yAxis[ele.index].min}`);
          //console.log(`this.userYAxisOptions[index] ${this.userYAxisOptions[ele.index]} ${this.userYAxisOptions[ele.index] && this.userYAxisOptions[ele.index].max} ${this.userYAxisOptions[ele.index] && this.userYAxisOptions[ele.index].min}`);
          //console.log(`unitData ${ele.plus} ${ele.minus}`);
          //console.log(`extremes ${null} ${null}`);
        });

        this.optionChange = false;
      }

      this.$forceUpdate();
      done();
    },
    handleChange(){
      //console.log('handleSwitchChange');
      //console.log(this.cloneOptions.yAxis);
      this.$forceUpdate();
    },
    handleOptionChange(){
      this.optionChange = true;
    },
    updateTable() {
      setTimeout(() => {
        this.$refs.hot.hotInstance.loadData(this.hotSettings.data);
      }, 100);
    },
    handlePointClick(event) {
      console.log('click');
      if (this.hasAccess.remark) {
        this.remark.instrument = event.point.instrument;
        this.remark.timestamp = event.point.x;
        if (event.point.remark) {
          const remark = event.point.remark;
          this.remark.formType = 'edit';
          this.remark.editing = remark;
        } else {
          this.remark.formType = 'create';
        }
        this.remark.show = true;
      }
    },

    tooltipFormatter(tooltip) {
      const vm = this;
      if (!vm.showRemark || !this.points) {
        return tooltip.defaultFormatter.call(this, tooltip);
      }
      const remarks = this.points
        .map(ele => {
          if (ele.point.remark) {
            return {
              content: ele.point.remark.content,
              color: ele.point.color,
            };
          }
        })
        .filter(ele => ele !== undefined);
      if (remarks.length > 0) {
        const defaultTooltip = tooltip.defaultFormatter.call(this, tooltip);
        remarks.forEach((remark, index) => {
          //if (index === 0) {
          //  const gap = '<span style="margin-top:8px"></span></br>';
          //  defaultTooltip.push(gap);
          //}
          //const remarkHtml = `<span style="color:${remark.color}">▲</span><span style="font-weight:bold; font-size:14px"> ${remark.content}</span><br/>`;
          const remarkHtml = remark.content;
          defaultTooltip.push(remarkHtml);
        });
        return defaultTooltip;
      }
      return tooltip.defaultFormatter.call(this, tooltip);
    },

    labelFormatter() {
      const vm = this;
      // if (this.isFirst || this.isLast) {
      if (this.isFirst) {
        const timestamp = vm.$moment(this.value);
        const hour = timestamp.hour();
        const minute = timestamp.minute();
        if (hour === 0 && minute === 0) {
          return vm.$moment(this.value).format('YYYY/MM/DD');
        } else {
          return vm.$moment(this.value).format('YYYY/MM/DD HH:mm');
        }
      } else {
        return this.axis.defaultLabelFormatter.call(this);
      }
    },

    yAxisRule_beAsIs(index, tickInterval) {
      //依照原樣
      if(this.cloneOptions && this.cloneOptions.yAxis){
        this.options.yAxis[index].max = this.cloneOptions.yAxis[index].max;
        this.options.yAxis[index].min = this.cloneOptions.yAxis[index].min;
        this.options.yAxis[index].tickInterval = this.cloneOptions.yAxis[index].tickInterval;
      }

      //this.options.yAxis[index].tickInterval = tickInterval;
      //let range = this.options.yAxis[index].max - this.options.yAxis[index].min;
      //if (Math.abs(range) / this.options.yAxis[index].tickInterval > 6 || Math.abs(range) / this.options.yAxis[index].tickInterval < 1) {
      //  if (this.showVelocity1Hr || Math.abs(range) * this.options.yAxis[index].tickInterval < 1) {
      //    this.options.yAxis[index].tickInterval = Math.round(range / 5 * 10) / 10;
      //  } else {
      //    this.options.yAxis[index].tickInterval = Math.ceil(range / 5);
      //  }
      //}
      this.options.yAxis[index].startOnTick = this.cloneOptions.yAxis[index].startOnTick;
      this.options.yAxis[index].endOnTick = this.cloneOptions.yAxis[index].endOnTick;
    },
    yAxisRule_beShared(index, unit, tickInterval) {
      //視為使用者的設定，將分享的內容套用到使用者的設定之中
      if(!this.userYAxisOptions || !this.userYAxisOptions[unit]) return;
      if(![null,undefined,NaN,false].includes(this.userYAxisOptions[unit].max)) this.options.yAxis[index].max = this.userYAxisOptions[unit].max;
      if(![null,undefined,NaN,false].includes(this.userYAxisOptions[unit].min)) this.options.yAxis[index].min = this.userYAxisOptions[unit].min;

      if(![null,undefined,NaN,false,0].includes(this.userYAxisOptions[unit].tickInterval)){
        this.options.yAxis[index].tickInterval = this.userYAxisOptions[unit].tickInterval;
      }
      else{
        this.options.yAxis[index].tickInterval = tickInterval;
        let range = this.options.yAxis[index].max - this.options.yAxis[index].min;
        if (Math.abs(range) / this.options.yAxis[index].tickInterval > 6 || Math.abs(range) / this.options.yAxis[index].tickInterval < 1) {
          if (this.showVelocity1Hr || Math.abs(range) * this.options.yAxis[index].tickInterval < 1) {
            this.options.yAxis[index].tickInterval = Math.round(range / 5 * 10) / 10;
          } else {
            this.options.yAxis[index].tickInterval = Math.ceil(range / 5);
          }
        }
      }
      this.options.yAxis[index].startOnTick = this.userYAxisOptions[unit].startOnTick;
      this.options.yAxis[index].endOnTick = this.userYAxisOptions[unit].endOnTick;
    },
    yAxisRule_rescale(index, unit, extremes, tickInterval, unitData) {
      //重置使用者的設定，並在警戒值上下限與自動縮放之間切換
      this.userYAxisOptions[unit] = null;
      if(this.rescaleChartStateMachine){
        this.options.yAxis[index].max = ![null,undefined,NaN,false].includes(unitData.plus)  ? unitData.plus  : extremes.max;
        this.options.yAxis[index].min = ![null,undefined,NaN,false].includes(unitData.minus) ? unitData.minus : extremes.min;
      }
      else{
        this.options.yAxis[index].max = extremes.max;
        this.options.yAxis[index].min = extremes.min;
      }
      this.options.yAxis[index].tickInterval = tickInterval;
      let range = this.options.yAxis[index].max - this.options.yAxis[index].min;
      //console.log(`only test now ${range}`);
      //console.log(`max ${this.options.yAxis[index].max} min ${this.options.yAxis[index].min}`);
      //console.log(`this.showVelocity1Hr || Math.abs(range) * this.options.yAxis[index].tickInterval < 1 ${this.showVelocity1Hr || Math.abs(range) * this.options.yAxis[index].tickInterval < 1}`);
      //console.log(Math.round(range / 5 * 10) / 10);
      //console.log(Math.abs(range) / this.options.yAxis[index].tickInterval);
      if (Math.abs(range) / this.options.yAxis[index].tickInterval > 6 || Math.abs(range) / this.options.yAxis[index].tickInterval < 1) {
        if (this.showVelocity1Hr || Math.abs(range) * this.options.yAxis[index].tickInterval < 1) {
          this.options.yAxis[index].tickInterval = Math.round(range / 5 * 10) / 10;
        } else {
          this.options.yAxis[index].tickInterval = Math.ceil(range / 5);
        }
      }
      this.options.yAxis[index].startOnTick = true;
      this.options.yAxis[index].endOnTick = true;
    },
    yAxisRule_optionPrecedeAlert(index, unit, extremes, tickInterval, unitData) {
      //若有使用者的設定，則使用使用者的設定；若無，則使用警戒值；若無警戒值，則自動調整
      if(this.userYAxisOptions[unit]){
        if(![null,undefined,NaN,false].includes(this.userYAxisOptions[unit].max)){
          this.options.yAxis[index].max = this.userYAxisOptions[unit].max;
        }
        else{
          this.options.yAxis[index].max = ![null,undefined,NaN,false].includes(unitData.plus)  ? unitData.plus  : extremes.max;
        }
        if(![null,undefined,NaN,false].includes(this.userYAxisOptions[unit].min)){
          this.options.yAxis[index].min = this.userYAxisOptions[unit].min;
        }
        else{
          this.options.yAxis[index].min = ![null,undefined,NaN,false].includes(unitData.minus)  ? unitData.minus  : extremes.min;
        }

        if(![null,undefined,NaN,false,0].includes(this.userYAxisOptions[unit].tickInterval)){
          this.options.yAxis[index].tickInterval = this.userYAxisOptions[unit].tickInterval;
        }
        else{
          this.options.yAxis[index].tickInterval = tickInterval;
          let range = this.options.yAxis[index].max - this.options.yAxis[index].min;
          if (Math.abs(range) / this.options.yAxis[index].tickInterval > 6 || Math.abs(range) / this.options.yAxis[index].tickInterval < 1) {
            if (this.showVelocity1Hr || Math.abs(range) * this.options.yAxis[index].tickInterval < 1) {
              this.options.yAxis[index].tickInterval = Math.round(range / 5 * 10) / 10;
            } else {
              this.options.yAxis[index].tickInterval = Math.ceil(range / 5);
            }
          }
        }
        this.options.yAxis[index].startOnTick = this.userYAxisOptions[unit].startOnTick;
        this.options.yAxis[index].endOnTick = this.userYAxisOptions[unit].endOnTick;
      }
      else {
        this.options.yAxis[index].max = ![null,undefined,NaN,false].includes(unitData.plus)  ? unitData.plus  : extremes.max;
        this.options.yAxis[index].min = ![null,undefined,NaN,false].includes(unitData.minus) ? unitData.minus : extremes.min;
        this.options.yAxis[index].tickInterval = tickInterval;
        let range = this.options.yAxis[index].max - this.options.yAxis[index].min;
        if (Math.abs(range) / this.options.yAxis[index].tickInterval > 6 || Math.abs(range) / this.options.yAxis[index].tickInterval < 1) {
          if (this.showVelocity1Hr || Math.abs(range) * this.options.yAxis[index].tickInterval < 1) {
            this.options.yAxis[index].tickInterval = Math.round(range / 5 * 10) / 10;
          } else {
            this.options.yAxis[index].tickInterval = Math.ceil(range / 5);
          }
        }
        this.options.yAxis[index].startOnTick = true;
        this.options.yAxis[index].endOnTick = true;
      }
    },
    yAxisRule_optionPrecedeRescale(index, unit, extremes, tickInterval, unitData) {
      //若有使用者的設定，則使用使用者的設定；若無，按照rescale切換的內容
      
      if(this.userYAxisOptions[unit]){
        //console.log(this.userYAxisOptions[unit]);
        if(![null,undefined,NaN,false].includes(this.userYAxisOptions[unit].max)){
          this.options.yAxis[index].max = this.userYAxisOptions[unit].max;
        }
        else{
          this.options.yAxis[index].max = (![null,undefined,NaN,false].includes(unitData.plus)  ? unitData.plus  : extremes.max);
        }
        if(![null,undefined,NaN,false].includes(this.userYAxisOptions[unit].min)){
          this.options.yAxis[index].min = this.userYAxisOptions[unit].min;
        }
        else{
          this.options.yAxis[index].min = (![null,undefined,NaN,false].includes(unitData.minus)  ? unitData.minus  : extremes.min);
        }

        if(![null,undefined,NaN,false].includes(this.userYAxisOptions[unit].tickInterval)){
          this.options.yAxis[index].tickInterval = this.userYAxisOptions[unit].tickInterval;
        }
        else{
          this.options.yAxis[index].tickInterval = tickInterval;
          let range = this.options.yAxis[index].max - this.options.yAxis[index].min;
          if (Math.abs(range) / this.options.yAxis[index].tickInterval > 6 || Math.abs(range) / this.options.yAxis[index].tickInterval < 1) {
            if (this.showVelocity1Hr || Math.abs(range) * this.options.yAxis[index].tickInterval < 1) {
              this.options.yAxis[index].tickInterval = Math.round(range / 5 * 10) / 10;
            } else {
              this.options.yAxis[index].tickInterval = Math.ceil(range / 5);
            }
          }
        }
        
        this.options.yAxis[index].startOnTick = this.userYAxisOptions[unit].startOnTick;
        this.options.yAxis[index].endOnTick = this.userYAxisOptions[unit].endOnTick;
      }
      else {
        console.log(`test now , max: ${extremes.max} , min: ${extremes.min}`);
        console.log(`plus: ${unitData.plus} , minus: ${unitData.minus} , 狀態機: ${this.rescaleChartStateMachine}`);
        if(this.rescaleChartStateMachine){
          this.options.yAxis[index].max = ![null,undefined,NaN,false].includes(unitData.plus)  ? unitData.plus  : undefined;
          this.options.yAxis[index].min = ![null,undefined,NaN,false].includes(unitData.minus) ? unitData.minus : undefined;
        }
        else{
          this.options.yAxis[index].max = undefined;
          this.options.yAxis[index].min = undefined;
        }
        this.options.yAxis[index].tickInterval = tickInterval;
        let range = this.options.yAxis[index].max - this.options.yAxis[index].min;
        if (Math.abs(range) / this.options.yAxis[index].tickInterval > 6 || Math.abs(range) / this.options.yAxis[index].tickInterval < 1) {
          if (this.showVelocity1Hr || Math.abs(range) * this.options.yAxis[index].tickInterval < 1) {
            this.options.yAxis[index].tickInterval = Math.round(range / 5 * 10) / 10;
          } else {
            this.options.yAxis[index].tickInterval = Math.ceil(range / 5);
          }
        }
        this.options.yAxis[index].startOnTick = true;
        this.options.yAxis[index].endOnTick = true;
      }
    },
  },
};

</script>

<style lang="stylus">
.el-scrollbar__wrap 
  height: 360px !important
.el-cascader__tags .el-tag > span
  flex-basis auto
.el-drawer__body
  overflow auto
.chart-container .el-card__body{
  min-height: 400px;
  padding: 5px !important;
}
.columns-chart{
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex: none;
  width: 100%;
  padding-top: 0px;
  padding-right: 12px;
  padding-bottom: 0px;
  padding-left: 12px;
}

.highcharts-axis-labels {
  font-weight: 700;
  font-size: 13px;
}

.highcharts-grid-top {
  .highcharts-grid-line {
      stroke: #e6e6e6;
    }
}

.highcharts-grid-1 {
  .highcharts-grid-line {
    stroke: #96F8EC;
  }  
}

.highcharts-grid-2 {
  .highcharts-grid-line {
    stroke: #D2B6D2;
  }  
}

.highcharts-grid-3 {
  .highcharts-grid-line {
    stroke: #98F898;
  }  
}

.highcharts-grid-4 {
  .highcharts-grid-line {
    stroke: #FF94A6;
  }  
}

.highcharts-grid-5 {
  .highcharts-grid-line {
    stroke: #F3F196;
  }  
}

.highcharts-WL-plot {
  stroke: #4188ff !important;
  fill: #4188ff;
  stroke-width: 2px;
}

.highcharts-AL-plot {
  stroke: #ffad00 !important;
  fill: #ffad00;
  stroke-width: 2px;
}

.highcharts-WSL-plot {
  stroke: #ff0000 !important;
  fill: #ff0000;
  stroke-width: 2px;
}

.highcharts-tooltip-box .highcharts-label-box {
  fill: #f7f7f7 !important;
  fill-opacity: 0.4 !important;
}

.tooltip-box .highcharts-label-box {
  fill: #f7f7f7 !important;
  fill-opacity: 0.4 !important;
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 1px;
}

</style>

<style lang="stylus" scoped>
.el-input-number
  max-width 150px
.el-form-item__label
  font-weight bold
.el-radio-button.is-active
  z-index 10
.el-row
  margin-bottom 1.5rem
.interval-button
  margin-left 10px
.input-label-right
  margin-left 10px
.button-primary
  color #FFF
  background-color #409EFF
  border-color #409EFF
  box-shadow -1px 0 0 0 #409EFF
.date-range-seperate
  margin 0 1rem
.card-header__bar
  border-style solid
  border-width 4px 0 0
</style>