<template>
  <div class="flex-column">
    <div class="flex-row align-center justify-end mb-16">
      <el-select
        style="margin-right: 12px;"
        v-model="selectedProject"
        filterable
        clearable
        placeholder="選擇專案">
        <el-option
          v-for="project in projectList"
          :key="project._id"
          :label="project.project_id"
          :value="project.area"
        ></el-option>
      </el-select>
      <el-button
        type="primary"
        @click="createArea"
      >
        新增區域
      </el-button>
    </div>
    <enhanced-table
      title="區域列表"
      :table-data="filteredAreaList"
      :col-configs="colConfigs"
    >
      <template v-slot:actions="{ colConfig }">
        <el-table-column v-bind="colConfig">
          <template v-slot="{ row }">
            <div class="flex-row justify-end">
              <el-button
                type="warning"
                size="small"
                @click="editAreaStation(row)"
              >
                設定測站
              </el-button>
              <el-button
                type="danger"
                size="small"
                @click="deleteArea(row)"
              >
                刪除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
    </enhanced-table>
    <el-dialog
      :visible.sync="showAreaStationForm"
      :title="selectedArea.name"
    >
      <area-station-form :area-id="selectedArea._id"></area-station-form>
    </el-dialog>
  </div>
</template>

<script>
import EnhancedTable from '@/components/common/EnhancedTable';
import AreaStationForm from '@/components/area/AreaStationForm';
export default {
  components: {
    EnhancedTable,
    AreaStationForm,
  },
  data() {
    const vm = this;
    return {
      selectedProject: '',
      showAreaStationForm: false,
      selectedArea: '',
      colConfigs: [
        {
          label: '區域名稱',
          prop: 'name',
        },
        {
          label: '測站',
          prop: 'station',
          'show-overflow-tooltip': true,
          formatter(row, column, cellValue) {
            return cellValue
              .map(station => {
                const result = vm.stationList.find(ele => {
                  return ele._id === station;
                });
                if (result) {
                  return result.name;
                } else {
                  return station;
                }
              })
              .join(', ');
          },
        },
        { slot: 'actions' },
      ],
    };
  },
  computed: {
    areaList() {
      return this.$store.getters['data/areaList'];
    },
    stationList() {
      return this.$store.getters['data/stationList'];
    },
    projectList() {
      return this.$store.getters['project/projectList'];
    },
    filteredAreaList() {
      if (this.selectedProject) {
        return this.areaList.filter(area => 
          this.selectedProject.includes(area._id),
        );
      } else {
        return this.areaList;
      }
    },
  },
  mounted() {
    this.getAreaList();
    this.getStationList(true);
  },
  methods: {
    createArea() {
      this.$prompt('請輸入區域名稱', '新增區域', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: this.validateInput,
      })
        .then(({ value }) => {
          return this.$axios({
            category: 'admin',
            method: 'post',
            apiName: 'area',
            data: {
              area: [value],
            },
          })
            .then(() => {
              this.$notifySuccess('區域新增成功');
              this.getAreaList(true);
            })
            .catch(this.$notifyError);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          });
        });
    },
    validateInput(val) {
      if (!val) {
        return '請輸入區域名稱';
      } else return true;
    },
    editAreaStation(row) {
      this.selectedArea = row;
      this.showAreaStationForm = true;
    },
    async deleteArea(row) {
      const response = await this.$confirmDelete('此操作將刪除此區域');
      if (response === 'confirm') {
        this.$axios({
          category: 'admin',
          method: 'delete',
          apiName: `area/${row._id}`,
        })
          .then(() => {
            this.$notifySuccess('刪除成功');
            this.getAreaList(true);
          })
          .catch(this.$notifyError);
      }
    },
    getStationList(isUpdate = false) {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getStationList', isUpdate)
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    getAreaList(isUpdate = false) {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getAreaList', isUpdate)
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

