import Vue from 'vue';
import VueI18n from 'vue-i18n';
import numberFormats from './numberFormats';
import ElementUiZhTwLocale from 'element-ui/lib/locale/lang/zh-TW';
import ElementUiEnLocale from 'element-ui/lib/locale/lang/en';

import zhTW from './lang/zh';
import enUS from './lang/en';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'zh-TW',
  messages: {
    'zh-TW': {
      ...zhTW,
      ...ElementUiZhTwLocale,
    },
    'en-US': {
      ...enUS,
      ...ElementUiEnLocale,
    },
  },
  numberFormats,
});

export default i18n;
