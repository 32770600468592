<template>
  <div class="column">
    <el-card>
      <el-button @click="newInstrumentType">
        新增儀器類型
      </el-button>
      <el-button @click="uploadInstrumentType">
        上傳儀器類型
      </el-button>
    </el-card>
    <el-card>
      <hot-table id="hot-container" ref="hot" root="hot" :settings="settings" />
    </el-card>
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue';
import moment from 'moment';
export default {
  components: {
    HotTable,
  },
  computed: {
    settings() {
      const vm = this;
      return {
        data: this.instrumentTypeList,
        fixedColumnsLeft: 1,
        colHeaders: this.columnsHeader,
        rowHeaders: true,
        manualRowResize: true,
        manualColumnResize: true,
        manualRowMove: true,
        customBorders: true,
        columnSorting: true,
        height: 600,
        width: '100%',
        columns: this.columns,
        licenseKey: 'non-commercial-and-evaluation',
        async beforeChange(changes, source) {
          // [[row, prop, oldVal, newVal], ...]
          //changes[0][3] = 10;
          for(let change of changes){
            if(change[1]=='startDate'){
              if(!isNaN(Number(change[3])) || isNaN(new Date(change[3]))){
                vm.$notifyError('請輸入正確的日期');
                change[3] = '';
              }
              else{
                change[3] = moment(change[3]).format('yyyy-MM-DD');
              }
            }
          }
        },
      };
    },
  },
  data(){
    return {
      instrumentTypeList: [],
      columnsHeader: [
        '儀器名稱',
        '初值',
        'A+initial',
        'A-initial',
        'A+',
        'A-',
        '位移量',
        'T0',
        'K',
        'F0',
        'F1',
        'F2',
        'F3',
        'F4',
        'F5',
        '深度',
        '公式',
      ],
      columns: [
        {
          data: 'name',
        },
        {
          data: 'R0',
          type: 'checkbox',
        },
        {
          data: 'A+initial',
          type: 'checkbox',
        },
        {
          data: 'A-initial',
          type: 'checkbox',
        },
        {
          data: 'A+',
          type: 'checkbox',
        },
        {
          data: 'A-',
          type: 'checkbox',
        },
        {
          data: 'offset',
          type: 'checkbox',
        },
        {
          data: 'T0',
          type: 'checkbox',
        },
        {
          data: 'K',
          type: 'checkbox',
        },
        {
          data: 'F0',
          type: 'checkbox',
        },
        {
          data: 'F1',
          type: 'checkbox',
        },
        {
          data: 'F2',
          type: 'checkbox',
        },
        {
          data: 'F3',
          type: 'checkbox',
        },
        {
          data: 'F4',
          type: 'checkbox',
        },
        {
          data: 'F5',
          type: 'checkbox',
        },
        {
          data: 'depth',
          type: 'checkbox',
        },
        {
          data: 'info',
        },
      ],
    };
  },
  mounted(){
    this.getInstrumentType();
  },
  methods: {
    async getInstrumentType(){
      const res = await this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'instrument_type',
      });
      this.instrumentTypeList = res.data;
      //console.log(this.instrumentTypeList);
      this.updateTable();
    },
    newInstrumentType(){
      this.instrumentTypeList.push({
        manual: true,
      });
    },
    async uploadInstrumentType(){
      let instrumentTypeList = this.instrumentTypeList.filter(ins=>{
        let isnull = Object.keys(ins).length == 0;
        return !isnull;
      });
      await this.$axios({
        category: 'admin',
        method: 'post',
        apiName: 'instrument_type',
        data: {
          instrumentTypeList,
        },
      });
      await this.getInstrumentType();
      this.$notifySuccess('新增成功');
    },
    updateTable() {
      setTimeout(() => {
        this.$refs.hot.hotInstance.loadData(this.instrumentTypeList);
      }, 100);  
    },
  },
};
</script>
  
<style>

</style>