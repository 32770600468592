<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" :inline="true" label-position="top">
      <el-card class="mb-16" v-loading="isLoading || isUploading">
        <el-row>
          <el-form-item required label="專案">
            <el-select
              v-model="form.project"
              filterable
              :loading="loadingProject"
              @change="onProjectChange"
            >
              <el-option
                v-for="item in projectList"
                :key="item._id"
                :label="item.project_id"
                :value="item._id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="儀器組" v-if="formType === 'edit'">
            <el-select
              v-model="form.instrumentGroup"
              filterable
              :loading="isLoading"
              @change="setInstrumentGroupForm"
            >
              <el-option
                v-for="item in instrumentGroupList"
                :key="item._id"
                :label="item.name"
                :value="item._id"
              />
            </el-select>
          </el-form-item>
          <!-- <el-form-item required label="儀器">
            <el-select
              v-model="form.instrumentList"
              :loading="loadingInstrumentList"
              :disabled="!instrumentList"
              filterable
              multiple
            >
              <el-option
                v-for="instrument in instrumentList"
                :key="instrument._id"
                :label="instrument.latestVersion.name"
                :value="instrument._id"
              />
            </el-select>
          </el-form-item> -->
        </el-row>
        <div class="mb-16" v-if="form.project">
          <el-row>
            <el-form-item label="儀器組名稱" required prop="name">
              <el-input v-model="form.name" placeholder="請輸入儀器組名稱"></el-input>
            </el-form-item>
            <el-form-item label="類型" prop="type">
              <el-select v-model="form.type">
                <el-option
                  v-for="type in typeList"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="x軸最小深度" prop="xAxisMin">
              <el-input-number v-model="form.xAxis.min" label="最小深度"></el-input-number>
            </el-form-item>
            <el-form-item label="x軸最大深度" prop="xAxisMax">
              <el-input-number v-model="form.xAxis.max" label="最大深度"></el-input-number>
            </el-form-item>
            <el-form-item label="x軸單位" prop="unit">
              <el-input v-model="form.xAxis.unit" label="單位"></el-input>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="y軸最小深度" prop="yAxisMin">
              <el-input-number v-model="form.yAxis.min" label="最小深度"></el-input-number>
            </el-form-item>
            <el-form-item label="y軸最大深度" prop="yAxisMax">
              <el-input-number v-model="form.yAxis.max" label="最大深度"></el-input-number>
            </el-form-item>
            <el-form-item label="y軸單位" prop="unit">
              <el-input v-model="form.yAxis.unit" label="單位"></el-input>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="快速匯入儀器">
              <el-upload
                class="upload-demo"
                drag
                action=""
                multiple
                :show-file-list="false"
                :before-upload="handleUpload"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row>
            <el-collapse v-model="activePanel">
              <el-collapse-item title="儀器列表" name="1">
                <!-- <draggable v-model="form.instrumentList"> -->
                <el-row
                  v-for="(instrument, index) in form.instrumentList"
                  class="mb-16"
                  :key="index"
                >
                  <el-select
                    v-model="form.instrumentList[index].instrument"
                    filterable
                    :disabled="!form.project"
                  >
                    <el-option
                      v-for="instrument in instrumentList"
                      :key="instrument._id"
                      :label="instrument.latestVersion.name"
                      :value="instrument._id"
                    />
                  </el-select>
                  <el-input-number
                    v-model="form.instrumentList[index].depth"
                    label="深度"
                    class="ml-16"
                  >
                    深度
                  </el-input-number>
                  <el-button
                    v-if="index === form.instrumentList.length - 1"
                    class="ml-16"
                    icon="el-icon-plus"
                    type="primary"
                    circle
                    @click="addNewInstrumentToInstrumentSet"
                  ></el-button>
                  <el-button
                    class="ml-16"
                    icon="el-icon-minus"
                    type="danger"
                    circle
                    @click="removeInstrumentFromInstrumentSet(index)"
                  ></el-button>
                </el-row>
                <div
                  class="flex-row align-center justify-center"
                  v-show="form.instrumentList.length == 0"
                >
                  <span>目前沒有儀器</span>
                  <el-button class="ml-16" type="primary" @click="addNewInstrumentToInstrumentSet">
                    新增儀器
                  </el-button>
                </div>
                <!-- </draggable> -->
              </el-collapse-item>
            </el-collapse>
            <!-- <draggable>
              <div
                v-for="(instrument, index) in form.instrumentList"
                :key="index"
              >
                {{ instrument }}
              </div>
            </draggable> -->
          </el-row>
        </div>
        <el-row type="flex" justify="end">
          <el-button v-if="formType === 'create'" type="primary" @click="onSubmitForm">
            建立
          </el-button>
          <template v-else>
            <el-button type="danger" @click="deleteInstrumentGroup">
              刪除
            </el-button>
            <el-button type="primary" @click="onSubmitForm">
              更新
            </el-button>
          </template>
          <!-- <el-button v-show="activeStep > 0" @click="prevStep">上一步</el-button>
          <el-button type="primary" @click="nextStep">下一步</el-button> -->
        </el-row>
      </el-card>
    </el-form>
  </div>
</template>

<script>
// import { coverObject } from '@/lib/base/helper';
// import draggable from 'vuedraggable';
import XLSX from 'xlsx';

const typesTemplate = {
  VERTICAL_DISTRIBUTION: {
    instrument: null,
    depth: 0,
  },
  HORIZONTAL_DISTRIBUTION: {
    instrument: null,
    depth: 0,
  },
};
export default {
  props: {
    formType: {
      type: String,
      required: true,
    },
  },
  components: {
    // draggable,
  },
  data() {
    return {
      isLoading: false,
      isUploading: false,
      loadingProject: false,
      loadingInstrumentList: false,
      selectedInstrument: null,
      activeStep: 0,
      activePanel: '1',
      form: {
        project: null,
        instrumentGroup: null,
        type: '',
        name: '',
        yAxis: {
          min: 0,
          max: 10,
          unit: '',
        },
        xAxis: {
          min: 0,
          max: 10,
          unit: '',
        },
        instrumentList: [],
      },
      rules: {
        name: [{ required: true, message: '請輸入儀器名稱' }],
        type: [{ required: true, message: '請選擇儀器組類型' }],
        xAxisMax: [{ validator: this.checkAxis }],
        xAxisMin: [{ validator: this.checkAxis }],
        yAxisMax: [{ validator: this.checkAxis }],
        yAxisMin: [{ validator: this.checkAxis }],
      },
      instrumentList: [],
      instrumentGroupList: [],
      selectedInstrumentGroup: null,
      typeList: [
        {
          label: '垂直分佈曲線',
          value: 'VERTICAL_DISTRIBUTION',
        },
        {
          label: '水平分佈曲線',
          value: 'HORIZONTAL_DISTRIBUTION',
        },
      ],
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    projectList() {
      return this.$store.getters['project/projectList'];
    },
  },
  mounted() {
    // this.addNewInstrumentToInstrumentSet();
    this.form.project = this.selectedProject._id;
    this.getProjectInstrumentListWithLatestVersion();
    if (this.formType === 'edit') {
      this.getInstrumentGroupList();
    }
  },
  methods: {
    checkAxis(rule, value, callback) {
      if (rule.field === 'xAxisMax') {
        if (this.form.xAxis.max <= this.form.xAxis.min) {
          callback(new Error('不能小於最小深度'));
        }
      } else if (rule.field === 'yAxisMax') {
        if (this.form.yAxis.max <= this.form.yAxis.min) {
          callback(new Error('不能小於最小深度'));
        }
      }
      callback();
    },
    getInstrumentGroupList() {
      this.$store
        .dispatch('data/getProjectInstrumentGroupList', {
          projectId: this.form.project,
        })
        .then(res => {
          this.instrumentGroupList = res;
        })
        .catch(this.$notifyError);
    },
    onProjectChange() {
      if (this.formType === 'create') {
        this.getProjectInstrumentListWithLatestVersion();
      } else {
        this.getInstrumentGroupList();
      }
    },
    onSubmitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const totalInstrumentCount = this.form.instrumentList.length;
          if (totalInstrumentCount === 0) {
            return this.$message.error('請至少設置一個儀器');
          } else {
            if (this.formType === 'create') {
              this.createInstrumentGroup();
            } else {
              this.updateInstrumentGroup();
            }
          }
        } else {
          return false;
        }
      });
    },
    async deleteInstrumentGroup() {
      this.isLoading = true;
      const response = await this.$confirmDelete('此操作將永久刪除該儀器組');
      if (response === 'confirm') {
        return this.$axios({
          category: 'admin',
          method: 'delete',
          apiName: `project/${this.form.project}/instrument-group/${this.selectedInstrumentGroup}`,
        })
          .then(() => {
            this.$message.success('刪除成功!');
            this.getInstrumentGroupList();
            this.form.instrumentGroup = this.instrumentGroupList[0]._id;
            this.setInstrumentGroupForm();
          })
          .catch(this.$notifyError)
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    async createInstrumentGroup() {
      const totalInstrumentCount = this.form.instrumentList.length;
      const response = await this.$confirm(
        `確認要建立儀器組嗎? (共${totalInstrumentCount}個儀器)`,
        '提示',
      );
      if (response === 'confirm') {
        this.isLoading = true;
        return this.$axios({
          category: 'admin',
          method: 'post',
          apiName: `project/${this.form.project}/instrument-group`,
          data: this.form,
        })
          .then(() => {
            this.$message.success('成功!');
          })
          .catch(this.$notifyError)
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    async updateInstrumentGroup() {
      const response = await this.$confirm('確認要更新儀器組嗎?', '提示');
      if (response === 'confirm') {
        this.isLoading = true;
        return this.$axios({
          category: 'admin',
          method: 'put',
          apiName: `project/${this.form.project}/instrument-group/${this.selectedInstrumentGroup}`,
          data: this.form,
        })
          .then(() => {
            this.$message.success('更新成功!');
            this.getInstrumentGroupList();
          })
          .catch(this.$notifyError)
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setInstrumentGroupForm() {
      const instrumentGroup = this.instrumentGroupList.find(
        group => group._id === this.form.instrumentGroup,
      );
      const { _id, name, type, instrumentList, xAxis, yAxis } = instrumentGroup;
      const data = {
        name,
        type,
        instrumentList,
        xAxis,
        yAxis,
      };
      Object.assign(this.form, data);
      this.selectedInstrumentGroup = _id;
    },
    handleUpload(file) {
      this.isUploading = true;
      this.parseExcelToJson(file)
        .then(res => {
          const uploadedData = res;
          const formattedData = uploadedData.map(data => {
            const instrumentDetails = this.instrumentList.find(instrument => {
              return instrument.latestVersion.name === data.name;
            });
            if (instrumentDetails) {
              return {
                depth: data.depth,
                instrument: instrumentDetails._id,
              };
            } else {
              return {
                depth: data.depth,
                instrument: null,
              };
            }
          });
          this.form.instrumentList = this.$lodash.cloneDeep(formattedData);
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
    parseExcelToJson(file) {
      // const vm = this
      return new Promise(function(resolve) {
        const reader = new FileReader();
        reader.onload = function(e) {
          const data = e.target.result;
          const workbook = XLSX.read(data, {
            type: 'array',
          });
          const result = workbook.SheetNames.map(sheetName => {
            return {
              sheetName,
              content: XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                range: 1,
              }),
            };
          });
          resolve(result[0].content);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    prevStep() {
      this.activeStep = this.activeStep - 1;
    },
    nextStep() {
      this.activeStep = this.activeStep + 1;
    },
    addNewInstrumentToInstrumentSet() {
      const object = this.$lodash.cloneDeep(typesTemplate[this.form.type]);
      this.form.instrumentList.push(object);
    },
    removeInstrumentFromInstrumentSet(index) {
      this.form.instrumentList.splice(index, 1);
    },
    getProjectInstrumentListWithLatestVersion(isUpdate = false) {
      this.isLoading = true;
      // if (this.instrumentList[this.form.project]) return;
      // this.loadingInstrumentList = true;
      this.selectedInstrument = null;
      this.selectedInstrumentVersion = null;
      // this.resetFormData();
      return this.$store
        .dispatch('data/getProjectInstrumentListWithLatestVersion', {
          projectId: this.form.project,
          isUpdate,
        })
        .then(res => {
          this.instrumentList = res;
          // this.instrumentList[this.form.project] = res;
          // coverObject(this.form, res);
        })
        .finally(() => {
          this.isLoading = false;
          // this.loadingInstrumentList = false;
        });
    },
  },
};
</script>
