// import moment from 'moment';
import { saveLocalData } from '@/lib/base/localData';
import { callAxios } from '@/lib/api/axios';

export const getters = {
  projectList(state) {
    return state.projectList;
  },
  selectedProject(state) {
    return state.selectedProject;
  },
  hasRemarkAccess(state, getters, rootState) {
    const userRoles = rootState.user.details.roles;
    if (userRoles === 'admin') return true;
    const userId = rootState.user.details._id;
    const hasRemarkAccess = state.selectedProject.access.remark.includes(
      userId,
    );
    return hasRemarkAccess;
  },
};

export const state = {
  projectList: [],
  selectedProject: null,
};

export const actions = {
  getProjectList({ commit, dispatch, state }) {
    return callAxios({
      category: 'project',
      method: 'get',
      apiName: '',
    }).then(res => {
      const projectList = res.data;
      commit('setProjectList', projectList);
      saveLocalData('projectList', projectList);
      if (!state.selectedProject && projectList.length > 0) {
        dispatch('setSelectedProject', projectList[0]._id);
      } else {
        if (state.selectedProject._id) {
          dispatch('setSelectedProject', state.selectedProject._id);
        }
      }
      return res.data;
    });
  },
  setSelectedProject({ commit, state, rootGetters }, projectId) {
    const isAdmin = rootGetters['user/isAdmin'];
    let project = state.projectList.find(project => project._id === projectId);
    if (!project) {
      project = state.projectList[0];
    }
    if (!isAdmin) {
      callAxios({
        category: 'log',
        method: 'post',
        apiName: '',
        data: {
          type: 'view_project',
          message: `查看了${project.name}`,
        },
      });
    }
    commit('setSelectedProject', project);
  },
};

export const mutations = {
  setSelectedProject(state, data) {
    state.selectedProject = data;
    saveLocalData('selectedProject', state.selectedProject);
  },
  setProjectList(state, data) {
    state.projectList = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
