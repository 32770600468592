const coverObject = (originalObject, object) => {
  const keyList = Object.keys(originalObject);
  keyList.forEach(key => {
    if (object[key]) {
      originalObject[key] = object[key];
    }
  });
  return originalObject;
};

export { coverObject };
