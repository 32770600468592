const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
  },
  'zh-TW': {
    currency: {
      style: 'currency',
      currency: 'TWD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
  'zh-CN': {
    currency: {
      style: 'currency',
      currency: 'CNY',
    },
  },
};
export default numberFormats;
