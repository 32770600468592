<template>
  <v-chart :options="chartOptions" :auto-resize="true"></v-chart>
</template>

<script>
import colorPalette from '@/constants/colorPalette.js';

const getCurveLineFromValue = (x, y, splitCount) => {
  let result = [];
  for (let i = 0; i < splitCount; i++) {
    const currentX = x * Math.cos((Math.PI / 180) * i);
    const currentY = y * Math.sin((Math.PI / 180) * i);
    result.push([currentX, currentY]);
  }
  return result;
};

const alertStandard = {
  threeHourRainFallIntercept: 25,
  oneDayRainFallIntercept: 190,
};

const WSLStandard = {
  threeHourRainFallIntercept: 40,
  oneDayRainFallIntercept: 285,
};

const curveLineData = {
  alert: getCurveLineFromValue(
    alertStandard.oneDayRainFallIntercept,
    alertStandard.threeHourRainFallIntercept,
    90,
  ),
  WSL: getCurveLineFromValue(
    WSLStandard.oneDayRainFallIntercept,
    WSLStandard.threeHourRainFallIntercept,
    90,
  ),
};

export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
  },
  computed: {
    seriesData() {
      if (!this.data.length) return [];
      return this.data.map(event => {
        const data = event.outputs.map(output => {
          const {
            oneDayAccumulatedRainFall,
            threeHourMeanRainFall,
            TIMESTAMP,
            threeHourRainFallIntercept,
            oneDayRainFallIntercept,
          } = output;
          const value = [
            oneDayAccumulatedRainFall,
            threeHourMeanRainFall,
            this.$moment(TIMESTAMP).format('YYYY-MM-DD HH:mm'),
            threeHourRainFallIntercept,
            oneDayRainFallIntercept,
          ];
          if (
            threeHourRainFallIntercept > WSLStandard.threeHourRainFallIntercept ||
            oneDayRainFallIntercept > WSLStandard.oneDayRainFallIntercept
          ) {
            return {
              value,
              itemStyle: {
                color: colorPalette.COLOR_RED,
              },
            };
          } else if (
            threeHourRainFallIntercept > alertStandard.threeHourRainFallIntercept ||
            oneDayRainFallIntercept > alertStandard.oneDayRainFallIntercept
          ) {
            return {
              value,
              itemStyle: {
                color: colorPalette.COLOR_YELLOW,
              },
            };
          } else {
            return {
              value,
              itemStyle: {
                color: colorPalette.COLOR_GREEN,
              },
            };
          }
        });
        return {
          name: event.instrument_id.data[0].name,
          type: 'line',
          data,
        };
      });
    },
    chartOptions() {
      return {
        legend: {
          data: this.data.map(event => {
            return event.instrument_id.data[0].name;
          }),
          verticalAlign: 'bottom',
        },
        xAxis: {
          min: 0,
          // max: 450,
          max: 500,
          // scale: false,
          name: '24小時累積雨量',
          nameLocation: 'middle',
        },
        yAxis: {
          min: 0,
          max: 60,
          // scale: false,
          name: '3小時平均雨量',
        },
        dataZoom: [
          {
            startValue: 0,
            bottom: '0%',
            type: 'inside',
          },
        ],
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
            },
            restore: {},
            saveAsImage: {},
          },
        },
        tooltip: {
          axisPointer: {
            type: 'cross',
            animation: false,
            // label: {
            //   backgroundColor: '#505765',
            // },
          },
          formatter(params) {
            let target = params.data;
            if (!Array.isArray(params.data)) {
              target = params.data.value;
            }
            const [
              oneDayAccumulatedRainFall,
              threeHourMeanRainFall,
              TIMESTAMP,
              threeHourRainFallIntercept,
              oneDayRainFallIntercept,
            ] = target;
            return (
              TIMESTAMP +
              '<br>3小時平均雨量: ' +
              threeHourMeanRainFall.toFixed(4) +
              '<br>24小時累計雨量: ' +
              oneDayAccumulatedRainFall.toFixed(4) +
              '<br>3小時平均雨量截距: ' +
              threeHourRainFallIntercept.toFixed(4) +
              '<br>24小時累計雨量截距: ' +
              oneDayRainFallIntercept.toFixed(4)
            );
          },
        },
        series: [
          ...this.seriesData,
          // {
          //   type: 'line',
          //   data: this.seriesData3,
          // },
          {
            data: curveLineData.alert,
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: { color: colorPalette.COLOR_YELLOW },
            tooltip: {
              show: false,
            },
          },
          {
            data: curveLineData.WSL,
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: { color: colorPalette.COLOR_RED },
            tooltip: {
              show: false,
            },
          },
        ],
        backgroundColor: '#FFFFFF',
      };
    },
  },
};
</script>
