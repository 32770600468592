const main_sidebar = {
  summary: false,
  snakeLine: false,
  curve: true,
  map: true,
  layout: true,
  data: true,
  chart: true,
  export: true,
  dashboard: true,
  monitor: true,
  slope: false,
  storm: false,
  alerts: false,
  occ:false,
};

const thsr_sidebar = {
  summary: false,
  snakeLine: false,
  curve: false,
  map: false,
  layout: false,
  data: false,
  chart: false,
  export: false,
  dashboard: true,
  monitor: true,
  slope: true,
  storm: true,
  alerts: true,
  occ:true,
};

// 2023/12/11 目前更新至33faf63..版，分布曲線介面調整
// 2024/01/09 目前更新至b25d011..版，chart圖調整
// 2024/01/11 目前更新至0a112f2..版，chart圖再調整
// 2024/01/11 目前更新至c0b8f33..版，地圖更新
// 2024/01/11 目前更新至f103b66..版，地圖再調整
// 2024/01/17 目前更新至d0eba2e..版，chart加高
// 2024/02/02 目前更新至da6af20..版，OCC人員可視事件紀錄
// 2024/02/02 目前更新至fd755a2..版，chart圖調整
// 2024/02/02 目前更新至65b0ffe..版，OCC人員可視chart
// 2024/02/06 目前更新至3fc00ac..版，新增OCC聯絡人
// 2024/02/07 目前更新至9dd9e23..版，更新OCC聯絡人內容
// 2024/02/16 目前更新至b7ed306..版，修復各種bug與更新OCC/Log頁面
// 2024/02/26 目前更新至915faba..版，修復缺乏advanced類別API導致的問題
// 2024/02/26 目前更新至ad72dd5..版，新增advanced獲取選取的專案下儀器的手段
// 2024/02/26 目前更新至276390c..版，bug修復
// 2024/02/26 目前更新至448263f..版，bug修復
// 2024/02/26 目前更新至5bde462..版，bug修復
// 2024/02/27 1.14.0 更新至721fde5..版，更新log頁面，並添加每3月要求更新密碼之功能
// 2024/02/27 1.14.1 更新至1ffb8ec..版，優化log頁面
// 2024/02/29 1.14.2 更新至aaeda49..版，使advOption可見所選專案的測站/區域
// 2024/02/29 1.14.3 更新至7eefbe1..版，非admin/noiot人員會屏蔽advOption選項
// 2024/03/01 1.14.4 更新至0f42c16..版，bug修復，並新增了occ區域燈號
// 2024/03/01 1.14.5 更新至760721d..版，移除了修改暴雨系統密碼的API
// 2024/03/01 1.14.6 更新至412c7c5..版，修復OCC的bug
// 2024/03/07 1.14.7 更新至e1f3dcc..版，儀錶板顯示GIS名稱
// 2024/03/18 1.14.8 更新至e481115..版，大改chart版面設計
// 2024/03/18 1.14.9 更新至5608433..版，調整英文譯名
// 2024/03/18 1.14.10 更新至4f7a7c2..版，調整export頁面
// 2024/03/27 1.14.11 更新至b64b3ca..版，chart/curve更新
// 2024/03/27 1.14.11 更新至aa3023a..版，bug fix
// 2024/03/27 1.14.12 更新至a33d7ea..版，可以設定任意專案地圖起始點
// 2024/03/28 1.14.13 更新至3a7af59..版，curve UI調整與bug fix
// 2024/03/28 1.14.14 更新至c4fce3d..版，水平向curve UI修正
// 2024/04/01 1.14.15 更新至563388a..版，bug修正
// 2024/04/01 1.14.16 更新至ed90c16..版，bug修正
// 2024/04/01 1.14.17 更新至900faec..版，時差bug修正
// 2024/04/01 1.14.18 更新至d235d1c..版，chart table bug修正
// 2024/04/01 1.14.19 更新至308c640..版，chart輸出圖片與chart比例相符
// 2024/04/08 1.14.20 更新至394db1e..版，按鈕顏色調整
// 2024/04/19 1.14.21 更新至84f3f10..版，修復一些錯誤
// 2024/04/19 1.14.22 更新至df6e2d9..版，修復一些錯誤
// 2024/04/25 1.14.23 更新至df03edc..版，修復雨量計顯示表格無內容之錯誤
// 2024/04/25 1.14.24 更新至78148d5..版，使使用者無法使用進階選項新增專案
// 2024/05/15 1.14.25 更新至ed6ffde..版，調整地圖instrument status顯示的內容
// 2024/05/23 1.14.26 更新至ef194a4..版，使endOnTick可以順利反映
// 2024/05/28 1.14.27 更新至firebase.json，新增headers
// 2024/05/28 1.14.28 更新至firebase.json，調整CSP
// 2024/06/03 1.14.29 更新至86a9bd0..版，大改數據排名表
// 2024/06/24 1.14.30 更新至0cbafd1..版，修復特定情況下chart調整上下線時沒有反應之問題
// 2024/07/01 1.14.31 更新至61be0ab..版，curve新增auto scale
// 2024/07/04 1.14.32 更新至151b56d..版，Curve與Snakeline設定白色背景
// 2024/07/15 1.14.33 更新至53b01cf版，shpjs相關版本升級
// 2024/07/16 1.14.34 更新至e69b935版，highcharts版本升級
// 2024/07/17 1.14.35 更新至4f0c6a0版，highcharts棄用option調整畫面，改用css+className+styleMode調整畫面
// 2024/07/17 1.14.36 更新至517d4a4版，禁用聯集選擇器的hover模式
// 2024/08/01 1.14.37 更新至f83c612版，更新儀器排行表新增功能，地圖優化
// 2024/08/06 1.14.38 更新至d962301版，更新預設頁面與噪音計
// 2024/09/02 1.15.01 更新至7270362版，大更新，新增給儀器設定計算function的功能，並調整一些chart的顯示
// 2024/09/12 1.15.02 更新至d87bdb8版，調整噪音計與地圖雨量透明的顯示

const version = '1.15.02';

module.exports = {
  version,
  //clientVersion: 'thsr-main',
  clientVersion: 'main',
  //clientVersion: 'thsr-rain',
  //clientVersion: 'test',
  //sidebar: thsr_sidebar,
  sidebar: main_sidebar,
  navbar: {
    useChanger: false,
    changerType: 'storm',
    //changerType: 'slope',
  },
  //apiUrl: 'https://api-thsr-main.geosupply.com.tw/',
  //apiUrl: 'https://api-thsr-rain.geosupply.com.tw/',
  apiUrl: 'https://api.geosupply.com.tw',
  //apiUrl: 'http://192.168.1.52:8080/',
  //apiUrl: 'http://127.0.0.1:3000/',
};
