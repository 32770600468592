<template>
  <div v-loading="isLoading">
    <el-card class="flex-row align-center justify-center" v-if="imageList && imageList.length" type="border-card">
      <image-marker :file="imageList[imageIndex]" :disable-edit="true"/>
    </el-card>
    <el-card v-else>
      <div class="flex-row align-center justify-center" style="min-height: 400px;">
        <div class="content">
          <h3>{{ $t('data.none') }}</h3>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import ImageMarker from '@/components/pin/ImageMarker';
export default {
  components: {
    ImageMarker,
  },
  props:{
    imageIndex: {
      type: Number,
      required: true,
    },
    imageList: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      isLoading: true,
      activeTab: '1',
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
  mounted() {
    if(!this.imageIndex) this.imageIndex = 0;
    this.isLoading = false;
  },
  beforeDestroy() {
    clearInterval(this.refreshFunction);
  },
};
</script>