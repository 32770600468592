<template>
  <div>
    <el-card v-if="!$device.mobile">
      <project-details></project-details>
    </el-card>
    <section
      class="section"
      v-else
    >
      <project-details></project-details>
    </section>
  </div>
</template>

<script>
export default {
  components: {
    ProjectDetails: () => import('@/components/Details'),
  },
};
</script>


<style lang="stylus" scoped>
.section
  padding-top 1rem
</style>
