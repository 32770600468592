<template>
  <el-dialog
    :visible="visible"
    :before-close="closeModal"
    title="錯誤"
    width="700px"
  >
    <enhanced-table
      :table-data="showList"
      :col-configs="colConfigs"
      :show-search="false"
      :sortable="false"
    />
    <!-- <div class="flex-row align-center justify-end">
      <el-button @click="closeModal">
        取消
      </el-button>
      <el-button
        @click="submitBatchCreate" 
        type="primary"
      >
        新增
      </el-button>
    </div> -->
  </el-dialog>
</template>

<script>
import EnhancedTable from '@/components/common/EnhancedTable';
// import { convertChartType } from '@/lib/chart'
export default {
  components: {
    EnhancedTable,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    instrumentList: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    // const vm = this
    return {
      isLoading: true,
      colConfigs: [
        {
          label: '行數',
          prop: 'rowNumber',
        },
        {
          label: '名稱',
          prop: 'name',
          'min-width': '100px',
        },
        {
          label: '錯誤訊息',
          prop: 'errorMessage',
        },
        // {
        //   label: '圖表類型',
        //   prop: 'chart_type',
        //   'min-width': '100px',
        //   formatter: (row, column, cellValue) => {
        //     return convertChartType(cellValue)
        //   }
        // },
        // {
        //   label: 'DAT名稱',
        //   prop: 'document_name',
        //   'min-width': '100px'
        // },
        // {
        //   label: '參考值',
        //   prop: 'reference',
        //   'min-width': '100px'
        // },
        // {
        //   label: '小數點長度',
        //   prop: 'decimal_points',
        //   'min-width': '100px'
        // },
        // {
        //   label: '單位',
        //   prop: 'unit',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'R0',
        //   prop: 'R0',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'offset',
        //   prop: 'offset',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'F0',
        //   prop: 'F0',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'F1',
        //   prop: 'F1',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'F2',
        //   prop: 'F2',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'F3',
        //   prop: 'F3',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'F4',
        //   prop: 'F4',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'F5',
        //   prop: 'F5',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'T0',
        //   prop: 'T0',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'K',
        //   prop: 'K',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'WL+',
        //   prop: 'WL_plus',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'WL-',
        //   prop: 'WL_minus',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'AL+',
        //   prop: 'AL_plus',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'AL-',
        //   prop: 'AL_minus',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'WSL+',
        //   prop: 'WSL_plus',
        //   'min-width': '100px'
        // },
        // {
        //   label: 'WSL-',
        //   prop: 'WSL_minus',
        //   'min-width': '100px'
        // },
        // {
        //   label: '座標',
        //   prop: 'coordinates',
        //   'min-width': '100px'
        // },
        // {
        //   label: '開始時間',
        //   prop: 'startDate',
        //   'min-width': '100px',
        //   formatter(row, column, cellValue) {
        //     return vm.$moment(cellValue).format('YYYY/MM/DD HH:mm')
        //   }
        // },
        // {
        //   label: '簡訊故障',
        //   prop: 'SMS-Fail',
        //   'min-width': '100px',
        //   formatter(row, column, cellValue) {
        //     return vm.convertSmsActive(cellValue)
        //   }
        // },
        // {
        //   label: '簡訊（WL）',
        //   prop: 'SMS-WL',
        //   'min-width': '100px',
        //   formatter(row, column, cellValue) {
        //     return vm.convertSmsActive(cellValue)
        //   }
        // },
        // {
        //   label: '簡訊（AL）',
        //   prop: 'SMS-AL',
        //   'min-width': '100px',
        //   formatter(row, column, cellValue) {
        //     return vm.convertSmsActive(cellValue)
        //   }
        // },
        // {
        //   label: '簡訊（WSL）',
        //   prop: 'SMS-WSL',
        //   'min-width': '120px',
        //   formatter(row, column, cellValue) {
        //     return vm.convertSmsActive(cellValue)
        //   }
        // }
      ],
    };
  },
  computed: {
    showList() {
      return this.instrumentList.map(ele => {
        const { hasError, errorMessage, rowNumber } = ele;
        return {
          name: ele.data[0].name,
          hasError,
          errorMessage,
          rowNumber,
        };
      });
    },
  },
  methods: {
    submitBatchCreate() {
      this.$emit('submit');
    },
    convertSmsActive(val) {
      return val === 'O' ? '開啟' : '關閉';
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
