<template >
  <div class="auth-container is-flex justify-center align-center">
    <div class="auth-form">
      <el-card>
        <client-form></client-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import ClientForm from '@/components/client/edit.vue';
export default {
  components: {
    ClientForm,
  },
};
</script>

<style lang="stylus" scoped>
.auth-container
  height 100%
.auth-form
  max-width 600px
  width 100%
.no-border
  border-width 0
</style>
