<template >
  <div class="auth-container is-flex justify-center align-center">
    <div class="auth-form">
      <el-card>
        <login-form></login-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/Login/Form.vue';
export default {
  components: {
    LoginForm,
  },
};
</script>

<style lang="stylus" scoped>
.auth-container
  height 100%
.auth-form
  max-width 600px
  width 100%
.no-border
  border-width 0
</style>
