import { render, staticRenderFns } from "./Remark.vue?vue&type=template&id=457f4ad4&scoped=true&"
import script from "./Remark.vue?vue&type=script&lang=js&"
export * from "./Remark.vue?vue&type=script&lang=js&"
import style0 from "./Remark.vue?vue&type=style&index=0&id=457f4ad4&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "457f4ad4",
  null
  
)

export default component.exports