<template>
  <div>
    <!--el-card class="mb-16">
      <label class="label">選擇專案</label>
      <div class="flex-row align-center justify-between">
        <el-select v-model="form.project" @change="getInstrumentList">
          <el-option
            v-for="project in projectList"
            :key="project._id"
            :label="project.project_id"
            :value="project._id"
          />
        </el-select>
        <div>
          <a href="https://firebasestorage.googleapis.com/v0/b/resounding-axe-221602.appspot.com/o/import_instrument_ver1.01.xlsx?alt=media&token=f941f293-bb3c-451a-bc61-93051ec236dc">
            <el-button v-if="form.project" type="warning">
              格式下載
            </el-button>
          </a>
          <el-button @click="activeImport" v-if="form.project" type="primary" class="ml-16">
            批量新增
          </el-button>
        </div>
      </div>
    </el-card-->
    <el-card class="mb-16">
      <div class="flex-row align-center justify-between">
        <div class="flex-row">
          <div>
            <label class="label" slot="label">{{ $t('project.pick') }}</label>
            <el-select
              v-model="form.project"
              filterable
              :placeholder="$t('project.pick')"
              @change="initCascaderSelector"
            >
              <el-option
                v-for="project in projectList"
                :key="project._id"
                :value="project._id"
                :label="project.project_id"
              ></el-option>
            </el-select>
          </div>
          <div>
            <label class="label" slot="label">
              {{ $t('chart.instrument.pick') }}
            </label>
            <el-cascader
              v-model="form.instrument"
              style="min-width:200px"
              :options="projectOptions"
              :props="props"
              :collapse-tags="true"
              :show-all-levels="false"
              :placeholder="$t('chart.instrument.pick')"
              @change="getCascaderInstrumentList"
            ></el-cascader>
          </div>
        </div>
        <div>
          <a href="https://firebasestorage.googleapis.com/v0/b/resounding-axe-221602.appspot.com/o/import_instrument_ver1.01.xlsx?alt=media&token=f941f293-bb3c-451a-bc61-93051ec236dc">
            <el-button v-if="form.project" type="warning">
              格式下載
            </el-button>
          </a>
          <el-button @click="activeImport" v-if="form.project" type="primary" class="ml-16">
            批量新增
          </el-button>
        </div>
      </div>
    </el-card>
    <el-card v-loading="isLoading" v-if="hasSubmitted">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="儀器設定" name="details" :lazy="true">
          <div class="spreadsheet-container flex-row">
            <hot-table id="hot-container" ref="hot" root="hot" :settings="settings" />
            <!-- <spread-sheet></spread-sheet> -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="簡訊設定" name="message" :lazy="true">
          <message-settings-table :instrument-list="instrumentList" />
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <batch-import-instrument
      ref="batch-import-instrument"
      @success="getInstrumentList(true)"
      :selected-project="form.project"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { HotTable } from '@handsontable/vue';
import Handsontable from 'handsontable';
import MessageSettingsTable from '@/components/instrument/MessageSettingsTable';
import BatchImportInstrument from '@/components/import/Instrument';
import 'handsontable/dist/handsontable.full.css';

// import SpreadSheet from '@/components/SpreadSheet';

// import { MessageBox } from 'element-ui'

export default {
  components: {
    HotTable,
    // SpreadSheet,
    MessageSettingsTable,
    BatchImportInstrument,
  },
  data() {
    return {
      form: {
        project: '',
        instrument: [],
      },
      columnsHeader: [
        '儀器名稱',
        '來源',
        'Header',
        'unit',
        'offset',
        'R0',
        'T0',
        'K',
        'F0',
        'F1',
        'F2',
        'F3',
        'F4',
        'F5',
        'AL(-)',
        'AL(+)',
        'WL(-)',
        'WL(+)',
        'WSL(-)',
        'WSL(+)',
        '儀器故障警報計數基準',
        '儀器故障警報計數上限',
        '警報計數上限',
        '啟用速率',
        '1小時速率 AL(-)',
        '1小時速率 AL(+)',
        '1小時速率 WL(-)',
        '1小時速率 WL(+)',
        '1小時速率 WSL(-)',
        '1小時速率 WSL(+)',
        '24小時速率 AL(-)',
        '24小時速率 AL(+)',
        '24小時速率 WL(-)',
        '24小時速率 WL(+)',
        '24小時速率 WSL(-)',
        '24小時速率 WSL(+)',
        '速率警報計數上限',
        '座標',
        '類別',
        '監視器',
        '啟用公式計算',
        '公式',
        'TRA_no',
      ],
      columns: [
        {
          data: 'latestVersion.name',
        },
        {
          data: 'latestVersion.document_id.name',
          readOnly: true,
        },
        {
          data: 'latestVersion.reference.name',
          readOnly: true,
        },
        {
          data: 'latestVersion.unit',
        },
        {
          data: 'latestVersion.offset',
        },
        {
          data: 'latestVersion.R0',
        },
        {
          data: 'latestVersion.T0',
        },
        {
          data: 'latestVersion.K',
        },
        {
          data: 'latestVersion.F0',
        },
        {
          data: 'latestVersion.F1',
        },
        {
          data: 'latestVersion.F2',
        },
        {
          data: 'latestVersion.F3',
        },
        {
          data: 'latestVersion.F4',
        },
        {
          data: 'latestVersion.F5',
        },
        {
          data: 'latestVersion.AL_minus',
        },
        {
          data: 'latestVersion.AL_plus',
        },
        {
          data: 'latestVersion.WL_minus',
        },
        {
          data: 'latestVersion.WL_plus',
        },
        {
          data: 'latestVersion.WSL_minus',
        },
        {
          data: 'latestVersion.WSL_plus',
        },
        {
          data: 'latestVersion.down_count_base',
        },
        {
          data: 'latestVersion.down_count_limit',
        },
        {
          data: 'latestVersion.alert_count_limit',
        },
        {
          data: 'latestVersion.allowRate',
        },
        {
          data: 'latestVersion.rate_1Hr_config.AL_minus',
        },
        {
          data: 'latestVersion.rate_1Hr_config.AL_plus',
        },
        {
          data: 'latestVersion.rate_1Hr_config.WL_minus',
        },
        {
          data: 'latestVersion.rate_1Hr_config.WL_plus',
        },
        {
          data: 'latestVersion.rate_1Hr_config.WSL_minus',
        },
        {
          data: 'latestVersion.rate_1Hr_config.WSL_plus',
        },
        
        {
          data: 'latestVersion.rate_24Hr_config.AL_minus',
        },
        {
          data: 'latestVersion.rate_24Hr_config.AL_plus',
        },
        {
          data: 'latestVersion.rate_24Hr_config.WL_minus',
        },
        {
          data: 'latestVersion.rate_24Hr_config.WL_plus',
        },
        {
          data: 'latestVersion.rate_24Hr_config.WSL_minus',
        },
        {
          data: 'latestVersion.rate_24Hr_config.WSL_plus',
        },
        {
          data: 'latestVersion.rate_alert_count_limit',
        },
        {
          data: 'latestVersion.coordinates',
        },
        {
          data: 'latestVersion.class',
        },
        {
          data: 'latestVersion.select_monitor',
        },
        {
          data: 'useFunction',
        },
        {
          data: 'latestVersion.evalFunction',
        },
        {
          data: 'latestVersion.TRA_no',
        },
      ],
      lastMove: '',
      activeTab: 'details',
      hasSubmitted: false,
      isLoading: false,
      allInstrumentList: [],
      instrumentList: [],
      $_instrumentList: [],
      projectOptions: [],
      instrumentMap: {},
      props: {
        multiple: true,
        //expandTrigger: 'hover',
        label: 'label',
        value: 'value',
        children: 'children',
      },
      //sampleLink:
      //  'https://firebasestorage.googleapis.com/v0/b/resounding-axe-221602.appspot.com/o/import_instrument.xlsx?alt=media&token=c2fda7f2-0bb3-4a87-9531-bc5440a4db59',
    };
  },
  computed: {
    projectList() {
      return this.$store.getters['project/projectList'];
    },
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    settings() {
      const vm = this;
      console.log(this.instrumentList);
      return {
        data: this.instrumentList,
        fixedColumnsLeft: 1,
        colHeaders: this.columnsHeader,
        // colWidths: 250,
        rowHeaders: true,
        manualRowResize: true,
        manualColumnResize: true,
        manualRowMove: true,
        customBorders: true,
        columnSorting: true,
        // preventOverflow: 'horizontal',
        height: 600,
        width: '100%',
        // width: '100vw',
        undo: true,
        columns: this.columns,
        async beforeChange(changes, source) {
          // const dataList = this.getSourceData();
          const objectList = this.getSourceData();
          // const _dataList = this.getData();
          const dataList = this.getData();

          console.log(changes);

          const updates = changes.map(([row, prop, oldValue, newValue]) => {
            const obj = objectList.find(
              instrument => instrument.latestVersion.name === dataList[row][0],
            );

            return {
              instrument_id: obj._id,
              version_id: obj.latestVersion._id,
              //[prop.split('.')[1]]: newValue,
              [(prop.substring(prop.indexOf('.')+1) === 'name') ? 'newName' : prop.substring(prop.indexOf('.')+1)]: newValue,
              name: dataList[row][0],
            };
          });
          console.log('beforeChange -> updates', updates);
          console.log('beforeChange -> source', source);
          vm.updateInstrument(updates, source);
          // if (source === 'edit') {
          //   // const message = `將更新${changes.length}筆資料, 是否繼續？`;
          //   // const response = await vm.$confirmEdit(message);
          //   // if (response === 'confirm') {
          //   vm.updateInstrument(updates, source);
          //   // } else {
          //   // vm.resetInstrumentList();
          //   // }
          // } else if (source === 'UndoRedo.undo') {
          //   vm.updateInstrument(updates, source);
          // }
        },
        contextMenu: {
          items: {
            separator: Handsontable.plugins.ContextMenu.SEPARATOR,
            copy: {
              name: '复制',
            },
            undo: {
              name: '撤消',
            },
            separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
            remove_row: {
              name: '删除行',
            },
          },
        },
        filters: true,
        // dropdownMenu: true,
        dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
        licenseKey: 'non-commercial-and-evaluation',
      };
    },
  },
  mounted() {
    this.form.project = this.selectedProject._id;
    this.getInstrumentList();
    this.initCascaderSelector();
  },
  methods: {
    initCascaderSelector() {
      this.projectOptions = [];
      this.form.instrument = [];
      //this.instrumentList = [];
      this.dataList = [];
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.form.project}/area-station-instrument`,
      })
        .then(res => {
          this.projectOptions = res.data.area.map(area => {
            const obj = {
              label: area.name,
              disabled: area.station.length === 0,
              children: area.station.map(station => {
                const obj = {
                  label: station.name,
                  disabled: station.instrument.length === 0,
                  children: station.instrument.map(instrument => {
                    const obj = {
                      label: instrument.latestVersion.name,
                      value: instrument._id,
                    };
                    //this.instrumentList.push(instrument);
                    this.instrumentMap[instrument._id] = instrument;
                    return obj;
                  }),
                };
                return obj;
              }),
            };
            return obj;
          });
        })
        .catch(error => {
          console.log(error);
          this.$notifyError(this.$t('chart.error.loading'), error.response.data.message);
        });
    },
    activeImport() {
      this.$refs['batch-import-instrument'].showUpload();
    },
    resetInstrumentList() {
      this.instrumentList = this.$lodash.cloneDeep(this.$_instrumentList);
      this.updateTable();
    },
    getInstrumentList(isUpdate = false) {
      this.hasSubmitted = true;
      this.isLoading = true;
      return this.$store
        .dispatch('data/getProjectInstrumentListWithLatestVersion', {
          projectId: this.form.project,
          isUpdate,
        })
        .then(res => {
          this.instrumentList = res;
          for(let ins of this.instrumentList){
            let allowRate = ins.latestVersion.allowRate;
            if(allowRate && allowRate != 'N'){
              ins.latestVersion.allowRate = 'Y';
            }
            else{
              ins.latestVersion.allowRate = 'N';
            }

            let useFunction = ins.useFunction;
            if(useFunction && useFunction != 'N'){
              ins.useFunction = 'Y';
            }
            else{
              ins.useFunction = 'N';
            }
          }
          this.updateTable();
          this.$_instrumentList = this.$lodash.cloneDeep(this.instrumentList);
          this.allInstrumentList = this.$lodash.cloneDeep(this.instrumentList);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getCascaderInstrumentList() {
      if(this.form.instrument.length==0){
        this.instrumentList = this.$lodash.cloneDeep(this.allInstrumentList);
        this.updateTable();
        return;
      }
      const usedInstruments = this.form.instrument.map(ele => ele[2]);
      //console.log(this.instrumentList);
      this.instrumentList = this.allInstrumentList.filter(ele=>{
        return usedInstruments.includes(ele._id);
      });
      this.updateTable();
    },
    setLastMove(type) {
      this.lastMove = type;
    },
    async updateInstrument(updateList, mode = 'edit') {
      this.isUpdating = true;
      return this.$axios({
        category: 'admin',
        method: 'put',
        apiName: 'bulk/instrument',
        data: updateList,
      })
        .then(() => {
          if (mode === 'UndoRedo.undo') {
            this.$notifyInfo('資料已復原');
          } else {
            this.$notifySuccess('資料已更新');
          }
          // this.getInstrumentList(true);
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isUpdating = false;
        });
    },
    updateTable() {
      this.$refs.hot.hotInstance.loadData(this.instrumentList);
    },
  },
};
</script>

<style lang="stylus" scoped>
.hot-container
  overflow-y auto
</style>
