<template>
  <div v-loading="isLoading">
    <div v-if="!$device.mobile">
      <div class="flex-row align-center justify-between mb-16">
        <div class="flex-row align-center">
          <el-switch
              v-model="autoRefresh"
              :active-text="$t('data.refresh')"
              @change="toggleAutoRefresh"
            ></el-switch>
          <div class="flex-row align-center"
               style="margin-left: 2rem;">
            <el-switch
              v-model="excludeNormalStatus"
              :active-text="$t('data.onlyWaring')"
              @change="toggleExcludeNormalStatus"
            ></el-switch>
          </div>
          <div
            class="flex-row align-center"
            v-for="dot in dotList"
            :key="dot.color"
            style="margin-left: 2rem;"
          >
            <span class="dot" :style="{ backgroundColor: dot.color }"></span>
            <span>{{ $t(dot.label) }}</span>
          </div>
        </div>
        <div>
          <span class="mr-8">{{ $t('data.compareDate') }}</span>
          <el-date-picker
            v-model="compareDate"
            type="datetime"
            default-time="00:00:00"
            value-format="timestamp"
            format="yyyy/MM/dd HH:mm"
            :picker-options="datePickerOptions"
            :placeholder="$t('chart.date.pick')"
            @change="compareDataWithDate"
          >
          </el-date-picker>
        </div>
      </div>
      <el-collapse v-model="activeCard">
        <el-card>
          <el-collapse-item
            v-for="area in areaList"
            :key="area._id"
            :title="area.name"
            :name="area._id"
          >
            <el-table
              :data="filteredList[area._id]"
              style="width: 100%;"
              stripe="stripe"
              :default-sort="{ prop: 'timestamp', order: 'descending' }"
            >
              <el-table-column sortable="sortable" prop="name" :label="$t(`data.instrument.name`)">
                <template slot-scope="scope">
                  <span
                    class="dot"
                    :style="{
                      backgroundColor: getStatusColor(scope.row.status),
                    }"
                  ></span>
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                sortable="sortable"
                v-for="{ prop, label, formatter } in configList"
                :key="prop"
                :prop="prop"
                :label="$t(`data.${label}`)"
                :formatter="formatter"
              ></el-table-column>
              <el-table-column sortable="sortable" prop="name" :label="$t(`data.update`)">
                <template slot-scope="scope">
                  <span v-if="scope.row.TIMESTAMP">
                    {{ $moment(scope.row.TIMESTAMP).format('YYYY-MM-DD HH:mm') }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column sortable="sortable" prop="remark" :label="$t('data.remark')">
                <template slot-scope="scope">
                  <span v-if="scope.row.remark">{{ scope.row.remark.content }}</span>
                </template>
              </el-table-column>
              <el-table-column
                width="150px"
                sortable="sortable"
                prop="status"
                :label="$t('data.chart')"
              >
                <template slot-scope="scope">
                  <router-link
                    class="button is-small"
                    :to="{ path: 'chart', query: { instrument: scope.row._id } }"
                  >
                    Chart
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
          </el-collapse-item>
        </el-card>
      </el-collapse>
    </div>

    <div class="data-page" v-else>
      <van-tabs v-model="selectedType">
        <van-tab :title="$t('data.navbar.all')" name="ALL" />
        <van-tab :title="$t('data.navbar.action')" name="WSL" />
        <van-tab :title="$t('data.navbar.alert')" name="AL" />
        <van-tab :title="$t('data.navbar.warning')" name="WL" />
      </van-tabs>
      <!-- <mobile-data-table v-if="!isLoading" :tableData="tableData" /> -->
      <div class="cell-list">
        <van-collapse v-model="activeCard">
          <van-collapse-item
            v-for="area in areaList"
            :key="area._id"
            :title="area.name"
            :name="area._id"
            size="large"
          >
            <div
              v-if="!filteredList[area._id].length"
              class="content"
              style="padding:2rem height:100%"
            >
              <section class="section">
                <div class="is-flex justify-center align-center">
                  <h4>{{ $t('data.none') }}</h4>
                </div>
              </section>
            </div>
            <van-cell
              v-for="instrument in filteredList[area._id]"
              :key="instrument._id"
              :is-link="true"
              :to="`/${$store.state.locale}/chart/${instrument._id}`"
            >
              <div slot="title">
                <span
                  :style="{ 'background-color': getStatusColor(instrument.status) }"
                  class="dot"
                />
                <span>{{ instrument.name }}</span>
              </div>
              <span slot="label">{{
                $moment(instrument.TIMESTAMP).format('YYYY-MM-DD HH:mm a')
              }}</span>
              <p v-if="checkShow('reading1')">{{ $t('data.raw') }}: {{ instrument.reading1 }}</p>
              <p v-if="checkShow('reading2')">
                <span v-if="checkShow('reading1')">{{ $t('data.measurements') }}:</span>
                {{ instrument.reading2 }} {{ instrument.unit }}
              </p>
            </van-cell>
          </van-collapse-item>
        </van-collapse>
      </div>
      <div class="data-page--footer">
        <van-button
          class="button is-primary has-text-weight-bold is-uppercase"
          @click="toggleAutoRefresh"
        >
          {{ $t('data.refresh') }}
          <font-awesome-icon v-if="autoRefresh" icon="check" style="margin-left: 12px" />
        </van-button>
        <van-button
          class="button is-primary has-text-weight-bold is-uppercase"
          @click="toggleExcludeNormalStatus"
        >
          {{ $t('data.onlyWaring') }}
          <font-awesome-icon v-if="excludeNormalStatus" icon="check" style="margin-left: 12px" />
        </van-button>
        <van-button
          class="button is-primary has-text-weight-bold is-uppercase"
          @click="showDatePicker = !showDatePicker"
        >
          {{ $t('data.compareDate') }}
          <font-awesome-icon v-if="isComparingData" icon="check" style="margin-left: 12px" />
        </van-button>
      </div>
    </div>
    <van-popup v-model="showDatePicker" round position="bottom" :style="{ height: '30%' }">
      <van-datetime-picker
        v-model="compareDate"
        type="datehour"
        :title="$t('chart.date.pick')"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="onCancelCompareDate"
        @confirm="compareDataWithDate"
      />
    </van-popup>
  </div>
</template>

<script>
import { uniqBy } from 'lodash';
import { dotList, getStatusColor } from '@/lib/base/stormAlert';
import { thisExpression } from '@babel/types';

export default {
  props: {
    useUrl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stormUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      //stormUrl: 'http://192.168.1.189:3000/',
      project:null,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      minDate: new Date('2017/01/01'),
      maxDate: new Date(),
      isLoading: false,
      isComparingData: false,
      showDatePicker: false,
      selectedType: 'ALL',
      dotList,
      compareDate: null,
      tableData: {},
      areaList: [],
      activeCard: [],
      configList: [
        {
          label: 'measurements',
          prop: 'mm/10min',
          formatter(row, column, cellValue) {
            return `${cellValue} ${row.unit}`;
          },
        },
        {
          label: 'R1',
          prop: 'mm/1HR',
          formatter(row, column, cellValue) {
            return `${cellValue} mm/1HR`;
          },
        },
        {
          label: 'R3',
          prop: 'mm/3HR',
          formatter(row, column, cellValue) {
            return `${cellValue} mm/3HR`;
          },
        },
        {
          label: 'R24',
          prop: 'mm/24HR',
          formatter(row, column, cellValue) {
            return `${cellValue} mm/24HR`;
          },
        },
        {
          label: 'day',
          prop: 'mm/1day',
          formatter(row, column, cellValue) {
            return `${cellValue} mm/day`;
          },
        },
        {
          label: 'month',
          prop: 'mm/1month',
          formatter(row, column, cellValue) {
            return `${cellValue} mm/month`;
          },
        },
      ],
      autoRefresh: false,
      excludeNormalStatus: false,
      refreshFunction: '',
      compareDatas: {},
    };
  },
  computed: {
    timezone() {
      return this.$store.getters['env/timezone'];
    },
    instrumentList() {
      return Object.keys(this.tableData)
        .map(areaId => {
          return this.tableData[areaId].map(instrument => instrument._id);
        })
        .flat();
    },
    selectedProject() {
      if(this.project) return this.project;
      return this.$store.getters['project/selectedProject'];
    },
    filteredList() {
      let result = this.tableData;
      const keyList = Object.keys(this.tableData);
      if (this.selectedType !== 'ALL') {
        result = keyList.reduce((obj, key) => {
          obj[key] = this.tableData[key].filter(ele => {
            return ele.status === this.selectedType;
          });
          return obj;
        }, {});
      }
      return result;
    },
  },
  beforeDestroy() {
    clearInterval(this.refreshFunction);
  },
  mounted() {
    if(this.useUrl){
      console.log('using url!');
      this.setProject()
        .then(() => {
          this.getLatestData();
        });
    }
    else{
      console.log('didnt use url!');
      this.getLatestData();
    }
  },
  methods: {
    setProject() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: '',
        useUrl: this.stormUrl,
      })
        .then(res => {
          this.project = res.data[0];
        })
        .catch(this.$notifyError);
    },
    getStatusColor,
    onCancelCompareDate() {
      this.compareDate = null;
      this.compareDatas = {};
      this.showDatePicker = false;
      this.isComparingData = false;
      this.getLatestData();
    },
    focusDatePicker() {
      this.showDatePicker = true;
      this.ref.datePicker.focus();
    },
    async compareDataWithDate() {
      this.showDatePicker = false;
      const result = await this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/output`,
        data: {
          date: [this.compareDate, this.compareDate],
          interval: 'all',
          instrument: this.instrumentList,
          timezone: this.timezone,
        },
        useUrl: this.useUrl ? this.stormUrl : null,
      });
      if (result.data.length > 0) {
        result.data.forEach(data => {
          const { instrument_id, reading2 } = data;
          this.compareDatas[instrument_id] = reading2;
        });
        this.getLatestData();
        this.isComparingData = true;
      } else {
        this.isComparingData = false;
        this.$notifyInfo('該時段內沒有資料');
      }
    },
    checkShow(value) {
      return this.selectedProject.config.data.display.includes(value);
    },
    async getLatestData() {
      this.isLoading = true;
      let subApiName = 'last/storm';
      //if(this.excludeNormalStatus) subApiName = 'last/excludeNormalStatus';
      let tempCard = this.activeCard;
      this.activeCard = [];
      
      await this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/data/${subApiName}/${Date.now()}`,
        useUrl: this.useUrl ? this.stormUrl : null,
      })
        .then(async res => {
          const areaList = res.data.map(ele => ele.area);
          //console.log(res.data);
          this.areaList = uniqBy(areaList, '_id');
          this.areaList.forEach(area => {
            this.tableData[area._id] = res.data
              .map(ele => {
                if (ele.area._id === area._id) {
                  return ele;
                }
              })
              .filter(ele => ele !== undefined);
            //console.log(this.tableData);
          });
          if (this.compareDate) {
            this.areaList.forEach(area => {
              this.tableData[area._id].forEach(instrument => {
                if (this.compareDatas[instrument._id]) {
                  instrument.reading2 = (
                    instrument.reading2 - this.compareDatas[instrument._id]
                  ).toFixed(4);
                }
              });
            });
          }
        })
        .finally(() => {
          this.alertCheck();
          if(this.excludeNormalStatus) this.excludeNormalStatusFunc();
          this.isLoading = false; 
          if(tempCard.length == 0) tempCard.push(this.areaList[0]._id);
          this.activeCard = tempCard;
        });
    },
    alertCheck(){
      this.areaList.forEach(area => {
        this.tableData[area._id].forEach(ele => {
          ele.status = 
            (ele['mm/24HR'] > 80 || ele['mm/1HR'] > 40) ?
              (ele['mm/24HR'] > 200 || ele['mm/3HR'] > 100) ?
                (ele['mm/24HR'] > 350 || ele['mm/3HR'] > 200) ? 
                  (ele['mm/24HR'] > 500) ? 
                    'EXTREME_TORRENTIAL_RAIN' : 
                    'SUPER_TORRENTIAL_RAIN' : 
                  'TORRENTIAL_RAIN' : 
                'HEAVY_RAIN' : 
              ele.status;
          if(!ele.remark)
          {
            switch(ele.status){
              case 'HEAVY_RAIN':
                ele.remark = {};
                ele.remark.content = this.$t('dots.heavyRain');
                break;
              case 'TORRENTIAL_RAIN':
                ele.remark = {};
                ele.remark.content = this.$t('dots.torrentialRain');
                break;
              case 'SUPER_TORRENTIAL_RAIN':
                ele.remark = {};
                ele.remark.content = this.$t('dots.superTorrentialRain');
                break;
              case 'EXTREME_TORRENTIAL_RAIN':
                ele.remark = {};
                ele.remark.content = this.$t('dots.extremeTorrentialRain');
                break;
              //default:
              //  ele.remark = {};
              //  ele.remark.content = this.$t('dots.normal');
              //  //不執行
              //  break;
            }
          }
          //console.log(this.tableData[area._id]);
        });
      });
    },
    excludeNormalStatusFunc(){
      this.areaList.forEach(area => {
        this.tableData[area._id] = this.tableData[area._id]
          .map(ele => {
            if(ele.status === 'GREEN'){
              return undefined;
            }
            else return ele;
          })
          .filter(ele => ele !== undefined);
        if(this.tableData[area._id].length === 0) {
          delete this.tableData[area._id];
          this.areaList[this.areaList.indexOf(area)] = null;
        }
        //console.log(this.tableData);
      });
      this.areaList = this.areaList.filter(ele => ele);
    },
    toggleAutoRefresh() {
      if (this.$device.mobile) {
        this.autoRefresh = !this.autoRefresh;
      }
      if (this.autoRefresh) {
        this.getLatestData();
        this.refreshFunction = setInterval(() => {
          this.getLatestData();
        }, 1000 * 60 * 1);
      } else {
        clearInterval(this.refreshFunction);
      }
    },
    toggleExcludeNormalStatus(){
      if(this.$device.mobile)
        this.excludeNormalStatus = !this.excludeNormalStatus;
      this.getLatestData();
    },
  },
};
</script>

<style lang="stylus" scoped>
.dot
  height 9px
  width 9px
  min-width 9px
  min-height 9px
  margin-right 0.7rem
  background-color #bbb
  border-radius 50%
  display inline-block
.data-page--footer
  height 50px
  width 100vw
  position fixed
  bottom 0
  left 0
  button
    height 100%
    border-radius 0
    // width 100%
    width 33%
    &:nth-child(3),&:nth-child(2)
      border-left 1px solid white
.dot
  height 9px
  width 9px
  min-width 9px
  min-height 9px
  margin-right 0.7rem
  background-color #bbb
  border-radius 50%
  display inline-block
/deep/ .van-collapse-item__content
  padding 0
</style>
