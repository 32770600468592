<template lang="html">
  <div class="global-wrapper">
    <el-container :class="deviceClass" class="has-background-white-bis">
      <el-header :height="navbarHeight">
        <nav-bar class="header-fixed header-shadow" />
      </el-header>
      <el-container class="main-container">
        <el-aside v-if="!$device.mobile && isAuthenticated" :width="sidebarWidth">
          <side-bar @collapse="collapse"/>
        </el-aside>
        <el-main :class="[$route.meta.mainStyle]">
          <router-view :key="$route.fullPath"/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { getLocalData } from '@/lib/base/localData';
import NavBar from '@/components/layout/NavBar';
import SideBar from '@/components/layout/SideBar';
export default {
  components: {
    NavBar,
    SideBar,
  },
  data() {
    return {
      lastScrollPosition: 0,
      sidebar_width: 200,
    };
  },
  computed: {
    navbarHeight() {
      if (this.$device.mobile) {
        return '3.25em';
      } else {
        return '60px';
      }
    },
    sidebarWidth() {
      return `${this.sidebar_width}px`;
    },
    deviceClass() {
      if (this.$device.mobile) {
        return 'mobile-mode';
      } else {
        return 'desktop-mode';
      }
    },
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    isAuthenticated() {
      return this.$store.getters['user/isAuthenticated'];
    },
  },
  beforeCreate() {
    if (getLocalData('jwt')) {
      this.$store.dispatch('user/getUserData', true);
    }
    const userLocalCache = getLocalData('user');
    const projectListCache = getLocalData('projectList');
    const projectCache = getLocalData('selectedProject');
    const localeCache = getLocalData('locale');
    if (userLocalCache) {
      this.$store.commit('user/setUser', JSON.parse(userLocalCache));
    }
    if (projectListCache) {
      this.$store.commit('project/setProjectList', JSON.parse(projectListCache));
    }
    if (projectCache) {
      this.$store.commit('project/setSelectedProject', JSON.parse(projectCache));
    }
    if (localeCache) {
      this.$store.commit('env/setLocale', localeCache);
      this.$i18n.locale = localeCache;
    }
  },
  mounted() {
    this.refetchProjects();
  },
  methods: {
    refetchProjects() {
      if (!this.isAuthenticated) return;
      this.$store.dispatch('project/getProjectList', true);
    },
    collapse(val){
      if(val)
        this.sidebar_width = 200;
      else 
        this.sidebar_width = 64;
    },
  },
};
</script>

<style lang="stylus" scoped>
.global-wrapper
  display flex
  min-height 100vh
#desktop, #mobile
  min-height 100vh
  height 100%
  .el-main, .el-aside
    max-height calc(100vh - 60px)
.el-header
  padding 0
  min-height 3.25em
  transition all 1s ease-out
.header-shadow
  box-shadow 0 0 6px 0 rgba(0, 0, 0, 0.1)
.header-fixed
  z-index 999
  position fixed
  width 100vw
  transform translate3d(0, 0, 0)
  transition 0.1s all ease-out
.navbar--hidden
  box-shadow none
  transform translate3d(0, -100%, 0)
.tab-bar-space
  height 55px
.mobile-mode
  .el-main
    padding 0
</style>
