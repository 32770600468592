<template >
  <Mapbox></Mapbox>
</template>

<script>
export default {
  components: {
    Mapbox: () => import('@/components/map/Mapbox'),
  },
};
</script>
