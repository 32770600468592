<template>
  <el-row :gutter="8">
    <el-col :span="16">
      <div class="image-container">
        <image-marker
          :file="file"
          :disable-edit="false"
          :disable-select="false"
          :editing="form"
          :project="project"
          @select="selectPin"
          @delete="$emit('reload')"
          trigger-type="click"
        />
      </div>
    </el-col>
    <el-col :span="8">
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="啟用向量">
          <el-switch
            v-model="form.enable">
          </el-switch>
        </el-form-item>
        <el-form-item label="向量X軸">
          <el-select
            v-model="form.vector_x"
            :disabled="!project||!VectorEnable"
            :popper-append-to-body="false"
            filterable
          >
            <el-option
              v-for="item in form.instrument"
              :key="item.value"
              :label="item.label.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="向量y軸">
          <el-select
            v-model="form.vector_y"
            :disabled="!project||!VectorEnable"
            :popper-append-to-body="false"
            filterable
          >
            <el-option
              v-for="item in form.instrument"
              :key="item.value"
              :label="item.label.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="offset(度)">
          <el-slider
            v-model="form.angle_offset"
            :disabled="!project||!VectorEnable"
            :min = "0"
            :max = "360"
            show-input>
          </el-slider>
        </el-form-item>
        <el-form-item label="角度單位">
          <el-tooltip content="此參數主要影響箭頭大小" placement="bottom" effect="light">
            <el-select
              v-model="form.angle_unit"
              :disabled="!project||!VectorEnable"
              :popper-append-to-body="false"
              filterable
            >
              <el-option
                v-for="item in unitList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-tooltip>
        </el-form-item>
        <el-form-item>
          注意：箭頭方向顯示以0度向右，以逆時鐘旋轉。<br>
          (ex. 90度時箭頭方向朝下)
        </el-form-item>
        <el-form-item>
          <el-button
            @click="updatePin"
            type="primary"
          >設定</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import ImageMarker from '@/components/pin/ImageMarker';
export default {
  components: {
    ImageMarker,
  },
  props: {
    editingProject: {
      type: String,
      required: true,
    },
    file: {
      type: Object,
      required: true,
    },
    projectList: {
      type: Array,
      required: true,
    },
    project: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        marker: '',
        instrument: [],
        vector_x: null,
        vector_y: null,
        angle_offset: 0,
        enable: false,
        angle_unit: '度',
      },
      editEnable: false,
      unitList: [],
    };
  },
  computed: {
    VectorEnable() {
      return this.form.enable&&this.editEnable;
    },
  },
  watch: {
    form: {
      handler(newVal) {
        console.log(newVal);
        /*
          const index = this.file.pin.findIndex(ele => {
            return ele._id === newVal.marker;
          });
          if (index >= 0) {
            this.file.pin[index].shape = this.form.shape;
            this.file.pin[index].size = this.form.size;
            this.file.pin[index].border_color = this.form.border_color;
          }*/
      },
      deep: true,
    },
  },
  methods: {
    selectPin(marker) {
      // const oriMarker = this.file.pin.find(ele => ele._id === marker._id)
      // this.form = oriMarker
      this.form = {
        ...marker,
        marker: marker._id,
        instrument: marker.instrument.map(ele=>{
          return{
            ...ele,
            label: ele.data[ele.data.length-1],
            value: ele._id,
          };
        }),
        enable: marker.vector_enable,
        vector_x: (marker.vector_x)?marker.vector_x:null,
        vector_y: (marker.vector_y)?marker.vector_y:null,
      };
      this.editEnable = true;
      this.unitList = [
        {
          value: '度',
          label: '度',
        },
        {
          value: '分',
          label: '分',
        },
        {
          value: '秒',
          label: '秒',
        },
      ];
    },
    updatePin() {
      const data = {
        ...this.form,
        imageId: this.file._id,
        pinId: this.form.marker,
        vector_x: this.form.vector_x,
        vector_y: this.form.vector_y,
        vector_enable: this.form.enable,
      };
      this.$axios({
        category: 'project',
        method: 'put',
        apiName: `${this.project}/updateImagePin`,
        data,
      }).then(() => {
        this.$emit('reload');
        this.$notifySuccess('更新成功');
      });
    },
  },
};
</script>


<style lang="scss">
@import '../../assets/scss/focus-point';
</style>

<style lang="stylus" scoped>
.focus-point
  position relative
.marker-container
  position absolute
  width 100%
  height 100%
  top 0
  left 0
/deep/ .el-select-dropdown{
  position absolute !important;
}
</style>
