/* eslint-disable no-unused-vars */
import { callAxios } from '@/lib/api/axios';
import { NotifyError } from '@/plugins/notify';

const apiData = {
  getDocumentDataIndex: {},
  getProjectInstrumentListWithVersion: {},
  getProjectInstrumentListWithLatestVersion: {},
};

export const getters = {
  areaList(state) {
    return state.areaList;
  },
  stationList(state) {
    return state.stationList;
  },
  instrumentList(state) {
    return state.instrumentList;
  },
};

export const state = {
  areaList: [],
  stationList: [],
  instrumentList: [],
};

export const actions = {
  getProjectInstrumentGroupList({ state }, { projectId, isUpdate = false }) {
    return callAxios({
      category: 'project',
      method: 'get',
      apiName: `${projectId}/instrument-group`,
    })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getProjectInstrumentListWithLatestVersion(
    { state },
    { projectId , isUpdate = false },
  ) {
    const apiName = 'getProjectInstrumentListWithLatestVersion';
    if (apiData[apiName][projectId] && !isUpdate) {
      return apiData[apiName][projectId];
    }
    return callAxios({
      method: 'get',
      apiName: `project/${projectId}/instrument`,
    })
      .then(res => {
        apiData[apiName][projectId] = res.data;
        return res.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getProjectInstrumentListWithVersion({ state }, { projectId, isUpdate = false }) {
    const apiName = 'getProjectInstrumentListWithVersion';
    if (apiData[apiName][projectId] && !isUpdate) {
      return apiData[apiName][projectId];
    }
    return callAxios({
      method: 'get',
      apiName: `project/${projectId}/instrument/version`,
    })
      .then(res => {
        apiData[apiName][projectId] = res.data;
        return res.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getDocumentDataIndex({ state }, { documentId, isUpdate = false }) {
    const apiName = 'getDocumentDataIndex';
    if (apiData[apiName][documentId] && !isUpdate) {
      return apiData[apiName][documentId];
    }
    return callAxios({
      category: 'admin',
      method: 'get',
      apiName: `document/${documentId}/index`,
    })
      .then(res => {
        apiData[apiName][documentId] = res.data;
        return res.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getInstrumentTypeList({ state }) {
    const apiName = 'getInstrumentTypeList';
    if (apiData[apiName]) return apiData[apiName];
    return callAxios({
      category: 'admin',
      method: 'get',
      apiName: 'instrument_type',
    })
      .then(res => {
        apiData[apiName] = res.data;
        return res.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getDocumentList({ state }) {
    const apiName = 'getDocumentList';
    if (apiData[apiName]) return apiData[apiName];
    return callAxios({
      category: 'admin',
      method: 'get',
      apiName: 'document',
    })
      .then(res => {
        apiData[apiName] = res.data;
        return res.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getInstrumentList({ state, commit }, isUpdate = false) {
    return callAxios({
      category: 'admin',
      method: 'get',
      apiName: 'instrument/option',
    })
      .then(res => {
        commit('setState', {
          key: 'instrumentList',
          data: res.data,
        });
        return res.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getStationList({ state, commit }, isUpdate = false) {
    const apiName = 'getStationList';
    if (apiData[apiName] && !isUpdate) return apiData[apiName];
    return callAxios({
      category: 'admin',
      method: 'get',
      apiName: 'station',
    })
      .then(res => {
        apiData[apiName] = res.data;
        commit('setState', {
          key: 'stationList',
          data: res.data,
        });
        return res.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getAreaList({ state, commit }, isUpdate = false) {
    const apiName = 'getAreaList';
    if (apiData[apiName] && !isUpdate) return apiData[apiName];
    return callAxios({
      category: 'admin',
      method: 'get',
      apiName: 'area',
    })
      .then(res => {
        apiData[apiName] = res.data;
        commit('setState', {
          key: 'areaList',
          data: res.data,
        });
        return res.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getUserList({ state }) {
    const apiName = 'getUserList';
    if (apiData[apiName]) return apiData[apiName];
    return callAxios({
      category: 'admin',
      method: 'get',
      apiName: 'user',
    })
      .then(res => {
        apiData[apiName] = res.data;
        return res.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
};

export const mutations = {
  setState(state, obj) {
    state[obj.key] = obj.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
