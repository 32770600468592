import colorPalette from '../../constants/colorPalette.js';

const GRID_LINE_COLOR_LIST = colorPalette.GRID_LINE_COLOR_LIST;

const TOP_GRID_LINE_COLOR = colorPalette.TOP_GRID_LINE_COLOR;

const chartColorPalettes = [
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#8085e9',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1',
];

export { 
  chartColorPalettes, 
  GRID_LINE_COLOR_LIST,
  TOP_GRID_LINE_COLOR,
};
