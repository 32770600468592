<template>
  <div>
    <el-card v-if="!$device.mobile">
      <page-contact></page-contact>
    </el-card>
    <section
      class="section"
      v-else
    >
      <page-contact></page-contact>
    </section>
  </div>
</template>

<script>
export default {
  components: {
    PageContact: () => import('@/components/Contact'),
  },
};
</script>

<style lang="stylus" scoped>
.section
  padding-top 1rem
</style>
