<template>
  <div>
    <el-upload
      :before-upload="handleUpload"
      :format="['xlsx', 'xls']"
      style="opacity:0; height:0px;width:0px;"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
      action=""
    >
      <button id="file-upload" />
    </el-upload>
    <error-modal
      :visible="showErrorModal"
      :instrument-list="uploadData"
      @submit="batchCreateInstrument"
      @close="closeErrorModal"
    />
  </div>
</template>

<script>
import XLSX from 'xlsx';
import ErrorModal from './ErrorModal';
export default {
  components: {
    ErrorModal,
  },
  props: {
    selectedProject: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      processingExcel: false,
      showErrorModal: false,
      excelData: [],
      uploadData: [],
    };
  },
  methods: {
    showUpload() {
      document.querySelector('#file-upload').click();
    },
    closeErrorModal() {
      this.showErrorModal = false;
    },
    batchCreateInstrument() {
      this.$axios({
        category: 'admin',
        method: 'post',
        apiName: 'instrument/import',
        data: this.uploadData,
      })
        // .$post('/admin/instrument/import', this.uploadData)
        .then(() => {
          this.$notifySuccess('新增成功');
          this.$emit('success');
        })
        .catch(error => {
          this.$notifyError('新增時發生錯誤', error.response.data.message);
        });
    },
    handleUpload(file) {
      this.file = file;
      this.processingExcel = true;
      this.parseExcelToJson(file)
        .then(res => {
          let instrumentList = res
            .map(worksheet => {
              return worksheet.content;
            })
            .flat();
          instrumentList = instrumentList.map(ele => {
            return { ...ele, rowNumber: ele.__rowNum__ };
          });
          this.excelData = this.$lodash.cloneDeep(instrumentList);
          instrumentList = instrumentList.map(ele => this.convertFormat(ele));
          return instrumentList;
        })
        .then(instrumentList => {
          return (
            this.$axios({
              category: 'admin',
              method: 'post',
              apiName: 'instrument/validate',
              data: instrumentList,
            })
              // .$post('/admin/instrument/validate', instrumentList)
              .then(res => {
                this.uploadData = res.data;
                if (this.uploadData.some(ele => ele.hasError)) {
                  this.showErrorModal = true;
                } else {
                  this.$confirm(`請確認是否新增${this.uploadData.length}個新儀器`, '新增儀器', {
                    confirmButtonText: '新增',
                    cancelButtonText: '取消',
                  }).then(() => {
                    return this.batchCreateInstrument();
                  });
                }
              })
              .catch(this.$notifyError)
          );
        })
        .catch(error => {
          console.log(error);
        });
    },
    parseExcelToJson(file) {
      // const vm = this
      return new Promise(function(resolve) {
        const reader = new FileReader();
        reader.onload = function(e) {
          const data = e.target.result;
          const workbook = XLSX.read(data, {
            type: 'array',
          });
          const result = workbook.SheetNames.map(sheetName => {
            return {
              sheetName,
              content: XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                range: 1,
              }),
            };
          });
          resolve(result);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    convertFormatBoolean(val) {
      return val === 'O';
    },
    convertFormat(data) {
      console.log('convertFormat -> data', data);
      const dataFields = [
        'name',
        'R0',
        'document_id',
        'display_output',
        'decimal_points',
        'R0',
        'offset',
        'unit',
        'F0',
        'F1',
        'F2',
        'F3',
        'F4',
        'F5',
        'T0',
        'K',
        'WL_plus',
        'WL_minus',
        'AL_plus',
        'AL_minus',
        'WSL_plus',
        'WSL_minus',
        'startDate',
      ];
      const obj = {
        project: this.selectedProject,
        data: [
          {
            coordinates: data.coordinates ? data.coordinates.split(',').map(Number) : null,

            alert_count_limit: data['alert_count_limit'] ? data['alert_count_limit'] : 2,

            allowRate: data['allowRate'] ? this.convertFormatBoolean(data['allowRate']) : false,
            rate_alert_count_limit: data['rate_alert_count_limit'] ? data['rate_alert_count_limit'] : 2,
            rate_1Hr_config: {
              WL_plus:   data['rate_1Hr_WL_plus']   ? Number(data['rate_1Hr_WL_plus']  ) : null,
              WL_minus:  data['rate_1Hr_WL_minus']  ? Number(data['rate_1Hr_WL_minus'] ) : null,
              AL_plus:   data['rate_1Hr_AL_plus']   ? Number(data['rate_1Hr_AL_plus']  ) : null,
              AL_minus:  data['rate_1Hr_AL_minus']  ? Number(data['rate_1Hr_AL_minus'] ) : null,
              WSL_plus:  data['rate_1Hr_WSL_plus']  ? Number(data['rate_1Hr_WSL_plus'] ) : null,
              WSL_minus: data['rate_1Hr_WSL_minus'] ? Number(data['rate_1Hr_WSL_minus']) : null,
            },
            rate_24Hr_config: {
              WL_plus:   data['rate_24Hr_WL_plus']   ? Number(data['rate_24Hr_WL_plus']  ) : null,
              WL_minus:  data['rate_24Hr_WL_minus']  ? Number(data['rate_24Hr_WL_minus'] ) : null,
              AL_plus:   data['rate_24Hr_AL_plus']   ? Number(data['rate_24Hr_AL_plus']  ) : null,
              AL_minus:  data['rate_24Hr_AL_minus']  ? Number(data['rate_24Hr_AL_minus'] ) : null,
              WSL_plus:  data['rate_24Hr_WSL_plus']  ? Number(data['rate_24Hr_WSL_plus'] ) : null,
              WSL_minus: data['rate_24Hr_WSL_minus'] ? Number(data['rate_24Hr_WSL_minus']) : null,
            },
          },
        ],
        config: {
          message: {
            WL: {
              enabled: this.convertFormatBoolean(data['SMS-WL']),
            },
            AL: {
              enabled: this.convertFormatBoolean(data['SMS-AL']),
            },
            WSL: {
              enabled: this.convertFormatBoolean(data['SMS-WSL']),
            },
            down: {
              enabled: this.convertFormatBoolean(data['SMS-Fail']),
            },
          },
        },
      };
      delete data['SMS-WL'];
      delete data['SMS-AL'];
      delete data['SMS-WSL'];
      delete data['SMS-Fail'];
      delete data.coordinates;

      delete data['allowRate'];
      delete data['rate_1Hr_WL_plus'];
      delete data['rate_1Hr_WL_minus'];
      delete data['rate_1Hr_AL_plus'];
      delete data['rate_1Hr_AL_minus'];
      delete data['rate_1Hr_WSL_plus'];
      delete data['rate_1Hr_WSL_minus'];

      delete data['rate_24Hr_WL_plus'];
      delete data['rate_24Hr_WL_minus'];
      delete data['rate_24Hr_AL_plus'];
      delete data['rate_24Hr_AL_minus'];
      delete data['rate_24Hr_WSL_plus'];
      delete data['rate_24Hr_WSL_minus'];

      const keyList = Object.keys(data);
      keyList.forEach(key => {
        if (dataFields.includes(key)) {
          obj.data[0][key] = data[key];
        } else {
          obj[key] = data[key];
        }
      });
      return obj;
    },
  },
};
</script>
