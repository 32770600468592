<template lang="html">
  <div>
    <el-card v-if="!simplify">
        <el-cascader
          v-model="instruments"
          style="min-width:300px"
          ref="cascader"
          filterable
          clearable
          :options="instrumentOptions"
          :props="props"
          :collapse-tags="true"
          :show-all-levels="false"
          :placeholder="$t('chart.instrument.pick')"
          @change="getCascaderInstrumentList"
        ></el-cascader>
      <el-date-picker 
        v-model="date"
        :start-placeholder="$t('date.start')"
        :end-placeholder="$t('date.end')"
        :picker-options="datePickerOptions"
        :default-time="['00:00:00']"
        @change="submit(true)"
        type="datetimerange"
        format="yyyy/MM/dd HH:mm"
        value-format="timestamp"
      />
    </el-card>
    <enhanced-table :title="simplify?'近七日事件紀錄':null" :showSearch="!simplify" :rightRadioOption="simplify" :default-radio-value=selectedRadio :table-data="messageList" :col-configs="colConfigs" :radio-configs="radioConfigs" :isLoading="isLoading">
    </enhanced-table>
  </div>
</template>

<script>
import moment from 'moment';
import EnhancedTable from '@/components/common/EnhancedTable';
export default {
  components: {
    EnhancedTable,
  },
  props: {
    simplify: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      useStorm: false,
      stormProject: null,
      stormUseUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      isLoading: false,
      selectedRadio: '警戒值|黃燈',
      messageList: [],
      allMessageList: [],
      clientList: [],
      colConfigs: [
        {
          label: this.$i18n.t('eventList.startTime'),
          prop: 'occurrenceDate',
          'min-width': '260px',
        },
        {
          label: this.$i18n.t('eventList.area'),
          prop: 'areaName',
          'min-width': '175px',
        },
        {
          label: this.$i18n.t('eventList.instrument'),
          prop: 'instrument',
          'min-width': '180px',
        },
        {
          label: this.$i18n.t('eventList.content'),
          prop: 'content',
          'min-width': '550px',
        },
        {
          label: this.$i18n.t('eventList.chart'),
          prop: 'btnOption',
          'min-width': '80px',
          urlBtn: true,
          //block: 'chart',
        },
        {
          label: this.$i18n.t('eventList.notifyStaff'),
          prop: 'inform.user.name',
          'min-width': '150px',
        },
        {
          label: this.$i18n.t('eventList.notifyTime'),
          prop: 'inform.time',
          'min-width': '150px',
          formatter(column, row, cellValue) {
            if(!cellValue) return '';
            return moment(cellValue).format('YYYY-MM-DD HH:mm');
          },
        },
      ],
      radioConfigs: [
        {
          text: this.$i18n.t('eventList.all'),
          value: '',
        },
        {
          text: this.$i18n.t('eventList.AL'),
          value: '警戒值|黃燈',
        },
        {
          text: this.$i18n.t('eventList.WSL'),
          value: '行動值|紅燈',
        },
        {
          text: this.$i18n.t('eventList.down'),
          value: 'other',
        },
      ],
      props: {
        multiple: true,
        //expandTrigger: 'hover',
        label: 'label',
        value: 'value',
        children: 'children',
      },
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      date:[
        (this.simplify) ? this.$moment()
          .subtract(7, 'day')
          .startOf('day')
          .format('x') : this.$moment()
          .subtract(3, 'month')
          .startOf('day')
          .format('x'),
        this.$moment().format('x'),
      ],
      useDate: true,
      instruments: [],
      instrumentOptions: [],
      instrumentStations: [],
      areaList: [],

    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    userRole() {
      return this.$store.getters['user/details'].roles;
    },
  },
  mounted() {
    if(this.selectedProject._id=='633d2757a4fbd61d20ee34ae' && (this.userRole === 'admin'|| this.userRole === 'thsr')) this.useStorm = true;
    this.submit(true);
  },
  methods: {
    setStormProject() {
      if(!this.useStorm) return;
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: '',
        useUrl: this.stormUseUrl,
      })
        .then(res => {
          this.stormProject = res.data[0];
        })
        .catch(this.$notifyError);
    },
    
    getStormStation() {
      console.log('getStormStation');
      if(!this.useStorm){
        console.log('no storm rule');
        return;
      }
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.stormProject._id}/area-station-instrument`,
        useUrl: this.stormUseUrl,
      })
        .then(res => {
          const stationInstruments = [];
          let index = 0;
          res.data.area.forEach((area) => {
            return area.station.forEach((station) => {
              const { _id, name } = station;
              const instrument = station.instrument.map((instrument) => {
                const { _id, last_reading, display_output, status } = instrument;
                const data = instrument.latestVersion;
                index += 1;
                return {
                  _id,
                  name: data.name,
                  reading: last_reading ? `${last_reading[display_output]} ${data.unit}` : null,
                  TIMESTAMP: last_reading ? last_reading.TIMESTAMP : null,
                  coordinates: data.coordinates,
                  status,
                  index,
                };
              });
              stationInstruments.push({ _id, name, instrument });
            });
          });
          const resultList = stationInstruments.map(station => {
            const obj = {
              label: station.name,
              children: station.instrument.map(instrument => {
                const obj = {
                  label: instrument.name,
                  value: instrument.name,
                };
                return obj;
              }),
            };
            return obj;
          });
          this.instrumentStations = [...resultList];
          this.areaList = res.data.area;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getEventList() {
      this.eventList = [];
      //const instrument = this.$refs.cascader.getCheckedNodes(true)[0].value;
      const instrument = 'all';
      let [startTime, endTime] = this.date;
      if(!this.useStorm) {
        return;
      }
      return this.$axios({
        category: 'event',
        method: 'get',
        apiName: `/project/${this.stormProject._id}/instrument/${instrument}/alertEvent?startTime=${startTime}&endTime=${endTime}`,
        useUrl: this.stormUseUrl,
      })
        .then(res => {
          let events = res.data.map(event => {
            if (event.endTime) {
              event.occurrenceDate = `${this.$moment(event.startTime).format(
                'YYYY-MM-DD HH:mm',
              )} ~ ${this.$moment(event.endTime).format('YYYY-MM-DD HH:mm')}`;
            } else {
              event.occurrenceDate = `${this.$moment(event.startTime).format('YYYY-MM-DD HH:mm')} (進行中)`;
            }
            return event;
          });
          //this.eventList = events;
          let eventList = events.map(ele => {
            return {
              ...ele,
              instrument: ele.instrument_id.data[0].name,
              station: this.getInstrumentStation(ele.instrument_id),
              area: this.getInstrumentArea(ele.instrument_id),
              alert: this.getAlertType(ele.outputs),
            };
          }).map(ele => {
            return {
              ...ele,
              content: '-高鐵邊坡暴雨預警系統通知-\n' +
                      ` ${ele.area}分區內高鐵里程${ele.station}之雨量站
                        ${ele.instrument}
                        達${(ele.alert === 'AL') ? '黃燈' : (ele.alert === 'WSL') ? '紅燈' : '未知'}警戒
                      `,
              areaName: ele.station,
              btnOption: {
                prop: ele.instrument_id,
                urlBtn: '/storm/snake-line',
                block: 'chart',
              },
              startDate: ele.startTime,
              storm: true,
            };
          }).filter(ele => {
            return ![null,undefined,NaN].includes(ele.station);
          });
          //console.log(this.eventList);
          this.messageList = [...this.messageList,...eventList];
        })
        .catch((e)=>{
          this.$notifyError(e);
          console.log(e);
        });
    },
    getInstrumentStation(instrument){
      for(let station of this.instrumentStations){
        for(let _instrument of station.children){
          if(_instrument.label === instrument.data[0].name) return station.label;
        }
      }
      return null;
    },
    getInstrumentArea(instrument){
      for(let area of this.areaList){
        for(let station of area.station){
          for(let _instrument of station.instrument){
            if(_instrument.latestVersion.name === instrument.data[0].name) return area.name;
          }
        }
      }
      return null;
    },
    getAlertType(outputs){
      const ALStandard = {
        threeHourRainFallIntercept: 25,
        oneDayRainFallIntercept: 190,
      };

      const WSLStandard = {
        threeHourRainFallIntercept: 40,
        oneDayRainFallIntercept: 285,
      };
      let result = null;
      for(let output of outputs){
        const { threeHourRainFallIntercept, oneDayRainFallIntercept } = output;
      
        if (
          threeHourRainFallIntercept > WSLStandard.threeHourRainFallIntercept ||
          oneDayRainFallIntercept > WSLStandard.oneDayRainFallIntercept
        ) {
          return 'WSL';
        } else if (
          threeHourRainFallIntercept > ALStandard.threeHourRainFallIntercept ||
          oneDayRainFallIntercept > ALStandard.oneDayRainFallIntercept
        ) {
          result = 'AL';
        }
      }
      return result;
    },

    async submit(useDate) {
      this.isLoading = true;
      this.useDate = useDate || this.simplify;

      if(this.useStorm) await this.setStormProject();
      this.messageList = [];
      await this.getMessage();
      if(this.useStorm) await this.getStormStation();
      this.instruments = [];
      if(this.useStorm) await this.getEventList();
      this.msgSort();
      await this.initCascaderSelector();
      this.allMessageList = this.$lodash.cloneDeep(this.messageList);
      this.isLoading = false;
      this.$emit('componentMounted');
    },
    initCascaderSelector() {
      this.instrumentOptions = [];
      this.instruments = [];
      this.dataList = [];
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/area-station-instrument`,
      })
        .then(res => {
          let instrumentOptions = res.data.area.map(area => {
            const obj = {
              label: area.name,
              disabled: area.station.length === 0,
              children: area.station.map(station => {
                const obj = {
                  label: station.name,
                  disabled: station.instrument.length === 0,
                  children: station.instrument.map(instrument => {
                    const obj = {
                      label: instrument.latestVersion.name,
                      value: instrument.latestVersion.name,
                    };
                    return obj;
                  }),
                };
                return obj;
              }),
            };
            return obj;
          });
          
          //console.log(instrumentOptions);
          //console.log(this.instrumentStations);
          if(this.useStorm) this.instrumentOptions = [ 
            {
              label: 'rain',
              disabled: false,
              children: this.instrumentStations,
            }, ...instrumentOptions];
          else this.instrumentOptions = [ ...instrumentOptions];
        })
        .catch(error => {
          console.log(error);
          this.$notifyError(this.$t('chart.error.loading'), error.response.data.message);
        });
    },
    getCascaderInstrumentList() {
      if(this.instruments.length==0){
        this.messageList = this.$lodash.cloneDeep(this.allMessageList);
        return;
      }
      const usedInstruments = this.instruments.map(ele => ele[2]);
      this.messageList = this.allMessageList.filter(ele=>{
        return usedInstruments.includes(ele.instrument);
      });
      console.log(this.messageList);
    },
    handleCurrentChange(page) {
      this.currentPage = page;
    },
    getMessage() {
      if(!this.useDate){
        return this.$axios({
          category: 'alerts',
          method: 'get',
          apiName: `${this.selectedProject._id}/getAlertsList/limit`,
        })
          .then(res => {
            let data = res.data.map(ele=>{
              return {
                ...ele,
                startDate: moment(ele.startTime).format('YYYY-MM-DD HH:mm'),
                areaName: ele.area,
                btnOption: {
                  prop: ele.instrumentId,
                  urlBtn: 'chart',
                  block: 'chart',
                },
                occurrenceDate: moment(ele.startTime).format('YYYY-MM-DD HH:mm'),
              };
            });
            this.messageList = [...this.messageList,...data];
          })
          .catch((error)=>{
            this.$notifyError(error);
            console.log(error);
          });
      }
      else{
        return this.$axios({
          category: 'alerts',
          method: 'get',
          apiName: `${this.selectedProject._id}/getAlertsList/${new Date(Number(this.date[0]))}&${new Date(Number(this.date[1]))}`,
        })
          .then(res => {
            let data = res.data.map(ele=>{
              return {
                ...ele,
                startDate: moment(ele.startTime).format('YYYY-MM-DD HH:mm'),
                areaName: ele.area,
                btnOption: {
                  prop: ele.instrumentId,
                  urlBtn: 'chart',
                  block: 'chart',
                },
                occurrenceDate: ele.startTime,
              };
            });
            this.messageList = [...this.messageList,...data];
          })
          .catch((error)=>{
            this.$notifyError(error);
            console.log(error);
          });
      }
    },

    msgSort(){
      this.messageList = this.messageList.sort((a,b)=>{
        return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
      });
    },
  },
};
</script>
