<template>
  <el-row :gutter="8">
    <el-col :span="16">
      <set-marker
        v-if="formType === 'create'"
        v-model="form"
        :size="form.size"
        :shape="form.shape"
        :border-color="form.border_color"
      >
        <image-marker
          :file="file"
          :disable-edit="true"
        />
      </set-marker>
      <div class="image-container">
        <image-marker
          v-if="formType === 'edit' && !enableEditPosition"
          :file="file"
          :disable-edit="false"
          :disable-select="false"
          :editing="form"
          :project="project"
          @select="selectPin"
          @delete="$emit('reload')"
          trigger-type="click"
        />
      </div>
      <set-marker
        v-if="formType === 'edit' && enableEditPosition"
        v-model="form"
        :size="form.size"
        :shape="form.shape"
        :border_color="form.border_color"
      >
        <image-marker
          :file="file"
          :disable-edit="true"
          :disable-select="false"
          :editing="form"
          :project="project"
          @select="selectPin"
          @delete="$emit('reload')"
          trigger-type="click"
        />
      </set-marker>
    </el-col>
    <el-col :span="8">
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
      >
        <el-tabs
          v-model="formType"
          @tab-click="changeType"
        >
          <el-tab-pane
            label="新增"
            name="create"
          />
          <el-tab-pane
            label="修改"
            name="edit"
          />
        </el-tabs>
        <!-- <el-form-item label="專案">
          <el-select
            v-model="project" 
            @change="selectedProjectChanged"
            filterable
          >
            <el-option
              v-for="item in projectList" 
              :key="item.value"
              :label="item.name"
              :value="item._id"
            />
          </el-select>
        </el-form-item>-->
        <el-form-item
          v-if="formType === 'edit'"
          label="位置"
        >
          <el-switch v-model="enableEditPosition" />
        </el-form-item>
        <el-form-item label="儀器">
          <el-select
            v-model="form.instrument"
            :disabled="!project"
            :popper-append-to-body="false"
            filterable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in instrumentList"
              :key="item.value"
              :label="item.latestVersion.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="形狀">
          <el-select
            v-model="form.shape"
            :disabled="!project"
            :popper-append-to-body="false"
            filterable
          >
            <el-option
              v-for="item in shapeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="大小">
          <el-select
            v-model="form.size"
            :disabled="!project"
            :popper-append-to-body="false"
            filterable
          >
            <el-option
              v-for="item in sizeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="邊框顏色">
          <el-select
            v-model="form.border_color"
            :disabled="!project"
            :popper-append-to-body="false"
            filterable
          >
            <el-option
              v-for="item in borderColorList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="formType === 'create'"
            :disabled="!enableSubmit"
            @click="addPin"
            type="primary"
          >新增</el-button>
          <el-button
            v-if="formType === 'edit'"
            :disabled="!enableSubmit"
            @click="updatePin"
            type="primary"
          >更新</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import { COLORS } from '@/constants/mapSettings.js';
import ImageMarker from '@/components/pin/ImageMarker';
import { shapeList, sizeList, borderColorList } from '@/lib/base/pin';
import SetMarker from '@/components/pin/SetMarker';
export default {
  components: {
    ImageMarker,
    SetMarker,
  },
  props: {
    editingProject: {
      type: String,
      required: true,
    },
    file: {
      type: Object,
      required: true,
    },
    projectList: {
      type: Array,
      required: true,
    },
    project: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formType: 'create',
      enableEditPosition: false,
      form: {
        marker: '',
        instrument: [],
        shape: 'square',
        size: 'medium',
        x: 50,
        y: 50,
        border_color: 'white',
      },
      instrumentList: [],
      shapeList,
      sizeList,
      borderColorList,
    };
  },
  computed: {
    enableSubmit() {
      return true;
      // return (
      //   this.form.instrument.length &&
      //   this.project &&
      //   (this.form.x && this.form.y)
      // )
    },
  },
  watch: {
    form: {
      handler(newVal) {
        console.log(newVal);
        if (this.formType === 'edit') {
          const index = this.file.pin.findIndex(ele => {
            return ele._id === newVal.marker;
          });
          if (index >= 0) {
            this.file.pin[index].shape = this.form.shape;
            this.file.pin[index].size = this.form.size;
            this.file.pin[index].border_color = this.form.border_color;
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.loadInstrumentList();
  },
  methods: {
    // handleMarkerChange(coordinates) {
    //   console.log(coordinates)
    //   this.form.x = coordinates.x
    //   this.form.y = coordinates.y
    // },
    initState() {
      this.form = {
        x: 50,
        y: 50,
      };
      // this.form.instrument = null
      // this.project = null
      this.$refs.form.resetFields();
      this.formType = 'create';
    },
    changeType(tab) {
      this.formType = tab.name;
    },
    selectPin(marker) {
      // const oriMarker = this.file.pin.find(ele => ele._id === marker._id)
      // this.form = oriMarker
      this.form = {
        ...marker,
        marker: marker._id,
        instrument: marker.instrument.map(ele => ele._id),
      };
    },
    updatePin() {
      const data = {
        ...this.form,
        imageId: this.file._id,
        pinId: this.form.marker,
      };
      this.$axios({
        category: 'project',
        method: 'put',
        apiName: `${this.project}/updateImagePin`,
        data,
      }).then(() => {
        this.$emit('reload');
        this.$notifySuccess('更新成功');
      });
    },
    addPin() {
      if (this.form.x && this.form.y && this.form.instrument) {
        const data = {
          ...this.form,
          imageId: this.file._id,
          pinId: this.form.marker,
        };
        this.$axios({
          category: 'project',
          method: 'post',
          apiName: `${this.project}/createImagePin`,
          data,
        })
          .then(() => {
            this.$emit('reload');
            this.initState();
            this.$notifySuccess('新增成功');
          })
          .catch(err => console.log(err));
      }
    },
    // selectedProjectChanged() {
    //   this.form.instrument = null
    //   this.loadInstrumentList()
    // },
    loadInstrumentList() {
      return (
        this.$store
          .dispatch('data/getProjectInstrumentListWithLatestVersion', { projectId: this.project, isUpdate: true })
          // this.$axios
          //   .$get(`/admin/project/${this.project}/instrument`)
          .then(res => {
            this.instrumentList = res.map(ele => {
              return {
                ...ele,
                label: ele.latestVersion.name,
                value: ele._id,
              };
            });
          })
          .catch(err => console.log(err))
      );
    },
    getStatusColor(status) {
      const statusList = ['WSL', 'WL', 'AL', 'GREEN'];
      const styleList = [COLORS.SUSPENSION, COLORS.WARNING, COLORS.ALERT, COLORS.GREEN];
      const styleIndex = statusList.findIndex(ele => ele.includes(status));
      if (styleIndex === -1) return COLORS.GREY;
      return styleList[styleIndex];
    },
  },
};
</script>


<style lang="scss">
@import '../../assets/scss/focus-point';
</style>

<style lang="stylus" scoped>
.focus-point
  position relative
.marker-container
  position absolute
  width 100%
  height 100%
  top 0
  left 0
/deep/ .el-select-dropdown{
  position absolute !important;
}
</style>
