import Vue from 'vue';
import Vuex from 'vuex';

import env from './modules/env';
import user from './modules/user';
import project from './modules/project';
import data from './modules/data';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    env,
    user,
    project,
    data,
  },
});
