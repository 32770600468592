<template >
  <div class="auth-container is-flex justify-center align-center">
    <div class="auth-form-img" style="max-width: 750px;">
      <img style="max-height:210px;height: 100%;" src="@/assets/thsr-geo-logo.png">
      <br>
      <img style="max-height:430px;height: 100%;" src="@/assets/thsr-train-2.jpg">
    </div>
    <div class="auth-form">
      <el-card>
        <login-form></login-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/Login/Form.vue';
export default {
  components: {
    LoginForm,
  },
};
</script>

<style lang="stylus" scoped>
.auth-container
  height 100%
.auth-form
  max-width 550px
  width 100%
.auth-form-img
  text-align: center
.no-border
  border-width 0

@media screen and (max-width: 800px) {
  .auth-form-img {
    display: none;
  }
}
</style>
