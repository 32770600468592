<template>
  <div id="mainContent" :key="key">
    <el-card class="box-card" v-loading="loading">
      <div slot="header" class="header-container" v-if="!$device.mobile">
        <div class="header-left">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{
    $t('rank.all_check') }}</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="checkedinstruments" @change="handlecheckedinstrumentsChange">
            <el-checkbox v-for="inst in instruments" :label="inst" :key="inst">{{ inst }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <span class="header-text">{{ $t('rank.title') }}</span>
        <div class="header-right">
          <el-date-picker v-model="selectedDate" type="datetimerange" start-placeholder="Start Date"
            end-placeholder="End Date" clearable="false" format="yyyy/MM/dd HH:mm" :picker-options="datePickerOptions"
            @change="getDate" :default-time="['00:00:00', '23:59:00']">
          </el-date-picker>
        </div>
      </div>
      <div slot="header" class="header-mobile" v-else>
        <div class="header-text">{{ $t('rank.title') }}</div>
        <div style="text-align: center;">
          <el-date-picker v-model="selectedDate" type="datetimerange" start-placeholder="Start Date"
            end-placeholder="End Date" clearable="false" format="yyyy/MM/dd HH:mm" :picker-options="datePickerOptions"
            @change="getDate" :default-time="['00:00:00', '23:59:00']">
          </el-date-picker>
        </div>
      </div>
      <div style="margin-bottom: 20px">
        {{ $t('rank.max_allow_rows') }}
        <el-radio-group v-model="radio" size="medium" @input="handleRadioClick">
          <el-radio-button label="10"></el-radio-button>
          <el-radio-button label="20"></el-radio-button>
          <el-radio-button label="30"></el-radio-button>
          <el-radio-button label="100"></el-radio-button>
        </el-radio-group>
        {{ $t('rank.max_allow_rows_2') }}
      </div>
      <el-table :data="tableData" border :header-cell-style="headerStyle" :cell-style="cellStyle">

        <el-table-column label="" align="center" width="60" v-if="showNo">
          <el-table-column prop="idx" label="No." align="center" width="60"></el-table-column>
        </el-table-column>

        <el-table-column :label="$t('rank.rg')" align="center" v-if="ischeckedRG">
          <el-table-column prop="rain_name" :label="$t('rank.instrument_name')" align="center" width="250">
            <template slot-scope="scope">
              <div>
                <a :href="'/chart?instrument=' + scope.row.rain_id + '&startDate=' + chart_time[1] + '&endDate=' + chart_time[0]"
                  target="_blank">
                  {{ scope.row.rain_name }}
                </a>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="rain_value" :label="$t('rank.rain_value')" align="center" width="200">
            <template slot-scope="scope">
              <div>
                {{ scope.row.rain_value }} mm
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column :label="$t('rank.tm')" align="center" v-if="ischeckedTM">
          <el-table-column prop="tm_name" :label="$t('rank.instrument_name')" align="center" width="250">
            <template slot-scope="scope">
              <div>
                <a :href="'/chart?instrument=' + scope.row.tm_id + '&startDate=' + chart_time[1] + '&endDate=' + chart_time[0]"
                  target="_blank">
                  {{ scope.row.tm_name }}
                </a>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="tm_value" align="center" width="200">
            <template slot="header">
              <el-dropdown @command="handleCommand" show-timeout="150">
                <span class="el-dropdown-link">
                  {{ selectedText }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item class="xlcdList" command="a">{{ $t('rank.tm_value') }}</el-dropdown-item>
                  <el-dropdown-item class="xlcdList" command="b">{{ $t('rank.tm_total') }}</el-dropdown-item>
                  <el-dropdown-item class="xlcdList" command="c">{{ $t('rank.tm_rate') }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <template slot-scope="scope">
              <div>
                {{ scope.row.tm_value }} mm
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column :label="$t('rank.sti')" align="center" v-if="ischeckedSTI">
          <el-table-column prop="sti_name" :label="$t('rank.instrument_name')" align="center" width="250">
            <template slot-scope="scope">
              <div>
                <a :href="'/chart?instrument=' + scope.row.sti_id + '&startDate=' + chart_time[1] + '&endDate=' + chart_time[0]"
                  target="_blank">
                  {{ scope.row.sti_name }}
                </a>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="sti_value" :label="$t('rank.sti_value')" align="center" width="200">
            <template slot-scope="scope">
              <div>
                {{ scope.row.sti_value }} {{ $t('rank.degree') }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<style scoped>
.horizontal-dropdown .el-dropdown-menu {
  display: flex;
}

.el-dropdown-menu {
  background-color: #409EFF;
}

.el-dropdown-link {
  cursor: pointer;
  color: #FFFFFF;
}

.xlcdList {
  color: white;
  font-weight: bold;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  flex: 1;
}

.header-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.header-text {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  flex: 1;
}

.header-mobile {
  display: block;
  text-align: center;
}

.box-card {
  margin-bottom: 10px;
  max-width: 1455px;
}

#mainContent a {
  color: #606266;
  text-decoration: none;
}

#mainContent a:visited {
  color: #606266;
}
</style>

<script>
import moment from 'moment';
import MomentTimeZone from 'moment-timezone';

window.moment = moment;
MomentTimeZone();
const instrumentOptions = ['RG 雨量計', 'TM 地滑計', 'STI 傾斜桿'];
export default {
  data() {
    return {
      selectedText: this.$t('rank.tm_value'),
      accumulatedRainfalls: [],
      accumulatedVariation: [],
      velocity: [],
      radio: '10',
      max_allow_rows: 10,
      numbers: Array.from({ length: 10 }, (_, i) => i + 1),
      selectedDate: '',
      flag: 0,
      key: 0,
      loading: false,
      timezone: moment.tz.guess(),
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      groupedOutput: {},
      TM: [],
      RG: [],
      STI: [],
      tableData: [],
      chart_time: [],
      checkAll: true,
      checkedinstruments: ['RG 雨量計', 'TM 地滑計', 'STI 傾斜桿'],
      instruments: instrumentOptions,
      isIndeterminate: false,
      ischeckedRG: true,
      ischeckedTM: true,
      ischeckedSTI: true,
      showNo: true,
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
  mounted() {
    this.getProject();
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'a':
          if (this.selectedText !== this.$t('rank.tm_value')) {
            this.selectedText = this.$t('rank.tm_value');
            this.getDate();
          }
          break;
        case 'b':
          if (this.selectedText !== this.$t('rank.tm_total')) {
            this.selectedText = this.$t('rank.tm_total');
            this.getDate();
          }
          break;
        case 'c':
          if (this.selectedText !== this.$t('rank.tm_rate')) {
            this.selectedText = this.$t('rank.tm_rate');
            this.getDate();
          }
          break;
        default:
          this.selectedText = this.$t('rank.tm_value');
      }
      this.key += 1;
    },
    handleRadioClick(value) {
      let v = parseInt(value, 10);
      // console.log(value);
      if (v == this.max_allow_rows) return;
      this.max_allow_rows = v;
      this.tableData = [];
      this.loadTableData();
    },
    ischecked() {
      let flag = false;
      this.ischeckedRG = false;
      this.ischeckedTM = false;
      this.ischeckedSTI = false;

      this.checkedinstruments.forEach(item => {
        if (item.includes('RG')) {
          this.ischeckedRG = true;
          flag = true;
        } else if (item.includes('TM')) {
          this.ischeckedTM = true;
          flag = true;
        } else if (item.includes('STI')) {
          this.ischeckedSTI = true;
          flag = true;
        }
      });

      if (!flag) this.showNo = false;
      else this.showNo = true;
    },
    handleCheckAllChange(val) {
      this.checkedinstruments = val ? instrumentOptions : [];
      this.isIndeterminate = false;
      console.log(this.checkedinstruments);
      this.ischecked();
    },
    handlecheckedinstrumentsChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.instruments.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.instruments.length;
      // console.log(this.checkedinstruments);
      this.ischecked();
    },
    addData(idx, rain_name, rain_value, tm_name, tm_value, sti_name, sti_value, rain_id, tm_id, sti_id) {
      const newData = {
        idx,
        rain_name,
        rain_value,
        tm_name,
        tm_value,
        sti_name,
        sti_value,
        rain_id,
        tm_id,
        sti_id,
      };
      this.tableData.push(newData);
    },
    headerStyle(row) {
      if (row.rowIndex === 0 && row.columnIndex === 0) {
        return {
          background: '#FFFFFF',
          color: '#FFFFFF',
        };
      }
      return {
        background: '#409EFF',
        color: '#FFFFFF',
      };
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      // console.log('Column Property:', column.property); // 调试输出，检查列属性

      if (column.property === 'sti_value' && (parseFloat(row.sti_value) > 0.3 || parseFloat(row.sti_value) < -0.3)) {
        return {
          backgroundColor: '#E6A23C',
        };
      } else if (column.property === 'tm_value') {
        if (this.selectedText === this.$t('rank.tm_rate') && (parseFloat(row.tm_value) > 10 || parseFloat(row.tm_value) < -10))
          return {
            backgroundColor: '#E6A23C',
          };
        else if (this.selectedText !== this.$t('rank.tm_rate') && (parseFloat(row.tm_value) > 30 || parseFloat(row.tm_value) < -30))
          return {
            backgroundColor: '#E6A23C',
          };
      }
      if (columnIndex === 0) {
        return {
          backgroundColor: '#C0C4CC',
          color: '#FFFFFF',
        };
      }
      return {};
    },
    loadTableData() {
      const rainResults = [];
      const tmResults = [];
      const stiResults = [];

      this.accumulatedRainfalls.sort((a, b) => b.rainfall - a.rainfall);
      this.accumulatedRainfalls.slice(0, this.max_allow_rows).forEach(item => {
        rainResults.push({
          name: item.instrument,
          value: `${item.rainfall}`,
          id: item.rain_id,
        });
      });

      this.accumulatedVariation.sort((a, b) => b.abs - a.abs);
      this.accumulatedVariation.slice(0, this.max_allow_rows).forEach(item => {
        tmResults.push({
          name: item.instrument,
          value: `${item.accumulated.toFixed(3)}`,
          id: item.tm_id,
        });
      });

      this.velocity.sort((a, b) => b.abs - a.abs);
      this.velocity.slice(0, this.max_allow_rows).forEach(item => {
        stiResults.push({
          name: item.instrument,
          value: `${item.velocity.toFixed(5)}`,
          id: item.sti_id,
        });
      });

      for (let i = 0; i < this.max_allow_rows; i++) {
        this.addData(
          i + 1,
          rainResults[i]?.name || '',
          rainResults[i]?.value || '',
          tmResults[i]?.name || '',
          tmResults[i]?.value || '',
          stiResults[i]?.name || '',
          stiResults[i]?.value || '',
          rainResults[i]?.id || '',
          tmResults[i]?.id[0] || '',
          stiResults[i]?.id[0] || '',
        );
      }
    },
    zScoreStandardize(data) {
      // Filter out null values
      const validData = data.filter(val => val !== null);

      const mean = validData.reduce((acc, val) => acc + val, 0) / validData.length;
      const stdDev = Math.sqrt(validData.map(val => (val - mean) ** 2).reduce((acc, val) => acc + val, 0) / validData.length);
      const zScores = validData.map(val => (val - mean) / stdDev);
      const filteredData = [];
      const filteredOutData = [];

      zScores.forEach((score, index) => {
        if (Math.abs(score) > 3.9) {
          filteredOutData.push(validData[index]);
        } else {
          filteredData.push(validData[index]);
        }
      });
      if (filteredOutData.length > 0)
        console.log('deleted: ', filteredOutData);
      return filteredData;
    },
    getRank() {
      console.log(this.groupedOutput);
      Object.keys(this.groupedOutput).forEach(key => {
        if (key.includes('RAIN')) {
          let result = 0;
          let id = '';
          this.groupedOutput[key].forEach(data => {
            result += data.reading2;
            id = data.instrument_id;
          });
          this.accumulatedRainfalls.push({
            instrument: key,
            rainfall: result,
            rain_id: id,
          });
        } else if (key.includes('TM')) {
          switch (this.selectedText) {
            case this.$t('rank.tm_value'): {
              let readings = this.groupedOutput[key].map(data => data.reading2);
              readings = this.zScoreStandardize(readings);
              let id = this.groupedOutput[key].map(data => data.instrument_id);
              let timestamps = this.groupedOutput[key].map(data => data.TIMESTAMP);
              let firstReading = readings[0];
              let lastReading = readings[readings.length - 1];
              let result = lastReading - firstReading;

              this.accumulatedVariation.push({
                instrument: key,
                accumulated: result,
                abs: Math.abs(result),
                tm_id: id,
              });
              break;
            }
            case this.$t('rank.tm_total'): {
              let readings = this.groupedOutput[key].map(data => data.reading2);
              readings = this.zScoreStandardize(readings);
              let id = this.groupedOutput[key].map(data => data.instrument_id);
              let maxAbsoluteValue = Math.max(...readings.map(Math.abs));
              let maxOriginalValue = readings.find(value => Math.abs(value) === maxAbsoluteValue);
              let result = maxOriginalValue;

              this.accumulatedVariation.push({
                instrument: key,
                accumulated: result,
                abs: Math.abs(result),
                tm_id: id,
              });
              break;
            }
            case this.$t('rank.tm_rate'): {
              let readings = this.groupedOutput[key].map(data => data.reading2);
              readings = this.zScoreStandardize(readings);
              let timestamps = this.groupedOutput[key].map(data => data.TIMESTAMP);
              let id = this.groupedOutput[key].map(data => data.instrument_id);

              let maxVelocity = 0;

              for (let i = 1; i < timestamps.length; i++) {
                let currentTimestamp = new Date(timestamps[i]);
                let previousTimestamp = new Date(timestamps[i]);
                previousTimestamp.setHours(currentTimestamp.getHours() - 1);

                let previousIndex = timestamps.findIndex(
                  timestamp => new Date(timestamp).getTime() === previousTimestamp.getTime(),
                );

                if (readings[previousIndex] !== null && readings[i] !== null && previousIndex !== -1) {
                  let currentReading = readings[i];
                  let previousReading = readings[previousIndex];

                  let velocity = currentReading - previousReading;

                  if (Math.abs(velocity) > Math.abs(maxVelocity)) {
                    maxVelocity = velocity;
                  }
                }
              }

              this.accumulatedVariation.push({
                instrument: key,
                accumulated: maxVelocity,
                abs: Math.abs(maxVelocity),
                tm_id: id,
              });
              break;
            }
            default:
              break;
          }

        } else if (key.includes('STI')) {
          let readings = this.groupedOutput[key].map(data => data.reading2);
          readings = this.zScoreStandardize(readings);
          const timestamps = this.groupedOutput[key].map(data => data.TIMESTAMP);
          const id = this.groupedOutput[key].map(data => data.instrument_id);

          let maxVelocity = 0;

          for (let i = 1; i < timestamps.length; i++) {
            const currentTimestamp = new Date(timestamps[i]);
            const previousTimestamp = new Date(timestamps[i]);
            previousTimestamp.setHours(currentTimestamp.getHours() - 1);

            const previousIndex = timestamps.findIndex(
              timestamp => new Date(timestamp).getTime() === previousTimestamp.getTime(),
            );

            if (readings[previousIndex] !== null && readings[i] !== null && previousIndex !== -1) {
              const currentReading = readings[i];
              const previousReading = readings[previousIndex];

              const velocity = currentReading - previousReading;

              if (Math.abs(velocity) > Math.abs(maxVelocity)) {
                maxVelocity = velocity;
              }
            }
          }

          this.velocity.push({
            instrument: key,
            velocity: maxVelocity,
            abs: Math.abs(maxVelocity),
            sti_id: id,
          });
        }
      });
      this.loadTableData();
    },
    getDate() {
      try {
        const start = new Date(this.selectedDate[0]);
        const start_range = new Date(start);
        const end = new Date(this.selectedDate[1]);
        const end_range = new Date(end);
        const diff = (end - start) / (1000 * 60 * 60 * 24);

        if (diff > 3) {
          this.$notifyInfo('選擇範圍不能超過三天');
          return;
        }

        this.loading = true;
        this.flag = 0;

        this.groupedOutput = {};
        this.tableData = [];
        this.chart_time = [];
        this.accumulatedRainfalls = [];
        this.accumulatedVariation = [];
        this.velocity = [];

        this.chart_time.push(this.selectedDate[0].toISOString());
        this.chart_time.push(this.selectedDate[1].toISOString());

        start_range.setHours(start.getHours() + 1);
        end_range.setHours(end.getHours() - 1);

        const prevday = new Date(this.selectedDate[0]);
        prevday.setDate(prevday.getDate() - 1);
        prevday.setHours(prevday.getHours() + 23);

        if (this.selectedText !== this.$t('rank.tm_rate')) {
          this.getOutput('TM', start, start_range);
          this.getOutput('TM', end_range, end);
        } else {
          this.getOutput('TM', prevday, end);
        }
        this.getOutput('STI', prevday, end);
        this.getOutput('RAIN', start, end);
      } catch (error) {
        this.$notifyInfo('請選擇日期');
      }
    },
    getInstrumentList(type) {
      if (type === 'STI')
        return this.STI.map(item => item._id);
      else if (type === 'TM')
        return this.TM.map(item => item._id);
      else if (type === 'RAIN')
        return this.RG.map(item => item._id);
    },
    async getOutput(type, startDate, endDate) {
      let startISO = startDate.toISOString();
      let endISO = endDate.toISOString();
      const instrumentList = this.getInstrumentList(type);
      return this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/output?type=all`,
        data: {
          date: [startISO, endISO],
          interval: 'all',
          instrument: instrumentList,
          timezone: this.timezone,
        },
      })
        .then(res => {
          if (!res.data.length) {
            this.$notifyInfo('該時段內沒有資料');
          } else {
            // Group data by instrument_id
            this.groupedOutput = res.data.reduce((acc, curr) => {
              if (!acc[curr.instrument_id]) {
                acc[curr.instrument_id] = [];
              }
              acc[curr.instrument_id].push(curr);
              return acc;
            }, this.groupedOutput || {});  // Use existing groupedOutput if it exists, otherwise create a new object

            // Sort each group by timestamp in ascending order
            for (let key in this.groupedOutput) {
              this.groupedOutput[key].sort((a, b) => new Date(a.TIMESTAMP) - new Date(b.TIMESTAMP));
            }

            // Create a map of instrument_id to name
            const combinedInstruments = this.TM.concat(this.RG, this.STI);
            const idToName = combinedInstruments.reduce((acc, curr) => {
              acc[curr._id] = curr.name;
              return acc;
            }, {});

            // Update keys in groupedOutput using the idToName map
            for (let id in this.groupedOutput) {
              if (idToName[id]) {
                if (this.groupedOutput[idToName[id]]) {
                  // If the key by name already exists, append the data
                  this.groupedOutput[idToName[id]] = this.groupedOutput[idToName[id]].concat(this.groupedOutput[id]);
                } else {
                  // Otherwise, create a new key with the name and assign the data
                  this.groupedOutput[idToName[id]] = this.groupedOutput[id];
                }
                delete this.groupedOutput[id];
              }
            }
            return res.data;
          }
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.flag += 1;
          // console.log(this.flag);
          if (this.selectedText !== this.$t('rank.tm_rate')) {
            if (this.flag === 4) {
              this.getRank();
              this.loading = false;
            }
          } else {
            if (this.flag === 3) {
              this.getRank();
              this.loading = false;
            }
          }
        });
    },
    async getProject() {
      this.loading = true;
      try {
        const res = await this.$axios({
          category: 'project',
          method: 'get',
          apiName: `${this.selectedProject._id}/station-instrument`,
        });

        res.data.forEach(station => {
          if (station.name.includes('TM')) {
            this.TM.push(...station.instrument);
            // console.log(station);
          } else if (station.name.includes('RAIN')) {
            this.RG.push(...station.instrument);
          } else if (station.name.includes('監測桿')) {
            const filteredInstruments = station.instrument.filter(instrument =>
              instrument.name.includes('-EW') || instrument.name.includes('-X'),
            );
            this.STI.push(...filteredInstruments);
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        const end = new Date();
        const start = new Date(end.getFullYear(), end.getMonth(), end.getDate());
        this.selectedDate = [start, end];
        this.getDate();
      }
    },
  },
};
</script>