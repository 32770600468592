<template>
  <div class="flex-column">
    <div class="flex-row">
      <enhanced-table
        v-if="!isLoading"
        :table-data="tableData"
        :table-options="tableOptions"
        :col-configs="colConfigs"
        :is-loading="isLoading"
      >
        <el-tabs
          slot="header"
          v-model="activeTab"
        >
          <el-tab-pane
            label="Online"
            name="online"
          />
          <el-tab-pane
            label="Offline"
            name="offline"
          />
        </el-tabs>
        <template v-slot:status="{ colConfig }">
          <el-table-column
            v-bind="colConfig"
            label="Active"
          >
            <template v-slot="{ row }">
              <status-dot
                v-if="row.status"
                :color="getDotColor(row.status)"
                class="dot"
              />
            </template>
          </el-table-column>
        </template>
        <template v-slot:actions="{ colConfig }">
          <el-table-column v-bind="colConfig">
            <template v-slot="{ row }">
              <el-button
                v-if="typeof row._id !== 'number'"
                @click="handleEdit(row)"
                type="primary"
                icon="el-icon-edit"
                circle
                size="small"
              />
            </template>
          </el-table-column>
        </template>
      </enhanced-table>
    </div>
    <edit-document-details-form
      v-if="showEditForm"
      :document-details="editingDocument"
      @success="getDocumentList"
      @close="showEditForm = false"
    />
  </div>
</template>

<script>
import moment from 'moment';
import EnhancedTable from '@/components/common/EnhancedTable';
import StatusDot from '@/components/common/StatusDot';
import EditDocumentDetailsForm from '@/components/document/EditDetailsForm';
const dataIntervalFormatter = value => {
  const d = moment.duration(value, 'milliseconds');
  const hours = Math.floor(d.asHours());
  const mins = Math.floor(d.asMinutes()) - hours * 60;
  const secs = Math.floor(d.asSeconds()) - hours * 60 - mins * 60;
  if (hours > 0 && mins > 0) {
    return `${hours}H ${mins}m`;
  } else if (hours > 0) {
    return `${hours}H`;
  } else if (mins > 0 && secs > 0) {
    return `${mins}m ${secs}s`;
  } else if (mins > 0) {
    return `${mins}m`;
  } else if (secs > 0) {
    return `${secs}s`;
  }
};
export default {
  components: {
    EnhancedTable,
    StatusDot,
    EditDocumentDetailsForm,
  },
  data() {
    return {
      isLoading: false,
      showEditForm: false,
      editingDocument: {},
      activeTab: 'online',
      watchingDocumentList: [],
      watchingDocumentNameList: [],
      documentList: [],
      documentReferList: {},
      tableOptions: {
        'row-key': '_id',
      },
      onlineColConfigs: [
        {
          label: 'Path',
          prop: 'path',
          'min-width': '300px',
        },
        {
          label: 'Counts',
          prop: 'count',
        },
        {
          label: 'Last Update Time',
          prop: 'updated_at',
          formatter(row, column, cellValue) {
            return cellValue ? moment(cellValue).format('YYYY/MM/DD HH:mm') : '';
          },
        },
        {
          label: 'Interval',
          prop: 'data_interval',
          formatter(row, column, cellValue) {
            return dataIntervalFormatter(cellValue);
          },
        },
        { slot: 'status' },
        { slot: 'actions' },
      ],
      offlineColConfigs: [
        {
          label: 'Name',
          prop: 'name',
        },
        {
          label: 'Last Update Time',
          prop: 'updated_at',
          formatter(row, column, cellValue) {
            return cellValue ? moment(cellValue).format('YYYY/MM/DD HH:mm') : '';
          },
        },
        {
          label: 'Interval',
          prop: 'data_interval',
          formatter(row, column, cellValue) {
            return dataIntervalFormatter(cellValue);
          },
        },
        { slot: 'actions' },
      ],
      onlineDataList: [],
      offlineDataList: [],
    };
  },
  computed: {
    tableData() {
      if (this.activeTab === 'online') {
        // return []
        return this.onlineDataList;
      } else {
        return this.offlineDataList;
      }
    },
    colConfigs() {
      if (this.activeTab === 'online') {
        return this.onlineColConfigs;
      } else {
        return this.offlineColConfigs;
      }
    },
  },
  mounted() {
    this.isLoading = true;
    this.getWatchingDocuments()
      .then(() => {
        this.getDocumentList();
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    handleEdit(document) {
      this.editingDocument = { ...document };
      this.showEditForm = true;
    },
    getDotColor(status) {
      if (status === 'active') {
        return 'green';
      } else if (status === 'down') {
        return 'red';
      }
    },
    setOnlineList() {
      const watchingDocumentNameList = [];
      const keyList = Object.keys(this.watchingDocumentList);
      this.onlineDataList = keyList
        .map((key, index) => {
          if (this.watchingDocumentList[key].length < 1) return;
          const children = this.watchingDocumentList[key]
            .filter(file => this.checkFileExtension(file, 'dat'))
            .map(child => {
              if (this.documentReferList[child]) {
                watchingDocumentNameList.push(child);
                return {
                  ...this.documentReferList[child],
                  path: child,
                };
              }
            })
            .filter(ele => ele !== undefined);
          if (children.length > 0) {
            return {
              _id: Number(index),
              path: key,
              count: children.length,
              children,
            };
          }
        })
        .filter(ele => ele !== undefined);
      console.log(this.onlineDataList);
      this.watchingDocumentNameList = watchingDocumentNameList;
    },
    setOfflineList() {
      this.offlineDataList = this.documentList.filter(document => !this.watchingDocumentNameList.includes(document.name));
    },
    getDocumentList() {
      return this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'document',
      })
        .then(res => {
          this.documentList = res.data;
          const obj = {};
          this.documentList.forEach(ele => {
            obj[ele.name] = ele;
          });
          this.documentReferList = obj;
        })
        .then(() => {
          this.setOnlineList();
          this.setOfflineList();
        });
    },
    getWatchingDocuments() {
      return this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'watching',
      }).then(res => {
        this.watchingDocumentList = res.data;
      });
    },
    checkFileExtension(filename, extension) {
      return filename.split('.').pop() === extension;
    },
  },
};
</script>
