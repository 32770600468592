<template>
  <div
    @click="click"
    class="focus-point"
  >
    <div
      ref="pin"
      :style="pinStyle"
      :class="[`icon-${shape} is-${size} is-border-${borderColor}`]"
      class="focus-point-pin set-marker-pin"
    />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        x: 50,
        y: 50,
      }),
    },
    shape: {
      type: String,
      required: true,
      default: 'square',
    },
    size: {
      type: String,
      required: true,
      default: 'medium',
    },
    borderColor: {
      type: String,
      required: true,
      default: 'white',
    },
  },
  data() {
    return {
      pin: null,
    };
  },
  computed: {
    // backgroundColor() {
    //   const exception = ['cross', 'star']
    //   if (exception.includes(this.shape)) {
    //     return 'none'
    //   } else return 'rgba(255, 0, 0, 0.6)'
    // },
    pinStyle() {
      if (!this.pin) {
        return null;
      }
      const { width, height } = this.pin;
      // if (!this.value) {
      //   return {
      //     left: this.s(50, width),
      //     top: this.s(50, height)
      //   }
      // }
      const { x, y } = this.value;
      return {
        left: this.s(x, width),
        top: this.s(y, height),
        // background: this.backgroundColor,
        color: 'rgba(255, 0, 0, 0.6)',
      };
    },
  },
  mounted() {
    this.pin = this.$refs.pin.getBoundingClientRect();
  },
  methods: {
    click({ clientX, clientY }) {
      const { left, width, top, height } = this.$el.getBoundingClientRect();
      console.log('TCL: click -> { left, width, top, height }', {
        left,
        width,
        top,
        height,
      });
      this.$emit('input', {
        ...this.value,
        x: this.getMarkerCoordinates(clientX, left, width),
        y: this.getMarkerCoordinates(clientY, top, height),
      });
    },
    getMarkerCoordinates(c, mw, w) {
      return Math.round(((c - mw) / w) * 100);
    },
    s(w, mw) {
      return `calc(${w}% - ${mw / 2}px)`;
    },
  },
};
</script>

<style lang="stylus" scoped>
.set-marker-pin {
  z-index: 99
}
</style>
