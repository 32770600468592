<template>
  <div>
    <el-card v-loading="loading">
      <el-select v-model="selectInstrumentGroup" placeholder="請選擇孔位" @change="changeInstrumentGroup">
        <el-option
          v-for="item in instrumentGroupList"
          :key="item.name"
          :label="item.name"
          :value="item.name">
        </el-option>
      </el-select>

      <span>初值時間 {{ startDate }}</span>
      <hr>
      <hot-table id="hot-container" ref="hot" root="hot" :settings="settings" />
    </el-card>
  </div>
</template>

<script>
import moment from 'moment';
import { HotTable } from '@handsontable/vue';
export default {
  components: {
    HotTable,
  },
  props: {
    date: {
      required: true,
    },
    outputList: {
      required: true,
    },
    getInstrumentType: {
      required: true,
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    settings() {
      const vm = this;
      return {
        data: this.instrumentsInGroup,
        fixedColumnsLeft: 1,
        colHeaders: this.columnsHeader,
        rowHeaders: true,
        manualRowResize: true,
        manualColumnResize: true,
        manualRowMove: true,
        customBorders: true,
        columnSorting: true,
        height: 600,
        width: '100%',
        columns: this.columns,
        licenseKey: 'non-commercial-and-evaluation',

        beforeChange(changes, source) {
          // [[row, prop, oldVal, newVal], ...]

          //for(let change of changes){
          //  let row = change[0];
          //  vm.instrumentsInGroup[row][change[1]] = Number(change[3]);
          //  if(change[1] === 'A+' && !isNaN(Number(change[3])) && !isNaN(Number(vm.instrumentsInGroup[row]['A-'])))
          //  {
          //    console.log(`${change[3]} ${vm.instrumentsInGroup[row]['A-']}`);
          //    vm.instrumentsInGroup[row].result = vm.computedOutput(vm.instrumentsInGroup[row]).result;
          //  }
          //  if(change[1] === 'A-' && !isNaN(Number(change[3])) && !isNaN(Number(vm.instrumentsInGroup[row]['A+'])))
          //  {
          //    console.log(`${change[3]} ${vm.instrumentsInGroup[row]['A+']}`);
          //    vm.instrumentsInGroup[row].result = vm.computedOutput(vm.instrumentsInGroup[row]).result;
          //  }
          //}
          for(let change of changes){
            let row = change[0];
            vm.instrumentsInGroup[row][change[1]] = Number(change[3]);
          }

          let depthList = vm.instrumentsInGroup.map(ele => ele.depth).sort((a, b)=> a-b);
          //console.log(depthList);
          let acc = 0;
          for(let depth of depthList){
            let depthIndex = vm.instrumentsInGroup.findIndex(ele=>ele.depth===depth);
            if(depthIndex < 0) continue;
            let result = vm.computedOutput(vm.instrumentsInGroup[depthIndex]).result;
            //console.log(result);
            if(result !== 0 && !result) continue;
            acc += Number(result);
            vm.instrumentsInGroup[depthIndex].result = acc;
          }
        },
      };
    },
  },
  data(){
    return {
      columns: [
        {
          data: 'name',
        },
        {
          data: 'depth',
        },
        {
          data: 'unit',
        },
        {
          data: 'A+initial',
          readOnly: true,
        },
        {
          data: 'A-initial',
          readOnly: true,
        },
        {
          data: 'F1',
        },
        {
          data: 'A+',
        },
        {
          data: 'A-',
        },
        {
          data: 'result',
          readOnly: true,
        },
        {
          data: 'lastOutput',
          readOnly: true,
        },
        {
          data: 'WL',
          readOnly: true,
        },
        {
          data: 'AL',
          readOnly: true,
        },
        {
          data: 'WSL',
          readOnly: true,
        },
        {
          data: 'remark',
          editor: 'select',
          selectOptions: ['', '阻礙', '損壞', '無法進入', '不合格', '暫停量測', '不明原因', '碰撞', '重新安裝'],
        },
      ],
      columnsHeader: [
        '儀器名稱',
        '深度',
        '單位',
        '正向初值',
        '負向初值',
        'F1',
        'A+',
        'A-',
        '變化量',
        '前次變化量',
        '注意值',
        '警戒值',
        '行動值',
        '備註',
      ],

      loading: false,

      selectInstrumentGroup: '',
      instrumentGroup: null,
      instrumentGroupList: [],

      instrumentsInGroup: [],

      startDate: moment().format('yyyy-MM-DD HH:mm'),
    };
  },
  async mounted() {
    console.log('test');
    this.getInstrumentGroupList();
    
  },
  methods:{
    async getInstrumentGroupList(){
      this.loading = true;
      this.instrumentGroupList = [];
      await this.getGroup();

      this.instrumentGroupList = this.instrumentGroupList.map(group=>{
        let isManual = true;

        // 如果儀器組包含非manual的內容，
        let instruments = group.instrumentList.map(groupIns=>{
          let index = this.outputList.findIndex(ins => `${groupIns.instrument}` === `${ins._id}`);
          if(index >= 0){
            return {
              ...this.outputList[index],
              depth: groupIns.depth,
            };
          }
          else{
            isManual = false;
            return null;
          }
        });

        if(!isManual) return null;

        return {
          instruments,
          type: group.type,
          name: group.name,
          startDate: instruments.startDate,
          axis: (group.type === '"VERTICAL_DISTRIBUTION"') ? group.yAxis : group.xAxis,
        };
      }).filter(group => group);
      this.loading = false;
    },
    async getGroup(){
      let res = await this.$store
        .dispatch('data/getProjectInstrumentGroupList', {
          projectId: this.selectedProject._id,
        });
      this.instrumentGroupList = res;
    },
    changeInstrumentGroup(){
      //console.log(this.instrumentGroup);
      this.instrumentGroup = this.instrumentGroupList.find(ele => ele.name === this.selectInstrumentGroup);
      this.instrumentsInGroup = this.instrumentGroup.instruments.sort((a, b) => b.depth - a.depth);
      //this.instrumentGroupType = this.instrumentGroup.type;
      this.startDate = this.instrumentGroup.startDate;
      this.updateTable();
    },
    isPass(){
      let ispass = true;
      let isnopass = [];
      for(let ins of this.instrumentsInGroup){
        //console.log(ins);
        let ispassInthisIns = (ins.name) && (ins.depth || ins.depth === 0) && (ins.area) && (ins.station) && (ins.unit) && (ins['A+'] || ins['A+'] === 0) && (ins['A-'] || ins['A-'] === 0) && (ins.F1 || ins.F1 === 0);
        ispass &&= ispassInthisIns;
        if(!ispassInthisIns) {
          isnopass.push(ins);
          //console.log(!(ins.name));
          //console.log(!(ins.depth || ins.depth === 0));
          //console.log(!(ins.area));
          //console.log(!(ins.station));
          //console.log(!(ins.unit));
          //console.log(!(ins['A+'] || ins['A+'] === 0));
          //console.log(!(ins['A-'] || ins['A-'] === 0));
          //console.log(!(ins.F1 || ins.F1 === 0));
        }
      }
      //console.log(ispass);
      //console.log(isnopass);
      if(!ispass){
        this.$notifyError('仍有欄位尚未填妥');
      }

      return ispass;
    },
    async upload(){
      if(!this.isPass) return;

      this.loading = true;

      let instrumentList = this.instrumentsInGroup.map(ele=>{
        return {
          ...ele,
          startDate: this.startDate,
        };
      });

      //console.log(instrumentList);

      await this.$axios({
        category: 'manual',
        method: 'put',
        apiName: 'addManualInstrumentGroup',
        data: {
          name: this.selectInstrumentGroup,
          instrumentList,
          projectId: this.selectedProject._id,
          instrumentType: this.getInstrumentType,
          type: this.instrumentGroupType,
          yAxis: this.instrumentGroupType === 'VERTICAL_DISTRIBUTION' ? this.instrumentGroup.axis : { min: -10, max: 10, unit: instrumentList[0].unit}, 
          xAxis: this.instrumentGroupType !== 'VERTICAL_DISTRIBUTION' ? this.instrumentGroup.axis : { min: -10, max: 10, unit: instrumentList[0].unit}, 
        },
      });
      
      this.loading = false;
    },
    uploadOutput(){
      //console.log(this.outputList);
      //console.log(this.instrumentsInGroup);
      //let outputList = this.outputList.filter(output=>{
      //  let isnull = Object.keys(output).length == 0 || Object.values(output).reduce((accumulator, currentValue) => accumulator && [''].includes(currentValue),true);
      //  return !isnull;
      //});
      this.$axios({
        category: 'manual',
        method: 'put',
        apiName: 'addManualOutput',
        data: {
          outputs: this.instrumentsInGroup,
          date: this.date,
        },
      }).then(()=>{
        this.$notifySuccess('新增成功');
      });
    },
    computedOutput(output){
      let A_plus = output['A+'] ? output['A+'] : 0;
      let A_minus = output['A-'] ? output['A-'] : 0;
      let A_plusInitial = output['A+initial'] ? output['A+initial'] : 0;
      let A_minusInitial = output['A-initial'] ? output['A-initial'] : 0;
      //  (F5(R1)^5＋F4(R1)^4＋F3(R1)^3＋F2(R1)^2+F1(R1)+F0 ) - (F5(R0)^5＋F4(R0)^4＋F3(R0)^3＋F2(R0)^2+F1(R0)+F0 )+offset
      let front =
        output.F1 * A_plus -
        output.F1 * A_minus ;
      let rear =
        output.F1 * A_plusInitial -
        output.F1 * A_minusInitial ;
      let reading2 = (front - rear + output.offset).toFixed(2);
      return {
        'A+': A_plus,
        'A-': A_minus,
        result : (output['A+']===0 || output['A+']) && (output['A-']===0 || output['A-']) ? reading2 : null,
        remark: output.remark,
      };
    },
    updateTable() {
      if(this.instrumentsInGroup)
        setTimeout(() => {
          this.$refs.hot.hotInstance.loadData(this.instrumentsInGroup);
        }, 100);
    },
  },
};
</script>

<style>

</style>