  <template >
  <page-export :useUrl="this.useUrl"></page-export>
</template>

<script>
export default {
  components: {
    PageExport: () => import('@/components/Export'),
  },
  props: {
    useUrl: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
