import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/';
import './registerServiceWorker';
import i18n from './i18n/index.js';

import Element from 'element-ui';
import device from 'vue-device-detector';
import '@/assets/bulma.scss';
import '@/assets/stylus/index.styl';
import 'element-ui/lib/theme-chalk/index.css';

import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import stockInit from 'highcharts/modules/stock';
import Exporting from 'highcharts/modules/exporting';
import SolidGauge from 'highcharts/modules/solid-gauge';
import hcMore from 'highcharts/highcharts-more';
import indicators from 'highcharts/indicators/indicators';
import trendline from 'highcharts/indicators/trendline';
//import Boost from 'highcharts/modules/boost.js';

import Transitions from 'vue2-transitions';
import lodash from 'lodash';

import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

import Vant from 'vant';
import { Locale as VantLocale } from 'vant';
import zhTW from 'vant/es/locale/lang/zh-TW';
import 'vant/lib/index.css';

import '@/plugins/notify.js';
import '@/plugins/moment.js';
import { callAxios } from '@/lib/api/axios';

import '@/assets/icomoon/style.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import ECharts from 'vue-echarts'; // refers to components/ECharts.vue in webpack
import echarts from 'echarts';

// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/chart/effectScatter';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/title';

import 'highcharts/css/highcharts.css';
// import vuetify from './plugins/vuetify';

// If you want to use ECharts extensions, just import the extension package and it will work
// Taking ECharts-GL as an example:
// You only need to install the package with `npm install --save echarts-gl` and import it as follows
// import 'echarts-gl';

// register component to use
Vue.component('v-chart', ECharts);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('draggable-resizable', VueDraggableResizable);
library.add(fas);

Vue.use(Transitions);
//Vue.use(require('vue-moment'));
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value),
});

Vue.use(device);
Vue.use(Vant);
VantLocale.use('zh-TW', zhTW);

HighchartsMore(Highcharts);
stockInit(Highcharts);
Exporting(Highcharts);
SolidGauge(Highcharts);
hcMore(Highcharts);
indicators(Highcharts);
trendline(Highcharts);
//Boost(Highcharts);

Vue.use(VueHighcharts);

Vue.prototype.$axios = callAxios;
Vue.prototype.$lodash = lodash;
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  // vuetify,
  render: h => h(App),
}).$mount('#app');
