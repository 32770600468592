import { COLORS } from '@/constants/mapSettings.js';

const dotList = [
  //{
  //  color: COLORS.SUSPENSION,
  //  label: 'dots.wsl',
  //  value: 'WSL',
  //},
  //{
  //  color: COLORS.ALERT,
  //  label: 'dots.alert',
  //  value: 'AL',
  //},
  //{
  //  color: COLORS.WARNING,
  //  label: 'dots.wl',
  //  value: 'WL',
  //},
  {
    color: COLORS.GREEN,
    label: 'dots.normal',
    value: 'GREEN',
  },
  {
    color: COLORS.HEAVY_RAIN,
    label: 'dots.heavyRain',
    value: 'HeavyRain',
  },
  {
    color: COLORS.TORRENTIAL_RAIN,
    label: 'dots.torrentialRain',
    value: 'TorrentialRain',
  },
  {
    color: COLORS.SUPER_TORRENTIAL_RAIN,
    label: 'dots.superTorrentialRain',
    value: 'SuperTorrentialRain',
  },
  {
    color: COLORS.EXTREME_TORRENTIAL_RAIN,
    label: 'dots.extremeTorrentialRain',
    value: 'ExtremeTorrentialRain',
  },
];

const getStatusColor = status => {
  const statusList = ['WSL', 'WL', 'AL', 'GREEN', 'down', 'HEAVY_RAIN', 'TORRENTIAL_RAIN', 'SUPER_TORRENTIAL_RAIN' , 'EXTREME_TORRENTIAL_RAIN'];
  const styleList = [
    COLORS.SUSPENSION,
    COLORS.WARNING,
    COLORS.ALERT,
    COLORS.GREEN,
    COLORS.GREY,
    COLORS.HEAVY_RAIN,
    COLORS.TORRENTIAL_RAIN,
    COLORS.SUPER_TORRENTIAL_RAIN,
    COLORS.EXTREME_TORRENTIAL_RAIN,
  ];
  const styleIndex = statusList.findIndex(ele => {
    return ele.includes(status);
  });
  return styleList[styleIndex];
};

export { dotList, getStatusColor };
