<template>
  <Chart :useUrl="this.useUrl"></Chart>
</template>

<script>
import Chart from '@/components/chart/index';
export default {
  components: {
    Chart,
  },
  props: {
    useUrl: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
