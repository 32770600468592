<template>
  <div class="columns is-multiline" v-loading="!isDataLoaded" element-loading-text="LOADING...">
    <div class="column" style="min-width: 52.5%;" id="map" v-if="isDataLoaded">
      <el-card style="height: 100%;">
        <div slot="header" class="clearfix" style="text-align: center;">
            <span class="header-text">GIS 平面圖</span>
        </div>
        <MapComponent @componentMounted="handleComponentMounted" :project-data="projectData"></MapComponent>
      </el-card>
    </div>
    <div class="column" style="min-width: 47.5%;" v-if="isDataLoaded">
      <el-card style="height: 100%;">
        <div slot="header" class="clearfix" style="text-align: center;">
            <span class="header-text">儀器狀態</span>
        </div>
        <instrumentStatus @componentMounted="handleComponentMounted" :project-data="projectData"></instrumentStatus>
      </el-card>
    </div>
    <div class="column" style="min-width: 52.5%;" v-if="isDataLoaded">
      <el-card style="height: 100%;">
        <div slot="header" class="clearfix" style="text-align: center;">
          <span class="header-text">系統健康統計表</span>
        </div>
        <systemHealth @componentMounted="handleComponentMounted" :project-data="projectData" :instruments-list="instrumentList"></systemHealth>
      </el-card>
    </div>
    <div class="column" style="width: 47.5%;" v-if="isDataLoaded">
      <el-card style="height: 100%;">
        <div slot="header" class="clearfix" style="text-align: center;">
          <span class="header-text">儀器狀態</span>
        </div>
        <div style="display: flex; justify-content: center; align-items: center; margin-top: 30px;">
          <div id="slopeChart"></div>
          <div id="iotChart"></div>
        </div>
      </el-card>
    </div>
    <div class="column" style="width: 100%;" v-if="isDataLoaded">
      <SMS @componentMounted="handleComponentMounted" :simplify="true"></SMS>
    </div>
  </div>
</template>

<style scoped>
  #slopeChart, #iotChart {
    height: 280px;
    width: 330px;
  }
  .header-text {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .grid-item {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .system-health {
    grid-column: span 1;
  }

  .sms {
    grid-column: span 2;
  }
  
  @media (max-width: 1300px) {
    .grid-container {
      grid-template-columns: 1fr 1fr;
    }

    .system-health, .sms {
      grid-column: span 2;
    }

    .order-change:first-child {
      order: 1;
    }

    .order-change:last-child {
      order: 2;
    }

    .order-system-health {
      order: 3;
    }

    .order-sms {
      order: 4;
    }
  }
</style>

<script>
import MapComponent from '../components/map/MapComponets.vue';
import instrumentStatus from './instrumentStatus.vue';
import systemHealth from './systemHealth.vue';
import SMS from './SMS.vue';

export default {
  components: {
    MapComponent,
    systemHealth,
    instrumentStatus,
    SMS,
  },
  data() {
    return {
      componentsMounted: 0, 
      isDataLoaded: false,
      projectData: null,
      instrumentList: [],
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
  mounted() {
    if (this.selectedProject._id === '633d2757a4fbd61d20ee34ae') {
      this.getProject();
    } else {
      this.$router.replace({ name: 'DashboardOld' });
    }
  },
  methods: {
    handleComponentMounted() {
      this.componentsMounted++;
      if (this.componentsMounted === 4) {
        //this.loading = false;
      }
    },
    getProject() {
      this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/area-station-instrument`,
      })
        .then(res => {
          this.projectData = res.data;
          res.data.area.map(area => {
            area.station.map(instruments => {
              instruments.instrument.map(item => {
                this.instrumentList.push(item);
              });
            });
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          //console.log(this.instrumentList);
          this.isDataLoaded = true;
        });
    },
  },
};
</script>