<template>
  <el-table
    :data="allInstrument"
    border
    :cell-style="rowStyle"
    :header-cell-style="{ background: '#409EFF', color: '#fff', textAlign: 'center'}"
  >
    <el-table-column
      prop="type"
      label="儀器類型"
      min-width="10"
    >
    <template slot-scope="scope">
      {{ getContent(scope.row.type) }}
    </template>
    </el-table-column>
    <el-table-column
      prop="instrument"
      label="儀器數量"
      min-width="10"
    >
    </el-table-column>
    <el-table-column
      prop="GREEN"
      label="正常"
      min-width="10"
    >
    </el-table-column>
    <el-table-column
      prop="AL"
      label="警戒值"
      min-width="10">
      <template slot-scope="scope">
        <div v-if="scope.row.AL !== 0">
          <el-popover placement="top" trigger="hover">
            <div v-for="(AL, index) in scope.row.AL_name" :key="index">
              <a :href="'/chart?instrument=' + scope.row.AL_id[index]" target="_blank">
                <p class="multiline">{{ AL }}</p>
              </a>
            </div>
            <span slot="reference">{{ scope.row.AL }}</span>
          </el-popover>
        </div>
        <div v-else>
          <span>{{ scope.row.AL }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="WSL"
      label="行動值"
      min-width="10">
      <template slot-scope="scope">
        <div v-if="scope.row.WSL !== 0">
          <el-popover placement="top" trigger="hover">
            <div v-for="(WSL, index) in scope.row.WSL_name" :key="index">
              <a :href="'/chart?instrument=' + scope.row.WSL_id[index]" target="_blank">
                <p class="multiline">{{ WSL }}</p>
              </a>
            </div>
            <span slot="reference">{{ scope.row.WSL }}</span>
          </el-popover>
        </div>
        <div v-else>
          <span>{{ scope.row.WSL }}</span>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    projectData: {
      type: Object,
      default: () => ({}),
    },
  },
  name: 'instrumentStatus',
  data() {
    return {
      iotChart: '',
      slopeChart: '',
      instrumentType: ['STI', 'MC', 'ELP', 'SAA', 'TM', 'LC', 'RAIN', 'DS', 'ETI'],
      instrumentList: [],
      instrumentGroup: [],
      allInstrument: [{
        type: 'TM',
        instrument: 0,
        GREEN: 0,
        AL: 0,
        AL_name: [],
        AL_id: [],
        WSL: 0,
        WSL_name: [],
        WSL_id: [],
        station: '',
      }, {
        type: 'RAIN',
        instrument: 0,
        GREEN: 0,
        AL: 0,
        AL_name: [],
        AL_id: [],
        WSL: 0,
        WSL_name: [],
        WSL_id: [],
        station: '',
      }, {
        type: 'STI',
        instrument: 0,
        GREEN: 0,
        AL: 0,
        AL_name: [],
        AL_id: [],
        WSL: 0,
        WSL_name: [],
        WSL_id: [],
        station: '',
      }, {
        type: 'ELP',
        instrument: 0,
        GREEN: 0,
        AL: 0,
        AL_name: [],
        AL_id: [],
        WSL: 0,
        WSL_name: [],
        WSL_id: [],
        station: '',
      }, {
        type: 'MC',
        instrument: 0,
        GREEN: 0,
        AL: 0,
        AL_name: [],
        AL_id: [],
        WSL: 0,
        WSL_name: [],
        WSL_id: [],
        station: '',
      }, {
        type: 'SAA',
        instrument: 0,
        GREEN: 0,
        AL: 0,
        AL_name: [],
        AL_id: [],
        WSL: 0,
        WSL_name: [],
        WSL_id: [],
        station: '',
      }, {
        type: 'LC',
        instrument: 0,
        GREEN: 0,
        AL: 0,
        AL_name: [],
        AL_id: [],
        WSL: 0,
        WSL_name: [],
        WSL_id: [],
        station: '',
      }, {
        type: 'DS',
        instrument: 0,
        GREEN: 0,
        AL: 0,
        AL_name: [],
        AL_id: [],
        WSL: 0,
        WSL_name: [],
        WSL_id: [],
        station: '',
      }, {
        type: 'ETI',
        instrument: 0,
        GREEN: 0,
        AL: 0,
        AL_name: [],
        AL_id: [],
        WSL: 0,
        WSL_name: [],
        WSL_id: [],
        station: '',
      }],
    };
  },
  mounted() {
    this.getProject();
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
  methods: {
    drawChart() {
      let iotData = [
        { value: 0, name: '正常', itemStyle: '#67C23A' },
        { value: 0, name: '警戒值', itemStyle: '#E6A23C' },
        { value: 0, name: '行動值', itemStyle: '#F56C6C' },
      ];

      let slopeData = [
        { value: 0, name: '正常', itemStyle: '#67C23A' },
        { value: 0, name: '警戒值', itemStyle: '#E6A23C' },
        { value: 0, name: '行動值', itemStyle: '#F56C6C' },
      ];

      this.allInstrument.map(inst => {
        if (inst.type === 'STI' || inst.type === 'MC') {
          iotData[0].value += inst.GREEN;
          iotData[1].value += inst.AL;
          iotData[2].value += inst.WSL;
        } else {
          slopeData[0].value += inst.GREEN;
          slopeData[1].value += inst.AL;
          slopeData[2].value += inst.WSL;
        }
      });
      this.iotChart = this.$echarts.init(document.getElementById('iotChart'));
      this.slopeChart = this.$echarts.init(document.getElementById('slopeChart'));
      this.iotChart.setOption(this.getChartOption('IoT 測站', iotData));
      this.slopeChart.setOption(this.getChartOption('邊坡測站', slopeData));
    },
    getChartOption(titleText, data) {
      const total = data.reduce((acc, cur) => acc + cur.value, 0);
      const percentages = data.map(item => {
        return (item.value / total * 100).toFixed(2);
      });
      
      let visible = {AL: false, WSL: false};

      visible.AL = data[1].value > 0 ? true : false;
      visible.WSL = data[2].value > 0 ? true : false;

      return {
        title: {
          text: titleText,
          subtext: '',
          x: 'right',
          y: 'top',
        },
        grid: { 
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        color: ['#67C23A', '#E6A23C', '#F56C6C'],
        legend: {
          trigger: 'item',
          orient: 'vertical',
          icon: 'circle',
          align: 'left',
          inactiveColor :'#909399',
          x: 'right',
          y: 'bottom',
          formatter (name) {
            const index = data.findIndex(item => item.name === name);
            let percentage = percentages[index] || 0;
            if (isNaN(percentage))
              return `${name}  (0.00%)`;
            else 
              return `${name}  (${percentage}%)`;
          },
          data: ['正常', '警戒值', '行動值'],
          selected: {
            '警戒值': visible.AL,
            '行動值': visible.WSL,
          },
        },
        series: [
          {
            name: titleText,
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            center: ['40%', '50%'],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
              color(params) {
                const colorList = ['#67C23A', '#E6A23C', '#F56C6C'];
                return colorList[params.dataIndex];
              },
            },
            data,
          },
        ],
      };
    },
    getContent(type) {
      const typeMap = {
        'STI': 'STI 傾斜桿',
        'MC': 'MC 土壤濕度計',
        'ELP': 'ELP 水壓計',
        'SAA': 'SAA 陣列式傾斜儀',
        'TM': 'TM 地滑計',
        'LC': 'LC 荷重計',
        'RAIN': 'RG 雨量計',
        'DS': 'DS 水位計',
        'ETI': 'ETI 傾斜計',
      };
      return typeMap[type] || type;
    },
    formattedALName(AL_name) {
      return AL_name.join('\n');
    },
    formattedWSLName(WSL_name) {
      return WSL_name.join('\n');
    },
    rowStyle() {
      return 'text-align:center';
    },
    incrementInstrument(instrumentArray, type, group_status, name, id) {
      const instrument = instrumentArray.find(instrument => instrument.type === type);
      const slope = ['TK172+819W-MC-01', 'TK172+780W-MC-03', 'TK172+802W-MC-01', 'TK123+584E-MC-01-2', 'TK123+584E-MC-01-1', 'TK93+840E-MC-01', 'TK93+840E-MC-02', 'TK93+889E-MC-02'];

      if (instrument) {
        let result = slope.some(sub => name === sub);
        if (result) {
          instrument.station = 'slope';
        }
        instrument.instrument += 1;
        if (instrument[group_status] !== undefined) {
          instrument[group_status] += 1;
          if (group_status === 'AL') {
            instrument.AL_name.push(name);
            instrument.AL_id.push(id);
          } else if (group_status === 'WSL') {
            instrument.WSL_name.push(name);
            instrument.WSL_id.push(id);
          }
        }
      }
    },
    updateInstrument() {
      for (const group in this.instrumentGroup) {
        this.instrumentType.forEach(type => {
          if (this.instrumentGroup[group][0].name.includes(type)) {
            var group_status;
            if(this.instrumentGroup[group].group_status === 'down')
              group_status = 'GREEN';
            else group_status = this.instrumentGroup[group].group_status;
            
            const instrumentName = this.instrumentGroup[group][0].name;
            const instrumentId = this.instrumentGroup[group][0].id;
            this.incrementInstrument(this.allInstrument, type, group_status, instrumentName, instrumentId);
          }
        });
      }
    },
    getProject() {
      this.getInstrumentStatus(this.projectData, this.instrumentType);
      this.getInstrumentGroup();
      this.updateInstrument();
      this.drawChart();
      //console.log(this.instrumentGroup);
    },
    getInstrumentGroup() {
      const priorityOrder = ['down', 'WSL', 'AL', 'GREEN'];

      this.instrumentGroup = this.instrumentList.reduce((acc, item) => {
        if (item.class !== '') {
          if (acc[item.class]) {
            acc[item.class].push(item);
            if(item.status === 'down') item.status = 'GREEN';
            // Determine group_status based on priorityOrder
            const itemPriority = priorityOrder.indexOf(item.status);
            const currentGroupStatusPriority = priorityOrder.indexOf(acc[item.class].group_status);

            if (itemPriority < currentGroupStatusPriority) {
              acc[item.class].group_status = item.status;
            }
          } else {
            acc[item.class] = [item];
            // Initially set group_status to the item's status
            acc[item.class].group_status = item.status;
          }
        }
        return acc;
      }, {});
    },
    getInstrumentStatus(data, type) {
      //console.log(data);
      type.forEach(t => {
        data.area.map(area => {
          let type;
          if(area.name.includes('邊坡'))
            type = 'slope';
          else if(area.name.includes('IoT'))
            type = 'iot';
          else
            type = 'iot';
          area.station.map(instruments => {
            const instru = instruments.instrument.filter(item => item.latestVersion.name.includes(t) && !item.latestVersion.name.includes('(Batt)'))
              .map(item => ({
                name: item.latestVersion.name,
                status: item.status,
                class: item.latestVersion.class,
                id: item._id,
                type,
              }));
            this.instrumentList.push(...instru);
          });
        });
      });
    },
  },
};
</script>

<style>
  .multiline {
    white-space: pre-line;
  }
</style>