<template>
  <el-table
    :data="sys"
    border
    :cell-style="rowStyle"
    :header-cell-style="{ background: '#409EFF', color: '#fff', textAlign: 'center'}"
  >
    <el-table-column
      prop="type"
      label="類別"
    >
    </el-table-column>
    <el-table-column
      prop="instrument"
      label="儀器或設備數量"
    >
    </el-table-column>
    <el-table-column
      prop="GREEN"
      label="正常"
    >
    </el-table-column>
    <el-table-column
      prop="err"
      label="異常(含未更新)">
      <template slot-scope="scope">
        <div v-if="scope.row.err !== 0">
          <el-popover
            placement="top"
            trigger="hover">
            <div v-for="(err, index) in scope.row.err_name" :key="index">
              <p class="multiline" @click="handleClick(err)">{{ err }}</p>
            </div>
            <span slot="reference">{{ scope.row.err }}</span>
          </el-popover>
        </div>
        <div v-else>
          <span>{{ scope.row.err }}</span>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    projectData: {
      type: Object,
      default: () => ({}),
    },
    instrumentsList: {
      type: Object,
      default: () => ({}),
    },
  },
  name: 'systemHealth',
  data() {
    return {
      resData: null,
      logger: ['028', '031', '066', '070', '077', '078', '081', '083', '086', '088', '093+100',
        '093+500W', '093+800E', '096', '098', '099', '100', '107', '117', '118', '123', '125', '126', '147', '171', '172'],
      gateway: ['28', '83', '86', '88', '93+500', '93+889', '96', '98', '107', '118', '123', '125', '126', '147', '171', '172'],
      type: ['STI', 'MC', 'ELP', 'SAA', 'TM', 'LC', 'RAIN', 'DS', 'ETI'],
      sys: [{
        type: 'IoT Gateway 資料更新',
        instrument: 0,
        GREEN: 0,
        err: 0,
        err_name: [],
      }, {
        type: 'IoT Node 電力狀態',
        instrument: 0,
        GREEN: 0,
        err: 0,
        err_name: [],
      }, {
        type: 'IoT Node/儀器狀態',
        instrument: 0,
        GREEN: 0,
        err: 0,
        err_name: [],
      }, {
        type: 'Logger 資料更新',
        instrument: 0,
        GREEN: 0,
        err: 0,
        err_name: [],
      }, {
        type: 'Logger 電力狀態',
        instrument: 0,
        GREEN: 0,
        err: 0,
        err_name: [],
      }, {
        type: 'Logger 儀器狀態',
        instrument: 0,
        GREEN: 0,
        err: 0,
        err_name: [],
      }],
      instrumentList: [],
      instrumentGroup: [],
    };
  },
  mounted() {
    this.resData = this.instrumentsList;
    //console.log(this.projectData);
    this.getProject();
    this.getDocument();
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
  methods: {
    handleClick(err) {
      console.log('Clicked on: ', err);
    },
    formattederrName(err_name) {
      return err_name.join('\n');
    },
    dataHealth(doc) {
      this.logger.forEach(t => {
        if(doc.name === `TK${t}_Data.dat`) {
          this.sys[3].instrument += 1;
          let currentTime = new Date();
          let updatedTime = new Date(doc.updated_at);
          let differenceInHours = (currentTime - updatedTime) / (1000 * 60 * 60);
  
          if(differenceInHours < 2) {
            this.sys[3].GREEN += 1;
          } else {
            this.sys[3].err += 1;
            this.sys[3].err_name.push(t[0].name);
          }
        }
      });
      this.gateway.forEach(t => {
        if(doc.name === `TK${t}`) {
          this.sys[0].instrument += 1;
          let currentTime = new Date();
          let updatedTime = new Date(doc.updated_at);
          let differenceInHours = (currentTime - updatedTime) / (1000 * 60 * 60);
  
          if(differenceInHours < 2 && doc.status !== 'down') {
            this.sys[0].GREEN += 1;
          } else {
            this.sys[0].err += 1;
            this.sys[0].err_name.push(t[0].name);
          }
        }
      });
    },
    battHealth(data) {
      data.map(item => {
        if(item.latestVersion.name.includes('(Batt)')) {
          this.sys[1].instrument += 1;
          if(item.status === 'GREEN') {
            this.sys[1].GREEN += 1;
          } else {
            this.sys[1].err += 1;
            this.sys[1].err_name.push(item.latestVersion.name);
          }
        }

        if(item.latestVersion.name.includes('-Batt')) {
          this.sys[4].instrument += 1;
          if(item.status === 'GREEN') {
            this.sys[4].GREEN += 1;
          } else {
            this.sys[4].err += 1;
            this.sys[4].err_name.push(item.latestVersion.name);
          }
        }
      });
    },
    instrumentHealth() {
      //console.log(this.instrumentGroup);
      Object.values(this.instrumentGroup).forEach(t => {
        if(!t[0].name.includes('STI') && !t[0].name.includes('MC')) {
          this.sys[5].instrument += 1;
          if(t.group_status === 'down') {
            this.sys[5].err += 1;
            this.sys[5].err_name.push(t[0].name);
          } else {
            this.sys[5].GREEN += 1;
          }
        } else {
          if(t[0].type === 'iot') {
            this.sys[2].instrument += 1;
            if(t.group_status === 'down') {
              this.sys[2].err += 1;
              this.sys[2].err_name.push(t[0].name);
            } else {
              this.sys[2].GREEN += 1;
            }
          } else if(t[0].type === 'slope') {
            this.sys[5].instrument += 1;
            if(t.group_status === 'down') {
              this.sys[5].err += 1;
              this.sys[5].err_name.push(t[0].name);
            } else {
              this.sys[5].GREEN += 1;
            }
          }
        }
      });
    },
    rowStyle() {
      return 'text-align:center';
    },
    getProject() {
      this.battHealth(this.resData);
      this.getInstrumentStatus(this.projectData, this.type);
      this.getInstrumentGroup();
      this.instrumentHealth();
    },
    getDocument() {
      this.$axios({
        category: 'project',
        method: 'get',
        apiName: 'document',
      })
        .then(res => {
          //console.log(res);
          res.data.map(t => {
            this.dataHealth(t);
          });
        })
        .catch(this.$notifyError)
        .finally(() => {
          //console.log('done.');
          this.$emit('componentMounted');
        });
    },
    getInstrumentGroup() {
      const priorityOrder = ['down', 'WSL', 'AL', 'GREEN'];
      //console.log(this.instrumentList);
      this.instrumentGroup = this.instrumentList.reduce((acc, item) => {
        if (item.class !== '') {
          if (acc[item.class]) {
            acc[item.class].push(item);

            // Determine group_status based on priorityOrder
            const itemPriority = priorityOrder.indexOf(item.status);
            const currentGroupStatusPriority = priorityOrder.indexOf(acc[item.class].group_status);

            if (itemPriority < currentGroupStatusPriority) {
              acc[item.class].group_status = item.status;
            }
          } else {
            acc[item.class] = [item];
            // Initially set group_status to the item's status
            acc[item.class].group_status = item.status;
          }
        }
        return acc;
      }, {});
    },
    getInstrumentStatus(data, type) {
      //console.log(data);
      type.forEach(t => {
        data.area.map(area => {
          let type;
          if(area.name.includes('邊坡'))
            type = 'slope';
          else if(area.name.includes('IoT'))
            type = 'iot';
          else
            type = 'iot';
          area.station.map(instruments => {
            const instru = instruments.instrument.filter(item => item.latestVersion.name.includes(t) && !item.latestVersion.name.includes('(Batt)'))
              .map(item => ({
                name: item.latestVersion.name,
                status: item.status,
                class: item.latestVersion.class,
                type,
              }));
            this.instrumentList.push(...instru);
          });
        });
      });
    },
  },
};
</script>
