<template>
  <el-form ref="form" :model="form" label-position="top" label-width="80px">
    <el-form-item label="圖片名稱">
      <el-input v-model="form.name" :style="{ maxWidth: '500px' }" placeholder="请输入内容" />
    </el-form-item>
    <el-form-item label="圖片介紹">
      <el-input
        v-model="form.description"
        :rows="4"
        :style="{ maxWidth: '500px' }"
        type="textarea"
        placeholder="请输入内容"
      />
    </el-form-item>
    <el-form-item>
      <el-button :disabled="!enableSubmit" @click="updateImage" type="primary">
        更新
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
      },
    };
  },
  computed: {
    enableSubmit() {
      return this.form.name;
    },
  },
  watch: {
    file() {
      this.initState();
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    initState() {
      this.form.name = this.file.name;
      this.form.description = this.file.description;
    },
    updateImage() {
      this.$axios({
        method: 'post',
        apiName: `/admin/project/${this.editingProject}/image/${this.file._id}`,
        data: this.form,
      })
        .then(() => {
          this.$notifySuccess('更新成功');
        })
        .then(res => {
          console.log(res);
        });
    },
  },
};
</script>
