const remarkTypeList = [
  {
    label: 'remark.typeList.status',
    value: 'status',
  },
  {
    label: 'remark.typeList.construction',
    value: 'construction',
  },
];

const convertRemarkType = type => {
  // Map from game ranking difinition to Go ranking definition.
  const findResult = remarkTypeList.find(item => {
    return item.value === type;
  });
  if (!findResult) return type;
  else return findResult.label;
};

export { remarkTypeList, convertRemarkType };
