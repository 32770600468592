<template>
  <div v-loading="isLoading">
    <div>
      <el-button-group>
        <el-button v-for="group in groupedImages" :key="group.name" @click="selectGroup(group)"
          style="margin-right: 10px; margin-bottom: 10px;">
          {{ group.name }}
        </el-button>
      </el-button-group>
    </div>

    <el-form class="columns is-multiline" label-position="top" label-width="80px" v-if="images && images.length > 0">
      <div v-for="(image) in filteredImages" :key="image" class="column" :style="{ width: `50%`, flex: 'none' }"
        @click.left="openImageDialog(image)">
        <el-card class="flex-row align-center justify-center">
          <ImageBox :file="image"></ImageBox>
        </el-card>
      </div>
    </el-form>
    <el-card v-else>
      <div class="flex-row align-center justify-center" style="min-height: 720px;">
        <div class="content">
          <h3>{{ $t('data.none') }}</h3>
        </div>
      </div>
    </el-card>
    <el-dialog v-loading="!getDlUrl" :visible.sync="imageDialogVisible" custom-class="image-dialog"
      :before-close="handleDialogClose">
      <div class="image-zoom-container" @mousedown="startDragging" @mousemove="drag" @mouseup="stopDragging"
        @mouseleave="stopDragging" @wheel="handleMouseWheel">
        <img :src="dlurl" :style="{ transform: 'translate(' + xPos + 'px, ' + yPos + 'px) scale(' + zoomScale + ')' }" />
        <div class="zoom-controls">
          <el-button icon="el-icon-zoom-in" circle @click="zoomIn" />
          <el-button icon="el-icon-zoom-out" circle @click="zoomOut" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import ImageBox from '@/components/monitor/ImageBox';
export default {
  components: {
    ImageBox,
  },
  data() {
    return {
      projectPath: '',
      images: [],
      selectedGroupName: null,
      selectedImages: [],
      isLoading: false,
      getDlUrl: false,
      dlurl: null,
      imageDialogVisible: false,
      zoomScale: 1,
      dragging: false,
      xPos: 0,
      yPos: 0,
      lastX: 0,
      lastY: 0,
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    timezone() {
      return this.$store.getters['env/timezone'];
    },
    filteredImages() {
      return this.images.filter(image => this.selectedImages.includes(image.url));
    },
    groupedImages() {
      const groups = {};

      for (const image of this.images) {
        const groupKey = image.content.split('-')[0];  // "TK93+100", "TK93+500", "TK93+800", etc.
        if (!groups[groupKey]) {
          console.log(groupKey);
          groups[groupKey] = {
            name: groupKey,
            items: [],
          };
        }

        groups[groupKey].items.push({
          name: image.content,
          url: image.url,
        });
      }

      return Object.values(groups);
    },
  },
  mounted() {
    this.isLoading = true;
    this.init();

    // 讀取本地儲存的選取狀態
    const savedGroupName = sessionStorage.getItem('selectedGroupName');
    if (savedGroupName) {
      this.selectedGroupName = savedGroupName;
    }

    this.getProjectMonitorImage();

    this.reflashFunction = setInterval(() => {
      this.isLoading = true;
      this.getProjectMonitorImage();
    }, 1000 * 60 * 5);
  },
  methods: {
    init() {
      this.projectPath = `${process.env.VUE_APP_IMAGE_CCTV_URL}/monitor/${this.selectedProject.project_id}`;
    },
    selectGroup(group) {
      this.selectedImages = group.items.map(item => item.url);

      // 保存選取狀態到本地儲存
      sessionStorage.setItem('selectedGroupName', group.name);
      this.selectedGroupName = group.name;
    },
    async getProjectMonitorImage() {
      try {
        const res = await this.$axios({
          category: 'project',
          method: 'get',
          apiName: `${this.selectedProject.project_id}/getMonitorImage`,
          useUrl: `${process.env.VUE_APP_IMAGE_CCTV_URL}`,
          useJWT: `${process.env.VUE_APP_VALUE_CCTV_JWT}`,
        });
        let images = res.data.images;
        if (images) {
          this.images = images.map(ele => {
            //console.log(`${this.projectPath}/${ele.name}`);
            let time = ele.time;
            let y = time.substring(0, 4);
            let M = time.substring(4, 6);
            let d = time.substring(6, 8);
            let h = time.substring(8, 10);
            let m = time.substring(10, 12);
            let s = time.substring(12);
            //console.log(`${this.projectPath}/${ele.name}`);
            return {
              url: `${this.projectPath}/${ele.name}`,
              content: ele.content,
              uptime: moment([y, M, d, h, m, s]).format('YYYY-MM-DD hh:mm'),
            };
          });
        }
        if (this.selectedGroupName) {
          const groupToSelect = this.groupedImages.find(group => group.name === this.selectedGroupName);
          if (groupToSelect) {
            this.selectGroup(groupToSelect);
          }
        }
        this.isLoading = false;
      }
      catch (e) {
        console.log(e);
        this.$notifyError();
      }
    },
    openImageDialog(image) {
      if (!this.imageDialogVisible) {
        this.imageDialogVisible = true;
        this.dlurl = image.url;
        this.getDlUrl = true;
      }
    },
    handleDialogClose(done) {
      if (!this.imageDialogVisible) return;
      this.imageDialogVisible = false;
      this.dlurl = null;
      this.getDlUrl = false;
      this.resetZoomAndPosition();
      done();
    },
    resetZoomAndPosition() {
      this.zoomScale = 1;
      this.xPos = 0;
      this.yPos = 0;
    },
    zoomIn() {
      this.zoomScale += 0.1;
    },
    zoomOut() {
      if (this.zoomScale > 1) {
        this.zoomScale -= 0.1;
      }
    },
    handleMouseWheel(event) {
      event.preventDefault();
      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    startDragging(event) {
      this.dragging = true;
      this.lastX = event.clientX;
      this.lastY = event.clientY;
    },
    drag(event) {
      if (!this.dragging) return;
      //this.closeAllPopovers();
      event.preventDefault();
      const deltaX = event.clientX - this.lastX;
      const deltaY = event.clientY - this.lastY;
      this.xPos += deltaX;
      this.yPos += deltaY;
      this.lastX = event.clientX;
      this.lastY = event.clientY;
    },
    stopDragging() {
      this.dragging = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.image-dialog
  .image-zoom-container
    position relative
    img
      object-fit contain
      max-width: 100%
      max-height: 90vh
    .zoom-controls
      position absolute
      bottom: 10px
      right: 10px
</style>