<template>
  <div class="image-marker-container">
    {{`監視器名稱: ${file.content}`}}
    <el-image :src="file.url" />
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
  .image-marker-container
    position relative
    display inline-block
    img
      object-fit contain
  </style>