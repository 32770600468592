<template>
  <div>
    <el-button
      style="padding: 10px"
      @click="ComPortTesting"
    >
      COM測試
    </el-button>
    <el-input
      style="padding: 10px"
      type="textarea"
      :rows="10"
      placeholder=""
      v-model="log">
    </el-input>
    <highcharts ref="highcharts" :options="chartOptions" />
    <el-button
      style="padding: 10px"
      @click="exportSVG"
    >
      getSVG測試
    </el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      log: '',
    };
  },
  computed: {
    chartOptions() {
      return {
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
            'Oct', 'Nov', 'Dec',
          ],
        },
        series: [{
          data: [
            29.9, 71.5, 106.4, 129.2, 144.0,
            176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4,
          ],
        }],
      };
    },
  },
  methods: {
    async ComPortTesting(){
      //this.Log('testing!');
      this.Log(`${process.env.VUE_APP_COM_URL}`);
      const res = await this.$axios({
        method: 'get',
        apiName: 'api/comtest',
        useUrl:`${process.env.VUE_APP_COM_URL}`,
      });
      this.Log(res.data);
    },
    Log(log){
      this.log += `${log}\n`;
      console.log(log);
    },
    GetSVGTesting() {
      const svg = this.$refs.highcharts.chart.getSVG()
        .replace(/</g, '\n&lt;') // make it slightly more readable
        .replace(/>/g, '&gt;');
      console.log(svg);
    },
    exportSVG() {
      const chart = this.$refs.highcharts.chart;
      const width = chart.chartWidth;
      const height = chart.chartHeight;

      // 手動構建 SVG 結構
      const svgHeader = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">`;
      const svgFooter = '</svg>';

      // 獲取圖表內部結構
      const innerSVG = chart.container.innerHTML;

      // 拼接完整的 SVG
      const fullSVG = svgHeader + innerSVG + svgFooter;

      // 解析 SVG 並移除內聯樣式
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(fullSVG, 'image/svg+xml');
      const elements = svgDoc.querySelectorAll('[style]');
      elements.forEach(el => el.removeAttribute('style'));

      // 添加外部樣式表引用
      const style = document.createElementNS('http://www.w3.org/2000/svg', 'style');
      style.textContent = '';
      svgDoc.querySelector('svg').insertBefore(style, svgDoc.querySelector('svg').firstChild);

      // 將修改後的 SVG 轉回字符串
      const cleanedSvg = new XMLSerializer().serializeToString(svgDoc);

      // 保存或顯示 SVG
      //this.svgs.push(cleanedSvg);
      console.log(cleanedSvg);

      this.drawSVGsOnCanvas([cleanedSvg],'onlyTest.png',chart.chartWidth,[chart.chartHeight]);
    },
    drawSVGsOnCanvas(svgs, pngName, width = 600, heights) {
      //const canvas = this.$refs.canvas;
      const canvas = document.createElement('canvas');
      
      const totalHeight = heights ? heights.reduce(
        (cur,acc) => {
          return acc + cur;
        }
        ,0) : 400 * svgs.length;
      canvas.width = width;
      canvas.height = totalHeight;
      const ctx = canvas.getContext('2d');
      
      // 逐一繪製每張SVG圖形
      let loadedCount = 0; // 記錄已經載入的SVG數量
      let addHeight = 0;
      for (let i = 0; i < svgs.length; i++) {
        const svg = new Blob([svgs[i]], { type: 'image/svg+xml;charset=utf-8' });
        const DOMURL = window.URL || window.webkitURL || window;
        const url = DOMURL.createObjectURL(svg);
        let height = heights[i];
        const adder = addHeight;
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, adder, width, height);
          DOMURL.revokeObjectURL(url);

          loadedCount++;
          if (loadedCount === svgs.length) {
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = canvas.toDataURL('image/png');

            link.setAttribute('download', `${pngName}.png`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        };
        img.src = url;
        addHeight += height;
      }
    },
  },
};
</script>

<style>

</style>