<template>
  <div id="mainContent" v-loading="loading" element-loading-text="In Progress...">
    <span style="font-size: 19.2px; margin-bottom: 15px; padding-left: 5px;">NOISE MONITORING
      REPORT</span>
    <el-card class="box-card">
      <el-select v-model="selectedStation" placeholder="Location" @change="getInstrumentList"
        style="margin-right: 20px;">
        <el-option v-for="item in stations" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>

      <el-select v-model="selectedInstrument" placeholder="Sensor Name" @change="getInstrumentData"
        style="margin-right: 20px;">
        <el-option v-for="item in instrumentList" :key="item.value" :label="item.location_details" :value="item.value">
        </el-option>
      </el-select>

      <el-date-picker unlink-panels :disabled="enablePicker" clearable="false" v-model="selectedDate" type="daterange"
        start-placeholder="Date From" end-placeholder="Date To" :picker-options="pickerOptions"
        style="margin-right: 20px;">
      </el-date-picker>

      <el-button type="primary" @click="showTableData">Run</el-button>
    </el-card>
    <div style="display: block; margin-top: 7px; margin-bottom: 7px; padding-left: 5px;" v-if="showInfo">
      <span class="content">BCA Reference :</span><span style="font-size: 12.8px;">{{ instrument_info.BCA_reference
        }}</span><br>
      <span class="content">Contractor's Name :</span><span style="font-size: 12.8px;">{{
    instrument_info.Contractors_name
  }}</span><br>
      <span class="content">Project Title :</span><span style="font-size: 12.8px;">{{ instrument_info.Project_title
        }}</span><br>
      <span class="content">Location Details :</span><span style="font-size: 12.8px;">{{
    instrument_info.Location_details
  }}</span><br>
      <span class="content">Sensor Reference :</span><span style="font-size: 12.8px;">{{
      instrument_info.Sensor_reference
    }}</span><br>
      <span class="content">Monitoring Category :</span><span style="font-size: 12.8px;">{{
      instrument_info.Monitoring_category }}</span><br>
      <span class="content">Sensor Brand :</span><span style="font-size: 12.8px;">{{ instrument_info.Sensor_brand
        }}</span><br>
      <span class="content">Sensor Model :</span><span style="font-size: 12.8px;">{{ instrument_info.Sensor_model
        }}</span><br>
      <span class="content">Sensor Serial No. :</span><span style="font-size: 12.8px;">{{ instrument_info.Sensor_serial
        }}</span><br>
      <span class="content">Calibration Date :</span><span style="font-size: 12.8px;">{{
    instrument_info.Calibration_date
  }}</span><br>
      <span class="content">Start Date :</span><span style="font-size: 12.8px;">{{ instrument_info.Start_date
        }}</span><br>
      <span class="content">Latitude (deg) :</span><span style="font-size: 12.8px;">{{ instrument_info.Latitude
        }}</span><br>
      <span class="content">Longitude (deg) :</span><span style="font-size: 12.8px;">{{ instrument_info.Longitude
        }}</span><br>
      <span class="content">Altitude (m) :</span><span style="font-size: 12.8px;">{{ instrument_info.Altitude
        }}</span><br>
    </div>
    <div v-for="(tableData, index) in allTablesData.slice().reverse()" :key="index"
      style="max-height: 641px; max-width: 1009px; margin-bottom: 1rem;">
      <el-table :data="tableData" style="width: 100%; font-size: 12px; color: navy;" :span-method="objectSpanMethod"
        :cell-style="cellStyleMethod">

        <el-table-column 
          prop="day" 
          label="Day" 
          width="104" 
          :cell-style="{ whiteSpace: 'normal', wordBreak: 'break-word' }">
        </el-table-column>
        <el-table-column prop="time" label="Time hr/min" width="52">
        </el-table-column>
        <el-table-column label="Leq 5min">
          <el-table-column prop="00" label="00" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['00'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['00'] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="05" label="05" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['05'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['05'] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="10" label="10" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['10'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['10'] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="15" label="15" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['15'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['15'] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="20" label="20" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['20'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['20'] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="25" label="25" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['25'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['25'] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="30" label="30" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['30'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['30'] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="35" label="35" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['35'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['35'] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="40" label="40" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['40'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['40'] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="45" label="45" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['45'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['45'] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="50" label="50" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['50'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['50'] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="55" label="55" width="52">
            <template slot-scope="scope">
              <div
                :style="getStyle(scope.row['55'], scope.row.maxallowleq, scope.row.day, scope.$index, scope.column.label)">
                {{ scope.row['55'] }}
              </div>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column prop="leq1h" label="Leq1hr/ Accum." width="52">
          <template slot-scope="scope">
            <div :style="getStyle(scope.row['leq1h'], scope.row.maxallowleq, scope.row.day, scope.$index, '55')">
              {{ scope.row['leq1h'] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="leq12h" label="Leq12hr/ Accum." width="52">
        </el-table-column>
        <el-table-column prop="currDose" label="Current Dose %" width="52">
        </el-table-column>
        <el-table-column prop="maxallowleq" label="Max. Allow Leq 5min" width="72">
        </el-table-column>
      </el-table>
    </div>
    <div v-if="showInfo" style="padding-left: 30px;">
      <span style="font-weight:bold; margin-right: 40px;">LEGEND :</span>

      <input type="text" readonly="readonly"
        style="background-color:White;font-size:10pt;text-decoration:none;height:15px;width:15px;margin-right: 5px;">

      <span style="margin-right: 40px;">NORMAL</span>

      <input type="text" readonly="readonly" style="background-color:Red;height:15px;width:15px;margin-right: 5px;">

      <span style="margin-right: 40px;">Readings Above Permissible</span>

      <input type="text" readonly="readonly" style="background-color:#CC9900;height:15px;width:15px;margin-right: 5px;">

      <span style="margin-right: 40px;">Dose Above 70%</span>

      <input type="text" readonly="readonly"
        style="color:White;background-color:#666666;text-decoration:none;height:15px;width:15px;margin-right: 5px;">

      <span style="margin-right: 40px;">No Data recorded</span>
    </div>
    <el-button @click="convertDivToPDF" v-if="showInfo">Save to PDF</el-button>
    <el-button @click="convertDivToPNG" v-if="showInfo">Save to PNG</el-button>
  </div>
</template>

<style scoped>
.content {
  display: inline-block;
  font-weight: bold;
  height: 16px;
  width: 200px;
  font-size: 12.8px;
  margin-bottom: 0rem !important;
}

.el-table tbody tr {
  pointer-events: none;
}

.box-card {
  width: 937px;
}

::v-deep .el-table .cell {
  padding: 0px !important;
  text-align: center !important;
  white-space: normal !important;
  word-break: break-word !important;
}

::v-deep .el-table--border th:first-child .cell {
  padding: 0px !important;
  text-align: center !important;
}

::v-deep .el-table td,
::v-deep .el-table th {
  padding: 0px !important;
  text-align: center !important;
}

::v-deep .el-table thead.is-group th {
  background: #006699;
  color: #FFFFFF;
}

::v-deep .el-table--border,
.el-table--group {
  border: 1px solid #003300;
}

::v-deep .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #003300;
}

::v-deep .el-table--border th {
  border-right: 1px solid #003300;
  border-bottom: 1px solid #003300;
}

::v-deep .el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
  border-right: 1px solid #003300;
}

::v-deep .el-table--border:after,
.el-table--group:after {
  width: 0px;
}

::v-deep .el-table td div {
  height: 23px;
}
</style>

<script>
import moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

window.moment = moment;
MomentTimeZone();

export default {
  data() {
    return {
      showNotification: false,
      loading: true,
      selectedDate: [],
      allTablesData: [],
      currentTableData: [],
      showInfo: false,
      selectedStation: '',
      selectedInstrument: '',
      selectedLabel: '',
      stations: [],
      instruments: [],
      instrumentList: [],
      instrument_info: {
        BCA_reference: '',
        Contractors_name: '',
        Project_title: '',
        Location_details: '',
        Sensor_reference: '',
        Monitoring_category: '',
        Sensor_brand: '',
        Sensor_model: '',
        Sensor_serial: '',
        Calibration_date: '',
        Start_date: '',
        Latitude: '',
        Longitude: '',
        Altitude: '',
      },
      instrumentGroup: [],
      enablePicker: true,
      timezone: moment.tz.guess(),
      pickerOptions: {
        disabledDate: (time) => {
          const projectDate = new Date(this.selectedProject.start_date).getTime();
          return time.getTime() > Date.now() || time.getTime() < projectDate;
        },
      },
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
  mounted() {
    this.getProject();

    const now = new Date();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    const delay = (5 - (minutes % 5) + 1) * 60 * 1000 - seconds * 1000;

    setTimeout(() => {
      setInterval(() => {
        this.initialLoad();
      }, 5 * 60 * 1000);
    }, delay);

    // 如果stations有元素，自動選擇第一個
    this.$watch('stations', (newVal) => {
      if (newVal.length > 0) {
        this.selectedStation = newVal[0].value;
        this.getInstrumentList();
      }
    });

    // 如果instrumentList有元素，自動選擇第一個
    this.$watch('instrumentList', (newVal) => {
      if (newVal.length > 0) {
        this.selectedInstrument = newVal[0].value;
        this.getInstrumentData();
      }
    });
  },
  methods: {
    showTableData() {
      this.loading = true;
      this.onDateChange(this.selectedDate);
    },
    convertDivToPNG() {
      this.loading = true;
      const element = document.getElementById('mainContent');

      html2canvas(element).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        let stationName = '';
        let instrumentName = '';

        this.stations.map(item => {
          if (this.selectedStation === item.value) {
            stationName = item.label;
          }
        });

        this.instrumentList.map(item => {
          if (this.selectedInstrument === item.value) {
            instrumentName = item.label;
          }
        });

        const link = document.createElement('a');
        link.href = imgData;
        link.download = `${stationName}-${instrumentName}-${this.formatDateToYYMMDD(this.selectedDate[0])}-${this.formatDateToYYMMDD(this.selectedDate[1])}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.loading = false;
      });
    },
    convertDivToPDF() {
      this.loading = true;
      const padding = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 20,
      };

      const element = document.getElementById('mainContent');

      html2canvas(element, { scale: 3 }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
        });

        let stationName = '';
        let instrumentName = '';

        this.stations.map(item => {
          if (this.selectedStation === item.value) {
            stationName = item.label;
          }
        });

        this.instrumentList.map(item => {
          if (this.selectedInstrument === item.value) {
            instrumentName = item.label;
          }
        });

        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const pdfWidth = pdf.internal.pageSize.getWidth() - padding.left - padding.right;
        const pdfHeight = (canvasHeight * pdfWidth) / canvasWidth;

        let heightLeft = pdfHeight;
        let position = padding.top;

        pdf.addImage(imgData, 'PNG', padding.left, position, pdfWidth, pdfHeight);
        heightLeft -= (pdf.internal.pageSize.getHeight() - padding.top - padding.bottom);

        while (heightLeft >= 0) {
          position = heightLeft - pdfHeight + padding.top;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', padding.left, position, pdfWidth, pdfHeight);
          heightLeft -= (pdf.internal.pageSize.getHeight() - padding.top - padding.bottom);
        }


        pdf.save(`${stationName}-${instrumentName}-${this.formatDateToYYMMDD(this.selectedDate[0])}-${this.formatDateToYYMMDD(this.selectedDate[1])}.pdf`);
        this.loading = false;
      });
    },
    formatDateToYYMMDD(dateString) {
      const date = new Date(dateString);

      let year = date.getFullYear().toString().slice(-2);
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');

      return year + month + day;
    },
    initialLoad() {
      this.loading = true;
      this.getInstrumentData();
      const savedDateRange = JSON.parse(localStorage.getItem('selectedDate'));
      if (savedDateRange) {
        this.selectedDate = savedDateRange;
      }
      this.onDateChange(this.selectedDate);
    },
    getStyle(value, max, day, rowIndex, minutes) {
      if (value !== '-' && value > max) {
        return {
          backgroundColor: 'red',
          color: 'white',
        };
      }

      let currentDate = new Date();
      //currentDate.setHours(0, 0, 0, 0);
      let cellDate = new Date(day);
      cellDate.setHours(rowIndex + 7);
      cellDate.setMinutes(parseInt(minutes, 10));
      currentDate.setMinutes(currentDate.getMinutes() + 1);

      if (value === '-' && cellDate < currentDate) {

        return {
          backgroundColor: '#666666',
          color: 'white',
        };
      }
      return {};
    },
    getOutput(instrumetList) {
      let start = new Date(this.selectedDate[0]);
      let end = new Date(this.selectedDate[1]);
      end.setDate(end.getDate() + 1);
      end.setHours(end.getHours() + 23);
      end.setMinutes(end.getMinutes() + 59);
      end.setSeconds(end.getSeconds() + 59);

      let startISO = start.toISOString();
      let endISO = end.toISOString();

      const instrument = instrumetList;
      return this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/output?type=all`,
        data: {
          date: [startISO, endISO],
          interval: 'all',
          instrument: [instrument],
          timezone: this.timezone,
        },
      })
        .then(res => {
          if (!res.data.length) {
            if (!this.showNotification) {
              this.$notifyInfo('No data available for this time period.');
              this.showNotification = true;
            }
          } else {
            return res.data;
          }
        })
        .catch(this.$notifyError);
    },
    getInstrumentData() {
      this.showInfo = false;
      this.allTablesData = [];
      this.currentTableData = [];
      this.instruments.map(instrument => {
        if (instrument.value === this.selectedInstrument) {
          //console.log(instrument);
          this.selectedLabel = instrument ? instrument.label : '';
          this.instrument_info.BCA_reference = instrument.instrumentType.BCA_Reference;
          this.instrument_info.Sensor_reference = instrument.instrumentType.Sensor_Reference;
          this.instrument_info.Monitoring_category = instrument.instrumentType.Monitoring_Category;
          this.instrument_info.Sensor_brand = instrument.instrumentType.Sensor_Brand;
          this.instrument_info.Sensor_model = instrument.instrumentType.Sensor_Model;
          this.instrument_info.Sensor_serial = instrument.instrumentType.Sensor_Serial;
          this.instrument_info.Calibration_date = instrument.instrumentType.Calibration_Date;
          this.instrument_info.Start_date = instrument.instrumentType.Start_Date;
          this.instrument_info.Latitude = instrument.coordinates[1];
          this.instrument_info.Longitude = instrument.coordinates[0];
          this.instrument_info.Altitude = instrument.instrumentType.Altitude;
          this.instrument_info.Contractors_name = this.selectedProject.contractor;
          this.instrument_info.Project_title = this.selectedProject.name;
          this.instrument_info.Location_details = this.selectedProject.location;
        }

        if (instrument.label !== this.selectedLabel && instrument.label.includes(this.selectedLabel) && this.selectedLabel !== '') {
          this.instrumentGroup.push(instrument.value);
        }
      });
      this.selectedDate = [];
      this.enablePicker = false;
    },
    getInstrumentList() {
      this.enablePicker = true;
      this.showInfo = false;
      this.selectedDate = [];
      this.selectedInstrument = '';
      this.selectedLabel = '';
      this.instrumentList = [];
      this.instruments.map(instrument => {
        if (instrument.station === this.selectedStation && instrument.instrumentType !== null) {
          // 這裡需要注意 如果未來每一個都要綁定 就需要新增大量的儀器類型 是否有其他方法
          if (instrument.instrumentType.name.includes('SLM-')) {
            this.instrumentList.push(instrument);
            console.log(this.instrumentList);
          }
        }
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 17,
            colspan: 1,
          };
        } else if (rowIndex === 17) {
          return {
            rowspan: 7,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      } else if (columnIndex === 15) {
        if (rowIndex === 0) {
          return {
            rowspan: 12,
            colspan: 1,
          };
        } else if (rowIndex === 12) {
          return {
            rowspan: 12,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      } else if (columnIndex >= 16) {
        if (rowIndex === 0) {
          return {
            rowspan: 12,
            colspan: 1,
          };
        }
      }
    },
    cellStyleMethod({ rowIndex, columnIndex, row, column, property }) {
      if (columnIndex === 15 && rowIndex >= 12) {
        return { backgroundColor: '#C0C0C0' };
      }
      return {};
    },
    async onDateChange(date) {
      console.log(this.instrumentGroup);
      if (this.selectedDate === undefined || this.selectedDate === null) return;
      let data = await this.getOutput(this.selectedInstrument);
      //console.log(this.instrumentGroup);
      let accum1h = await this.getOutput(this.instrumentGroup[0]);
      let accum12h = await this.getOutput(this.instrumentGroup[1]);
      let currentDose = await this.getOutput(this.instrumentGroup[2]);
      let maxAllowLeq5m = await this.getOutput(this.instrumentGroup[3]);
      this.showNotification = false;
      let lastHour = null;
      let lastHourRecords = [];

      if (!currentDose) {
        this.loading = false;
        return;
      }

      // 2. Loop through the sorted data
      for (let i = 0; i < currentDose.length; i++) {
        const currentHour = moment.tz(currentDose[i].TIMESTAMP, 'UTC').tz('Asia/Taipei').hour();

        if (lastHour !== null && currentHour !== lastHour) {
          // 3. If the hour is different, the previous record is the last of that hour
          lastHourRecords[lastHour] = currentDose[i - 1];
        }

        lastHour = currentHour;
      }

      // The last record for the final hour
      if (currentDose.length > 0) {
        lastHourRecords[lastHour] = currentDose[currentDose.length - 1];
      }

      let startDate = moment(date[0]);
      const endDate = moment(date[1]);
      this.allTablesData = [];
      let category = this.instrument_info.Monitoring_category;

      while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
        this.currentTableData = [];

        for (let i = 0; i < 24; i++) {
          // let max = '-';
          // if (category.includes('Category A')) {
          //   if (i >= 0 && i < 12)
          //     max = 75;
          //   else if (i >= 12 && i < 15)
          //     max = 55;
          //   else
          //     max = 55;
          // } else if (category.includes('Category B')) {
          //   if (i >= 0 && i < 12)
          //     max = maxAllowLeq5m;
          //   else if (i >= 12 && i < 15)
          //     max = maxAllowLeq5m;
          //   else
          //     max = maxAllowLeq5m;
          // } else if (category.includes('Category C')) {
          //   if (i >= 0 && i < 12)
          //     max = 90;
          //   else if (i >= 12 && i < 15)
          //     max = 70;
          //   else
          //     max = 70;
          // }
          let currentHour = startDate.clone().add(i + 7, 'hours');
          this.currentTableData.push({
            time: currentHour.format('ha'),
            '00': '-',
            '05': '-',
            '10': '-',
            '15': '-',
            '20': '-',
            '25': '-',
            '30': '-',
            '35': '-',
            '40': '-',
            '45': '-',
            '50': '-',
            '55': '-',
            day: currentHour.format('ddd DD-MMM-YY'),
            leq1h: '-',
            leq12h: '-',
            currDose: '-',
            maxallowleq: '-',
          });
        }

        this.allTablesData.unshift([...this.currentTableData]);
        startDate.add(1, 'days');
      }
      if (data) {
        data.forEach(item => {
          const convertedTime = moment.tz(item.TIMESTAMP, 'UTC').tz('Asia/Taipei');
          const dateStr = (convertedTime.hour() < 7) ? convertedTime.clone().subtract(1, 'days').format('ddd DD-MMM-YY') : convertedTime.format('ddd DD-MMM-YY');
          const hour = convertedTime.hour();
          const minute = convertedTime.minute();

          let targetTable = this.allTablesData.find(table => table[0].day === dateStr);

          if (targetTable) {
            let timeFormat;
            if (hour === 0) {
              timeFormat = '12am';
            } else if (hour > 0 && hour < 12) {
              timeFormat = `${hour}am`;
            } else if (hour === 12) {
              timeFormat = '12pm';
            } else {
              timeFormat = `${hour - 12}pm`;
            }
            let targetRow = targetTable.find(row => row.time === timeFormat);

            if (targetRow) {
              const columnLabel = `${minute < 10 ? '0' + minute : minute}`;
              // 格式化 reading2 为固定小数点
              targetRow[columnLabel] = parseFloat(item.reading2).toFixed(1);
            }
          }
        });
      }


      if (accum1h) {
        accum1h.forEach(item => {
          const convertedTime = moment.tz(item.TIMESTAMP, 'UTC').tz('Asia/Taipei');
          const dateStr = (convertedTime.hour() < 7) ? convertedTime.clone().subtract(1, 'days').format('ddd DD-MMM-YY') : convertedTime.format('ddd DD-MMM-YY');
          const hour = convertedTime.hour();

          let targetTable = this.allTablesData.find(table => table[0].day === dateStr);

          if (targetTable) {
            let timeFormat;
            if (hour === 0) {
              timeFormat = '12am';
            } else if (hour > 0 && hour < 12) {
              timeFormat = `${hour}am`;
            } else if (hour === 12) {
              timeFormat = '12pm';
            } else {
              timeFormat = `${hour - 12}pm`;
            }
            let targetRow = targetTable.find(row => row.time === timeFormat);

            if (targetRow) {
              targetRow.leq1h = parseFloat(item.reading2).toFixed(1);
            }
          }
        });
      }

      if (currentDose) {
        currentDose.forEach(item => {
          const convertedTime = moment.tz(item.TIMESTAMP, 'UTC').tz('Asia/Taipei');
          const dateStr = (convertedTime.hour() < 7) ? convertedTime.clone().subtract(1, 'days').format('ddd DD-MMM-YY') : convertedTime.format('ddd DD-MMM-YY');
          const hour = convertedTime.hour();

          let targetTable = this.allTablesData.find(table => table[0].day === dateStr);

          if (targetTable) {
            let timeFormat;
            if (hour === 0) {
              timeFormat = '12am';
            } else if (hour > 0 && hour < 12) {
              timeFormat = `${hour}am`;
            } else if (hour === 12) {
              timeFormat = '12pm';
            } else {
              timeFormat = `${hour - 12}pm`;
            }
            let targetRow = targetTable.find(row => row.time === timeFormat);

            if (targetRow) {
              targetRow.currDose = item.reading2;
            }
          }
        });
      }

      if (maxAllowLeq5m) {
        maxAllowLeq5m.forEach(item => {
          const convertedTime = moment.tz(item.TIMESTAMP, 'UTC').tz('Asia/Taipei');
          const dateStr = (convertedTime.hour() < 7) ? convertedTime.clone().subtract(1, 'days').format('ddd DD-MMM-YY') : convertedTime.format('ddd DD-MMM-YY');
          const hour = convertedTime.hour();

          let targetTable = this.allTablesData.find(table => table[0].day === dateStr);

          if (targetTable) {
            let timeFormat;
            if (hour === 0) {
              timeFormat = '12am';
            } else if (hour > 0 && hour < 12) {
              timeFormat = `${hour}am`;
            } else if (hour === 12) {
              timeFormat = '12pm';
            } else {
              timeFormat = `${hour - 12}pm`;
            }
            let targetRow = targetTable.find(row => row.time === timeFormat);

            if (targetRow) {
              targetRow.maxallowleq = item.reading2;
            }
          }
        });
      }

      let latestLeq12h;
      for (let i = accum12h.length - 1; i >= 0; i--) {
        const convertedTime = moment.tz(accum12h[i].TIMESTAMP, 'UTC').tz('Asia/Taipei');
        const hour = convertedTime.hour();
        if (hour >= 7 && hour <= 18) {
          latestLeq12h = accum12h[i].reading2;
          break;
        }
      }

      this.allTablesData.forEach(table => {
        let latestLeq12hForTable;
        let latestcurrDose;
        let latestmaxAllowLeq5m;

        for (let i = accum12h.length - 1; i >= 0; i--) {
          const convertedTime = moment.tz(accum12h[i].TIMESTAMP, 'UTC').tz('Asia/Taipei');
          const dateStr = (convertedTime.hour() < 7) ? convertedTime.clone().subtract(1, 'days').format('ddd DD-MMM-YY') : convertedTime.format('ddd DD-MMM-YY');

          if (dateStr === table[0].day) {
            const hour = convertedTime.hour();
            if (hour >= 7 && hour <= 18) {
              latestLeq12hForTable = accum12h[i].reading2;
              latestcurrDose = currentDose[i].reading2;
              latestmaxAllowLeq5m = maxAllowLeq5m[i].reading2;
              break;
            }
          }
        }

        table.forEach((row, rowIndex) => {
          const time = moment(row.time, 'ha');
          const hour = time.hour();
          if (hour >= 7 && hour <= 18 && latestLeq12hForTable !== undefined) {
            row.leq12h = latestLeq12hForTable;
            row.currDose = parseFloat(latestcurrDose).toFixed(1);
            row.maxallowleq = parseFloat(latestmaxAllowLeq5m).toFixed(1);
          } else {
            row.leq12h = '-';
          }
        });
      });

      this.removeHoverStyle();
      this.showInfo = true;
      console.log(this.stations);
      this.stations.forEach(item => {
        if (item.value == this.selectedStation) {
          this.instrument_info.Project_title = item.label;
        }
      });
      this.loading = false;
      localStorage.setItem('selectedDate', JSON.stringify(this.selectedDate));
      console.log(this.selectedDate);
    },
    removeHoverStyle() {
      setTimeout(() => {
        const elements = document.getElementsByClassName('el-table--enable-row-hover');

        for (const el of elements) {
          let clz = el.getAttribute('class');
          clz = clz.replace('el-table--enable-row-hover', '');
          el.setAttribute('class', clz);
        }
      }, 100);
    },
    getProject() {
      this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/station-instrument`,
      })
        .then(res => {
          res.data.map(station => {
            this.stations.push({
              value: station._id,
              label: station.name,
            });
            station.instrument.map(instrument => {
              this.instruments.push({
                value: instrument._id,
                label: instrument.name,
                station: station._id,
                coordinates: instrument.coordinates,
                instrumentType: instrument.instrument_type,
                pj_title: this.selectedProject.name,
                location_details: this.selectedProject.location,
              });
            });
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          //console.log('done.');
          if (this.selectedInstrument) {
            let init_dt = new Date();
            init_dt.setHours(0, 0, 0, 0);
            this.selectedDate = [init_dt, init_dt];
            this.loading = false;
            // this.onDateChange(this.selectedDate);
          }
        });
    },
  },
};
</script>
