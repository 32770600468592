<template>
  <el-tabs v-model="selectedTab">
    <el-tab-pane
      name="list"
      label="列表"
      :lazy="true"
    >
      <project-list @change-tab="handleChangeTab"></project-list>
    </el-tab-pane>
    <el-tab-pane
      name="create"
      label="新增"
      :lazy="true"
    >
      <project-form form-type="create" />
    </el-tab-pane>
    <el-tab-pane
      name="edit"
      label="編輯"
      :lazy="true"
    >
      <project-form form-type="edit" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import ProjectForm from '@/components/project/Form';
import ProjectList from '@/components/project/List';
export default {
  components: {
    ProjectForm,
    ProjectList,
  },
  data() {
    return {
      selectedTab: 'list',
    };
  },
  mounted(){
    if(this.$route.query.projectId) this.selectedTab = 'edit';
  },
  methods: {
    handleChangeTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>
