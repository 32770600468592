<template lang="html">
  <enhanced-table :table-data="messageList" :col-configs="colConfigs" :is-laoding="isLoading">
    <div slot="header" v-loading="loadingCredit" class="flex-row justify-between align-center">
      <div v-if="credit">
        <p>SMS-GET 點數: {{ credit['sms-get'] }}</p>
        <p>MONTY 點數: {{ credit['monty'] }}</p>
      </div>
    </div>
    <template v-slot:target="{ colConfig }">
      <el-table-column v-bind="colConfig" label="發送對象">
        <template v-slot="{ row }">
          <p v-for="client in row.target" :key="client._id">
            {{ client.name ? `${client.name},` : '' }} {{ client.phone }}
          </p>
        </template>
      </el-table-column>
    </template>
  </enhanced-table>
</template>

<script>
import moment from 'moment';
import EnhancedTable from '@/components/common/EnhancedTable';
export default {
  components: {
    EnhancedTable,
  },
  data() {
    return {
      loadingCredit: false,
      isLoading: false,
      credit: null,
      messageList: [],
      clientList: [],
      colConfigs: [
        {
          label: '狀態',
          prop: 'failed',
          formatter(column, row, cellValue) {
            console.log(cellValue);
            return cellValue === false ? '成功' : '失敗';
          },
        },
        {
          label: '訊息ID',
          prop: 'sms_id',
        },
        {
          label: '訊息內容',
          prop: 'content',
          'min-width': '400px',
        },
        {
          slot: 'target',
          'min-width': '250px',
        },
        {
          label: '發送時間',
          prop: 'created_at',
          'min-width': '150px',
          formatter(column, row, cellValue) {
            return moment(cellValue).format('YYYY-MM-DD HH:mm');
          },
        },
      ],
    };
  },
  mounted() {
    this.getMessageCredit();
    this.getMessage();
  },
  methods: {
    handleCurrentChange(page) {
      this.currentPage = page;
    },
    getMessageCredit() {
      this.loadingCredit = true;
      return this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'sms/credit',
      })
        .then(res => {
          this.credit = res.data;
        })
        .catch(error => {
          this.$notifyError(error);
        })
        .finally(() => {
          this.loadingCredit = false;
        });
    },
    getMessage() {
      this.isLoading = true;
      return this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'sms',
      })
        .then(res => {
          this.messageList = res.data;
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
