<template>
  <el-card class="container" shadow="always">
    <el-switch v-model="maxOrAdd" @change="switchChange"></el-switch>
    <!--v-chart
      class="chart"
      :autoresize="true"
      :options="option"
    ></v-chart-->
    <div id="lineChart" class="chart"></div>
  </el-card>
</template>

<script>
import 'echarts-gl';
import echart from 'echarts';

export default {
  data(){
    return {
      maxOrAdd: false,
      dot:[
        {
          x: 13,
          y: 25,
          z: 1.72,
          r: 10,
        },
        {
          x: 22,
          y: 20,
          z: 1.91,
          r: 10,
        },
        {
          x: 18,
          y: 18,
          z: 0.92,
          r: 10,
        },
        {
          x: 33,
          y: 25,
          z: 1.33,
          r: 10,
        },
        {
          x: 2,
          y: 40,
          z: 2.01,
          r: 10,
        },
        {
          x: 28,
          y: 38,
          z: 1.5,
          r: 10,
        },
      ],
      table: [],
      chart: null,
    };
  },
  computed: {
    getOption(){
      let vm = this;
      return {
        tooltip: {},
        backgroundColor: '#fff',
        visualMap: {
          show: false,
          dimension: 2,
          min: 0,
          max: 2,
          inRange: {
            color: [
              '#00EC00',
              '#F9F900',
              '#FF0000',
              //'#313695',
              //'#4575b4',
              //'#74add1',
              //'#abd9e9',
              //'#e0f3f8',
              //'#ffffbf',
              //'#fee090',
              //'#fdae61',
              //'#f46d43',
              //'#d73027',
              //'#a50026',
            ],
          },
        },
        xAxis3D: {
          type: 'value',
        },
        yAxis3D: {
          type: 'value',
        },
        zAxis3D: {
          type: 'value',
          max: 3,
          splitNumber: 2,
        },
        grid3D: {
          viewControl: {
            // projection: 'orthographic'
          },
          boxHeight: 40,
        },
        series: [
          {
            type: 'surface',
            wireframe: {
              show: false,
            },
            shading: 'color',
            equation: {
              x: {
                step: 1,
                min: 0,
                max: 10,
              },
              y: {
                step: 1,
                min: 0,
                max: 3,
              },
              z: function z (x, y) {
                //console.log(`x:${x} y:${y}`);
                //console.log(vm.table);
                if(vm.table[x]&&vm.table[x][y]) return vm.table[x][y];
                return 0;
              },
            },
          },
        ],
      };
    },
  },
  mounted(){
    this.init();
  },
  methods: {
    makeDots(){
      let result = [];
      //x長度有100m
      //y長度有30m
      //x每10m一個點
      //y每10m一個點
      let x_Max = 100, x_step = 10, y_Max = 30, y_step = 10;
      for(let x = 0; x < ((x_Max+1) / x_step); x++){
        result.push([]);
        for(let y = 0; y < ((y_Max+1) / y_step); y++){
          result[x].push(0);
        }
      }
      for(let x = 0; x < (x_Max / x_step); x++){
        result[x][1] = Math.random()*2;
        result[x][2] = Math.random()*2;
      }
      return result;
    },
    init(){
      //this.table = this.getData();
      this.table = this.makeDots();
      this.chart = echart.init(document.getElementById('lineChart'));
      this.chart.setOption(this.getOption,true);
    },
    getData(){
      let table = [];
      for(let x = 0; x < 50; x++){
        table.push([]);
        for(let y = 0; y < 50; y++){
          table[x].push(0);
        }
      }
      let dots = this.dot;
      for(let index in dots){
        let dot = dots[index];
        for(let x = 0; x < 50; x++){
          for(let y = 0; y < 50; y++){
            //let distance = Math.pow((Math.pow(dot.x-x,2)+Math.pow(dot.y-y,2)),0.5);
            //if(distance<=dot.r) //distance
            //{
            //  //console.log(`${distance} ${dot.r}`);
            //  //let add = dot.z * Math.cos((90*distance/dot.r) * Math.PI / 180);
            //  let add = dot.z - dot.z * distance/dot.r;
            //  if(this.maxOrAdd) {
            //    if(table[x][y]<add) table[x][y] = add;
            //  }
            //  else {
            //    table[x][y]+=(add>=0)?add:0;
            //  }
            //}
            const r = 3;
            let add = 0;
            for(let _x = 0; _x < 50; _x++){
              for(let _y = 0; _y < 50; _y++){
                let distance = Math.pow((Math.pow(_x-x,2)+Math.pow(_y-y,2)),0.5);
                if(distance <= r){
                  add += table[x][y];
                }
              }
            }
            if(this.maxOrAdd) {
              if(table[x][y]<add) table[x][y] = add;
            }
            else {
              table[x][y]+=(add>=0)?add:0;
            }
          }
        }
      }
      return table;
    },
    switchChange(){
      //this.table = this.getData();
      this.table = this.makeDots();
      this.chart.setOption(this.getOption);
    },
  },
};
</script>


<style lang="stylus" scoped>
  .container
    width:  100%
    height: 100%
    min-height: 400px
  .chart
    width: 100%
    height: 100%
    min-height: 400px
    border: 1px solid #ccc
</style>