<template>
  <el-card v-loading="isLoading" shadow="always">
    <highcharts ref="highcharts" :options="chartOptions" />
  </el-card>
</template>

<script>

export default {
  props:{
    selectedInstrument: {
      type: String,
      required: true,
    },
    selectedInstrumentData: {
      type: Object,
      required: false,
    },
  },
  data(){
    return{
      targetInstrument: null,
      chart : {      
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title : {
        text: 'Voltage',
      },
      pane : {
        startAngle: -120,
        endAngle: 120,
        background: [
          {
            backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, '#FFF'],
                [1, '#333'],
              ],
            },
            borderWidth: 0,
            outerRadius: '109%',
          }, {
            backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, '#333'],
                [1, '#FFF'],
              ],
            },
            borderWidth: 1,
            outerRadius: '107%',
          }, {
            // default background
          }, {
            backgroundColor: '#DDD',
            borderWidth: 0,
            outerRadius: '105%',
            innerRadius: '103%',
          },
        ],
      },
      yAxis : {
        min: 8,
        max: 14,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#666',

        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#666',
        labels: {
          step: 2,
          rotation: 'auto',
        },
        title: {
          text: 'V',
        },
        plotBands: [{
          from: 11,
          to: 14,
          color: '#8BD622', // green :#55BF3B 8bd622
        }, {
          from: 10,
          to: 11,
          color: '#FFC000', // yellow :#DDDF0D ffc000
        }, {
          from: 8,
          to: 10,
          color: '#E83618', // red :#DF5353 e83618
        }],
      },
      series: [{
        name: 'Voltage',
        data: [0],
        tooltip: {
          valueSuffix: ' V',
        },
        overshoot: 5,
      }],
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: this.chart,
        title: this.title,
        pane: this.pane,
        yAxis: this.yAxis,
        series: this.series,
        credits: {
          enabled: false,
        },
      };
    },
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
  async mounted(){
    await this.Init();
  },
  methods: {
    async Init(){
      if(this.selectedInstrumentData) {
        //當收到完整的InstrumentData時，不使用API請求資料
        console.log(this.selectedInstrumentData);
        this.targetInstrument = this.selectedInstrumentData;
        this.SetGuage();
      }
      else{
        //未收到InstrumentData，將使用API請求讀值
        await this.$axios({
          category: 'project',
          method: 'get',
          apiName: `${this.selectedProject._id}/data/last/${Date.now()}`,
        })
          .then(res=>{
            for(let i in res.data){
              if(res.data[i]._id == this.selectedInstrument){
                this.targetInstrument = res.data[i];
                break;
              }
            }
            this.SetGuage();
          });
      }
    },
    SetGuage(){
      if(!this.targetInstrument) return;
      this.series[0].data = [this.targetInstrument.reading2];
      this.title.text = this.targetInstrument.name;
    },
  },
};
</script>
