import { COLORS } from '@/constants/mapSettings.js';

const dotList = [
  {
    color: COLORS.SUSPENSION,
    label: 'dots.wsl',
    value: 'WSL',
  },
  {
    color: COLORS.ALERT,
    label: 'dots.alert',
    value: 'AL',
  },
  {
    color: COLORS.WARNING,
    label: 'dots.wl',
    value: 'WL',
  },
  {
    color: COLORS.GREEN,
    label: 'dots.normal',
    value: 'GREEN',
  },
  {
    color: COLORS.GREY,
    label: 'dots.down',
    value: 'down',
  },
];

const getStatusColor = status => {
  const statusList = ['WSL', 'WL', 'AL', 'GREEN', 'down'];
  const styleList = [
    COLORS.SUSPENSION,
    COLORS.WARNING,
    COLORS.ALERT,
    COLORS.GREEN,
    COLORS.GREY,
  ];
  const styleIndex = statusList.findIndex(ele => {
    return ele.includes(status);
  });
  return styleList[styleIndex];
};

export { dotList, getStatusColor };
