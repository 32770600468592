import Cookies from 'js-cookie';
let isSupLocalStorage = true;
if (!window.localStorage) {
  console.log('瀏覽器不支援localStorage');
  isSupLocalStorage = false;
} else {
  try {
    window.localStorage.setItem('test', 'test');
    window.localStorage.removeItem('test');
  } catch (e) {
    isSupLocalStorage = false;
    console.log('瀏覽器不支援localStorage');
  }
}

/**
 * 儲存到使用者端本地資料 (cookie and LocalStorage)
 * @param {String} key key
 * @param {String} data data
 */
function saveLocalData (key, data) {
  if (isSupLocalStorage) {
    if(typeof data === 'object' && data !== null)  {
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      localStorage.setItem(key, data);
    }
  }
  // Cookies.set(key, data, { expires: 99999, path: '' });
}

/**
 * 取得使用者端本地資料 (cookie and LocalStorage)
 * @param {String} key key
 * @return {String} 取得的值
 */
function getLocalData (key) {
  if (isSupLocalStorage) {
    return localStorage.getItem(key) || Cookies.get(key);
  } else {
    // return Cookies.get(key);
  }
}

/**
 * 刪除使用者端本地資料 (cookie and LocalStorage)
 * @param {String} key key 沒輸入的話 全部清除
 */
function removeLocalData (key) {
  if (key) {
    if (isSupLocalStorage) {
      localStorage.removeItem(key);
    }
    Cookies.remove(key, { path: '' });
  } else {
    const allCookie = Cookies.get();
    for (const k in allCookie) {
      if (isSupLocalStorage) {
        localStorage.removeItem(k);
      }
      Cookies.remove(k, { path: '' });
    }
  }
}

export { saveLocalData, getLocalData, removeLocalData };
