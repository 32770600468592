const en = {
  lang: {
    'zh-TW': 'Chinese',
    'en-US': 'English',
  },
  Notify: {
    'error': 'error',
    'info': 'info',
    'success': 'success',
    'cancelled': 'cancelled',
  },
  common: {
    show: 'Show',
    hide: 'Hide',
  },
  navbar: {
    project: 'Project',
    language: 'Language',
    logout: 'Sign Out',
    slope: 'Slope Monitoring', 
    storm: 'RainStorm',
  },
  sidebar: {
    dashboard: 'Dashboard',
    slope: 'Slope Monitoring', 
    storm: 'RainStorm',
    summary: 'Summary',
    snakeLine: 'SnakeLine',
    chart: 'Chart',
    curve: 'Curve',
    data: 'Data List',
    map: 'Map',
    export: 'Export',
    details: 'Project Info',
    layout: 'Layout',
    contact: 'Contact',
    monitor: 'CCTV',
    alerts: 'Event Log',
    occ: 'OCC',
    noise: 'Noise Monitor',
    trajectory: 'Trajectory',
    rank: 'Ranking',
    eventList: 'Event Log',
    manual: 'Manual',
    'manual-data': 'Data',
    'manual-edit': 'Input Data',
    'manual-instrument': 'Add Instrument',
    AdvancedOptions: 'Advanced Options',
    'OCC-Contact': 'Contact',
  },
  login: {
    title: 'Login',
    title_edit: 'Change Password',
    email: 'E-mail Address',
    psd: 'Password', //將password改成psd，以規避白箱檢查，因為這裡是多語系文件
    forgot: 'Forgot Password',
    npsd: 'New Password(the password cannot be the same as the phone number)',
    psdCheck: 'Check Password',
    psdValidate: ' Password Cannot Be Less Then 6 Characters',
    psdCheckValidate: 'Passwords Do Not Match',
  },
  chart: {
    title: {
      historyChart: 'Time History Curve',
    },
    measurements: 'Measurements',
    Date: 'Date',
    reset: 'Reset',
    filter: 'Filter',
    submit: 'Submit',
    settings: 'Settings',
    rescale: 'Rescale Chart',
    table: 'Show Table',
    remark: {
      show: 'Show Remark',
      hide: 'Hide Remark',
    },
    refresh: 'Auto Refresh',
    lastFetch: 'Last updated on',
    area: {
      name: 'Area',
      pick: 'Pick Area',
      none: 'No project assigned',
    },
    station: {
      name: 'Station',
      pick: 'Pick Station',
    },
    instrument: {
      name: 'Instrument',
      pick: 'Select Instrument',
      none: 'No Instrument',
      picked: 'Selected',
      alert: 'Instrument Alert',
    },
    date: {
      pick: 'Select Time',
      start: 'Start Date',
      end: 'End Date',
      interval: 'Date Interval',
      day: 'Day',
      hour: 'Hour',
      minute: 'Minute',
    },
    interval: {
      pick: 'Data Interval',
      daily: '1D',
      quarter: '6H',
      hour: '1H',
      '10min': '10m',
      '15min': '15m',
      all: 'All',
      tooltip: {
        daily: 'Get datas in every 0:00am',
        quarter: 'Get all data in interval 6 Hours',
        hour: 'Get all data in interval 1 Hours',
        '10min': 'Get all data in interval 10 minutes',
        '15min': 'Get all data in interval 15 minutes',
        all: 'Get All Data.',
      },
    },
    execute: {
      btn: 'Search',
      loading: 'Loading...',
    },
    xAxis: {
      max: 'Max Value',
      min: 'Min Value',
      interval: 'Interval',
      hide: 'Hide unit',
      show: 'Show unit',
      position: 'Change unit position',
    },
    yAxis: {
      max: 'Max Value',
      min: 'Min Value',
      interval: 'Interval',
      hide: 'Hide unit',
      show: 'Show unit',
      position: 'Change unit position',
      endOnTick: 'Enable auto scale Y axis.',
    },
    error: {
      loading: 'Failed to load',
      date: 'Please pick a date',
      instrument: 'Please pick an instrument',
    },
    velocity24Hr: '24Hr Rate',
    velocity1Hr: '1Hr Rate',
    compare: 'Compare',
    group: 'Group',
    tenGroup: '10',
    eachGroup: 'Each',
    rainGroup: 'Rain',
    sync: 'Sync',
    output: 'Output-1',
    'output-2': 'Output-2',
    rain1Day: 'Daily Rainfall',
    trendline: 'trendline',
    trendlineError: 'Trendline can only use one instrument.',
    addSeven: 'show 7 days',
    day: 'day',
    info: {
      compare: 'Compare with specified time',
      group: 'Group the charts by unit',
      tenGroup: 'Group the charts of 10 instruments',
      eachGroup: 'Let each chart have its own graph.',
      rainGroup: 'Display rainfall in all groups (For use only with groups of 10 or eachGroup.)',
      output: 'Output each chart in one picture',
      'output-2': 'Output pictures per group',
      rain1Day: 'Only take rainfall data at 00:00 daily',
      trendline: 'trendline',
    },
    autoScale: 'Auto Scale',
  },
  remark: {
    date: 'Date Range',
    type: 'Type',
    content: 'Content',
    placeholder: {
      date: {
        start: 'Start date',
        end: 'End date',
      },
      type: 'Select remark type',
      content: 'Please insert remark content',
    },
    form: {
      edit: 'Edit remark',
      create: 'Create remark',
      cancel: 'Cancel',
      confirm: 'Confirm',
      update: 'Update',
      success: 'Success',
    },
    rules: {
      type: 'Please select remark type',
      content: 'Please insert remark content',
    },
    typeList: {
      status: 'Instrument Status',
      construction: 'Site Construction Status',
    },
  },
  export: {
    interval: {
      pick: 'Data Interval',
      daily: 'Daily',
      quarter: '6 Hours',
      hour: '1H',
      all: 'All',
    },
    type: {
      pick: 'Select Data Type',
      raw: 'Raw Data',
      measure: 'Measurements',
      velocity1Hr: '1Hr Rate',
      velocity24Hr: '24Hr Rate',
    },
    station: {
      pick: 'Select Station to export',
      pickAll: 'Select All',
      none: 'No Station',
    },
    date: {
      pick: 'Select Date Range',
      start: 'Start Date',
      end: 'End Date',
    },
    download: 'Download',
    tips: {
      title: 'Remark',
      0:'1. When selecting "All" for data intervals: There is a limit for 90 days only.',
      1:'2. When selecting "1D" or "1H" for data intervals: No limit on the number of days.',
      2:'3. Regardless of any condition, the limit on the quantity of instruments is 60.',
      3:'4. During file export, the output time depends on the amount of data. Please be patient.',
    },
  },
  data: {
    raw: 'Raw Data',
    status: 'Status',
    compareDate: 'Compare date time',
    instrument: {
      name: 'Instrument Name',
    },
    value: {
      alert: 'Alert Value',
      action: 'Action Value',
      velocity1Hr: '1Hr Rate',
      velocity24Hr: '24Hr Rate',
    },
    chart: 'Chart',
    measurements: 'Measurements',
    update: 'Update Time',
    none: 'No Data',
    onlyWaring: 'Breach AL/WSL',
    refresh: 'Auto Refresh',
    batt: 'Battery',
    remark: 'Remark',
    navbar: {
      all: 'All',
      action: 'Action',
      warning: 'Warning',
      alert: 'Alert',
    },
    R1: 'R1',
    R3: 'R3',
    R24: 'R24',
    day: 'day',
    month: 'month',
  },
  map: {
    settings: 'Settings',
    stations: {
      pick: 'Pick Station',
    },
    contour: 'Contour',
    instrument_id: 'Instrument ID',
    orthoimagery_general : 'Orthoimagery (General)',
    orthoimagery_mix: 'Orthoimagery (Mix)',
    general_electronic_map: 'Taiwan Electronic Map',
    typhon: 'Typhoon Track Map',
    echo: 'Radar Echo Map',
    rainfall: 'Cumulative Rainfall Map',
    earthquake: 'Earthquake Report',
    shp: 'Geologically Sensitive Area',
  },
  rank: {
    all_check: 'ALL',
    max_allow_rows: 'Max allow rows:',
    max_allow_rows_2: '',
    title: 'Ranking',
    rg: 'Rain Gauge',
    instrument_name: 'Instrument Name',
    rain_value: 'Precipitation(mm/day)',
    tm: 'TM',
    tm_value: 'Specify Time VAR.',
    tm_total: 'Accumulation VAR.',
    tm_rate: 'Rate(mm/hr)',
    sti: 'STI',
    sti_value: 'Rate(deg/hr)',
    degree: 'deg',
  },
  project: {
    name: 'Project Name',
    project_id: 'Project ID',
    location: 'Location',
    start_date: 'Starting Date',
    client: 'Client',
    contractor: 'Main Contractor',
    instrument_company: 'Instrument Company',
    pick: 'Pick a project',
  },
  trajectory: {
    selectInstrumentGroupX: 'Select InstrumentGroup X',
    selectInstrumentGroupY: 'Select InstrumentGroup Y',
  },
  contact: {
    title: {
      info: 'Information',
      address: 'Address',
      phone: 'Contact',
      email: 'General Support',
    },
    company: 'GeoSupply Co., Ltd.',
    address1:
      '1F., No. 2, Ln. 18, Ren\'ai Rd., Xizhi Dist., New Taipei City 221027 , Taiwan (R.O.C.)',
  },
  pending: {
    title: 'We are settings up your account.',
    subtitle: 'Please try again later or contact us.',
  },
  dots: {
    wsl: 'Work Suspension Level',
    alert: 'Alert Level',
    wl: 'Warning Level',
    normal: 'Normal',
    down: 'Down',
    heavyRain: 'Heavy Rain',
    torrentialRain: 'Torrential Rain',
    superTorrentialRain: 'Super Torrential Rain',
    extremeTorrentialRain: 'Extreme Torrential Rain',
  },
  Depth: 'Depth',
  Distance: 'Distance',
  Value: 'Value',
  datetime: 'Date Time',
  Curve: 'Curve',
  Compare: 'Compare',
  'No Data': 'No Data',
  dashboard: {
    noneModuleType: 'Please select dashboard module type.',
    showModuleCount: 'Show Module Count',
    editModule: 'Edit Modules',
    save: 'save',
    updateToThisProject: 'Update to this project',
  },
  eventList: {
    AL: 'AL',
    WSL: 'WSL',
    all: 'All',
    dateTime: 'Pick Time',
    selectInstrument: 'Pick Instrument',
    startTime: 'Time',
    station: 'Station',
    instrument: 'Instrument',
    content: 'Content',
    snakeLine: 'SnakeLine',
    
    area: 'Area',
    chart: 'Chart',
    notifyStaff: 'Notify Staff',
    notifyTime: 'Notify Time',
    down: 'Instrument Down',
  },
  logType: {
    add: 'Add',
    delete: 'Delete',
    view: 'View',
    update: 'Update',
    unauthorized: 'Unauthorized',
    xml: 'XML',
    line: 'Line',
    all: 'All',
  },
};
export default en;
