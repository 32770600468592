<template>
  <station-list @change-tab="handleChangeTab"></station-list>
</template>

<script>
import StationList from '@/components/station/List';
export default {
  components: {
    StationList,
  },
  data() {
    return {
      selectedTab: 'list',
    };
  },
  methods: {
    handleChangeTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>
