<template>
  <div v-loading="isLoading">
    <el-form 
      class= "columns is-multiline"
      label-position="top"
      label-width="80px"
      v-if="images && images.length > 0"
    >
      <div v-for="(image) in images" :key="image" class="column" :style="{ width:`50%`,flex: 'none' }">
        <el-card class="flex-row align-center justify-center">
          <ImageBox :file="image"></ImageBox>
        </el-card>
      </div>
    </el-form>
    <el-card v-else>
      <div class="flex-row align-center justify-center" style="min-height: 720px;">
        <div class="content">
          <h3>{{ $t('data.none') }}</h3>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment';
import ImageBox from '@/components/monitor/ImageBox';
export default {
  components: {
    ImageBox,
  },
  props:{
    selectedMonitor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      images: [],
      lastSelectedMonitor: null,
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    timezone() {
      return this.$store.getters['env/timezone'];
    },
  },
  mounted() {
    this.isLoading = true;
    this.reflashFunction = setInterval(() => {
      this.updatedMonitor();
    }, 1000 * 1);
  },
  updated() {
    this.updatedMonitor();
  },
  methods: {
    updatedMonitor(){
      console.log(this.selectedMonitor);
      if(!this.selectedMonitor) return;
      this.isLoading = true;
      this.images = [this.selectedMonitor];
    },
  },
};
</script>
