<template lang="html">
  <div>
    <el-form
      ref="stationForm"
      :model="form"
      :validate-on-rule-change="false"
      :hide-required-asterisk="true"
      :inline="true"
      label-position="top"
      label-width="80px"
    >
      <el-row>
        <el-form-item :label="$t('chart.instrument.pick')">
          <el-cascader
            v-model="form.instrument"
            style="min-width:300px"
            ref="cascader"
            filterable
            clearable
            :options="instrumentOptions"
            :collapse-tags="true"
            :show-all-levels="false"
            :placeholder="$t('chart.instrument.pick')"
            @change="getEventList"
          ></el-cascader>
        </el-form-item>
        <el-form-item prop="date" :label="$t('eventList.dateTime')">
          <el-date-picker
            v-model="form.date"
            type="datetimerange"
            format="yyyy/MM/dd HH:mm"
            value-format="timestamp"
            :start-placeholder="$t('chart.date.start')"
            :end-placeholder="$t('chart.date.end')"
            :picker-options="datePickerOptions"
            :default-time="['00:00:00']"
            @change="getEventList"
          />
        </el-form-item>
      </el-row>
    </el-form>
    <enhanced-table :table-data="eventList" :col-configs="colConfigs" :radio-configs="radioConfigs" :isLoading="isLoading">
    </enhanced-table>
  </div>
</template>

<script>
import EnhancedTable from '@/components/common/EnhancedTable';
export default {
  components: {
    EnhancedTable,
  },
  data() {
    return {
      project: null,
      useUrl: 'https://api-thsr-rain.geosupply.com.tw/',//https://api-thsr-rain.geosupply.com.tw/
      isLoading: false,
      instrumentOptions: [],
      areaList: [],
      form: {
        area: '',
        station: '',
        instrument: null,
        event: null,
        date: [
          this.$moment()
            .subtract(6, 'month')
            .startOf('day')
            .format('x'),
          this.$moment().format('x'),
        ],
      },
      colConfigs: [
        {
          label: this.$i18n.t('eventList.startTime'),
          prop: 'label',
          'min-width': '250px',
        },
        {
          label: this.$i18n.t('eventList.station'),
          prop: 'station',
          'min-width': '180px',
        },
        {
          label: this.$i18n.t('eventList.instrument'),
          prop: 'instrument',
          'min-width': '150px',
        },
        {
          label: this.$i18n.t('eventList.content'),
          prop: 'content',
          'min-width': '600px',
        },
        {
          label: this.$i18n.t('eventList.snakeLine'),
          prop: 'instrument_id',
          'min-width': '80px',
          urlBtn: 'snakeline',
          block: 'chart',
        },
      ],
      radioConfigs: [
        {
          text: this.$i18n.t('eventList.all'),
          value: '',
        },
        {
          text: this.$i18n.t('eventList.AL'),
          value: '黃燈',
        },
        {
          text: this.$i18n.t('eventList.WSL'),
          value: '紅燈',
        },
        //{
        //  text: 'other',
        //  value: '未知',
        //},
      ],
    };
  },
  computed: {
    selectedProject() {
      if(this.project) return this.project;
      return this.$store.getters['project/selectedProject'];
    },
  },
  mounted() {
    this.setProject()
      .then(() => {
        this.initCascaderSelector().then(()=>{
          this.form.instrument = [undefined, 'all'];
          return this.getEventList();
        });
      });
  },
  methods: {
    setProject() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: '',
        useUrl: this.useUrl,
      })
        .then(res => {
          this.project = res.data[0];
        })
        .catch(this.$notifyError);
    },
    initCascaderSelector() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/area-station-instrument`,
        useUrl: this.useUrl,
      })
        .then(res => {
          const stationInstruments = [];
          let index = 0;
          res.data.area.forEach((area) => {
            return area.station.forEach((station) => {
              const { _id, name } = station;
              const instrument = station.instrument.map((instrument) => {
                const { _id, last_reading, display_output, status } = instrument;
                const data = instrument.latestVersion;
                index += 1;
                return {
                  _id,
                  name: data.name,
                  reading: last_reading ? `${last_reading[display_output]} ${data.unit}` : null,
                  TIMESTAMP: last_reading ? last_reading.TIMESTAMP : null,
                  coordinates: data.coordinates,
                  status,
                  index,
                };
              });
              stationInstruments.push({ _id, name, instrument });
            });
          });
          const resultList = stationInstruments.map(station => {
            const obj = {
              label: station.name,
              children: station.instrument.map(instrument => {
                const obj = {
                  label: instrument.name,
                  value: instrument._id,
                };
                return obj;
              }),
            };
            return obj;
          });
          const allList = [{
            label:'all-station',
            children:[
              {
                label: 'all-instrument',
                value: 'all',
              },
            ],
          }];
          this.instrumentOptions = [...allList, ...resultList];
          this.areaList = res.data.area;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getEventList() {
      this.isLoading = true;
      this.eventList = [];
      this.form.event = null;
      //const instrument = this.$refs.cascader.getCheckedNodes(true)[0].value;
      const instrument = this.form.instrument[1];
      let [startTime, endTime] = this.form.date;
      return this.$axios({
        category: 'event',
        method: 'get',
        apiName: `/project/${this.selectedProject._id}/instrument/${instrument}/alertEvent?startTime=${startTime}&endTime=${endTime}`,
        useUrl: this.useUrl,
      })
        .then(res => {
          let events = res.data.map(event => {
            if (event.endTime) {
              event.label = `${this.$moment(event.startTime).format(
                'YYYY-MM-DD HH:mm',
              )} ~ ${this.$moment(event.endTime).format('YYYY-MM-DD HH:mm')}`;
            } else {
              event.label = `${this.$moment(event.startTime).format('YYYY-MM-DD HH:mm')} (進行中)`;
            }
            return event;
          });
          this.eventList = events;
          if (this.eventList.length > 0) {
            this.form.event = this.eventList[0].startTime;
            // this.form.event = '2020-05-21T05:50:00.000Z';
          }
          this.eventList = this.eventList.map(ele => {
            return {
              ...ele,
              instrument: ele.instrument_id.data[0].name,
              station: this.getInstrumentStation(ele.instrument_id),
              area: this.getInstrumentArea(ele.instrument_id),
              alert: this.getAlertType(ele.outputs),
            };
          }).map(ele => {
            return {
              ...ele,
              content: '-高鐵邊坡暴雨預警系統通知-\n' +
                      ` ${ele.area}分區內高鐵里程${ele.station}之雨量站
                        ${ele.instrument}
                        達${(ele.alert === 'AL') ? '黃燈' : (ele.alert === 'WSL') ? '紅燈' : '未知'}警戒
                      `,
            };
          }).filter(ele => {
            return ![null,undefined,NaN].includes(ele.station);
          });
          //console.log(this.eventList);
        })
        .catch((e)=>{
          this.$notifyError(e);
          console.log(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getInstrumentStation(instrument){
      //console.log(this.instrumentOptions);
      for(let station of this.instrumentOptions){
        for(let _instrument of station.children){
          if(_instrument.label === instrument.data[0].name) return station.label;
        }
      }
      return null;
    },
    getInstrumentArea(instrument){
      for(let area of this.areaList){
        for(let station of area.station){
          for(let _instrument of station.instrument){
            if(_instrument.latestVersion.name === instrument.data[0].name) return area.name;
          }
        }
      }
      return null;
    },
    getAlertType(outputs){
      const ALStandard = {
        threeHourRainFallIntercept: 25,
        oneDayRainFallIntercept: 190,
      };

      const WSLStandard = {
        threeHourRainFallIntercept: 40,
        oneDayRainFallIntercept: 285,
      };
      let result = null;
      for(let output of outputs){
        const { threeHourRainFallIntercept, oneDayRainFallIntercept } = output;
      
        if (
          threeHourRainFallIntercept > WSLStandard.threeHourRainFallIntercept ||
          oneDayRainFallIntercept > WSLStandard.oneDayRainFallIntercept
        ) {
          return 'WSL';
        } else if (
          threeHourRainFallIntercept > ALStandard.threeHourRainFallIntercept ||
          oneDayRainFallIntercept > ALStandard.oneDayRainFallIntercept
        ) {
          result = 'AL';
        }
      }
      return result;
    },
  },
};
</script>
