<template>
  <el-card v-loading="chartLoading" class="minichart-container" shadow="always">
    <el-row>
      <!--div class="flex-row align-center justify-between">
          <span v-if="autoRefresh" class="is-size-7"
            >{{ $t('chart.lastFetch') }}:
            {{ $moment(lastRefreshTimestamp).format('YYYY/MM/DD HH:mm') }}</span
          >
        </div>
      </div-->
    </el-row>
    <highcharts ref="highcharts" :options="chartOptions" />
  </el-card>
</template>

<script>
import { chartColorPalettes } from '../chart/chartColorPalettes.js';
import { symbols } from '../chart/symbol.js';
import { getStatusColor } from '@/lib/base/systemAlert.js';
import MomentTimeZone from 'moment-timezone';
import moment from 'moment';
window.moment = moment;
MomentTimeZone();

export default{
  props: {
    selectedInstruments: {
      type: Array,
      required: true,
    },
    instrumentOptions: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      remark: {
        formType: 'create',
        editing: {},
        instrument: '',
        timestamp: '',
        show: false,
      },
      hadSubmitted: false,
      initLoading: true,
      chartLoading: false,
      chartReady: false,
      showRemark: false,
      showSettings: false,
      alertInstrument: {
        _id: null,
        showTarget: ['WSL', 'AL', 'WL'],
      },
      lastRefreshTimestamp: 0,
      form: {
        instrument: [],
        date: [
          moment()
            .subtract(7, 'd')
            .startOf('day')
            .utc()
            .format(),
          moment()
            .utc()
            .format(),
        ],
        interval: 'all',
        intervalInitialTime: '00:00',
        selectedArea: '',
        selectedStation: '',
      },
      chartData: {},
      instrumentRemarkList: [],
      customize: {
        xAxisTickInterval: {
          day: 1,
          hours: null,
        },
      },
      colorPalettes: chartColorPalettes,
      options: {
        series: [],
        xAxis: {
          min: undefined,
          max: undefined,
          type: 'datetime',
        },
        yAxis: [],
      },
      oldYAxis: [],
      unitIndex: [],
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    timezone() {
      return this.$store.getters['env/timezone'];
    },
    hasAccess() {
      return {
        remark: this.$store.getters['project/hasRemarkAccess'],
      };
    },
    chartOptions() {
      const vm = this;
      return {
        title: {
          text: this.$t('chart.title.historyChart'),
        },
        exporting: {
          enabled: false,
          scale: 3,
          // sourceWidth: 1200
        },
        time: {
          timezone: this.timezone,
        },
        chart: {
          zoomType: 'xy',
          height: 500,
          spacingLeft: 50,
          spacingRight: 30,
          styledMode: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            turboThreshold: 0,
            cursor: 'pointer',
            point: {
              events: {
                click(event) {
                  if (vm.hasAccess.remark) {
                    vm.remark.instrument = event.point.instrument;
                    vm.remark.timestamp = event.point.x;
                    if (event.point.remark) {
                      const remark = event.point.remark;
                      vm.remark.formType = 'edit';
                      vm.remark.editing = remark;
                    } else {
                      vm.remark.formType = 'create';
                    }
                    vm.remark.show = true;
                  }
                },
              },
            },
            scatter: {
              tooltip: {
                dateTimeLabelFormats: '%d/%m/%Y %H:%M',
              },
            },
          },
          scatter: {
            showInLegend: false,
            tooltip: {
              crosshairs: true,
              xDateFormat: '%d/%m/%Y %H:%M',
            },
          },
        },
        tooltip: {
          // useHTML: true,
          xDateFormat: '%d/%m/%Y %H:%M',
          valueDecimals: 3,
          shared: true,
          className: 'p-16 ',
          formatter(tooltip) {
            return tooltip.defaultFormatter.call(this, tooltip);
          },
        },
        yAxis: this.options.yAxis,
        xAxis: {
          ...this.options.xAxis,
          // startOnTick: true,
          title: {
            text: 'Date',
            align: 'low',
          },
          endOnTick: true,
          showFirstLabel: true,
          gridLineWidth: 1,
          labels: {
            style: {
              fontWeight: 700,
              fontSize: '13px',
            },
            formatter() {
              // if (this.isFirst || this.isLast) {
              if (this.isFirst) {
                const timestamp = vm.$moment(this.value);
                const hour = timestamp.hour();
                const minute = timestamp.minute();
                if (hour === 0 && minute === 0) {
                  return vm.$moment(this.value).format('YYYY/MM/DD');
                } else {
                  return vm.$moment(this.value).format('YYYY/MM/DD HH:mm');
                }
              } else {
                return this.axis.defaultLabelFormatter.call(this);
              }
            },
          },
          dateTimeLabelFormats: {
            millisecond: '%m/%d %H:%M',
            second: '%m/%d %H:%M',
            minute: '%m/%d %H:%M',
            hour: '%m/%d %H:%M',
            day: '%m/%d',
            week: '%m/%d',
            month: '%m/%d',
            year: '%m/%d',
          },
          tickInterval:
            (this.customize.xAxisTickInterval.day * 24 + this.customize.xAxisTickInterval.hours) *
            3600 *
            1000,
        },
        series: this.options.series,
        legend: {
          maxHeight: 80,
        },
      };
    },
    selectedInstrument() {
      const instrumentList = [];
      this.form.instrument.forEach(array => {
        array.forEach(id => {
          if (id && id.trim().length && !instrumentList.includes(id)) {
            const instrument = this.instrumentOptions.find(elem => {
              return elem._id === id;
            });
            instrumentList.push(instrument);
          }
        });
      });
      return instrumentList;
    },
  },
  async mounted() {
    this.initLoading = false;
    this.initCascaderSelector();
  },
  methods: {
    initCascaderSelector() {
      this.initLoading = false;
      let push = false;
      //console.log(this.selectedInstruments);
      this.selectedInstruments.forEach(instrumentId=>{
        if (
          instrumentId &&
          this.instrumentOptions.some(ele => {
            return ele._id === instrumentId;
          })
        ) {
          this.form.instrument.push([undefined, undefined, instrumentId]);
          push = true;
        }
      });
      if(push) this.submitForm();
    },
    strictFilterInstrumentList() {
      return new Promise(resolve => {
        const instrumentList = [];
        this.form.instrument.forEach(ele => {
          ele.forEach(instrument => {
            if (instrument && instrument.trim().length && !instrumentList.includes(instrument))
              instrumentList.push(instrument);
          });
        });
        resolve(instrumentList);
      });
    },
    submitForm() {
      //this.$refs.form.validate(valid => {
      //  if (valid) {
      //    this.hadSubmitted = true;
      //    this.updateData();
      //  } else {
      //    return false;
      //  }
      //});
      this.hadSubmitted = true;
      this.updateData();
    },
    async updateData() {
      if (!this.hadSubmitted) return;
      this.chartReady = false;
      this.chartLoading = true;
      await this.getRemarks();
      await this.getOutput();
      if (this.selectedInstrument.length) {
        this.chartLoading = false;
      }
      //console.log(this.selectedInstrument);
      //console.log(this.form.instrument);
    },
    async getOutput() {
      const instrumentList = await this.strictFilterInstrumentList();
      if (this.form.instrument.length <= 0) return;
      return this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/output`,
        data: {
          date: this.form.date,
          interval: this.form.interval,
          intervalInitialTime: this.form.intervalInitialTime,
          instrument: instrumentList,
          timezone: this.timezone,
        },
      })
        .then(res => {
          this.lastRefreshTimestamp = Date.now();
          this.selectedInstrument.forEach(instrument => {
            const instrumentName = instrument.latestVersion.name;
            if (!this.chartData[instrumentName]) {
              this.chartData[instrumentName] = {};
            }
            this.getInstrumentData(res.data, instrument);
          });
        })
        .then(() => {
          this.alertInstrument._id = this.selectedInstrument[0]._id;
          return this.rescaleChart().then(() => {
            this.chartReady = true;
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getRemarks() {
      const [startDate, endDate] = this.form.date;
      const instrumentList = await this.strictFilterInstrumentList();
      return this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/instrument/remark?type=multiple`,
        data: {
          instrument: instrumentList,
          start_date: startDate,
          end_date: endDate,
        },
      })
        .then(res => {
          this.instrumentRemarkList = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    generateSeries() {
      return new Promise(resolve => {
        this.options.series = this.selectedInstrument.map((instrument, index) => {
          const ref = instrument.latestVersion;
          const yAxisIndex = this.unitIndex.findIndex(record => {
            return record.unit === ref.unit;
          });

          const dataLength = this.chartData[ref.name].data.length;
          const data = [...this.chartData[ref.name].data];

          if (this.showRemark) {
            const remarkList = this.instrumentRemarkList.find(
              remark => remark.instrument_id === instrument._id,
            );
            //  find corresponding data from list and replace it with data wf remark
            if (remarkList) {
              remarkList.remark.forEach(remark => {
                const dataFitRemarkIndex = data.findIndex((ele, index) => {
                  if (moment(remark.start_date).isSame(ele.x)) {
                    return true;
                  } else if (index !== dataLength - 1) {
                    const nextEle = data[index + 1];
                    if (moment(remark.start_date).isBetween(ele.x, nextEle.x)) {
                      return true;
                    }
                  }
                });
                data[dataFitRemarkIndex] = {
                  ...data[dataFitRemarkIndex],
                  // x: data[dataFitRemarkIndex][0],
                  // y: data[dataFitRemarkIndex][1],
                  // instrument: ele._id,
                  remark,
                  marker: {
                    enabled: true,
                    width: 20,
                    height: 20,
                    fillColor: '#FFFFFF',
                    symbol: `url(${symbols[remark.type]})`,
                  },
                };
              });
            }
          }

          let unitFormat = ref.unit;
          if (instrument.instrument_type && instrument.instrument_type.type === 'rainGauge') {
            if (
              instrument.instrument_type.hourInterval > 0 &&
              instrument.instrument_type.minuteInterval > 0
            ) {
              unitFormat = `${instrument.instrument_type.unit}/${instrument.instrument_type.hourInterval}H ${instrument.instrument_type.minuteInterval}M`;
            } else if (instrument.instrument_type.hourInterval > 0) {
              unitFormat = `${instrument.instrument_type.unit}/${instrument.instrument_type.hourInterval}H`;
            } else if (instrument.instrument_type.minuteInterval > 0) {
              unitFormat = `${instrument.instrument_type.unit}/${instrument.instrument_type.minuteInterval}M`;
            }
            // if (instrument.instrument_type.isHour) {
            //   unitFormat = `${instrument.instrument_type.unit}/${instrument.instrument_type.hourInterval}H`;
            // } else {
            //   unitFormat = `${instrument.instrument_type.unit}/${instrument.instrument_type.minuteInterval}M`;
            // }
          }

          const obj = {
            name: ref.name,
            data,
            unit: unitFormat,
            type: instrument.chart_type,
            instrument_type: instrument.instrument_type,
            yAxis: yAxisIndex,
            tooltip: {
              valueSuffix: ` ${unitFormat}`,
            },
            color: this.colorPalettes[index],
          };
          return obj;
        });

        // const allRemarkList = this.instrumentRemarkList
        //   .map(ele => ele.remark)
        //   .flat()
        // allRemarkList.forEach(remark => {
        //   const obj = {
        //     // x: data[dataFitRemarkIndex][0],
        //     // y: data[dataFitRemarkIndex][1],
        //     // instrument: ele._id,
        //     x: remark.start_date,
        //     y: 0,
        //     remark: remark.content,
        //     marker: {
        //       enabled: true,
        //       width: 20,
        //       height: 20,
        //       fillColor: '#FFFFFF',
        //       symbol: `url(${symbols[remark.type]})`
        //     }
        //   }
        //   this.options.series.push(obj)
        // })

        this.options.series.forEach(series => {
          if (series.instrument_type && series.instrument_type.type === 'rainGauge') {
            // let instrumentInterval, lastN;
            let lastN;
            const hourInterval = series.instrument_type.hourInterval * 3600000;
            const minuteInterval = series.instrument_type.minuteInterval * 60 * 1000;
            lastN = (hourInterval + minuteInterval) / series.instrument_type.dataInterval;
            // if (series.instrument_type.isHour) {
            //   instrumentInterval = moment.duration(series.instrument_type.dataInterval).hours();
            //   lastN = series.instrument_type.hourInterval / instrumentInterval;
            // } else {
            //   instrumentInterval = moment.duration(series.instrument_type.dataInterval).minutes();
            //   lastN = series.instrument_type.minuteInterval / instrumentInterval;
            // }
            series.data.forEach((ele, index) => {
              if (!this.$lodash.has(ele, 'originalY')) {
                ele.originalY = ele.y;
              }
              if (index > 2) {
                const totalData = this.accumulatedRainGaugeSum({
                  data: series.data,
                  lastN,
                  index,
                });
                ele.y = totalData;
              }
            });
          }
        });

        const columnCharts = this.options.series.filter(ele => ele.type === 'column');

        // const chart = this.$refs.highcharts.chart
        // const yAxisMinValueArray = this.unitIndex.map(ele => {
        //   if (!chart.yAxis[ele.index]) {
        //     return 0
        //   }
        //   const extremes = chart.yAxis[ele.index].getExtremes()
        //   return extremes.min
        // })
        // const minValue = Math.min(...yAxisMinValueArray)

        columnCharts.forEach(series => {
          const newObj = this.$lodash.cloneDeep(series);
          newObj.type = 'scatter';
          newObj.data = newObj.data.filter(data => {
            return data.remark;
          });
          this.options.series.push(newObj);
        });
        resolve();
      });
      // }
    },
    getInstrumentData(data, instrument) {
      const instrumentName = instrument.latestVersion.name;
      const result = data.filter(ele => ele.instrument_id === instrument._id);
      this.chartData[instrumentName].data = result.map(obj => {
        return {
          x: Date.parse(obj.TIMESTAMP),
          y: obj[instrument.display_output],
          instrument: instrument._id,
        };
      });
    },
    rescaleChart() {
      return this.generateYAxis(true)
        .then(() => {
          this.generateSeries();
        })
        .then(() => {
          this.getAxisData(true);
        });
    },
    generateYAxis(isUpdate = false) {
      return new Promise(resolve => {
        this.unitIndex = [];
        const unitList = this.selectedInstrument.map(ele => ele.latestVersion.unit);
        const indexList = [];
        unitList.filter((unit, index) => {
          if (unitList.indexOf(unit) === index) {
            indexList.push(index);
            return true;
          }
        });
        this.options.yAxis = this.selectedInstrument
          .map((ele, index) => {
            const showUnit = indexList.includes(index);
            if (!showUnit) return;
            const ref = ele.latestVersion;
            let obj = {};
            if (!isUpdate) {
              obj = this.oldYAxis.find(old => old.name === ref.unit);
            }
            let unitFormat = ref.unit;
            if (ele.instrument_type && ele.instrument_type.type === 'rainGauge') {
              unitFormat = `${ele.instrument_type.unit}/`;
              if (ele.instrument_type.hourInterval > 0 && ele.instrument_type.minuteInterval > 0) {
                unitFormat = `${ele.instrument_type.unit}/${ele.instrument_type.hourInterval}H ${ele.instrument_type.minuteInterval}M`;
              } else if (ele.instrument_type.hourInterval > 0) {
                unitFormat = `${ele.instrument_type.unit}/${ele.instrument_type.hourInterval}H`;
              } else if (ele.instrument_type.minuteInterval > 0) {
                unitFormat = `${ele.instrument_type.unit}/${ele.instrument_type.minuteInterval}M`;
              }
            }
            const format = `{value}${unitFormat}`;
            if (this.$lodash.isEmpty(obj)) {
              let plotLines = [];
              if (this.alertInstrument._id) {
                const instrument = this.selectedInstrument.find(
                  instrument => instrument._id === this.alertInstrument._id,
                );
                this.alertInstrument.showTarget.forEach(target => {
                  const plus_value = instrument.latestVersion[`${target}_plus`];
                  const minus_value = instrument.latestVersion[`${target}_minus`];
                  if (![null, undefined].includes(plus_value)) {
                    plotLines.push({
                      color: getStatusColor(target),
                      width: 2,
                      value: plus_value,
                    });
                  }
                  if (![null, undefined].includes(minus_value)) {
                    plotLines.push({
                      color: getStatusColor(target),
                      width: 2,
                      value: minus_value,
                    });
                  }
                });
              }

              const oldYAxis = this.oldYAxis.find(ele => ele.name === unitFormat);

              obj = {
                name: unitFormat,
                instrument_name: ref.name,
                instrument_type: ele.instrument_type,
                title: {
                  align: 'high',
                  offset: 0,
                  text: this.$t('chart.measurements'),
                  rotation: 0,
                  y: -10,
                },
                labels: {
                  enabled: showUnit,
                  format,
                  style: {
                    fontWeight: 700,
                    fontSize: '13px',
                  },
                },
                // opposite: optionExist.length ? optionExist[0].opposite : index % 2,
                opposite: this.unitIndex.length % 2,
                tickInterval: oldYAxis ? oldYAxis.tickInterval : undefined,
                plotLines,
                max: oldYAxis ? oldYAxis.max : undefined,
                min: oldYAxis ? oldYAxis.min : undefined,
              };
            }
            this.unitIndex.push({
              unit: ref.unit,
              index: this.unitIndex.length,
            });
            return obj;
          })
          .filter(ele => ele);
        resolve();
      });
    },
    getAxisData(isUpdate = false) {
      return new Promise(resolve => {
        const chart = this.$refs.highcharts.chart;
        this.unitIndex.forEach(ele => {
          const extremes = chart.yAxis[ele.index].getExtremes();
          if (isUpdate) {
            this.options.yAxis[ele.index].max =
              extremes.max < extremes.dataMax ? extremes.dataMax : extremes.max;
            this.options.yAxis[ele.index].min =
              extremes.min > extremes.dataMin ? extremes.dataMin : extremes.min;
          } else {
            this.options.yAxis[ele.index].max = extremes.max;
            this.options.yAxis[ele.index].min = extremes.min;
          }
          this.options.yAxis[ele.index].tickInterval = chart.yAxis[ele.index].tickInterval;
        });
        this.oldYAxis = this.chartOptions.yAxis;
        resolve();
      });
    },
  },
};
</script>

<style lang="stylus">
.el-cascader__tags .el-tag > span
  flex-basis auto
.el-drawer__body
  overflow auto
  .highcharts-axis-labels {
  font-weight: 700;
  font-size: 13px;
}

.highcharts-grid-top {
  .highcharts-grid-line {
      stroke: #e6e6e6;
    }
}

.highcharts-grid-1 {
  .highcharts-grid-line {
    stroke: #96F8EC;
  }  
}

.highcharts-grid-2 {
  .highcharts-grid-line {
    stroke: #D2B6D2;
  }  
}

.highcharts-grid-3 {
  .highcharts-grid-line {
    stroke: #98F898;
  }  
}

.highcharts-grid-4 {
  .highcharts-grid-line {
    stroke: #FF94A6;
  }  
}

.highcharts-grid-5 {
  .highcharts-grid-line {
    stroke: #F3F196;
  }  
}

.highcharts-WL-plot {
  stroke: #4188ff !important;
  fill: #4188ff;
  stroke-width: 2px;
}

.highcharts-AL-plot {
  stroke: #ffad00 !important;
  fill: #ffad00;
  stroke-width: 2px;
}

.highcharts-WSL-plot {
  stroke: #ff0000 !important;
  fill: #ff0000;
  stroke-width: 2px;
}

.highcharts-tooltip-box .highcharts-label-box {
  fill: #f7f7f7 !important;
  fill-opacity: 0.4 !important;
}

.tooltip-box .highcharts-label-box {
  fill: #f7f7f7 !important;
  fill-opacity: 0.4 !important;
}
</style>

<style lang="stylus" scoped>
.el-input-number
  max-width 150px
.el-form-item__label
  font-weight bold
.minichart-container
  min-height 200px
.el-radio-button.is-active
  z-index 10
.el-row
  margin-bottom 1.5rem
.interval-button
  margin-left 10px
.input-label-right
  margin-left 10px
.button-primary
  color #FFF
  background-color #409EFF
  border-color #409EFF
  box-shadow -1px 0 0 0 #409EFF
.date-range-seperate
  margin 0 1rem
.card-header__bar
  border-style solid
  border-width 4px 0 0
</style>
