<template>
  <div v-loading="isLoading">
    <div v-if="!$device.mobile">
      <div class="flex-row align-center justify-between mb-16">
        <div class="flex-row align-center">
          <el-switch
              v-model="autoRefresh"
              :active-text="$t('data.refresh')"
              @change="toggleAutoRefresh"
            ></el-switch>
          <div class="flex-row align-center"
               style="margin-left: 2rem;">
            <el-switch
              v-model="excludeNormalStatus"
              :active-text="$t('data.onlyWaring')"
              @change="toggleExcludeNormalStatus"
            ></el-switch>
          </div>
          <div
            class="flex-row align-center"
            v-for="dot in dotList"
            :key="dot.color"
            style="margin-left: 2rem;"
          >
            <span v-if="excludeNormalStatus ? true : dot.value != 'down'" class="dot" :style="{ backgroundColor: dot.color }"></span>
            <span v-if="excludeNormalStatus ? true : dot.value != 'down'">{{ $t(dot.label) }}</span>
          </div>
        </div>
        <div>
          <span class="mr-8">{{ $t('data.compareDate') }}</span>
          <el-date-picker
            v-model="compareDate"
            type="datetime"
            default-time="00:00:00"
            value-format="timestamp"
            format="yyyy/MM/dd HH:mm"
            :picker-options="datePickerOptions"
            :placeholder="$t('chart.date.pick')"
            @change="compareDataWithDate"
          >
          </el-date-picker>
        </div>
      </div>
      <el-collapse v-model="activeCard">
        <el-card>
          <el-collapse-item
            v-for="area in areaList"
            :key="area._id"
            :name="area._id"
          >
          <template slot="title">
            <span class="dot" :style="{backgroundColor: getStatusColor(showStationStatus(area._id))}"></span>
            {{ area.name }}
          </template>
            <el-table
              :data="filteredList[area._id]"
              style="width: 100%;"
              stripe="stripe"
              border
              :default-sort="{ prop: 'timestamp', order: 'descending' }"
            >
              <el-table-column sortable="sortable" prop="name" :label="$t(`data.instrument.name`)">
                <template slot-scope="scope">
                  <span
                    class="dot"
                    :style="{
                      backgroundColor: getStatusColor(scope.row.status),
                    }"
                  ></span>
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                sortable="sortable"
                v-for="{ prop, label, formatter } in colConfigs"
                :key="prop"
                :prop="prop"
                :label="$t(`data.${label}`)"
                :formatter="formatter"
              ></el-table-column>
              <el-table-column v-if="shouldShow_batt" sortable="sortable" prop="bind_batt_reading2" :label="$t(`data.batt`)">
                <template slot-scope="scope">
                  <div v-if="scope.row.bind_batt_reading2 && scope.row.bind_batt_reading2.reading2 !== undefined" :style="getBackgroundStyle(scope.row.bind_batt_reading2)">
                    <p :style="{ textAlign: 'left', fontWeight: 'bold', color: 'white', WebkitTextStroke: '0.5px black', fontSize: 'larger', position: 'relative', left: getLeftOffset(scope.row.bind_batt_reading2.reading2) }">
                      {{ scope.row.bind_batt_reading2.reading2 }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column sortable="sortable" prop="name" :label="$t(`data.update`)">
                <template slot-scope="scope">
                  <span v-if="scope.row.TIMESTAMP">
                    {{ $moment(scope.row.TIMESTAMP).format('YYYY-MM-DD HH:mm') }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column sortable="sortable" prop="remark" :label="$t('data.remark')">
                <template slot-scope="scope">
                  <span v-if="scope.row.remark">{{ scope.row.remark.content }}</span>
                </template>
              </el-table-column>
              <el-table-column
                width="150px"
                sortable="sortable"
                prop="status"
                :label="$t('data.chart')"
              >
                <template slot-scope="scope">
                  <router-link
                    class="button is-small"
                    :to="{ path: 'chart', query: { instrument: scope.row._id } }"
                  >
                    Chart
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
          </el-collapse-item>
        </el-card>
      </el-collapse>
    </div>

    <div class="data-page" v-else>
      <van-tabs v-model="selectedType">
        <van-tab :title="$t('data.navbar.all')" name="ALL" />
        <van-tab :title="$t('data.navbar.action')" name="WSL" />
        <van-tab :title="$t('data.navbar.alert')" name="AL" />
        <van-tab :title="$t('data.navbar.warning')" name="WL" />
      </van-tabs>
      <!-- <mobile-data-table v-if="!isLoading" :tableData="tableData" /> -->
      <div class="cell-list">
        <van-collapse v-model="activeCard">
          <van-collapse-item
            v-for="area in areaList"
            :key="area._id"
            :title="area.name"
            :name="area._id"
            size="large"
          >
            <div
              v-if="!filteredList[area._id].length"
              class="content"
              style="padding:2rem height:100%"
            >
              <section class="section">
                <div class="is-flex justify-center align-center">
                  <h4>{{ $t('data.none') }}</h4>
                </div>
              </section>
            </div>
            <van-cell
              v-for="instrument in filteredList[area._id]"
              :key="instrument._id"
              :is-link="true"
              :to="`/${$store.state.locale}/chart/${instrument._id}`"
            >
              <div slot="title">
                <span
                  :style="{ 'background-color': getStatusColor(instrument.status) }"
                  class="dot"
                />
                <span>{{ instrument.name }}</span>
              </div>
              <span slot="label">{{
                $moment(instrument.TIMESTAMP).format('YYYY-MM-DD HH:mm a')
              }}</span>
              <p v-if="checkShow('reading1')">{{ $t('data.raw') }}: {{ instrument.reading1 }}</p>
              <p v-if="checkShow('reading2')">
                <span v-if="checkShow('reading1')">{{ $t('data.measurements') }}:</span>
                {{ instrument.reading2 }} {{ instrument.unit }}
              </p>
            </van-cell>
          </van-collapse-item>
        </van-collapse>
      </div>
      <div class="data-page--footer">
        <van-button
          class="button is-primary has-text-weight-bold is-uppercase"
          @click="toggleAutoRefresh"
        >
          {{ $t('data.refresh') }}
          <font-awesome-icon v-if="autoRefresh" icon="check" style="margin-left: 12px" />
        </van-button>
        <van-button
          class="button is-primary has-text-weight-bold is-uppercase"
          @click="toggleExcludeNormalStatus"
        >
          {{ $t('data.onlyWaring') }}
          <font-awesome-icon v-if="excludeNormalStatus" icon="check" style="margin-left: 12px" />
        </van-button>
        <van-button
          class="button is-primary has-text-weight-bold is-uppercase"
          @click="showDatePicker = !showDatePicker"
        >
          {{ $t('data.compareDate') }}
          <font-awesome-icon v-if="isComparingData" icon="check" style="margin-left: 12px" />
        </van-button>
      </div>
    </div>
    <van-popup v-model="showDatePicker" round position="bottom" :style="{ height: '30%' }">
      <van-datetime-picker
        v-model="compareDate"
        type="datehour"
        :title="$t('chart.date.pick')"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="onCancelCompareDate"
        @confirm="compareDataWithDate"
      />
    </van-popup>
  </div>
</template>

<script>
import { uniqBy } from 'lodash';
import { dotList, getStatusColor } from '@/lib/base/systemAlert';
import { thisExpression } from '@babel/types';

export default {
  props: {
    useUrl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      project:null,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      minDate: new Date('2017/01/01'),
      maxDate: new Date(),
      isLoading: false,
      isComparingData: false,
      showDatePicker: false,
      selectedType: 'ALL',
      dotList,
      compareDate: null,
      tableData: {},
      areaList: [],
      activeCard: [],
      colConfigs: [],
      configList: [
        {
          label: 'raw',
          prop: 'reading1',
        },
        {
          label: 'measurements',
          prop: 'reading2',
          formatter(row, column, cellValue) {
            return `${cellValue} ${row.unit}`;
          },
        },
        {
          label: 'value.velocity1Hr',
          prop: 'velocity1Hr',
          formatter(row, column, cellValue) {
            let value = cellValue ? cellValue : row[column.property];
            if(!value) return '';
            return `${value} ${row.unit}/1Hr`;
          },
        },
        {
          label: 'value.velocity24Hr',
          prop: 'velocity24Hr',
          formatter(row, column, cellValue) {
            let value = cellValue ? cellValue : row[column.property];
            if(!value) return '';
            return `${value} ${row.unit}/24Hr`;
          },
        },
        {
          label: 'value.alert',
          prop: 'AL',
          formatter(row, column, cellValue) {
            const empty = [null, undefined];
            const { AL_plus, AL_minus } = cellValue;
            if (!empty.includes(AL_plus) && !empty.includes(AL_minus)) {
              if (Math.abs(AL_plus) === Math.abs(AL_minus)) {
                return `± ${AL_plus} ${row.unit}`;
              } else {
                return `${AL_plus} ${row.unit} ~ ${AL_minus} ${row.unit}`;
              }
            } else if (!empty.includes(AL_plus)) {
              return `${AL_plus} ${row.unit}`;
            } else if (!empty.includes(AL_minus)) {
              return `${AL_minus} ${row.unit}`;
            }
          },
        },
        {
          label: 'value.action',
          prop: 'WSL',
          formatter(row, column, cellValue) {
            const empty = [null, undefined];
            const { WSL_plus, WSL_minus } = cellValue;
            if (!empty.includes(WSL_plus) && !empty.includes(WSL_minus)) {
              if (Math.abs(WSL_plus) === Math.abs(WSL_minus)) {
                return `± ${WSL_plus} ${row.unit}`;
              } else {
                return `${WSL_plus} ${row.unit} ~ ${WSL_minus} ${row.unit}`;
              }
            } else if (!empty.includes(WSL_plus)) {
              return `${WSL_plus} ${row.unit}`;
            } else if (!empty.includes(WSL_minus)) {
              return `${WSL_minus} ${row.unit}`;
            }
          },
        },
      ],
      autoRefresh: false,
      excludeNormalStatus: false,
      refreshFunction: '',
      compareDatas: {},
      priority: { 'down': -1, 'WSL': 4, 'AL': 3, 'WL': 2, 'GREEN': 0 },
      shouldShow_batt: false,
    };
  },
  computed: {
    timezone() {
      return this.$store.getters['env/timezone'];
    },
    instrumentList() {
      return Object.keys(this.tableData)
        .map(areaId => {
          return this.tableData[areaId].map(instrument => instrument._id);
        })
        .flat();
    },
    selectedProject() {
      if(this.project) return this.project;
      return this.$store.getters['project/selectedProject'];
    },
    filteredList() {
      let result = this.tableData;
      const keyList = Object.keys(this.tableData);
      if (this.selectedType !== 'ALL') {
        result = keyList.reduce((obj, key) => {
          obj[key] = this.tableData[key].filter(ele => {
            return ele.status === this.selectedType;
          });
          return obj;
        }, {});
      }
      return result;
    },
  },
  beforeDestroy() {
    clearInterval(this.refreshFunction);
  },
  mounted() {
    if(this.useUrl){
      console.log('using url!');
      this.setProject()
        .then(() => {
          this.getLatestData();
          this.setConfig();
        });
    }
    else{
      console.log('didnt use url!');
      this.getLatestData();
      this.setConfig();
    }
  },
  methods: {
    getLeftOffset(value) {
      const length = value.toString().length;
      switch (length) {
        case 1:
          return '22px';
        case 2:
          return '17px';
        case 3:
          return '15px';
        case 4:
          return '9px';
        case 5:
          return '5px';
        default:
          return '0px';
      }
    },
    showStationStatus(areaId) {
      let areaStatus = 'GREEN';
      this.tableData[areaId].map(instrument => {
        if (this.priority[instrument.status] > this.priority[areaStatus]) {
          areaStatus = instrument.status;
        }
        //console.log(instrument.status);
      });
      return areaStatus;
    },
    setProject() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: '',
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      })
        .then(res => {
          this.project = res.data[0];
        })
        .catch(this.$notifyError);
    },
    getStatusColor,
    onCancelCompareDate() {
      this.compareDate = null;
      this.compareDatas = {};
      this.showDatePicker = false;
      this.isComparingData = false;
      this.getLatestData();
    },
    focusDatePicker() {
      this.showDatePicker = true;
      this.ref.datePicker.focus();
    },
    async compareDataWithDate() {
      this.showDatePicker = false;
      const result = await this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/output`,
        data: {
          date: [this.compareDate, this.compareDate],
          interval: 'all',
          instrument: this.instrumentList,
          timezone: this.timezone,
        },
        useUrl: this.useUrl ? 'https://api-thsr-rain.geosupply.com.tw/' : null,
      });
      if (result.data.length > 0) {
        result.data.forEach(data => {
          const { instrument_id, reading2 } = data;
          this.compareDatas[instrument_id] = reading2;
        });
        this.getLatestData();
        this.isComparingData = true;
      } else {
        this.isComparingData = false;
        this.$notifyInfo('該時段內沒有資料');
      }
    },
    checkShow(value) {
      return this.selectedProject.config.data.display.includes(value);
    },
    async getLatestData() {
      this.isLoading = true;
      let subApiName = 'last';
      if(this.excludeNormalStatus) subApiName = 'last/excludeNormalStatus';
      let tempCard = this.activeCard;
      this.activeCard = [];
      
      await this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/data/${subApiName}/${Date.now()}`,
        useUrl: this.useUrl ? 'https://api-thsr-rain.geosupply.com.tw/' : null,
      })
        .then(async res => {
          this.isLoading = false;
          const areaList = res.data.map(ele => ele.area);
          this.areaList = uniqBy(areaList, '_id');
          this.areaList.forEach(area => {
            this.tableData[area._id] = res.data
              .map(ele => {
                if (ele.area._id === area._id) {
                  return ele;
                }
              })
              .filter(ele => ele !== undefined);
          });
          if (this.compareDate) {
            this.areaList.forEach(area => {
              this.tableData[area._id].forEach(instrument => {
                if (this.compareDatas[instrument._id]) {
                  instrument.reading2 = (
                    instrument.reading2 - this.compareDatas[instrument._id]
                  ).toFixed(4);
                }
              });
            });
          }
          const showList = this.selectedProject.config.data.display;
          //if(tempCard.length == 0) tempCard.push(this.areaList[0]._id);
          //if(!this.excludeNormalStatus) this.activeCard = tempCard;
          this.activeCard = [];
          if(tempCard.length == 0) tempCard.push(this.areaList[0]._id);
          this.activeCard = tempCard;
          
          const dataMap = new Map(res.data.map(item => [item._id, item]));
          res.data.forEach(inst => {
            if (inst.bind_batt && dataMap.has(inst.bind_batt)) {
              let batt = dataMap.get(inst.bind_batt);
              this.areaList.forEach(area => {
                this.tableData[area._id] = this.tableData[area._id].map(instrument => {
                  if (instrument._id === inst._id) {
                    instrument['bind_batt_reading2'] = {
                      reading2: batt.reading2,
                      status: batt.status,
                    };
                  }
                  return instrument;
                });
              });
            }
          });

          let promisesList = [];
          if(showList.includes('velocity1Hr')) promisesList.push(this.get1HrVelocity());
          if(showList.includes('velocity24Hr')) promisesList.push(this.get24HrVelocity());
          return Promise.all(promisesList);
        })
        .finally(() => {
          if(!this.excludeNormalStatus) this.activeCard = [];
          setTimeout(() => {
            if(tempCard.length==0) this.activeCard.push(this.areaList[0]._id);
            else this.activeCard = tempCard;
          }, 450);
          //if(tempCard.length == 0) tempCard.push(this.areaList[0]._id);
          //this.activeCard = tempCard;
        });
    },
    async get1HrVelocity(){
      //按照更新時間分類
      let instrumentTimeStampDict = {};
      this.areaList.forEach(area=>{
        this.tableData[area._id].forEach(instrument => {
          if(instrumentTimeStampDict[instrument.TIMESTAMP]) instrumentTimeStampDict[instrument.TIMESTAMP].push(instrument._id);
          else instrumentTimeStampDict[instrument.TIMESTAMP] = [instrument._id];
        });
      });
      //為每個儀器抓取相互比較的數據
      let velocityCompareDatas = {};
      let promises = [];
      for(let [TIMESTAMP, instrumentList] of Object.entries(instrumentTimeStampDict)){
        let velocityCompareTime = new Date(TIMESTAMP);
        velocityCompareTime.setHours( velocityCompareTime.getHours() - 1 );
        velocityCompareTime = velocityCompareTime.getTime();
        promises.push(
          this.$axios({
            category: 'project',
            method: 'post',
            apiName: `${this.selectedProject._id}/output`,
            data: {
              date: [velocityCompareTime, velocityCompareTime],
              interval: 'all',
              instrument: instrumentList,
              timezone: this.timezone,
            },
            useUrl: this.useUrl ? 'https://api-thsr-rain.geosupply.com.tw/' : null,
          }),
        );
      }
      let results = await Promise.all(promises);
      results.forEach(result => {
        if(result.data.length > 0){
          result.data.forEach(data => {
            const { instrument_id, reading2 } = data;
            velocityCompareDatas[instrument_id] = reading2;
          });
        }
      });
      //產生速率值
      this.areaList.forEach(area => {
        this.tableData[area._id].forEach(instrument => {
          //計算速率
          instrument.velocity1Hr = (instrument.allowRate) && ((instrument.reading2 || instrument.reading2 === 0) && (velocityCompareDatas[instrument._id] || velocityCompareDatas[instrument._id] === 0)) ? (
            (instrument.reading2 - velocityCompareDatas[instrument._id]) / 1
          ).toFixed(4) : null;
        });
      });
    },
    async get24HrVelocity(){
      //按照更新時間分類
      let instrumentTimeStampDict = {};
      this.areaList.forEach(area=>{
        this.tableData[area._id].forEach(instrument => {
          if(instrumentTimeStampDict[instrument.TIMESTAMP]) instrumentTimeStampDict[instrument.TIMESTAMP].push(instrument._id);
          else instrumentTimeStampDict[instrument.TIMESTAMP] = [instrument._id];
        });
      });
      //為每個儀器抓取相互比較的數據
      let velocityCompareDatas = {};
      let promises = [];
      for(let [TIMESTAMP, instrumentList] of Object.entries(instrumentTimeStampDict)){
        let velocityCompareTime = new Date(TIMESTAMP);
        velocityCompareTime.setHours( velocityCompareTime.getHours() - 24 );
        velocityCompareTime = velocityCompareTime.getTime();
        promises.push(
          this.$axios({
            category: 'project',
            method: 'post',
            apiName: `${this.selectedProject._id}/output`,
            data: {
              date: [velocityCompareTime, velocityCompareTime],
              interval: 'all',
              instrument: instrumentList,
              timezone: this.timezone,
            },
            useUrl: this.useUrl ? 'https://api-thsr-rain.geosupply.com.tw/' : null,
          }),
        );
      }
      let results = await Promise.all(promises);
      results.forEach(result => {
        if(result.data.length > 0){
          result.data.forEach(data => {
            const { instrument_id, reading2 } = data;
            velocityCompareDatas[instrument_id] = reading2;
          });
        }
      });
      //產生速率值
      this.areaList.forEach(area => {
        this.tableData[area._id].forEach(instrument => {
          //計算速率
          instrument.velocity24Hr = (instrument.allowRate) && ((instrument.reading2 || instrument.reading2 === 0) && (velocityCompareDatas[instrument._id] || velocityCompareDatas[instrument._id] === 0)) ? (
            (instrument.reading2 - velocityCompareDatas[instrument._id])
          ).toFixed(4) : null;
        });
      });
    },
    setConfig() {
      const showList = this.selectedProject.config.data.display;
      showList.forEach(prop => {
        if (prop === 'batt')
          this.shouldShow_batt = true;
      });
      this.colConfigs = this.configList.filter(ele => {
        return showList.includes(ele.prop);
      });
    },
    toggleAutoRefresh() {
      if (this.$device.mobile) {
        this.autoRefresh = !this.autoRefresh;
      }
      if (this.autoRefresh) {
        this.getLatestData();
        this.refreshFunction = setInterval(() => {
          this.getLatestData();
        }, 1000 * 60 * 1);
      } else {
        clearInterval(this.refreshFunction);
      }
    },
    toggleExcludeNormalStatus(){
      this.activeCard = [];
      this.priority['down'] *= -1;
      if(this.$device.mobile)
        this.excludeNormalStatus = !this.excludeNormalStatus;
      this.getLatestData();
    },
    getBackgroundStyle(batt) {
      if (!batt || batt.status === undefined || batt.reading2 === undefined) {
        return {};
      }

      let imageUrl;
      if (batt.status === 'down') {
        imageUrl = require('@/assets/imgur/8M7DqBb.png');
      } else if (batt.status === 'GREEN') {
        imageUrl = require('@/assets/imgur/kDokY1N.png');
      } else if (batt.status === 'WSL') {
        imageUrl = require('@/assets/imgur/bdw9bqY.png');
      } else if (batt.status === 'AL' || batt.status === 'WL') {
        imageUrl = require('@/assets/imgur/xygQiAl.png');
      }

      return {
        backgroundImage: `url('${imageUrl}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left',
        width: '100%',
        height: '100%',
        display: 'inline-block',
      };
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/ .el-table th {
  background-color: #409EFF;
}
/deep/ .el-table th .cell {
  color: white;
}
/deep/ .el-table {
  border: 1px solid #409EFF;
}
/deep/ .el-table td {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
}

.dot
  height 9px
  width 9px
  min-width 9px
  min-height 9px
  margin-right 0.7rem
  background-color #bbb
  border-radius 50%
  display inline-block
.data-page--footer
  height 50px
  width 100vw
  position fixed
  bottom 0
  left 0
  button
    height 100%
    border-radius 0
    // width 100%
    width 33%
    &:nth-child(3),&:nth-child(2)
      border-left 1px solid white
.dot
  height 9px
  width 9px
  min-width 9px
  min-height 9px
  margin-right 0.7rem
  background-color #bbb
  border-radius 50%
  display inline-block
/deep/ .van-collapse-item__content
  padding 0
</style>
