let url;

url = {
  function: {
    user: 'user/',
    project: 'project/',
    output: 'output/',
    instrument: 'instrument/',
    admin: 'admin/',
    log: 'log/',
    alerts: 'alerts/',
    occ: 'occ/',
    thsr: 'thsr/',
    manual: 'manual/',
    advanced: 'advanced/',
  },
};

export default url;
