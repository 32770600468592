import { getLocalData } from '@/lib/base/localData';


/**
 * Check user have all roles in given array
 * @param {Array} target array of required permission
 * @returns {Boolean} return result of user access
 */
const hasAllRoles = target => {
  const user = JSON.parse(getLocalData('user'));
  if (!user) return false;
  const roles = user.roles;
  if (!roles) return false;
  return target.every(requiredRole => roles.includes(requiredRole));
};

/**
 * Check user fit any roles in the given array
 * @param {Array} target array of required permission
 * @returns {Boolean} return result of user access
 */
const hasAnyRole = target => {
  const user = JSON.parse(getLocalData('user'));
  if (!user) return false;
  const roles = user.roles;
  if (!roles) return false;
  return target.some(requiredRole => roles.includes(requiredRole));
};

export { hasAllRoles, hasAnyRole  };