<template lang="html">
    <enhanced-table 
      :table-data="logList"
      :col-configs="colConfigs"
      :is-loading="isLoading"
      :rightRadioOption="simplify" 
      :default-radio-value="全部"
      :radio-configs="radioConfigs"
      title="登入記錄"
    >
      <template v-slot:client_id="{ colConfig }">
        <el-table-column v-bind="colConfig" label="用戶">
          <template v-slot="{ row }">
            <!--span style="margin-right: 1rem">{{ row.client_id.email }}</span-->
            <span>{{ row.client_id.name }}</span>
          </template>
        </el-table-column>
      </template>
    </enhanced-table>
</template>

<script>
import moment from 'moment';
import EnhancedTable from '@/components/common/EnhancedTable';
export default {
  components: {
    EnhancedTable,
  },
  data() {
    return {
      loadingCredit: true,
      isLoading: true,
      username: null,
      credit: null,
      logList: [],
      clientList: [],
      colConfigs: [
        {
          label: '登入時間',
          prop: 'created_at',
          formatter(column, row, cellValue) {
            return moment(cellValue).format('YYYY-MM-DD HH:mm');
          },
        },
        {
          slot: 'client_id',
          sortable: true,
          'min-width': '250px',
        },
        {
          label: '類型',
          prop: 'type',
          formatter(column, row, cellValue) {
            if(cellValue === 'add_log') return '新增';
            else if(cellValue === 'delete_log') return '刪除';
            else if(cellValue === 'update_log') return '更新';
            else if(cellValue === 'view_log') return '查看';
            else if(cellValue === 'unauthorized_view_log') return '未登入查看';
            else if(cellValue === 'xml_log') return 'XML';
            else if(cellValue === 'line_api_log') return 'line';
            else cellValue;
          },
        },
        {
          label: '內容',
          prop: 'message',
          'min-width': '400px',
        },
      ],
      radioConfigs: [
        {
          text: this.$i18n.t('logType.all'),
          value: '',
        },
        {
          text: this.$i18n.t('logType.add'),
          value: 'add_log',
        },
        {
          text: this.$i18n.t('logType.delete'),
          value: 'delete_log',
        },
        {
          text: this.$i18n.t('logType.update'),
          value: 'update_log',
        },
        {
          text: this.$i18n.t('logType.view'),
          value: 'view_log',
        },
        {
          text: this.$i18n.t('logType.unauthorized'),
          value: 'unauthorized_view_log',
        },
        {
          text: this.$i18n.t('logType.xml'),
          value: 'xml_log',
        },
        {
          text: this.$i18n.t('logType.line'),
          value: 'line_api_log',
        },
      ],
    };
  },
  mounted() {
    this.getLog();
  },
  methods: {
    getLog() {
      this.isLoading = true;
      return this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'log',
      })
        .then(res => {
          this.logList = res.data;
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    
  },
};
</script>
