/* eslint-disable no-empty-pattern */

import { callAxios } from '@/lib/api/axios';
import { saveLocalData, removeLocalData } from '@/lib/base/localData';
import { NotifyError } from '@/plugins/notify';
import router from '@/router';
import { clientVersion } from '../../constants/env';

let thsrRole = clientVersion === 'thsr-main' || clientVersion === 'test';
// import lodash from 'lodash'

let apiPromise = {};

export const getters = {
  isAdmin(state){
    return state.details.roles === 'admin';
  },
  isAuthenticated(state) {
    return state.isLoggedIn;
  },
  details(state) {
    return state.details;
  },
};
const initialState = () => ({
  details: {},
  isLoggedIn: false,
});

export const state = {
  details: {},
  isLoggedIn: false,
};

export const actions = {
  loginUser({ dispatch }, user) {
    return callAxios({
      category: 'user',
      apiName: 'login',
      method: 'post',
      data: user,
    })
      .then(res => {
        saveLocalData('jwt', `Bearer ${res.data.token}`);
        return dispatch('getUserData', true);
      })
      .then(userData=>{
        if(thsrRole && (userData.roles == 'admin' || userData.roles == 'thsr')){
          return callAxios({
            category: 'user',
            apiName: 'login',
            method: 'post',
            data: user,
            useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
          })
            .then(res => {
              saveLocalData('thsr-rain', `Bearer ${res.data.token}`);
              //return dispatch('getUserData', true);
            });
        }
      })
      .catch(error => {
        //NotifyError(error);
        throw error;
      });
  },
  logoutUser({ commit }) {
    commit('resetState');
  },
  getUserData({ commit }, isUpdate) {
    const apiName = 'getUserData';
    if (apiPromise[apiName] && !isUpdate) return apiPromise[apiName];
    return callAxios({
      category: 'user',
      method: 'get',
      apiName: 'getUser',
    })
      .then(res => {
        commit('setUser', res.data);
        saveLocalData('user', res.data);
        return res.data;
      })
      .catch(error => {
        NotifyError(error);
      });
  },
};

export const mutations = {
  setUser(state, data) {
    state.details = data;
    state.isLoggedIn = true;
  },
  resetState(state) {
    removeLocalData('user');
    removeLocalData('jwt');
    removeLocalData('projectList');
    removeLocalData('selectedProject');
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
    router.push({
      path: '/login',
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
