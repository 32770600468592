<template>
  <div class="flex-column">
    <div class="flex-row align-center justify-end mb-16">
      <el-select
        style="margin-right: 12px;"
        v-model="selectedProject"
        filterable
        clearable
        placeholder="選擇專案">
        <el-option
          v-for="project in projectList"
          :key="project._id"
          :label="project.project_id"
          :value="project.area"
        ></el-option>
      </el-select>
      <el-button
        type="primary"
        @click="createStation"
      >
        新增測站
      </el-button>
    </div>
    <enhanced-table
      title="測站列表"
      :table-data="fliterStationList"
      :col-configs="colConfigs"
    >
      <template v-slot:actions="{ colConfig }">
        <el-table-column v-bind="colConfig">
          <template v-slot="{ row }">
            <div class="flex-row justify-end">
              <el-button
                type="warning"
                size="small"
                @click="editStationInstrument(row)"
              >
                設定儀器
              </el-button>
              <el-button
                type="danger"
                size="small"
                @click="deleteStation(row)"
              >
                刪除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
    </enhanced-table>
    <el-dialog
      :visible.sync="showStationInstrumentForm"
      :title="selectedStation.name"
    >
      <station-instrument-form :station-id="selectedStation._id"></station-instrument-form>
    </el-dialog>
  </div>
</template>

<script>
import EnhancedTable from '@/components/common/EnhancedTable';
import StationInstrumentForm from '@/components/station/StationInstrumentForm';
export default {
  components: {
    EnhancedTable,
    StationInstrumentForm,
  },
  data() {
    const vm = this;
    return {
      selectedProject: '',
      showStationInstrumentForm: false,
      selectedStation: '',
      colConfigs: [
        {
          label: '測站名稱',
          prop: 'name',
        },
        {
          label: '儀器',
          prop: 'instrument',
          'show-overflow-tooltip': true,
          formatter(row, column, cellValue) {
            return cellValue
              .map(instrument => {
                const result = vm.instrumentList.find(ele => {
                  return ele._id === instrument;
                });
                if (result) {
                  return result.name;
                } else {
                  return instrument;
                }
              })
              .join(', ');
          },
        },
        { slot: 'actions' },
      ],
    };
  },
  computed: {
    areaList() {
      return this.$store.getters['data/areaList'];
    },
    stationList() {
      return this.$store.getters['data/stationList'];
    },
    instrumentList() {
      return this.$store.getters['data/instrumentList'];
    },
    projectList() {
      return this.$store.getters['project/projectList'];
    },
    fliterStationList() {
      if (this.selectedProject) {
        let relatedAreas = this.areaList.filter(area => 
          this.selectedProject.includes(area._id),
        );

        let relatedStations = this.stationList.filter(station => 
          relatedAreas.some(area => 
            area.station.includes(station._id),
          ),
        );
        return relatedStations;
      } else {
        return this.stationList;
      }
    },
  },
  mounted() {
    this.getAreaList();
    this.getInstrumentList();
    this.getStationList(true);
  },
  methods: {
    createStation() {
      this.$prompt('請輸入測站名稱', '新增測站', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: this.validateInput,
      })
        .then(({ value }) => {
          return this.$axios({
            category: 'admin',
            method: 'post',
            apiName: 'station',
            data: {
              station: [value],
            },
          })
            .then(() => {
              this.$notifySuccess('測站新增成功');
              this.getStationList(true);
            })
            .catch(this.$notifyError);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          });
        });
    },
    validateInput(val) {
      if (!val) {
        return '請輸入測站名稱';
      } else return true;
    },
    editStationInstrument(row) {
      this.selectedStation = row;
      this.showStationInstrumentForm = true;
    },
    async deleteStation(row) {
      const response = await this.$confirmDelete('此操作將刪除此測站');
      if (response === 'confirm') {
        this.$axios({
          category: 'admin',
          method: 'delete',
          apiName: `station/${row._id}`,
        })
          .then(() => {
            this.$notifySuccess('刪除成功');
            this.getInstrumentList(true);
            this.getStationList(true);
          })
          .catch(this.$notifyError);
      }
    },
    getInstrumentList(isUpdate = false) {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getInstrumentList', isUpdate)
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    getStationList(isUpdate = false) {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getStationList', isUpdate)
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    getAreaList(isUpdate = false) {
      this.isLoading = true;
      return this.$store
        .dispatch('data/getAreaList', isUpdate)
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
