<template>
  <div class="column">
    <el-card v-loading="loading">
      <el-select v-model="instrumentType" placeholder="請選擇儀器類型" @change="changeInstrumentType">
        <el-option
          v-for="item in instrumentTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-cascader
        v-if="!isContinuityType"
        v-model="selectedInstrumentList"
        style="min-width:200px"
        :options="instrumentOptions"
        :props="props"
        :collapse-tags="true"
        :show-all-levels="false"
        :placeholder="$t('chart.instrument.pick')"
        @change="getCascaderInstrumentList"
      ></el-cascader>
      <el-button v-if="isSelectInstrumentType && !isContinuityType" @click="uploadInstrument">
        上傳儀器
      </el-button>
      <el-button v-if="isSelectInstrumentType && !isContinuityType" @click="newInstrument">
        新增欄位
      </el-button>
    </el-card>
    <el-card v-if="isSelectInstrumentType && !isContinuityType">
      <hot-table id="hot-container" ref="hot" root="hot" :settings="settings" />
    </el-card>
    <insturment-group 
      v-else-if="isSelectInstrumentType && isContinuityType && !loading" 
      :instrumentList="instrumentList"
      :getInstrumentType="getInstrumentType"
    ></insturment-group>
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue';
import moment from 'moment';
import InsturmentGroup from '@/components/manual/InstrumentGroup.vue';
export default {
  components: {
    HotTable,
    InsturmentGroup,
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    settings() {
      const vm = this;
      return {
        data: this.instrumentList,
        fixedColumnsLeft: 1,
        colHeaders: this.columnsHeader,
        rowHeaders: true,
        manualRowResize: true,
        manualColumnResize: true,
        manualRowMove: true,
        customBorders: true,
        columnSorting: true,
        height: 600,
        width: '100%',
        columns: this.columns,
        licenseKey: 'non-commercial-and-evaluation',
        cells(row, col) {
          //console.log(`row ${row} col ${col}`);
          //console.log(this.instance.getData());
          if(this.instance.countCols() == 0 || this.instance.countRows() == 0) return;
          let cp = {};
          //console.log(this.instance.getData(row, col));
          if(col === 0 && vm.originInstrumentList.findIndex(ele=>ele.name === this.instance.getData()[row][col]) >= 0){
            cp.readOnly = true;
          }
          return cp;
        },
        async beforeChange(changes, source) {
          // [[row, prop, oldVal, newVal], ...]
          //changes[0][3] = 10;
          for(let change of changes){
            if(change[1]=='startDate'){
              if(!isNaN(Number(change[3])) || isNaN(new Date(change[3]))){
                vm.$notifyError('請輸入正確的日期');
                change[3] = '';
              }
              else{
                change[3] = moment(change[3]).format('yyyy-MM-DD HH:mm');
              }
            }
          }
        },
      };
    },
    isSelectInstrumentType(){
      return this.getInstrumentType;
    },
    getInstrumentType(){
      return this.instrumentTypeList.find(ele=>{
        return ele.name === this.instrumentType;
      });
    },
    columns() {
      return  this.col.filter(ele => {
        let insTypeObjKey = Object.keys(this.getInstrumentType);
        return insTypeObjKey.includes(ele.data) || this.constCol.includes(ele.data);
      });
    },
    columnsHeader() {
      let col = this.col.map((ele, index)=>{
        return {
          data: ele.data,
          index,
        };
      }).filter(ele => {
        let insTypeObjKey = Object.keys(this.getInstrumentType);
        return insTypeObjKey.includes(ele.data) || this.constCol.includes(ele.data);
      });
      return this.colHeader.filter((ele, index)=>{
        return col.findIndex(c=> c.index == index) >= 0;
      });
    },
    isContinuityType() {
      return this.instrumentType === '傾斜儀';
    },
  },
  data(){
    return {
      constCol: [
        'name',
        'area',
        'station',
        'startDate',
        'unit',
        'WL_plus',
        'WL_minus',
        'AL_plus',
        'AL_minus',
        'WSL_plus',
        'WSL_minus',
      ],
      col: [
        {
          data: 'name',
        },
        {
          data: 'area',
        },
        {
          data: 'station',
        },
        {
          data: 'startDate',
        },
        {
          data: 'unit',
        },
        {
          data: 'R0',
        },
        {
          data: 'A+',
        },
        {
          data: 'A-',
        },
        {
          data: 'offset',
        },
        {
          data: 'T0',
        },
        {
          data: 'K',
        },
        {
          data: 'F0',
        },
        {
          data: 'F1',
        },
        {
          data: 'F2',
        },
        {
          data: 'F3',
        },
        {
          data: 'F4',
        },
        {
          data: 'F5',
        },

        {
          data: 'WL_plus',
        },
        {
          data: 'WL_minus',
        },
        {
          data: 'AL_plus',
        },
        {
          data: 'AL_minus',
        },
        {
          data: 'WSL_plus',
        },
        {
          data: 'WSL_minus',
        },
      ],
      colHeader: [
        '儀器名稱',
        '區域',
        '測站',
        '初值日期',
        '單位',
        '初值',
        'A+',
        'A-',
        '位移量',
        'T0',
        'K',
        'F0',
        'F1',
        'F2',
        'F3',
        'F4',
        'F5',
        '注意值(+)',
        '注意值(-)',
        '警戒值(+)',
        '警戒值(-)',
        '行動值(+)',
        '行動值(-)',
      ],

      loading: false,
      instrumentLoading: false,
      typeLoading: false,

      instrumentTypeList: [],
      instrumentType: '',
      listMinCount: 20,
      instrumentList: [],
      originInstrumentList: [],

      instrumentOptions: [],
      selectedInstrumentList: [],
      props: {
        multiple: true,
        //expandTrigger: 'hover',
        label: 'label',
        value: 'value',
        children: 'children',
      },
    };
  },
  mounted(){
    this.getInstrumentTypeList();
    this.getInstruments();
  },
  watch: {
    instrumentLoading() {
      this.loading = this.instrumentLoading || this.typeLoading;
    },
    typeLoading() {
      this.loading = this.instrumentLoading || this.typeLoading;
    },
  },
  methods: {
    async getInstrumentTypeList(){
      this.typeLoading = true;
      const res = await this.$axios({
        category: 'admin',
        method: 'get',
        apiName: 'instrument_type',
      });
      this.instrumentTypeList = res.data.filter(ele => ele.manual).map(ele=>{
        return {
          ...ele,
          value: ele.name,
          label: ele.name,
        };
      });
      
      this.typeLoading = false;
    },
    async getInstruments(){
      this.instrumentLoading = true;
      const res = await this.$axios({
        category: 'manual',
        method: 'get',
        apiName: `getManualInstrument/${this.selectedProject._id}`,
      });
      this.originInstrumentList = res.data.reduce((acc,cur)=>{
        cur.data.forEach(ele=>{
          acc.push({
            _id: cur._id,
            name: ele.name,
            area: cur.area,
            station: cur.station,
            startDate: moment(ele.startDate).format('yyyy-MM-DD HH:mm'),
            unit: ele.unit,
            R0: ele.R0,
            offset: ele.offset,
            T0: ele.T0,
            K : ele.K,
            F0: ele.F0,
            F1: ele.F1,
            F2: ele.F2,
            F3: ele.F3,
            F4: ele.F4,
            F5: ele.F5,
            'A+': ele['A+'],
            'A-': ele['A-'],
            WL_plus: ele.WL_plus,
            WL_minus: ele.WL_minus,
            AL_plus: ele.AL_plus,
            AL_minus: ele.AL_minus,
            WSL_plus: ele.WSL_plus,
            WSL_minus: ele.WSL_minus,
            instrument_type: cur.instrument_type,
          });
        });
        return acc;
      },[]);
      
      this.originInstrumentList = this.originInstrumentList.sort((a, b)=>{
        if(a.name === b.name) return a.startDate - b.startDate;
        return a.name - b.name;
      });
      
      this.initCascaderSelector();
      
      this.instrumentLoading = false;
    },
    initCascaderSelector() {
      this.instrumentOptions = [];
      this.selectedInstrumentList = [];

      let areas = {};

      this.originInstrumentList.forEach(ins=>{
        if(areas[ins.area]){
          areas[ins.area].push(ins);
        }
        else areas[ins.area] = [ins];
      });

      let instrumentOptions = [];

      for(let key of Object.keys(areas)){
        areas[key] = areas[key].reduce((acc,cur)=>{
          let index = acc.stations.findIndex(ele => ele.station === cur.station);
          if(index < 0){
            acc.stations.push({
              station: cur.station,
              instruments: [cur],
            });
          }
          else{
            if(acc.stations[index].instruments.findIndex(ele=> ele.name === cur.name) < 0)
              acc.stations[index].instruments.push(cur);
          }
          return acc;
        },{ stations: []});

        instrumentOptions.push({
          label: key,
          //disabled: areas[key].stations.length === 0,
          children: areas[key].stations.map((station)=>{
            return {
              label: station.station,
              //disabled: station.instruments.length === 0,
              children: station.instruments.map(ins=>{
                return {
                  label: ins.name,
                  value: ins.name,
                };
              }),
            };
          }),
        });
      }

      this.instrumentOptions = instrumentOptions;
    },
    newInstrument(){
      this.instrumentList.push({});
    },
    async uploadInstrument(){
      this.instrumentLoading = true;
      for(let index in this.instrumentList){
        let ins = this.instrumentList[index];
        let isnull = Object.keys(ins).length == 0 || Object.values(ins).reduce((accumulator, currentValue) => accumulator && [''].includes(currentValue),true);
        if(isnull) continue;
        if([null,undefined,''].includes(ins.name)){
          this.$notifyError('未輸入儀器名稱');
          this.instrumentLoading = false;
          return;
        }
        if([null,undefined,''].includes(ins.startDate)){
          this.$notifyError('未輸入初值日期');
          this.instrumentLoading = false;
          return;
        }
        if([null,undefined,''].includes(ins.area)){
          this.$notifyInfo('未輸入區域名稱');
        }
        if([null,undefined,''].includes(ins.station)){
          this.$notifyInfo('未輸入測站名稱');
        }
        let flag = false;
        for(let j = 0; j < index; j++){
          flag = flag || (this.instrumentList[j].name == ins.name && this.instrumentList[j].startDate == ins.startDate);
        }
        if(flag){
          this.$notifyError('存在相同初值日期的儀器名稱');
          this.instrumentLoading = false;
          return;
        }
        if(['',null,undefined,NaN].includes(ins.F1)) ins.F1 = '1';
      }

      let instrumentList = this.instrumentList.filter(ins=>{
        let isnull = Object.keys(ins).length == 0 || Object.values(ins).reduce((accumulator, currentValue) => accumulator && [''].includes(currentValue),true);
        return !isnull;
      });
      await this.$axios({
        category: 'manual',
        method: 'put',
        apiName: 'addManualInstrument',
        data: {
          insturments: instrumentList,
          project_id: this.selectedProject._id,
          instrumentType: this.getInstrumentType,
        },
      });
      await this.getInstruments();
      this.$notifySuccess('新增成功');
      this.instrumentLoading = false;
    },
    changeInstrumentType(){
      //console.log(this.getInstrumentType);
      if(!this.getInstrumentType) return;

      this.instrumentList = this.originInstrumentList.filter(ele=> {
        //console.log(ele.instrument_type);
        return ele.instrument_type._id == this.getInstrumentType._id && ((this.selectedInstrumentList.length > 0) ? this.selectedInstrumentList.findIndex( ins => ins[2] === ele.name) >= 0 : true);
      });

      for(let i = this.instrumentList.length; i < this.listMinCount;i++){
        this.instrumentList.push(({}));
      }
      this.updateTable();
    },
    getCascaderInstrumentList(){
      this.changeInstrumentType();
    },
    updateTable() {
      if(this.isSelectInstrumentType && !this.isContinuityType)
        setTimeout(() => {
          this.$refs.hot.hotInstance.loadData(this.instrumentList);
        }, 100);
    },
  },
};
</script>
  
<style>

</style>