import colorPalette from './colorPalette.js';

const {
  COLOR_LANDSCAPE,
  COLOR_BORDERS,
  COLOR_WATER,
  COLOR_LINE,
  COLOR_POINT_FILL,
  COLOR_SELECTED_POINT,
  COLOR_YELLOW,
  COLOR_RED,
  COLOR_GREEN,
  COLOR_BLUE,
  COLOR_GREY,
  COLOR_HEAVY_RAIN,
  COLOR_TORRENTIAL_RAIN,
  COLOR_SUPER_TORRENTIAL_RAIN,
  COLOR_EXTREME_TORRENTIAL_RAIN,
} = colorPalette;

const COLORS = {
  BORDERS: COLOR_BORDERS,
  LANDSCAPE: COLOR_LANDSCAPE,
  LINE: COLOR_LINE,
  POINT: COLOR_SELECTED_POINT,
  POINT_FILL: COLOR_POINT_FILL,
  WATER: COLOR_WATER,
  ALERT: COLOR_YELLOW,
  SUSPENSION: COLOR_RED,
  GREEN: COLOR_GREEN,
  WARNING: COLOR_BLUE,
  GREY: COLOR_GREY,
  HEAVY_RAIN: COLOR_HEAVY_RAIN,
  TORRENTIAL_RAIN: COLOR_TORRENTIAL_RAIN,
  SUPER_TORRENTIAL_RAIN: COLOR_SUPER_TORRENTIAL_RAIN,
  EXTREME_TORRENTIAL_RAIN: COLOR_EXTREME_TORRENTIAL_RAIN,
};

const POINT_MARKER_ICON_SUSPENSION_CONFIG = {
  // path:
  // 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
  path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
  strokeOpacity: 1,
  strokeWeight: 4,
  strokeColor: COLORS.SUSPENSION,
  fillColor: COLORS.SUSPENSION,
  fillOpacity: 1,
  scale: 2.5,
  // labelOrigin: {
  //   x: 0,
  //   y: -28
  // }, // 设置label中心位置，使数字在marker icon内居中
  // anchor: {
  //   x: 0,
  //   y: 34
  // },
  // scale: 0.7
};

const POINT_MARKER_ICON_WARNING_CONFIG = {
  path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
  strokeOpacity: 1,
  strokeWeight: 4,
  strokeColor: COLORS.WARNING,
  fillColor: COLORS.WARNING,
  fillOpacity: 1,
  scale: 2.5,
};
const POINT_MARKER_ICON_ALERT_CONFIG = {
  path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
  strokeOpacity: 1,
  strokeWeight: 4,
  strokeColor: COLORS.ALERT,
  fillColor: COLORS.ALERT,
  fillOpacity: 1,
  scale: 2.5,
};
const POINT_MARKER_ICON_GREEN_CONFIG = {
  path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
  strokeOpacity: 1,
  strokeWeight: 4,
  strokeColor: COLORS.GREEN,
  fillColor: COLORS.GREEN,
  fillOpacity: 1,
  scale: 2.5,
};

const LINE_SYMBOL_CONFIG = {
  path: 'M 0,-2 0,2',
  strokeOpacity: 1,
  strokeWeight: 2,
  scale: 1,
};

const LINE_PATH_CONFIG = {
  clickable: false,
  geodesic: false,
  strokeOpacity: 0,
  strokeColor: COLORS.LINE,
  icons: [
    {
      icon: LINE_SYMBOL_CONFIG,
      repeat: '10px',
    },
  ],
};

const mapSettings = {
  clickableIcons: false,
  // disableDefaultUI: true,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  panControlOptions: false,
  // scrollwheel: true,
  gestureHandling: 'greedy',
  // backgroundColor: COLORS.LANDSCAPE,
  zoomControlOptions: {
    style: 'SMALL',
  },
  zoom: 5,
  minZoom: 2,
  // maxZoom: 8,
  styles: [
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.business',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.local',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
};

export {
  mapSettings,
  LINE_PATH_CONFIG,
  POINT_MARKER_ICON_GREEN_CONFIG,
  POINT_MARKER_ICON_ALERT_CONFIG,
  POINT_MARKER_ICON_WARNING_CONFIG,
  POINT_MARKER_ICON_SUSPENSION_CONFIG,
  COLORS,
};
