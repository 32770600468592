const chartTypeList = [
  {
    label: 'XY Chart',
    value: 'line',
  },
  {
    label: 'Histogram',
    value: 'column',
  },
];

const convertChartType = value => {
  return chartTypeList.find(ele => ele.value === value).label;
};

export { chartTypeList, convertChartType };
