<template>
  <div>
    <el-card class="mb-16">
      <div class="columns ">
        <div class="column is-narrow">
          <label class="label">選擇專案</label>
          <el-select v-model="form.project" @change="getInstrumentList" filterable>
            <el-option
              v-for="project in projectList"
              :key="project._id"
              :value="project._id"
              :label="project.project_id"
            />
          </el-select>
        </div>
        <div class="column is-narrow">
          <label class="label">選擇儀器</label>
          <el-select
            v-model="form.instrument"
            :loading="loadingInstrument"
            @change="getInstrumentRemark"
            filterable
          >
            <el-option
              v-for="instrument in instrumentList"
              :key="instrument._id"
              :value="instrument._id"
              :label="instrument.latestVersion.name"
            />
          </el-select>
        </div>
      </div>
    </el-card>
    <!-- <el-card
      v-if="form.instrument && selectedInstrument"
      v-loading="isLoading"
    > -->
    <enhanced-table
      v-if="form.instrument && selectedInstrument"
      :table-data="remarkList"
      :col-configs="colConfigs"
      :is-loading="isLoading"
      :title="selectedInstrument.latestVersion.name"
    >
      <div slot="header" class="table-header flex-row justify-between">
        <el-button @click="showCreateForm" type="primary">
          新增
        </el-button>
      </div>
      <template v-slot:actions="{ colConfig }">
        <el-table-column v-bind="colConfig">
          <template v-slot="{ row }">
            <el-button @click="showEditForm(row._id)" size="mini" type="warning">
              修改
            </el-button>
            <el-button @click="deleteInstrumentRemark(row._id)" size="mini" type="danger">
              刪除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </enhanced-table>
    <!-- </el-card> -->
    <remark-form
      v-if="showCreateRemark"
      :editing-remark="editingRemark"
      :instrument="form.instrument"
      :form-type="formType"
      @close="handleFormClose"
      @success="getInstrumentRemark"
    />
  </div>
</template>

<script>
import RemarkForm from '@/components/remark/CreateForm.vue';
import EnhancedTable from '@/components/common/EnhancedTable';
import { convertRemarkType } from '@/lib/base/remark';
export default {
  components: {
    RemarkForm,
    EnhancedTable,
  },
  data() {
    const vm = this;
    return {
      isLoading: false,
      showCreateRemark: false,
      loadingInstrument: false,
      form: {
        project: '',
        instrument: '',
      },
      editingRemark: {},
      formType: 'create',
      instrumentList: [],
      remarkList: [],
      colConfigs: [
        {
          label: '類型',
          prop: 'type',
          formatter(column, row, cellValue) {
            return vm.$t(convertRemarkType(cellValue));
          },
        },
        {
          label: '內容',
          prop: 'content',
        },
        {
          label: '開始時間',
          prop: 'start_date',
          formatter(column, row, cellValue) {
            return vm.$moment(cellValue).format('YYYY-MM-DD HH:mm');
          },
        },
        {
          label: '結束時間',
          prop: 'end_date',
          formatter(column, row, cellValue) {
            return vm.$moment(cellValue).format('YYYY-MM-DD HH:mm');
          },
        },
        { slot: 'actions', width: '150px' },
      ],
    };
  },
  computed: {
    projectList() {
      return this.$store.getters['project/projectList'];
    },
    selectedInstrument() {
      if (this.instrumentList.length > 0 && this.form.instrument) {
        return this.instrumentList.find(ele => ele._id === this.form.instrument);
      } else {
        return {};
      }
    },
  },
  methods: {
    async deleteInstrumentRemark(remarkId) {
      const response = await this.$confirmDelete('是否删除此備註？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      });
      if (response === 'confirm') {
        this.isLoading = true;
        this.$axios({
          category: 'admin',
          method: 'delete',
          apiName: `instrument/${this.form.instrument}/remark/${remarkId}`,
        })
          .then(() => {
            this.$notifySuccess('刪除成功');
            this.getInstrumentRemark();
          })
          .catch(this.$notifyError)
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleFormClose() {
      this.showCreateRemark = false;
      this.editingRemark = {};
    },
    showEditForm(remarkId) {
      this.formType = 'edit';
      const remark = this.remarkList.find(ele => ele._id === remarkId);
      this.editingRemark = remark;
      this.showCreateRemark = true;
    },
    showCreateForm() {
      this.formType = 'create';
      this.showCreateRemark = true;
    },
    getInstrumentRemark() {
      this.isLoading = true;
      this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.form.project}/instrument/remark?type=all`,
        data: {
          instrument: this.form.instrument,
        },
      })
        .then(res => {
          this.remarkList = res.data;
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    getInstrumentList() {
      this.loadingInstrument = true;
      this.instrumentList = [];
      this.form.instrument = '';
      this.$store
        .dispatch('data/getProjectInstrumentListWithLatestVersion', {
          projectId: this.form.project,
          isUpdate: true,
        })
        .then(res => {
          this.instrumentList = res;
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.loadingInstrument = false;
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.table-header
  width 100%
  margin-right 16px
</style>
