export default {
  COLOR_GREEN: '#3ED154',
  COLOR_YELLOW: '#FFAD00',
  COLOR_RED: 'rgb(255,0,0)',
  COLOR_GREY: '#bbb',
  COLOR_BLUE: 'rgb(65,136,255)',
  COLOR_POINT: 'rgb(243,114,114)',
  COLOR_POINT_FILL: 'rgb(255,255,255)',
  COLOR_LANDSCAPE: '#FFAD00',
  // COLOR_LANDSCAPE: '#ebe3cd',
  COLOR_BORDERS: 'rgb(195,230,255)',
  COLOR_LINE: 'rgb(255,255,255)',
  COLOR_SELECTED_POINT: 'rgb(0,184,83)',
  COLOR_BROWN: 'rgb(139,69,19)',
  COLOR_BLACK: 'rgb(0,0,0)',
  COLOR_LIGHT_BLUE: 'rgb(124,156,201)',
  COLOR_WHITE: 'rgb(255,255,255)',
  COLOR_WHITEY: 'rgb(240,240,240)',
  // COLOR_GREEN: 'rgb(0,155,77)',
  COLOR_TOMATO: 'rgb(243,114,114)',

  COLOR_EXTREME_TORRENTIAL_RAIN: 'rgb(255,0,255)',
  COLOR_SUPER_TORRENTIAL_RAIN: 'rgb(255,0,0)',
  COLOR_TORRENTIAL_RAIN: 'rgb(255,102,0)',
  COLOR_HEAVY_RAIN: 'rgb(255,255,153)',

  GRID_LINE_COLOR_LIST: [
    '#96F8EC', // blue
    '#D2B6D2', // purple
    '#98F898', // green
    '#FF94A6', // red
    '#F3F196', // yellow
  ],
  TOP_GRID_LINE_COLOR: '#A3A3A3', // gray
};
