<template>
  <el-dialog
    v-loading="formLoading"
    :visible="true"
    :center="true"
    :title="formTitle"
    @close="closeModal"
  >
    <el-form ref="form" :model="form" label-position="top">
      <el-form-item :label="$t('remark.date')" prop="dateRange">
        <el-date-picker
          v-model="form.dateRange"
          :default-time="['12:00:00']"
          :start-placeholder="$t('remark.placeholder.date.start')"
          :end-placeholder="$t('remark.placeholder.date.end')"
          format="yyyy/MM/dd HH:mm"
          type="datetimerange"
        />
      </el-form-item>
      <el-form-item :label="$t('remark.type')" prop="type">
        <el-select v-model="form.type" :placeholder="$t('remark.placeholder.type')">
          <el-option
            v-for="type in remarkTypeList"
            :label="$t(type.label)"
            :key="type.value"
            :value="type.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('remark.content')" prop="content">
        <el-input
          v-model="form.content"
          :placeholder="$t('remark.placeholder.content')"
          type="text"
        />
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="closeModal">{{ $t('remark.form.cancel') }}</el-button>
      <el-button @click="createInstrumentRemark" v-if="formType === 'create'" type="primary">{{
        $t('remark.form.confirm')
      }}</el-button>
      <el-button @click="editInstrumentRemark" v-if="formType === 'edit'" type="primary">{{
        $t('remark.form.update')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { remarkTypeList } from '@/lib/base/remark';
export default {
  props: {
    formType: {
      type: String,
      default: 'create',
      required: true,
    },
    editingRemark: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    instrument: {
      type: String,
      required: true,
    },
    timestamp: {
      type: [Number, Date],
      required: false,
      default: Date.now(),
    },
  },
  data() {
    return {
      formLoading: false,
      form: {
        content: '',
        dateRange: [],
        project: '',
        type: '',
        _id: '',
      },
      rules: {
        dateRange: [
          {
            type: 'array',
            required: true,
            message: this.$t('chart.error.date'),
            trigger: 'change',
          },
        ],
        type: [
          {
            required: true,
            message: this.$t('remark.rules.type'),
            trigger: 'blur',
          },
        ],
        content: [
          {
            required: true,
            message: this.$t('remark.rules.content'),
            trigger: 'blur',
          },
        ],
      },
      remarkTypeList,
    };
  },
  computed: {
    formTitle() {
      if (this.formType === 'create') {
        return this.$t('remark.form.create');
      } else {
        return this.$t('remark.form.edit');
      }
    },
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
  mounted() {
    if (this.formType === 'edit') {
      this.form.content = this.editingRemark.content;
      this.form.dateRange = [this.editingRemark.start_date, this.editingRemark.end_date];
      this.form.type = this.editingRemark.type;
      this.form._id = this.editingRemark._id;
    }
    if (this.formType === 'create') {
      if (this.timestamp) {
        this.form.dateRange = [this.timestamp, this.timestamp];
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    createInstrumentRemark() {
      this.formLoading = true;
      this.$axios({
        category: 'project',
        method: 'post',
        apiName: `${this.selectedProject._id}/instrument/${this.instrument}/remark`,
        data: {
          content: this.form.content,
          start_date: this.form.dateRange[0],
          end_date: this.form.dateRange[1],
          type: this.form.type,
        },
      })
        .then(() => {
          this.$notifySuccess(this.$t('remark.form.success'));
          this.$emit('success');
          this.closeModal();
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    editInstrumentRemark() {
      this.formLoading = true;
      this.$axios({
        category: 'project',
        method: 'put',
        apiName: `${this.selectedProject._id}/instrument/${this.instrument}/remark/${this.form._id}`,
        data: {
          content: this.form.content,
          start_date: this.form.dateRange[0],
          end_date: this.form.dateRange[1],
          type: this.form.type,
        },
      })
        .then(() => {
          this.$notifySuccess(this.$t('remark.form.success'));
          this.$emit('success');
          this.closeModal();
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
};
</script>
