<template>
  <el-tabs v-model="selectedTab">
    <!-- <el-tab-pane
      name="list"
      label="列表"
    >
      <instrument-list></instrument-list>
    </el-tab-pane> -->
    <el-tab-pane
      name="create"
      label="新增"
      :lazy="true"
    >
      <instrument-group-form form-type="create" />
    </el-tab-pane>
    <el-tab-pane
      name="edit"
      label="編輯"
      :lazy="true"
    >
      <instrument-group-form form-type="edit" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import InstrumentGroupForm from '@/components/instrumentGroup/Form';
// import InstrumentList from '@/components/instrument/List';
export default {
  components: {
    InstrumentGroupForm,
    // InstrumentList,
  },
  data() {
    return {
      selectedTab: 'edit',
    };
  },
};
</script>
