<template>
  <div v-loading="isLoading">
    <el-tabs v-model="activeTab" v-if="imageList && imageList.length" type="border-card">
      <el-tab-pane
        v-for="(file, index) in imageList"
        :label="(index + 1).toString()"
        :name="(index + 1).toString()"
        :key="file._id"
      >
        <image-marker :file="file" :disable-edit="true" v-if="activeTab == index + 1" />
        <div class="content" style="margin-top:1rem;">
          <h3>{{ file.description }}</h3>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-card v-else>
      <div class="flex-row align-center justify-center" style="min-height: 400px;">
        <div class="content">
          <h3>{{ $t('data.none') }}</h3>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import ImageMarker from '@/components/pin/ImageMarker';
export default {
  components: {
    ImageMarker,
  },
  data() {
    return {
      imageList: null,
      refreshFunction: null,
      isLoading: true,
      activeTab: '1',
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    timezone() {
      return this.$store.getters['env/timezone'];
    },
  },
  mounted() {
    this.awaitForGetProjectImage();
    this.refreshFunction = setInterval(() => {
      this.awaitForGetProjectImage();
    }, 1000 * 60 * 1);
  },
  beforeDestroy() {
    clearInterval(this.refreshFunction);
  },
  methods: {
    async getProjectImage() {
      try{
        const res = await this.$axios({
          category: 'project',
          method: 'get',
          apiName: `${this.selectedProject._id}/image`,
        });
        //  .then(async res => {
        //    this.imageList = res.data.map(ele => {
        //      return {
        //        _id: ele._id,
        //        name: ele.name,
        //        // url: ele.path,
        //        url: `${process.env.VUE_APP_IMAGE_BASE_URL}/layout/${ele.path}`,
        //        description: ele.description,
        //        pin: ele.pin,
        //      };
        //    });
        //    await this.getVelocity(this.imageList);
        //    //console.log(this.imageList);
        //  })
        //  .catch(this.$notifyError);
        //.finally(() => (console.log(this.isLoading)));

        //this.imageList.forEach(image=>{
        //  image.pin.sort((a, b)=>{
        //    if(a._id < b._id) return -1;
        //    if(a._id > b._id) return 1;
        //    return 0;
        //  });
        //});
        this.imageList = res.data.map((ele, index) => {
          /*sort((a, b) => {
            if(a._id < b._id) return -1;
            if(a._id > b._id) return 1;
            return 0;
          }).*/
          let pins = ele.pin.map((p, pi)=>{
            p.instrument.forEach((ins, ii)=>{
              if(!this.imageList || !this.imageList[index] || !this.imageList[index].pin || !this.imageList[index].pin[pi] || !this.imageList[index].pin[pi].instrument || !this.imageList[index].pin[pi].instrument[ii]) return;
              ins.velocity1Hr = this.imageList[index].pin[pi].instrument[ii].velocity1Hr ;
              ins.velocity24Hr = this.imageList[index].pin[pi].instrument[ii].velocity24Hr ;
            });
            return p;
          });
          return {
            _id: ele._id,
            name: ele.name,
            // url: ele.path,
            url: `${process.env.VUE_APP_IMAGE_BASE_URL}/layout/${ele.path}`,
            description: ele.description,
            pin: pins,
            //pin: ele.pin,
          };
        });
        //console.log(this.imageList);
        await this.getVelocity(this.imageList);
      }
      catch(e){
        console.log(e);
        this.$notifyError();
      }
    },
    awaitForGetProjectImage() {
      this.isLoading = true;
      this.getProjectImage().then(()=>{
        this.isLoading = false;
      });
    },
    async getVelocity(imageList){
      //const showList = this.selectedProject.config.data.display;
      //if(showList.includes('velocity1Hr')){
      //  await this.getVelocityOfHours(imageList, 1);
      //}
      //if(showList.includes('velocity24Hr')){
      //  await this.getVelocityOfHours(imageList, 24);
      //}
      console.log('test');
      await this.getVelocityOfHours(imageList, 1);
      await this.getVelocityOfHours(imageList, 24);
      /*
      //按照更新時間分類
      let instrumentTimeStampDict = {};
      this.areaList.forEach(area=>{
        this.tableData[area._id].forEach(instrument => {
          if(instrumentTimeStampDict[instrument.TIMESTAMP]) instrumentTimeStampDict[instrument.TIMESTAMP].push(instrument._id);
          else instrumentTimeStampDict[instrument.TIMESTAMP] = [instrument._id];
        });
      });
      //為每個儀器抓取相互比較的數據
      let velocityCompareDatas = {};
      for await(let [TIMESTAMP, instrumentList] of Object.entries(instrumentTimeStampDict)){
        //為儀器的速率去抓取上個時間段/上個小時/上一天的數據
        //let instrumentTime = new Date(TIMESTAMP).getTime();
        let velocityCompareTime = new Date(TIMESTAMP);
        velocityCompareTime.setHours( velocityCompareTime.getHours() - 1 );
        velocityCompareTime = velocityCompareTime.getTime();
        //console.log(`get time: ${velocityCompareTime} - ${instrumentTime}`);
        //console.log(instrumentList);
        const result = await this.$axios({
          category: 'project',
          method: 'post',
          apiName: `${this.selectedProject._id}/output`,
          data: {
            date: [velocityCompareTime, velocityCompareTime],
            interval: 'all',
            instrument: instrumentList,
            timezone: this.timezone,
          },
        });

        if(result.data.length > 0){
          result.data.forEach(data => {
            const { instrument_id, reading2 } = data;
            velocityCompareDatas[instrument_id] = reading2;
          });
        }
      }
      //產生速率值
      this.areaList.forEach(area => {
        this.tableData[area._id].forEach(instrument => {
          //計算速率
          instrument.velocity1Hr = ((instrument.reading2 || instrument.reading2 === 0) && (velocityCompareDatas[instrument._id] || velocityCompareDatas[instrument._id] === 0)) ? (
            (instrument.reading2 - velocityCompareDatas[instrument._id]) / 1
          ).toFixed(4) : null;
        });
      });*/
    },
    async getVelocityOfHours(imageList, hours){
      for await(let image of imageList){
        for await(let pin of image.pin){
          let instruments = pin.instrument;
          let timeClassificationInstrument = {};
          let timeList = [];
          for await(let ins of instruments){
            if(!ins.last_reading) continue;
            if(!timeClassificationInstrument[ins.last_reading.TIMESTAMP]){
              timeClassificationInstrument[ins.last_reading.TIMESTAMP] = [ins];
              timeList.push(ins.last_reading.TIMESTAMP);
            }
            else timeClassificationInstrument[ins.last_reading.TIMESTAMP].push(ins);
          }
          let velocityCompareDatas = {};
          for await(let t of timeList){
            let instrumentList = timeClassificationInstrument[t];
            let instrumentIDList = instrumentList.filter(ins=>{
              return ins.data[ins.data.length-1].allowRate;
            }).map(ins=>{
              return ins._id;
            });
            let velocityCompareTime = new Date(t);
            velocityCompareTime.setHours( velocityCompareTime.getHours() - hours );
            velocityCompareTime = velocityCompareTime.getTime();
            const result = await this.$axios({
              category: 'project',
              method: 'post',
              apiName: `${this.selectedProject._id}/output`,
              data: {
                date: [velocityCompareTime, velocityCompareTime],
                interval: 'all',
                instrument: instrumentIDList,
                timezone: this.timezone,
              },
            });
            if(result.data.length > 0){
              result.data.forEach(data => {
                const { instrument_id, reading2 } = data;
                velocityCompareDatas[instrument_id] = reading2;
              });
            }
          }
          for (let instrument of instruments){
            if(!instrument.last_reading) instrument[`velocity${hours}Hr`] = null;
            else
              instrument[`velocity${hours}Hr`] = ((instrument.last_reading[instrument.display_output] || instrument.last_reading[instrument.display_output] === 0) && (velocityCompareDatas[instrument._id] || velocityCompareDatas[instrument._id] === 0)) ? (
                (instrument.last_reading[instrument.display_output] - velocityCompareDatas[instrument._id])
              ).toFixed(4) : null;
          }
        }
      }
    },
  },
};
</script>
