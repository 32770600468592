<template>
  <div v-loading="isLoading">
    <el-card>
      <el-collapse v-model="activeNames" v-if="!isLoading">
        <el-collapse-item
          v-for="(area, name, index) in eventList"
          :title="areasObj[name].name"
          :name="index"
          :key="name"
        >
          <div class="rain-chart-container flex-row align-center" style="flex-wrap: wrap;">
            <rain-fall-chart style="width: 800px;height: 600px;" :data="area"></rain-fall-chart>
            <img
              v-if="areasObj[name]._id === '5f8c706cdcfc04000a2137e6'"
              :src="require('@/assets/rain_gauge/linkou.png')"
            />
            <img
              v-if="areasObj[name]._id === '5f8c7075dcfc04000a2137e7'"
              :src="require('@/assets/rain_gauge/yangtao.png')"
            />
            <img
              v-if="areasObj[name]._id === '5f8c707ddcfc04000a2137e8'"
              :src="require('@/assets/rain_gauge/zhunan.png')"
            />
            <img
              v-if="areasObj[name]._id === '5f8c7084dcfc04000a2137e9'"
              :src="require('@/assets/rain_gauge/miaoli.png')"
            />
            <img
              v-if="areasObj[name]._id === '5f8c7090dcfc04000a2137ea'"
              :src="require('@/assets/rain_gauge/bagua.png')"
            />
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <!-- <div class="columns is-multiline is-narrow" v-if="!isLoading">
      <div
        class="column"
        style="max-width: 700px;"
        v-for="(area, name) in areaWithEvents"
        v-show="area.length > 0"
        :key="name"
      >
        <el-card>
          <div slot="header" class="clearfix">
            <div class="columns">
              <div class="column flex-column">
                <span>設定涵蓋邊坡</span>
                <span class="has-text-weight-bold">{{ areasObj[name].name }}</span>
              </div>
            </div>
          </div>
          <rain-fall-chart :data="area"></rain-fall-chart>
        </el-card>
      </div>
    </div> -->
  </div>
</template>

<script>
import RainFallChart from '@/components/chart/RainFallChart';

export default {
  components: {
    RainFallChart,
  },
  data() {
    return {
      project: null,
      activeNames: [0, 1, 2, 3, 4],
      isLoading: false,
      areaList: [],
      eventList: {},
    };
  },
  computed: {
    selectedProject() {
      return this.project;
    },
    areasObj() {
      return this.areaList.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {});
    },
  },
  created() {
    this.isLoading = true;
    // this.$store
    //   .dispatch('data/getAreaList')
    this.setProject()
      .then(() => {
        return  this.getProjectArea();
      })
      .then(() => {
        return this.getAreaEvents();
      })
      .then(() => {
        this.$forceUpdate();
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    setProject() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: '',
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      })
        .then(res => {
          this.project = res.data[0];
        })
        .catch(this.$notifyError);
    },
    getProjectArea() {
      return this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/area`,
        useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
      })
        .then(res => {
          this.areaList = res.data;
        })
        .catch(this.$notifyError);
    },
    getAreaEvents() {
      const promises = this.areaList.map(area => {
        console.log(area);
        return this.$axios({
          category: 'event',
          method: 'get',
          apiName: `/project/${this.selectedProject._id}/area/${area._id}/event`,
          useUrl: 'https://api-thsr-rain.geosupply.com.tw/',
        }).then(res => {
          this.eventList[area._id] = res.data;
        });
      });
      return Promise.all(promises);
    },
  },
};
</script>

<style lang="scss" scoped>
.rain-chart-container {
  img {
    height: 600px;
    width: auto;
    margin-left: 16px;
  }
}
</style>
